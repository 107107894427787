import React, { memo, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isArray, noop, isEmpty, isNil } from 'lodash';
import { useLocation } from 'react-router-dom';
// Import Components
import { DataTable } from 'components';
import { SegmentActionsModals, SegmentActionsButtons } from 'components/SegmentList';
// Import Hooks
import { useListBaseRequestBody, useGoToRoute, useToaster, useRequest, useQueryParams } from 'hooks';
// Import Services
import { SegmentHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Constants
import { ModalsActions, SegmentTypesLabels, TargetTypeEnum, l } from 'constants/common';
import { DataTableColumnsConfigs } from './config.js';
import { RoutesList } from 'routes';
// Import SCSS
import 'assets/scss/segmentListPage.scss';

const {
    SEGMENT_DYNAMIC_EDIT,
    SEGMENT_DYNAMIC_CLONE,
    SEGMENT_COMPOSITE_EDIT,
    SEGMENT_COMPOSITE_CLONE,
    CONVERT_TO_REPORT,
} = RoutesList;
const { getPageNumber } = Helpers;

// eslint-disable-next-line react/display-name
const SegmentList = forwardRef(
    (
        {
            getTitle,
            isViewMode,
            getHeaderActions,
            isShowVerticalDots,
            isShowColumnChooser,
            withManageLabels,
            rowsUpdateCallback,
            isHasRowMultiSelect,
            isHasBulkActions,
            listAction,
            listSelectorKey,
            isHasRowRadioSelect,
            getRadioSelectedRow,
            defaultRadioSelectedRow,
            getDragAcceptType,
            CustomPreview,
            dragDisable,
            tableKey,
            withQueryParams,
            dataTableClassNames,
        },
        ref,
    ) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const { goToRoute } = useGoToRoute();
        const { search } = useLocation();
        const { showToaster } = useToaster();
        const { doPostRequest, doGetRequest } = useRequest();

        const { tableData } = useSelector((state) => state[listSelectorKey]);
        const { filters, paging, sorting, sortingThen } = tableData;
        const isInit = useRef(false);
        const dataTableRef = useRef();
        const [
            baseRequestBody,
            // eslint-disable-next-line no-unused-vars
            newFilterField,
            setBaseRequestBodyFilters,
            setBaseRequestBodyPagingPageNumber,
            setBaseRequestBodyPagingPageSize,
            setBaseRequestBodySorting,
        ] = useListBaseRequestBody(filters, paging, sorting, sortingThen);

        const {
            // eslint-disable-next-line no-unused-vars
            setSegmentListTableFilters,
            setSegmentListTablePagingPageNumber,
            setSegmentListTablePagingPageSize,
            setSegmentListTableSorting,
            resetSegmentListState,
        } = listAction;

        const [isSegmentListLoading, setIsSegmentListLoading] = useState(false);
        const [segmentListFilters, setSegmentListFilters] = useState(baseRequestBody);
        const [segmentListData, setSegmentListData] = useState([]);
        const [segmentListDataCount, setSegmentListDataCount] = useState(0);

        const [isDeleteModalOpenedState, setIsDeleteModalOpenedState] = useState(false);
        const [isConvertToReportModalOpenedState, setIsConvertToReportModalOpenedState] = useState(false);
        const [isArchiveModalOpenedState, setIsArchiveModalOpenedState] = useState(false);
        const [isUnArchiveModalOpenedState, setIsUnArchiveModalOpenedState] = useState(false);
        const [isInfoModalOpenedState, setIsInfoModalOpenedState] = useState(false);
        const [openedModalData, setOpenedModalData] = useState({});
        const [isUsedInConfirmationModalOpenedState, setIsUsedInConfirmationModalOpenedState] = useState(false);

        const { columns } = DataTableColumnsConfigs(t);

        // Segment fetch part
        const { getSegmentsRequest, getCampaignsBySegmentRequest } = useRef({
            getSegmentsRequest: SegmentHttpService.getSegmentList(),
            getCampaignsBySegmentRequest: SegmentHttpService.getCampaignsBySegment(),
        }).current;

        const goToRouteQueryParams = useQueryParams();

        const getSegments = useCallback(
            (filter = segmentListFilters) => {
                setIsSegmentListLoading(true);

                doPostRequest(getSegmentsRequest.request, {
                    requestBody: filter,
                    successCallback: ({ Data, Count }) => {
                        const rows = getTableRows(Data || []);
                        setSegmentListData(rows);
                        rowsUpdateCallback(rows);
                        setSegmentListDataCount(Count);
                    },
                }).then(() => {
                    setIsSegmentListLoading(false);
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [segmentListFilters],
        );

        const goToEditSegment = (segment) => {
            const { Type, SegmentId } = segment;
            const params = { segmentId: SegmentId };
            const path = SegmentTypesLabels.Dynamic === Type ? SEGMENT_DYNAMIC_EDIT : SEGMENT_COMPOSITE_EDIT;

            goToRoute(path, params, goToRouteQueryParams.encode({ backParams: search }));
        };

        const goToConvertSegmentToReport = (segment) => {
            const { SegmentId } = segment;
            const params = { segmentId: SegmentId };
            const path = CONVERT_TO_REPORT;
            goToRoute(path, params, goToRouteQueryParams.encode({ backParams: search }));
        };

        // Actions part
        const deleteSegmentAction = (segment) => {
            setOpenedModalData(segment);
            setIsDeleteModalOpenedState(true);
        };

        const archiveSegmentAction = (segment) => {
            setOpenedModalData(segment);
            setIsArchiveModalOpenedState(true);
        };

        const unArchiveSegmentAction = (segment) => {
            setOpenedModalData(segment);
            setIsUnArchiveModalOpenedState(true);
        };

        const infoSegmentAction = (segment) => {
            setOpenedModalData(segment);
            setIsInfoModalOpenedState(true);
        };

        const cloneSegmentAction = (segment) => {
            const { Type, SegmentId } = segment;
            const params = { segmentId: SegmentId };
            if (SegmentTypesLabels.Dynamic === Type) {
                goToRoute(SEGMENT_DYNAMIC_CLONE, params);
            } else {
                goToRoute(SEGMENT_COMPOSITE_CLONE, params);
            }
        };

        const editSegmentAction = (segment) => {
            doGetRequest(getCampaignsBySegmentRequest.request, {
                queryString: { segmentId: segment.SegmentId },
                successCallback: (Data) => {
                    if (isEmpty(Data)) {
                        goToEditSegment(segment);
                    } else {
                        setOpenedModalData({ objects: Data.map(({ Name, Type }) => ({ Name, Type })), segment });
                        setIsUsedInConfirmationModalOpenedState(true);
                    }
                },
            });
        };

        const modalsStateSetDict = {
            deleteSegment: {
                key: 'deleteSegment',
                fn: setIsDeleteModalOpenedState,
            },
            convertToReport: {
                key: 'convertToReport',
                fn: setIsConvertToReportModalOpenedState,
            },
            archiveSegment: {
                key: 'archiveSegment',
                fn: setIsArchiveModalOpenedState,
            },
            unarchiveSegment: {
                key: 'unarchiveSegment',
                fn: setIsUnArchiveModalOpenedState,
            },
            infoSegment: {
                key: 'infoSegment',
                fn: setIsInfoModalOpenedState,
            },
            editSegment: {
                key: 'editSegment',
                fn: setIsUsedInConfirmationModalOpenedState,
            },
        };

        const onCloseModalHandler = (modalStateKey, action, alertType, alertMessage, count = 1) => {
            const isConverToReport = action === ModalsActions.CONVERT_TO_REPORT;

            if (
                action === ModalsActions.DELETE ||
                isConverToReport ||
                action === ModalsActions.ARCHIVE ||
                action === ModalsActions.UNARCHIVE
            ) {
                if (
                    !isConverToReport ||
                    (!openedModalData?.IsUsed && openedModalData?.Type === SegmentTypesLabels.Static)
                ) {
                    dataTableRef.current.resetSelectedRows();
                    dataTableRef.current.changePaginationPageNumber(
                        getPageNumber(
                            baseRequestBody.Filters,
                            action,
                            segmentListDataCount,
                            segmentListFilters.Pageing.PageSize,
                            segmentListFilters.Pageing.PageNumber,
                            count,
                        ),
                    );
                }
                showToaster(alertType, alertMessage);
            }

            modalsStateSetDict[modalStateKey].fn(false);
        };

        const getRowActionBar = (row) => {
            const { data } = row;

            return (
                <>
                    <SegmentActionsButtons
                        data={data}
                        isInfoVisible={true}
                        isViewMode={isViewMode}
                        convertToReportAction={goToConvertSegmentToReport}
                        unArchiveSegmentAction={unArchiveSegmentAction}
                        archiveSegmentAction={archiveSegmentAction}
                        deleteSegmentAction={deleteSegmentAction}
                        cloneSegmentAction={cloneSegmentAction}
                        editSegmentAction={editSegmentAction}
                        infoSegmentAction={infoSegmentAction}
                    />
                </>
            );
        };

        // Data table part
        const setPagingPageNumberHandler = (pageNumber) => {
            setBaseRequestBodyPagingPageNumber(pageNumber);
            dispatch(setSegmentListTablePagingPageNumber(pageNumber));
            setSegmentListFilters(baseRequestBody);
        };

        const setPagingPageSizeHandler = (pageSize) => {
            setBaseRequestBodyPagingPageSize(pageSize);
            dispatch(setSegmentListTablePagingPageSize(pageSize));
            setSegmentListFilters(baseRequestBody);
        };

        const setSortingHandler = (sortingDirection, columnName) => {
            setBaseRequestBodySorting(sortingDirection, columnName);
            dispatch(setSegmentListTableSorting(baseRequestBody.Sorting));
            setSegmentListFilters(baseRequestBody);
        };

        const getTableColumns = () => {
            return columns.map((item) => ({ ...item, sortable: item.sortable && dragDisable }));
        };

        const getTableRows = (segmentList) => {
            return segmentList.map(
                ({
                    Name,
                    Type,
                    IsUsed,
                    CreatorName,
                    State,
                    SegmentId,
                    DefinitionType,
                    CreatedDate,
                    CompositeConfiguration,
                    CategoryId,
                    CategoryName,
                    CategoryColor,
                    TargetObject,
                    ArchivedDate,
                }) => {
                    const isComposite = Type === SegmentTypesLabels.Composite;

                    return {
                        hasHover: true,
                        dragDisable: dragDisable,
                        isComposite: isComposite,
                        isRowExpandable: isComposite,
                        nestedData: [],
                        className: !isNil(ArchivedDate) ? 'crm-data-table-row-archive' : '',
                        data: {
                            isRowOpend: false,
                            State,
                            Name: { Name, TargetObject },
                            Type,
                            IsUsed,
                            'CreatedBy.Name': CreatorName,
                            SegmentId,
                            DefinitionType,
                            CreatedDate,
                            CompositeConfiguration,
                            CategoryId,
                            CategoryName,
                            CategoryColor,
                            TargetObject,
                            ArchivedDate,
                        },
                    };
                },
            );
        };

        const getTableRefreshFn = () => {
            getSegments(segmentListFilters);
        };

        const getDataTableName = () => <span>{t(l.Segments)}</span>;

        // Filter update part
        useEffect(() => {
            if (isInit.current) {
                getSegments(segmentListFilters);
            }
            /* eslint-disable react-hooks/exhaustive-deps */
        }, [segmentListFilters]);

        useEffect(() => {
            setBaseRequestBodyFilters(filters);
            setSegmentListFilters(baseRequestBody);

            isInit.current = true;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filters]);

        useImperativeHandle(ref, () => ({
            resetSelectedRows() {
                dataTableRef.current.resetSelectedRows();
            },
            reset() {
                dataTableRef.current.reset();
            },
        }));

        const cleanUp = () => {
            return () => {
                // Cancel all async processes
                getSegmentsRequest.cancel('SegmentListPage:getSegmentList');
                // Reset store state
                resetSegmentListState && dispatch(resetSegmentListState());
            };
        };

        useEffect(cleanUp, []);

        const isAllow = (segment) => {
            const { IsUsed, ArchivedDate } = segment;
            const isArchive = !isNil(ArchivedDate);

            return {
                delete: !IsUsed,
                archive: !isArchive,
                unarchive: isArchive,
            };
        };

        const getSegmentsNamesIds = (selectedRows) => {
            if (!isArray(selectedRows)) return;

            let allowDeleteSegments = [];
            let notAllowDeleteSegments = [];
            let allowArchiveSegments = [];
            let notAllowArchiveSegments = [];
            let allowUnarchiveSegments = [];
            let notAllowUnarchiveSegments = [];

            selectedRows.forEach(({ data }) => {
                const { SegmentId, Name } = data;
                const isAllowData = isAllow(data);
                if (isAllowData.delete) {
                    allowDeleteSegments.push({ segmentId: SegmentId, name: Name?.Name });
                } else {
                    notAllowDeleteSegments.push({ segmentId: SegmentId, name: Name?.Name });
                }
                if (isAllowData.archive) {
                    allowArchiveSegments.push({ segmentId: SegmentId, name: Name?.Name });
                } else {
                    notAllowArchiveSegments.push({ segmentId: SegmentId, name: Name?.Name });
                }

                if (isAllowData.unarchive) {
                    allowUnarchiveSegments.push({ segmentId: SegmentId, name: Name?.Name });
                } else {
                    notAllowUnarchiveSegments.push({ segmentId: SegmentId, name: Name?.Name });
                }
            });

            return {
                allowDeleteSegments,
                notAllowDeleteSegments,
                allowArchiveSegments,
                notAllowArchiveSegments,
                allowUnarchiveSegments,
                notAllowUnarchiveSegments,
            };
        };

        const deleteSelectedRows = (selectedRows) => {
            const segmentNamesIds = getSegmentsNamesIds(selectedRows);
            const namesIds = {
                allowDeleteSegments: segmentNamesIds.allowDeleteSegments,
                notAllowDeleteSegments: segmentNamesIds.notAllowDeleteSegments,
            };
            deleteSegmentAction(namesIds);
        };

        const archiveSelectedRows = (selectedRows) => {
            const segmentNamesIds = getSegmentsNamesIds(selectedRows);
            const namesIds = {
                allowArchiveSegments: segmentNamesIds.allowArchiveSegments,
                notAllowArchiveSegments: segmentNamesIds.notAllowArchiveSegments,
            };
            archiveSegmentAction(namesIds);
        };

        const unarchiveSelectedRows = (selectedRows) => {
            const segmentNamesIds = getSegmentsNamesIds(selectedRows);
            const namesIds = {
                allowUnarchiveSegments: segmentNamesIds.allowUnarchiveSegments,
                notAllowUnarchiveSegments: segmentNamesIds.notAllowUnarchiveSegments,
            };
            unArchiveSegmentAction(namesIds);
        };

        return (
            <>
                <DataTable
                    classNames={dataTableClassNames}
                    getTitle={getTitle}
                    rowKey="SegmentId"
                    columnKey="dataKey"
                    name={getDataTableName()}
                    isColumnsSortable={true}
                    tableKey={tableKey}
                    withPagination={true}
                    withManageLabels={withManageLabels}
                    setLabelObjectIdKey="SegmentId"
                    labelNameKey="CategoryName"
                    labelColorKey="CategoryColor"
                    labelObjectType={TargetTypeEnum.Segment}
                    data={segmentListData}
                    dataCount={segmentListDataCount}
                    rowActionBar={getRowActionBar}
                    columns={getTableColumns()}
                    isLoading={isSegmentListLoading}
                    isShowRefreshButton={true}
                    isShowColumnChooser={isShowColumnChooser}
                    isShowVerticalDots={isShowVerticalDots}
                    setPagingPageSize={setPagingPageSizeHandler}
                    setPagingPageNumber={setPagingPageNumberHandler}
                    sorting={
                        dragDisable
                            ? {
                                  name: segmentListFilters.Sorting?.Name,
                                  direction: segmentListFilters.Sorting?.Direction,
                              }
                            : {}
                    }
                    setSorting={setSortingHandler}
                    onRefreshClick={getTableRefreshFn}
                    headerActions={getHeaderActions()}
                    isHasRowMultiSelect={isHasRowMultiSelect}
                    isHasBulkActions={isHasBulkActions}
                    archiveSelectedRows={archiveSelectedRows}
                    unarchiveSelectedRows={unarchiveSelectedRows}
                    deleteSelectedRows={deleteSelectedRows}
                    isHasRowRadioSelect={isHasRowRadioSelect}
                    getRadioSelectedRow={getRadioSelectedRow}
                    defaultRadioSelectedRow={defaultRadioSelectedRow}
                    getDragAcceptType={getDragAcceptType}
                    CustomPreview={CustomPreview}
                    recevedCurrentPageNumber={segmentListFilters.Pageing.PageNumber}
                    withQueryParams={withQueryParams}
                    ref={dataTableRef}
                />

                <SegmentActionsModals
                    isDeleteModalOpenedState={isDeleteModalOpenedState}
                    isConvertToReportModalOpenedState={isConvertToReportModalOpenedState}
                    isArchiveModalOpenedState={isArchiveModalOpenedState}
                    isUnArchiveModalOpenedState={isUnArchiveModalOpenedState}
                    isInfoModalOpenedState={isInfoModalOpenedState}
                    isUsedInConfirmationModalOpenedState={isUsedInConfirmationModalOpenedState}
                    onCloseModalHandler={onCloseModalHandler}
                    modalsStateSetDict={modalsStateSetDict}
                    openedModalData={openedModalData}
                    goToEditSegment={goToEditSegment}
                />
            </>
        );
    },
);

SegmentList.propTypes = {
    listSelectorKey: PropTypes.string.isRequired,
    listAction: PropTypes.shape({
        setSegmentListTableFilters: PropTypes.func.isRequired,
        setSegmentListTablePagingPageNumber: PropTypes.func.isRequired,
        setSegmentListTablePagingPageSize: PropTypes.func.isRequired,
        setSegmentListTableSorting: PropTypes.func.isRequired,
        resetSegmentListState: PropTypes.func.isRequired,
    }).isRequired,
    getRadioSelectedRow: PropTypes.func,
    isShowVerticalDots: PropTypes.bool,
    withManageLabels: PropTypes.bool,
    isViewMode: PropTypes.bool,
    getHeaderActions: PropTypes.func,
    rowsUpdateCallback: PropTypes.func,
    isHasRowMultiSelect: PropTypes.bool,
    isHasRowRadioSelect: PropTypes.bool,
    isHasBulkActions: PropTypes.bool,
    isShowColumnChooser: PropTypes.bool,
    defaultRadioSelectedRow: PropTypes.object,
    getDragAcceptType: PropTypes.func,
    CustomPreview: PropTypes.node,
    dragDisable: PropTypes.bool,
    tableKey: PropTypes.string,
    withQueryParams: PropTypes.bool,
    dataTableClassNames: PropTypes.string,
    getTitle: PropTypes.func,
};

SegmentList.defaultProps = {
    getHeaderActions: noop,
    rowsUpdateCallback: noop,
    getRadioSelectedRow: noop,
    isShowVerticalDots: true,
    withManageLabels: false,
    isViewMode: false,
    isHasRowMultiSelect: false,
    isHasRowRadioSelect: false,
    isHasBulkActions: false,
    isShowColumnChooser: true,
    defaultRadioSelectedRow: null,
    getDragAcceptType: noop,
    dragDisable: false,
    tableKey: 'segmentListDataTable',
    withQueryParams: false,
    dataTableClassNames: 'default-height',
    getTitle: noop,
};

export default memo(SegmentList);
