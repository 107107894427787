/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isNil, isFunction, keys, cloneDeep, isString, isEmpty, trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import { infoItemsConfig } from './config';
// Import Constants
import { l } from 'constants/common';
//Import Components
import { KeyValueItems } from 'components';
//Import SCSS
import 'assets/scss/common.scss';

const CustomerJourneyGeneralDetails = (props) => {
    const { t } = useTranslation();

    const getInfoItems = useCallback(() => {
        return keys(props)
            .filter((propKey) => !isNil(infoItemsConfig[propKey]))
            .map((propKey) => {
                const itemConfig = cloneDeep(infoItemsConfig[propKey]);
                itemConfig.isLoading = propKey === 'usedIn' ? props.isLoadingUsedIn : props.isLoading;
                itemConfig.value =
                    isNil(props[propKey]) || (isString(props[propKey]) && isEmpty(trim(props[propKey])))
                        ? t(l.NA)
                        : isFunction(itemConfig.format)
                        ? itemConfig.format(props[propKey])
                        : isFunction(itemConfig.renderer)
                        ? itemConfig.renderer(props[propKey])
                        : props[propKey];
                return itemConfig;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <>
            <div className="key-value-items-details-section">
                <div className="title-wrapper">
                    <p className="title-wrapper-text">{t(l.GeneralDetails)}</p>
                    <span className="title-wrapper-line" />
                </div>
                <div className="general-details-content">
                    <div className="general-details-column">
                        <KeyValueItems items={getInfoItems()} />
                    </div>
                </div>
            </div>
        </>
    );
};
CustomerJourneyGeneralDetails.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    mode: PropTypes.number,
    status: PropTypes.number,
    User: PropTypes.string,
    createdDate: PropTypes.string,
    lastModified: PropTypes.string,
    lastModifier: PropTypes.string,
    usedIn: PropTypes.array,
    isLoadingUsedIn: PropTypes.bool,
    isLoading: PropTypes.bool,
    workflowId: PropTypes.number,
};
export default memo(CustomerJourneyGeneralDetails);
