import { lazy } from 'react';
import { l, RouteLeaveConfirmationType } from 'constants/common';
import RoutesList from './constants';

const {
    HOMEPAGE,
    DASHBOARD,
    SEGMENT_LIST,
    TEMPLATE_LIST,
    CAMPAIGN_LIST,
    SEGMENT_DYNAMIC_CREATE,
    SEGMENT_DYNAMIC_EDIT,
    SEGMENT_DYNAMIC_CLONE,
    SEGMENT_DYNAMIC_PREDEFINED,
    SEGMENT_STATIC_CREATE,
    SEGMENT_COMPOSITE_CREATE,
    SEGMENT_NON_CUSTOMER_CREATE,
    SEGMENT_COMPOSITE_CLONE,
    SEGMENT_COMPOSITE_EDIT,
    TEMPLATE_CREATE_EMAIL,
    TEMPLATE_CREATE_CLONE,
    TEMPLATE_CREATE_EDIT,
    TEMPLATE_PREDEFINED_EMAIL,
    TEMPLATE_CREATE_SMS,
    TEMPLATE_CLONE_SMS,
    TEMPLATE_EDIT_SMS,
    TEMPLATE_CREATE_HOORY,
    TEMPLATE_CLONE_HOORY,
    TEMPLATE_EDIT_HOORY,
    TEMPLATE_PREDEFINED_HOORY,
    TEMPLATE_CREATE_FASTEX_NOTIFICATION,
    TEMPLATE_CLONE_FASTEX_NOTIFICATION,
    TEMPLATE_EDIT_FASTEX_NOTIFICATION,
    TEMPLATE_PREDEFINED_FASTEX_NOTIFICATION,
    TEMPLATE_PREDEFINED_SMS,
    TEMPLATE_CREATE_INTERNAL_MESSAGE,
    TEMPLATE_CLONE_INTERNAL_MESSAGE,
    TEMPLATE_EDIT_INTERNAL_MESSAGE,
    TEMPLATE_PREDEFINED_INTERNAL_MESSAGE,
    TEMPLATE_CREATE_SOCIAL_TELEGRAM,
    TEMPLATE_CLONE_SOCIAL_TELEGRAM,
    TEMPLATE_EDIT_SOCIAL_TELEGRAM,
    TEMPLATE_PREDEFINED_SOCIAL_TELEGRAM,
    TEMPLATE_CREATE_SOCIAL_VIBER,
    TEMPLATE_EDIT_SOCIAL_VIBER,
    TEMPLATE_CLONE_SOCIAL_VIBER,
    TEMPLATE_CLONE_PUSH_NOTIFICATION,
    TEMPLATE_CREATE_PUSH_NOTIFICATION,
    TEMPLATE_EDIT_PUSH_NOTIFICATION,
    TEMPLATE_PREDEFINED_PUSH_NOTIFICATION,
    TEMPLATE_CREATE_POP_UP_NOTIFICATION,
    TEMPLATE_CREATE_DYNAMIC_POP_UP,
    TEMPLATE_EDIT_DYNAMIC_POP_UP,
    TEMPLATE_CLONE_DYNAMIC_POP_UP,
    TEMPLATE_PREDEFINED_DYNAMIC_POP_UP,
    TEMPLATE_EDIT_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_POP_UP_NOTIFICATION,
    TEMPLATE_PREDEFINED_POP_UP_NOTIFICATION,
    TEMPLATE_CREATE_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION,
    REPORT_LIST,
    REPORT_CREATE,
    REPORT_CLONE,
    REPORT_EDIT,
    REPORT_EXECUTED_LIST,
    REPORT_SCHEDULE,
    REPORT_EXECUTE,
    CONVERT_TO_REPORT,
    CAMPAIGN_CREATE,
    CAMPAIGN_EDIT,
    CAMPAIGN_CLONE,
    CAMPAIGN_REPORT,
    REALTIME_PROMOTIONS_LIST,
    REALTIME_JOURNEY_CREATE,
    REALTIME_JOURNEY_EDIT,
    REALTIME_JOURNEY_CLONE,
    REALTIME_JOURNEY_PREDEFINED,
    REALTIME_JOURNEY_VIEW,
    COMMUNICATION,
    REALTIME_JOURNEY_REPORT,
    REALTIME_JOURNEY_VISUALIZATION_CREATE,
    REALTIME_JOURNEY_VISUALIZATION_EDIT,
    CONFIGURATION,
    CONFIGURATION_NOTIFICATIONS,
    CONFIGURATION_PROVIDERS,
    CONFIGURATION_AWARDS,
    CONFIGURATION_GENERAL,
    NOTIFICATION,
    REPORT_COMPOSITE_CREATE,
    REPORT_COMPOSITE_CLONE,
    REPORT_COMPOSITE_EDIT,
    ADMINISTRATION,
    ADMINISTRATION_PARTNER_LIST,
    ADMINISTRATION_CJ_BLOCK_MANAGEMENT,
    ADMINISTRATION_PROVIDERS,
    ADMINISTRATION_SETTINGS,
    REPORT_CUSTOMER_JOURNEY_CREATE,
    REPORT_CUSTOMER_JOURNEY_EDIT,
    REPORT_CUSTOMER_JOURNEY_CLONE,
    CONFIGURATION_TEMPLATE_LANGUAGES,
    ADMINISTRATION_DATA_WAREHOUSE,
    ADMINISTRATION_CLASSIFIERS,
    ADMINISTRATION_MENUITEMS,
    CLIENT_VIEW,
    CLIENT_LIST,
    REALTIME_ACTION_CREATE,
    REALTIME_ACTION_EDIT,
    REALTIME_ACTION_CLONE,
    REALTIME_ACTION_VIEW,
    REALTIME_ACTION_REPORT,
} = RoutesList;

const mainAppRoutes = [
    {
        titleKey: l.HomePage,
        path: HOMEPAGE.path,
        component: lazy(() => import('pages/HomePage')),
    },
    {
        titleKey: l.Dashboard,
        path: DASHBOARD.path,
        component: lazy(() => import('pages/DashboardPage')),
    },
    {
        titleKey: l.SegmentList,
        path: SEGMENT_LIST.path,
        component: lazy(() => import('pages/SegmentListPage')),
    },
    {
        titleKey: l.TemplateList,
        path: TEMPLATE_LIST.path,
        component: lazy(() => import('pages/TemplateListPage')),
    },
    {
        titleKey: l.CampaignList,
        path: CAMPAIGN_LIST.path,
        component: lazy(() => import('pages/CampaignListPage')),
    },
    {
        titleKey: l.CreateDynamicSegment,
        path: SEGMENT_DYNAMIC_CREATE.path,
        component: lazy(() => import('pages/DynamicSegmentCECPPage/DynamicSegmentCreatePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.EditDynamicSegment,
        path: SEGMENT_DYNAMIC_EDIT.path,
        component: lazy(() => import('pages/DynamicSegmentCECPPage/DynamicSegmentEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CloneDynamicSegment,
        path: SEGMENT_DYNAMIC_CLONE.path,
        component: lazy(() => import('pages/DynamicSegmentCECPPage/DynamicSegmentClonePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.PredefinedDynamicSegment,
        path: SEGMENT_DYNAMIC_PREDEFINED.path,
        component: lazy(() => import('pages/DynamicSegmentCECPPage/DynamicSegmentPredefinedPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.CreateStaticSegment,
        path: SEGMENT_STATIC_CREATE.path,
        component: lazy(() => import('pages/StaticSegmentPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CreateNonCustomerSegment,
        path: SEGMENT_NON_CUSTOMER_CREATE.path,
        component: lazy(() => import('pages/NonCustomerSegmentPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CreateCompositeSegment,
        path: SEGMENT_COMPOSITE_CREATE.path,
        component: lazy(() => import('pages/CompositeSegmentCECPage/CompositeSegmentCreatePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CloneCompositeSegment,
        path: SEGMENT_COMPOSITE_CLONE.path,
        component: lazy(() => import('pages/CompositeSegmentCECPage/CompositeSegmentClonePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.EditCompositeSegment,
        path: SEGMENT_COMPOSITE_EDIT.path,
        component: lazy(() => import('pages/CompositeSegmentCECPage/CompositeSegmentEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CreateEmailTemplate,
        path: TEMPLATE_CREATE_EMAIL.path,
        component: lazy(() => import('pages/EmailTemplateCECPPage/EmailTemplateCreatePage')),
    },
    {
        titleKey: l.CloneEmailTemplate,
        path: TEMPLATE_CREATE_CLONE.path,
        component: lazy(() => import('pages/EmailTemplateCECPPage/EmailTemplateClonePage')),
    },
    {
        titleKey: l.EditEmailTemplate,
        path: TEMPLATE_CREATE_EDIT.path,
        component: lazy(() => import('pages/EmailTemplateCECPPage/EmailTemplateEditPage')),
    },
    {
        titleKey: l.PredefinedEmailTemplate,
        path: TEMPLATE_PREDEFINED_EMAIL.path,
        component: lazy(() => import('pages/EmailTemplateCECPPage/EmailTemplateCreatePredefinedPage')),
    },
    {
        titleKey: l.CreatePushNotificationTemplate,
        path: TEMPLATE_CREATE_PUSH_NOTIFICATION.path,
        component: lazy(() => import('pages/PushNotificationTemplateCECPPage/PushNotificationTemplateCreatePage')),
    },
    {
        titleKey: l.ClonePushNotificationTemplate,
        path: TEMPLATE_CLONE_PUSH_NOTIFICATION.path,
        component: lazy(() => import('pages/PushNotificationTemplateCECPPage/PushNotificationTemplateClonePage')),
    },
    {
        titleKey: l.EditPushNotificationTemplate,
        path: TEMPLATE_EDIT_PUSH_NOTIFICATION.path,
        component: lazy(() => import('pages/PushNotificationTemplateCECPPage/PushNotificationTemplateEditPage')),
    },
    {
        titleKey: l.PredefinedPushNotificationTemplate,
        path: TEMPLATE_PREDEFINED_PUSH_NOTIFICATION.path,
        component: lazy(() => import('pages/PushNotificationTemplateCECPPage/PushNotificationTemplatePredefinedPage')),
    },
    {
        titleKey: l.CreatePopUpNotificationTemplate,
        path: TEMPLATE_CREATE_POP_UP_NOTIFICATION.path,
        component: lazy(() => import('pages/PopUpNotificationTemplateCECPage/PopUpNotificationTemplateCreatePage')),
    },
    {
        titleKey: l.EditPopUpNotificationTemplate,
        path: TEMPLATE_EDIT_POP_UP_NOTIFICATION.path,
        component: lazy(() => import('pages/PopUpNotificationTemplateCECPage/PopUpNotificationTemplateEditPage')),
    },
    {
        titleKey: l.ClonePopUpNotificationTemplate,
        path: TEMPLATE_CLONE_POP_UP_NOTIFICATION.path,
        component: lazy(() => import('pages/PopUpNotificationTemplateCECPage/PopUpNotificationTemplateClonePage')),
    },
    {
        titleKey: l.PredefinedPopUpNotificationTemplate,
        path: TEMPLATE_PREDEFINED_POP_UP_NOTIFICATION.path,
        component: lazy(() => import('pages/PopUpNotificationTemplateCECPage/PopUpNotificationTemplatePredefinedPage')),
    },
    {
        titleKey: l.CreateDynamicPopUpTemplate,
        path: TEMPLATE_CREATE_DYNAMIC_POP_UP.path,
        component: lazy(() => import('pages/DynamicPopUpTemplateCECPage/DynamicPopUpTemplateCreatePage')),
    },
    {
        titleKey: l.EditDynamicPopUpTemplate,
        path: TEMPLATE_EDIT_DYNAMIC_POP_UP.path,
        component: lazy(() => import('pages/DynamicPopUpTemplateCECPage/DynamicPopUpTemplateEditPage')),
    },
    {
        titleKey: l.CloneDynamicPopUpTemplate,
        path: TEMPLATE_CLONE_DYNAMIC_POP_UP.path,
        component: lazy(() => import('pages/DynamicPopUpTemplateCECPage/DynamicPopUpTemplateClonePage')),
    },
    {
        titleKey: l.PredefinedDynamicPopUpTemplate,
        path: TEMPLATE_PREDEFINED_DYNAMIC_POP_UP.path,
        component: lazy(() => import('pages/DynamicPopUpTemplateCECPage/DynamicPopUpTemplatePredefinedPage')),
    },
    {
        titleKey: l.CreateCMSPopUpNotificationTemplate,
        path: TEMPLATE_CREATE_CMS_POP_UP_NOTIFICATION.path,
        component: lazy(() =>
            import('pages/CMSPopUpNotificationTemplateCECPage/CMSPopUpNotificationTemplateCreatePage'),
        ),
    },
    {
        titleKey: l.EditCMSPopUpNotificationTemplate,
        path: TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION.path,
        component: lazy(() => import('pages/CMSPopUpNotificationTemplateCECPage/CMSPopUpNotificationTemplateEditPage')),
    },
    {
        titleKey: l.CloneCMSPopUpNotificationTemplate,
        path: TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION.path,
        component: lazy(() =>
            import('pages/CMSPopUpNotificationTemplateCECPage/CMSPopUpNotificationTemplateClonePage'),
        ),
    },
    {
        titleKey: l.CreateSMSTemplate,
        path: TEMPLATE_CREATE_SMS.path,
        component: lazy(() => import('pages/SMSTemplateCECPPage/SMSTemplateCreatePage')),
    },
    {
        titleKey: l.CloneSMSTemplate,
        path: TEMPLATE_CLONE_SMS.path,
        component: lazy(() => import('pages/SMSTemplateCECPPage/SMSTemplateClonePage')),
    },
    {
        titleKey: l.EditSMSTemplate,
        path: TEMPLATE_EDIT_SMS.path,
        component: lazy(() => import('pages/SMSTemplateCECPPage/SMSTemplateEditPage')),
    },
    {
        titleKey: l.PredefinedSMSTemplate,
        path: TEMPLATE_PREDEFINED_SMS.path,
        component: lazy(() => import('pages/SMSTemplateCECPPage/SMSTemplatePredefinedPage')),
    },
    {
        titleKey: l.CreateHooryTemplate,
        path: TEMPLATE_CREATE_HOORY.path,
        component: lazy(() => import('pages/HooryTemplateCECPPage/HooryTemplateCreatePage')),
    },
    {
        titleKey: l.CloneHooryTemplate,
        path: TEMPLATE_CLONE_HOORY.path,
        component: lazy(() => import('pages/HooryTemplateCECPPage/HooryTemplateClonePage')),
    },
    {
        titleKey: l.EditHooryTemplate,
        path: TEMPLATE_EDIT_HOORY.path,
        component: lazy(() => import('pages/HooryTemplateCECPPage/HooryTemplateEditPage')),
    },
    {
        titleKey: l.PredefinedHooryTemplate,
        path: TEMPLATE_PREDEFINED_HOORY.path,
        component: lazy(() => import('pages/HooryTemplateCECPPage/HooryTemplatePredefinedPage')),
    },
    {
        titleKey: l.CreateFastexNotificationTemplate,
        path: TEMPLATE_CREATE_FASTEX_NOTIFICATION.path,
        component: lazy(() => import('pages/FastexNotificationTemplateCECPPage/FastexNotificationTemplateCreatePage')),
    },
    {
        titleKey: l.CloneFastexNotificationTemplate,
        path: TEMPLATE_CLONE_FASTEX_NOTIFICATION.path,
        component: lazy(() => import('pages/FastexNotificationTemplateCECPPage/FastexNotificationTemplateClonePage')),
    },
    {
        titleKey: l.EditFastexNotificationTemplate,
        path: TEMPLATE_EDIT_FASTEX_NOTIFICATION.path,
        component: lazy(() => import('pages/FastexNotificationTemplateCECPPage/FastexNotificationTemplateEditPage')),
    },
    {
        titleKey: l.PredefinedFastexNotificationTemplate,
        path: TEMPLATE_PREDEFINED_FASTEX_NOTIFICATION.path,
        component: lazy(() =>
            import('pages/FastexNotificationTemplateCECPPage/FastexNotificationTemplatePredefinedPage'),
        ),
    },
    {
        titleKey: l.CreateInternalMessageTemplate,
        path: TEMPLATE_CREATE_INTERNAL_MESSAGE.path,
        component: lazy(() => import('pages/InternalMessageTempalteCECPPage/InternalMessageTemplateCreatePage')),
    },
    {
        titleKey: l.CloneInternalMessageTemplate,
        path: TEMPLATE_CLONE_INTERNAL_MESSAGE.path,
        component: lazy(() => import('pages/InternalMessageTempalteCECPPage/InternalMessageTemplateClonePage')),
    },
    {
        titleKey: l.EditInternalMessageTemplate,
        path: TEMPLATE_EDIT_INTERNAL_MESSAGE.path,
        component: lazy(() => import('pages/InternalMessageTempalteCECPPage/InternalMessageTemplateEditPage')),
    },
    {
        titleKey: l.PredefinedInternalMessageTemplate,
        path: TEMPLATE_PREDEFINED_INTERNAL_MESSAGE.path,
        component: lazy(() => import('pages/InternalMessageTempalteCECPPage/InternalMessageTemplatePredefinedPage')),
    },
    {
        titleKey: l.CreateTelegramTemplate,
        path: TEMPLATE_CREATE_SOCIAL_TELEGRAM.path,
        component: lazy(() => import('pages/TelegramTemplateCECPPage/TelegramTemplateCreatePage')),
    },
    {
        titleKey: l.CloneTelegramTemplate,
        path: TEMPLATE_CLONE_SOCIAL_TELEGRAM.path,
        component: lazy(() => import('pages/TelegramTemplateCECPPage/TelegramTemplateClonePage')),
    },
    {
        titleKey: l.EditTelegramTemplate,
        path: TEMPLATE_EDIT_SOCIAL_TELEGRAM.path,
        component: lazy(() => import('pages/TelegramTemplateCECPPage/TelegramTemplateEditPage')),
    },
    {
        titleKey: l.PredefinedTelegramTemplate,
        path: TEMPLATE_PREDEFINED_SOCIAL_TELEGRAM.path,
        component: lazy(() => import('pages/TelegramTemplateCECPPage/TelegramTemplatePredefinedPage')),
    },
    {
        titleKey: l.CreateViberTemplate,
        path: TEMPLATE_CREATE_SOCIAL_VIBER.path,
        component: lazy(() => import('pages/ViberTemplateCECPage/ViberTemplateCreatePage')),
    },
    {
        titleKey: l.CloneViberTemplate,
        path: TEMPLATE_CLONE_SOCIAL_VIBER.path,
        component: lazy(() => import('pages/ViberTemplateCECPage/ViberTemplateClonePage')),
    },
    {
        titleKey: l.EditViberTemplate,
        path: TEMPLATE_EDIT_SOCIAL_VIBER.path,
        component: lazy(() => import('pages/ViberTemplateCECPage/ViberTemplateEditPage')),
    },
    {
        titleKey: l.ReportList,
        path: REPORT_LIST.path,
        component: lazy(() => import('pages/ReportListPage')),
    },
    {
        titleKey: l.CreateReport,
        path: REPORT_CREATE.path,
        component: lazy(() => import('pages/ReportCECPage/ReportCreatePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CloneReport,
        path: REPORT_CLONE.path,
        component: lazy(() => import('pages/ReportCECPage/ReportClonePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.EditReport,
        path: REPORT_EDIT.path,
        component: lazy(() => import('pages/ReportCECPage/ReportEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.ExecutedReportsList,
        path: REPORT_EXECUTED_LIST.path,
        component: lazy(() => import('pages/ExecutedReportListPage')),
    },
    {
        titleKey: l.ScheduleReport,
        path: REPORT_SCHEDULE.path,
        component: lazy(() => import('pages/ExecuteReportScheduleCEPage/ReportSchedulePage')),
    },
    {
        titleKey: l.ExecuteReport,
        path: REPORT_EXECUTE.path,
        component: lazy(() => import('pages/ExecuteReportScheduleCEPage/ReportExecutePage')),
    },
    {
        titleKey: l.CreateReportFromSegment,
        path: CONVERT_TO_REPORT.path,
        component: lazy(() => import('pages/ReportCECPage/ConvertToReportPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.CreateCampaign,
        path: CAMPAIGN_CREATE.path,
        component: lazy(() => import('pages/CampaignCECPage/CampaignCreatePage')),
    },
    {
        titleKey: l.EditCampaign,
        path: CAMPAIGN_EDIT.path,
        component: lazy(() => import('pages/CampaignCECPage/CampaignEditPage')),
    },
    {
        titleKey: l.CloneCampaign,
        path: CAMPAIGN_CLONE.path,
        component: lazy(() => import('pages/CampaignCECPage/CampaignClonePage')),
    },
    {
        titleKey: l.CampaignReport,
        path: CAMPAIGN_REPORT.path,
        component: lazy(() => import('pages/CampaignReportPage')),
    },
    {
        titleKey: l.JourneyPage,
        path: REALTIME_PROMOTIONS_LIST.path,
        component: lazy(() => import('pages/CustomerJourneyListPage')),
    },
    {
        titleKey: l.CreateCustomerJourney,
        path: REALTIME_JOURNEY_CREATE.path,
        component: lazy(() => import('pages/CustomerJourneyCECPVPage/CustomerJourneyCreatePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.EditCustomerJourney,
        path: REALTIME_JOURNEY_EDIT.path,
        component: lazy(() => import('pages/CustomerJourneyCECPVPage/CustomerJourneyEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CloneCustomerJourney,
        path: REALTIME_JOURNEY_CLONE.path,
        component: lazy(() => import('pages/CustomerJourneyCECPVPage/CustomerJourneyClonePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.CreateFromPredefinedCustomerJourney,
        path: REALTIME_JOURNEY_PREDEFINED.path,
        component: lazy(() => import('pages/CustomerJourneyCECPVPage/CustomerJourneyPredefinedPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.ViewCustomerJourney,
        path: REALTIME_JOURNEY_VIEW.path,
        component: lazy(() => import('pages/CustomerJourneyCECPVPage/CustomerJourneyViewPage')),
    },
    {
        titleKey: l.Communication,
        path: COMMUNICATION.path,
        component: lazy(() => import('pages/CommunicationPage')),
    },
    {
        titleKey: l.ReportCustomerJourney,
        path: REALTIME_JOURNEY_REPORT.path,
        component: lazy(() => import('pages/CustomerJourneyReportPage')),
    },
    {
        titleKey: l.CreateCustomerJourneyVisualization,
        path: REALTIME_JOURNEY_VISUALIZATION_CREATE.path,
        component: lazy(() =>
            import('pages/CustomerJourneyVisualizationCEPage/CustomerJourneyVisualizationCreatePage'),
        ),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.EditCustomerJourneyVisualization,
        path: REALTIME_JOURNEY_VISUALIZATION_EDIT.path,
        component: lazy(() => import('pages/CustomerJourneyVisualizationCEPage/CustomerJourneyVisualizationEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.Configuration,
        path: CONFIGURATION.path,
        component: lazy(() => import('pages/ConfigurationPage')),
        isExact: true,
    },
    {
        titleKey: l.Notifications,
        path: CONFIGURATION_NOTIFICATIONS.path,
        component: lazy(() => import('pages/NotificationsPage')),
    },
    {
        titleKey: l.Providers,
        path: CONFIGURATION_PROVIDERS.path,
        component: lazy(() => import('pages/ProvidersPage')),
    },
    {
        titleKey: l.Awards,
        path: CONFIGURATION_AWARDS.path,
        component: lazy(() => import('pages/AwardsPage')),
    },
    {
        titleKey: l.General,
        path: CONFIGURATION_GENERAL.path,
        component: lazy(() => import('pages/GeneralPage')),
    },
    {
        titleKey: l.NotificationCampaign,
        path: NOTIFICATION.path,
        component: lazy(() => import('pages/NotificationPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CreateCompositeReport,
        path: REPORT_COMPOSITE_CREATE.path,
        component: lazy(() => import('pages/CompositeReportCECPage/CompositeReportCreatePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CloneCompositeReport,
        path: REPORT_COMPOSITE_CLONE.path,
        component: lazy(() => import('pages/CompositeReportCECPage/CompositeReportClonePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.EditCompositeReport,
        path: REPORT_COMPOSITE_EDIT.path,
        component: lazy(() => import('pages/CompositeReportCECPage/CompositeReportEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.Administration,
        path: ADMINISTRATION.path,
        component: lazy(() => import('pages/AdministrationPage')),
        isExact: true,
    },
    {
        titleKey: l.PartnerList,
        path: ADMINISTRATION_PARTNER_LIST.path,
        component: lazy(() => import('pages/AdministrationPage/PartnersListPage')),
    },
    {
        titleKey: l.CJBlockManagement,
        path: ADMINISTRATION_CJ_BLOCK_MANAGEMENT.path,
        component: lazy(() => import('pages/AdministrationPage/CJBlockManagementPage')),
    },
    {
        titleKey: l.AdministrationProviders,
        path: ADMINISTRATION_PROVIDERS.path,
        component: lazy(() => import('pages/AdministrationPage/ProviderListPage')),
    },
    {
        titleKey: l.AdministrationSettings,
        path: ADMINISTRATION_SETTINGS.path,
        component: lazy(() => import('pages/AdministrationPage/SettingsListPage')),
    },
    {
        titleKey: l.DataWarehouse,
        path: ADMINISTRATION_DATA_WAREHOUSE.path,
        component: lazy(() => import('pages/AdministrationPage/DataWarehouseListPage')),
    },
    {
        titleKey: l.AdministrationClassifiers,
        path: ADMINISTRATION_CLASSIFIERS.path,
        component: lazy(() => import('pages/AdministrationPage/ClassifiersListPage')),
    },
    {
        titleKey: l.AdministrationMenuItems,
        path: ADMINISTRATION_MENUITEMS.path,
        component: lazy(() => import('pages/AdministrationPage/MenuItemsListPage')),
    },
    {
        titleKey: l.CreateCustomerJourneyReport,
        path: REPORT_CUSTOMER_JOURNEY_CREATE.path,
        component: lazy(() => import('pages/CustomerJourneyReportCECPage/CustomerJourneyReportCreatePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.EditCustomerJourneyReport,
        path: REPORT_CUSTOMER_JOURNEY_EDIT.path,
        component: lazy(() => import('pages/CustomerJourneyReportCECPage/CustomerJourneyReportEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CloneCustomerJourneyReport,
        path: REPORT_CUSTOMER_JOURNEY_CLONE.path,
        component: lazy(() => import('pages/CustomerJourneyReportCECPage/CustomerJourneyReportClonePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.TemplateLanguages,
        path: CONFIGURATION_TEMPLATE_LANGUAGES.path,
        component: lazy(() => import('pages/TemplateLanguagesPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.ViewClient,
        path: CLIENT_VIEW.path,
        component: lazy(() => import('pages/ClientViewPage')),
    },
    {
        titleKey: l.ClientList,
        path: CLIENT_LIST.path,
        component: lazy(() => import('pages/ClientListPage')),
    },
    {
        titleKey: l.CreateActionCampaign,
        path: REALTIME_ACTION_CREATE.path,
        component: lazy(() => import('pages/ActionCampaignCECVPage/ActionCampaignCreatePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.EditActionCampaign,
        path: REALTIME_ACTION_EDIT.path,
        component: lazy(() => import('pages/ActionCampaignCECVPage/ActionCampaignEditPage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.ChangeRelated,
    },
    {
        titleKey: l.CloneActionCampaign,
        path: REALTIME_ACTION_CLONE.path,
        component: lazy(() => import('pages/ActionCampaignCECVPage/ActionCampaignClonePage')),
        routeLeaveConfirmationType: RouteLeaveConfirmationType.Always,
    },
    {
        titleKey: l.ViewActionCampaign,
        path: REALTIME_ACTION_VIEW.path,
        component: lazy(() => import('pages/ActionCampaignCECVPage/ActionCampaignViewPage')),
    },
    {
        titleKey: l.ReportActionCampaign,
        path: REALTIME_ACTION_REPORT.path,
        component: lazy(() => import('pages/ActionCampaignReportPage')),
    },
];

const publicRoutesList = [
    {
        titleKey: l.SignIn,
        path: '/',
        component: lazy(() => import('pages/SignInPage')),
        exact: true,
        isPrivate: false,
    },
    {
        titleKey: l.SignIn,
        path: '/signin',
        component: lazy(() => import('pages/SignInPage')),
        exact: false,
        isPrivate: false,
    },
    {
        titleKey: l.MainApp,
        path: '/crm',
        component: lazy(() => import('components/MainApp')),
        exact: false,
        isPrivate: true,
    },
];

const notFoundRoute = {
    titleKey: l.NotFound,
    component: lazy(() => import('pages/NotFoundPage')),
};

const allRoutePaths = publicRoutesList
    .map((item) => item.path)
    .concat(mainAppRoutes.map((item) => `/crm/${item.path}`))
    .map((item) => {
        // eslint-disable-next-line
        const params = [...item.matchAll(/(?<param>:[^\/]+)/g)].map((m) => ({
            value: m.groups.param,
            key: m.groups.param.substring(1),
        }));
        let regex = `^${item}`;
        params.forEach((param) => {
            regex = regex.replaceAll(param.value, `(?<${param.key}>[^/.]+)`);
        });
        regex = regex.replaceAll('/', '\\/');
        regex = `${regex}([?].*)*$`;
        return {
            value: item,
            params,
            regex,
        };
    });

export { publicRoutesList, notFoundRoute, mainAppRoutes, allRoutePaths };
