import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { keys, isNil, isEmpty } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
import moment from 'moment';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import Constants
import {
    l,
    ScheduleModalViews,
    Hours,
    Days,
    WeekDaysKeyList,
    Months,
    DateFormat,
    DateTimeWithoutSecondsFormat,
    TimeZonePack,
    CJArgumentValuesTypes,
} from 'constants/common';
// Import UI Components
import { Popover } from '@geneui/components';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
// Import Services
import { Helpers } from 'services';

const { hourlyView, dailyView, weeklyView, monthlyView, notRepeatView } = ScheduleModalViews;
const { getStringElementsByIndexArray } = Helpers;

const CJScheduleInfoBlock = ({ data }) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();

    const [recurrenceData, setRecurrenceData] = useState(0);

    const { getScheduleRunDates } = useMemo(
        () => ({
            getScheduleRunDates: UtilsHttpService.getScheduleRunDates(),
        }),
        [],
    );

    const getRecCount = (cron, startDate, endDate, timeZone) => {
        if (!isNil(cron)) {
            doPostRequest(getScheduleRunDates.request, {
                requestBody: { cronExpression: cron, startDate: startDate, endDate: endDate, timeZone: timeZone },
                successCallback: (Data) => setRecurrenceData(Data),
            });
        }
    };

    useEffect(() => {
        if (data.modalView !== notRepeatView) {
            getRecCount(data?.cronSchedule, data?.startDate, data?.endDate, data?.timeZone);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cleanUp = () => {
        return () => {
            getScheduleRunDates.cancel('CJScheduleInfoBlock:getScheduleRunDates');
        };
    };

    const getScheduleSelections = (labels, selections, every) => {
        if (isEmpty(selections)) {
            return `${t(l.Every)} ${every}`;
        }

        return labels ? getStringElementsByIndexArray(labels, selections, t) : selections.join(',');
    };

    useEffect(cleanUp, []);

    const renderByViewType = () => {
        const viewType = keys(data?.datesDict)?.[0];
        const scheduleInfo = data?.datesDict?.[viewType];

        switch (viewType) {
            case hourlyView: {
                return (
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.Hours)}
                        value={getScheduleSelections(Hours, scheduleInfo.hours, scheduleInfo.hour)}
                    />
                );
            }
            case dailyView:
                return (
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.Day)}
                        value={getScheduleSelections(Days, scheduleInfo.days, scheduleInfo.day)}
                    />
                );
            case weeklyView: {
                return (
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.Weekday)}
                        value={getScheduleSelections(WeekDaysKeyList, scheduleInfo.weeks, scheduleInfo.week)}
                    />
                );
            }
            case monthlyView: {
                return (
                    <>
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.Month)}
                            value={getScheduleSelections(Months, scheduleInfo.months, scheduleInfo.month)}
                        />
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.Day)}
                            value={getScheduleSelections(Days, scheduleInfo.days, scheduleInfo.day)}
                        />
                    </>
                );
            }

            default: {
                return (
                    <>
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.Year)}
                            value={getScheduleSelections(null, scheduleInfo.years, scheduleInfo.year)}
                        />
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.Month)}
                            value={getScheduleSelections(Months, scheduleInfo.months, scheduleInfo.month)}
                        />
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.Day)}
                            value={getScheduleSelections(Days, scheduleInfo.days, scheduleInfo.day)}
                        />
                    </>
                );
            }
        }
    };

    return (
        <>
            {data.argumentInType === CJArgumentValuesTypes.DefaultInArgument ? (
                <CJInfoBlockLabelValueWrapper label={t(l.OptionValue)} />
            ) : (
                <>
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.StartDate)}
                        value={
                            data.modalView === notRepeatView
                                ? moment(data?.startDate).format(DateTimeWithoutSecondsFormat)
                                : moment(data?.startDate).format(DateFormat)
                        }
                    />
                    {data.modalView !== notRepeatView && (
                        <CJInfoBlockLabelValueWrapper
                            label={t(l.EndDate)}
                            value={moment(data?.endDate).format(DateFormat)}
                        />
                    )}
                    {data.modalView !== notRepeatView && (
                        <>
                            <CJInfoBlockLabelValueWrapper
                                label={t(l.StartTime)}
                                value={data?.datesDict[data?.modalView].startTime}
                            />
                            <CJInfoBlockLabelValueWrapper
                                label={t(l.RecurrenceCount)}
                                value={
                                    <Popover
                                        className="next-run-date-popover-container"
                                        extendTargetWidth={false}
                                        Content={recurrenceData?.UpcomingExecutions?.map((executionDate, i) => {
                                            return <p key={i}>{moment(executionDate).format(DateFormat)}</p>;
                                        })}
                                        align="end"
                                        position="right"
                                    >
                                        <button disabled={recurrenceData?.Count === 0} className="blue-color-button">
                                            {recurrenceData?.Count}
                                        </button>
                                    </Popover>
                                }
                            />
                        </>
                    )}
                    <CJInfoBlockLabelValueWrapper label={t(l.TimeZone)} value={TimeZonePack[data?.timeZone ?? 0]} />
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.ScheduleType)}
                        value={t(data.modalView === notRepeatView ? l.OneTime : l.Repeat)}
                    />
                    {data.modalView !== notRepeatView && renderByViewType()}
                </>
            )}
        </>
    );
};

CJScheduleInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
};

export default memo(CJScheduleInfoBlock);
