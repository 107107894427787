import { isNull, keys } from 'lodash';
// Import Constants
import { ErrorShowType, l, TemplateInputTypes } from 'constants/common';

const pageSMSPartsScenario = (state) => ({
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'lessThanOrEqual',
            ErrorMessage: l.MaxCharactersCountForSMSPartIsReached,
            ParametersValues: state.maxSMSPartsCount,
        },
    ],
});

const bodyScenario = (state) => ({
    isValid: null,
    errorText: '',
    value: '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'minLength',
            ErrorMessage: l.MustContainMinimumCharacters,
            ParametersValues: 3,
        },
    ],
});

const smsTemplateValidationScenario = (state) => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    CrossValidation: {
        isValid: null,
        errorText: '',
        value: {},
        type: ErrorShowType.Toaster,
        validationCredentials: [
            {
                ValidationType: 'templateCrossValidation',
                ErrorMessage: '',
                ParametersValues: [TemplateInputTypes.Body],
            },
        ],
    },
    defaultLangBody: { ...bodyScenario(state) },
    defaultLangSMSParts: { ...pageSMSPartsScenario(state) },
});

function smsTemplateScenarioPropertiesCreator(data, editCloneMode, state) {
    if (editCloneMode) {
        return keys(data).reduce((acc, id) => {
            acc[`${id}Body`] = {
                ...bodyScenario(state),
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].Body,
            };
            acc[`${id}SMSParts`] = {
                ...pageSMSPartsScenario(state),
                isValid: isNull(data[id].isValid) ? null : true,
                value: data[id].SMSParts,
            };
            return acc;
        }, {});
    }
    return data.reduce((acc, id) => {
        acc[`${id}Body`] = { ...bodyScenario(state) };
        acc[`${id}SMSParts`] = { ...pageSMSPartsScenario(state) };
        return acc;
    }, {});
}

export { smsTemplateValidationScenario, smsTemplateScenarioPropertiesCreator };
