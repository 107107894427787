import { dynamicSegmentValidationScenario } from './dynamicSegment.scenario';
import { staticSegmentValidationScenario } from './staticSegment.scenario';
import { nonCustomerSegmentValidationScenario } from './nonCustomerSegment.scenario';
import { smsTemplateValidationScenario, smsTemplateScenarioPropertiesCreator } from './smsTemplate.scenario';
import { hooryTemplateValidationScenario, HooryTemplateScenarioPropertiesCreator } from './hooryTemplate.scenario';
import {
    fastexNotificationTemplateValidationScenario,
    FastexNotificationTemplateScenarioPropertiesCreator,
} from './fastexNotificationTemplate.scenario';
import { compositeSegmentValidationScenario } from './compositeSegment.scenario';
import { reportValidationScenario } from './report.scenario';
import { compositeReportValidationScenario } from './compositeReport.scenario';
import { customerJourneyReportValidationScenario } from './customerJourneyReport.scenario';
import {
    telegramTemplateValidationScenario,
    telegramTemplateScenarioPropertiesCreater,
} from './telegramTemplate.scenario';
import {
    internalMessageTemplateValidationScenario,
    internalMessageTemplateScenarioProperiesCreater,
} from './internalMessageTemplate.scenario';
import {
    pushNotificationTemplateScenarioProperiesCreater,
    pushNotificationTemplateValidationScenario,
} from './pushNotificationTemplate.scenario';
import {
    popUpNotificationTemplateScenarioPropertiesCreator,
    popUpNotificationTemplateValidationScenario,
} from './popUpNotificationTemplate.scenario';
import { cmsPopUpNotificationTemplateValidationScenario } from './cmsPopUpNotificationTemplate.scenario';
import {
    reportExecuteScheduleValidationScenario,
    reportExecuteScheduleValidationCreate,
} from './reportExecuteSchedule.scenario';

import { emailTemplateValidationScenario, emailTemplateScenarioProperiesCreater } from './emailTemplate.scenario';

import { campaignValidationScenario, bonusCalculationScenarioProperiesCreater } from './campaign.scenario';
import {
    customerJourneyValidationScenario,
    customerJourneyScenarioPropertiesCreator,
} from './customerJourney.scenario';
import { customerJourneyVisualizationScenario } from './customerJourneyVisualization.scenario';
import { viberTemplateValidationScenario, viberTemplateScenarioPropertiesCreator } from './viberTemplate.scenario';
import { dynamicPopUpTemplateScenarioPropertiesCreator, dynamicPopUpTemplateValidationScenario } from './dynamicPopUpTemplate.scenario';

import { PageValidationTypes } from 'constants/common';

const {
    DynamicSegmentPage,
    StaticSegmentPage,
    SMSTemplatePage,
    HooryTemplatePage,
    FastexNotificationTemplatePage,
    TelegramTemplatePage,
    InternalMessageTemplatePage,
    EmailTemplatePage,
    DynamicPopUpTemplatePage,
    CompositeSegmentPage,
    CampaignPage,
    NonCustomerSegmentPage,
    JourneyPage,
    ReportPage,
    PushNotificationTemplatePage,
    CustomerJourneyVisualizationPage,
    PopUpNotificationTemplatePage,
    CMSPopUpNotificationTemplatePage,
    ReportExecuteSchedulePage,
    CompositeReportPage,
    CustomerJourneyReportPage,
    ViberTemplatePage,
} = PageValidationTypes;

export default {
    [DynamicSegmentPage]: { scenario: dynamicSegmentValidationScenario },
    [StaticSegmentPage]: { scenario: staticSegmentValidationScenario },
    [SMSTemplatePage]: {
        scenario: smsTemplateValidationScenario,
        propertiesCreator: smsTemplateScenarioPropertiesCreator,
    },
    [HooryTemplatePage]: {
        scenario: hooryTemplateValidationScenario,
        propertiesCreator: HooryTemplateScenarioPropertiesCreator,
    },
    [FastexNotificationTemplatePage]: {
        scenario: fastexNotificationTemplateValidationScenario,
        propertiesCreator: FastexNotificationTemplateScenarioPropertiesCreator,
    },
    [TelegramTemplatePage]: {
        scenario: telegramTemplateValidationScenario,
        propertiesCreator: telegramTemplateScenarioPropertiesCreater,
    },
    [InternalMessageTemplatePage]: {
        scenario: internalMessageTemplateValidationScenario,
        propertiesCreator: internalMessageTemplateScenarioProperiesCreater,
    },
    [EmailTemplatePage]: {
        scenario: emailTemplateValidationScenario,
        propertiesCreator: emailTemplateScenarioProperiesCreater,
    },
    [DynamicPopUpTemplatePage]: {
        scenario: dynamicPopUpTemplateValidationScenario,
        propertiesCreator: dynamicPopUpTemplateScenarioPropertiesCreator,
    },
    [CompositeSegmentPage]: { scenario: compositeSegmentValidationScenario },
    [CampaignPage]: {
        scenario: campaignValidationScenario,
        propertiesCreator: bonusCalculationScenarioProperiesCreater,
    },
    [NonCustomerSegmentPage]: { scenario: nonCustomerSegmentValidationScenario },
    [JourneyPage]: {
        scenario: customerJourneyValidationScenario,
        propertiesCreator: customerJourneyScenarioPropertiesCreator,
    },
    [CustomerJourneyVisualizationPage]: {
        scenario: customerJourneyVisualizationScenario,
    },
    [ReportPage]: { scenario: reportValidationScenario },
    [PushNotificationTemplatePage]: {
        scenario: pushNotificationTemplateValidationScenario,
        propertiesCreator: pushNotificationTemplateScenarioProperiesCreater,
    },
    [PopUpNotificationTemplatePage]: {
        scenario: popUpNotificationTemplateValidationScenario,
        propertiesCreator: popUpNotificationTemplateScenarioPropertiesCreator,
    },
    [CMSPopUpNotificationTemplatePage]: {
        scenario: cmsPopUpNotificationTemplateValidationScenario,
    },
    [ReportExecuteSchedulePage]: {
        scenario: reportExecuteScheduleValidationScenario,
        propertiesCreator: reportExecuteScheduleValidationCreate,
    },
    [CompositeReportPage]: { scenario: compositeReportValidationScenario },
    [CustomerJourneyReportPage]: { scenario: customerJourneyReportValidationScenario },
    [ViberTemplatePage]: {
        scenario: viberTemplateValidationScenario,
        propertiesCreator: viberTemplateScenarioPropertiesCreator,
    },
};
