import { PartnerSettingsDataActionTypes as Types } from './types';

const getPartnerSettingsSuccess = (state) => ({
    type: Types.GET_PARTNER_SETTINGS_SUCCESSED,
    payload: state,
});

const getPartnerSettingsFailed = (error) => ({
    type: Types.GET_PARTNER_SETTINGS_FAILED,
    payload: error,
});

const setPartnerCurrencies = (state) => ({
    type: Types.SET_PARTNER_CURRENCIES,
    payload: state,
});

export default {
    getPartnerSettingsSuccess,
    getPartnerSettingsFailed,
    setPartnerCurrencies,
};
