import { ClientKPIColumnTypes } from 'constants/common';
import { objectTypesMap, hashTypeMap } from '../constants';
import {
    UIHashGroupContainer,
    UIHashButtonItem,
    UIHashComparisonContainer,
    UIComparisonObject,
} from '../UIHashContainers';

/// hash data
export function hashRecursion(treeNode, storageHash, hashKey, parentHashKey) {
    let categoryList = treeNode.SubCategories || [];
    for (let i = 0; i < categoryList.length; i++) {
        const hashKeyIndex = `${objectTypesMap.categoriesFilters}${categoryList[i].SegmentationCategoryId}`;
        hashRecursion(categoryList[i], storageHash, hashKeyIndex, hashKey);
    }

    let uiColor;
    if (treeNode.SegmentationCategoryUI) {
        //should be in every
        uiColor = treeNode.SegmentationCategoryUI.Color;
    }
    const { DisplayName } = treeNode;
    const groupContainer = new UIHashGroupContainer();
    groupContainer.parent = new UIHashButtonItem(parentHashKey, null, DisplayName, null, uiColor);
    storageHash[hashKey] = groupContainer;
    categoriesToHash(categoryList, storageHash, hashKey);
    filtersToHash(treeNode.SegmentationFilters, storageHash, hashKey, uiColor);
}

export function comparisonToHash(comparison, storageHash, hashKey, parentHashKey, options) {
    const { Color, DisplayName, Comparision, Type, Source, ViewType, Validation, SegmentationFilterId } = comparison;
    const validationOptions = JSON.parse(Validation || '{}');
    const { MinValue, Pattern, MaxValue } = validationOptions;
    const {
        filterColumnId,
        filterDisplayName,
        applicationColumnId,
        schemaId,
        schemaName,
        isAggregated,
        isKPI,
    } = options;
    const comparisonContainer = new UIHashComparisonContainer();
    const containerFilterDisplayName = `${filterDisplayName}(${DisplayName})`;
    comparisonContainer.parent = new UIHashButtonItem(
        parentHashKey,
        null,
        containerFilterDisplayName,
        null,
        options.uiColor,
        null,
        filterDisplayName,
    );
    comparisonContainer.comparison = new UIComparisonObject(
        Comparision,
        DisplayName,
        ViewType,
        Type,
        Color,
        Source,
        Pattern,
        MinValue,
        MaxValue,
        filterColumnId,
        SegmentationFilterId,
        hashKey,
        applicationColumnId,
        schemaId,
        schemaName,
        isAggregated,
        isKPI,
    );
    storageHash[hashKey] = comparisonContainer;
}

export function categoriesToHash(categoryList, storageHash, hashKey) {
    let uiColor;
    let nextBtn;
    let nextHashKey;
    for (let i = 0; i < categoryList.length; i++) {
        if (categoryList[i].SegmentationCategoryUI) {
            uiColor = categoryList[i].SegmentationCategoryUI.Color;
        }
        nextHashKey = `${objectTypesMap.categoriesFilters}${categoryList[i].SegmentationCategoryId}`;
        nextBtn = new UIHashButtonItem(nextHashKey, hashTypeMap.category, categoryList[i].DisplayName, null, uiColor);
        storageHash[hashKey].addNextItem(nextBtn);
    }
}

export function filtersToHash(filterList, storageHash, hashKey, uiColor) {
    let nextBtn;
    let nextHashKey;
    for (let i = 0; i < filterList.length; i++) {
        const columnIdIndex = filterList[i].ColumnId;
        if (filterList[i].ComparisionUIs) {
            let hashKeyForComparison = `${objectTypesMap.filterComparisons}${columnIdIndex}`;
            filterList[i].ComparisionUIs.ColumnId = columnIdIndex;
            let options = {
                uiColor: uiColor,
                filterDisplayName: filterList[i].DisplayName,
                filterDescription: filterList[i].Description,
                filterColumnId: columnIdIndex,
                applicationColumnId: filterList[i].ApplicationColumnId,
                schemaId: filterList[i].Column.SchemaId,
                schemaName: filterList[i].Column.SchemaName,
                isAggregated:
                    (filterList[i].Column.Type & (ClientKPIColumnTypes.Aggregate | ClientKPIColumnTypes.Formula)) > 0,
                isKPI: filterList[i].Column.IsKPI,
            };
            comparisonsToHash(filterList[i].ComparisionUIs, storageHash, hashKeyForComparison, hashKey, options);
        }
        nextHashKey = objectTypesMap.filterComparisons + columnIdIndex;
        nextBtn = new UIHashButtonItem(
            nextHashKey,
            hashTypeMap.filter,
            filterList[i].DisplayName,
            filterList[i].Description,
            uiColor,
        );
        storageHash[hashKey].addNextItem(nextBtn);
    }
}

export function startHashing(dataToHash) {
    const hahStorage = {};
    const treeNodeForRecursion = {
        SubCategories: dataToHash,
        SegmentationFilters: [],
        DisplayName: '',
        SegmentationCategoryUI: {
            Color: '#ff5418',
        },
    };
    const rootHashKay = objectTypesMap.categoriesFilters + 'root';
    hashRecursion(treeNodeForRecursion, hahStorage, rootHashKay, null);
    return hahStorage;
}

export function comparisonsToHash(comparisonsList, storageHash, hashKey, parentHashKey, options) {
    let nextBtn;
    let nextHashKey;
    const {
        filterDisplayName,
        uiColor,
        filterColumnId,
        filterDescription,
        minValue,
        applicationColumnId,
        schemaId,
        schemaName,
        isAggregated,
        isKPI,
    } = options;
    const groupContainer = new UIHashGroupContainer();
    const groupContainerParentBtn = new UIHashButtonItem(parentHashKey, null, filterDisplayName, null, uiColor);
    groupContainer.parent = groupContainerParentBtn;
    for (let i = 0; i < comparisonsList.length; i++) {
        const comparisonIndex = comparisonsList[i];
        const hashKeyForComparisonIndex = `${objectTypesMap.comparison}${filterColumnId}/${comparisonIndex.Comparision}`;
        nextHashKey = hashKeyForComparisonIndex;
        nextBtn = new UIHashButtonItem(
            nextHashKey,
            hashTypeMap.comparison,
            comparisonIndex.DisplayName,
            null,
            uiColor,
            comparisonIndex.Comparision,
        );
        groupContainer.addNextItem(nextBtn);
        const optionsForComparison = {
            uiColor: uiColor,
            filterDisplayName: filterDisplayName,
            filterDescription: filterDescription,
            filterColumnId: filterColumnId,
            minValue: minValue,
            applicationColumnId: applicationColumnId,
            schemaId: schemaId,
            schemaName: schemaName,
            isAggregated: isAggregated,
            isKPI: isKPI,
        };
        comparisonToHash(comparisonIndex, storageHash, hashKeyForComparisonIndex, hashKey, optionsForComparison);
    }
    storageHash[hashKey] = groupContainer;
}
