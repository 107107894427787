import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
// Import components
import CompositeReportInfoTab from './CompositeReportInfoTab';
//Import UI components
import { Tabs, Tab, BusyLoader } from '@geneui/components';
// Import services
import { ReportHttpService } from 'services/http';
// Import hooks
import { useRequest } from 'hooks';

const CompositeReportInfoTabs = ({ compositeConfiguration }) => {
    const { doGetRequest, doPostRequest } = useRequest();

    const [isLoading, setIsLoading] = useState(false);
    const [childReports, setChildReports] = useState();

    const { getReportById, getReportsByReport } = useMemo(
        () => ({
            getReportById: ReportHttpService.getReportById(),
            getReportsByReport: ReportHttpService.getReportsByReport(),
        }),
        [],
    );

    const getReports = (reportIdsAndTypes) => {
        return reportIdsAndTypes.map((report) => {
            return doGetRequest(getReportById.request, {
                queryString: {
                    reportId: report.ReportId,
                    reportType: report.ReportType,
                },
            });
        });
    };

    const mapReportsInfo = (responses) => {
        // for promise all need has error but case dows not cover
        let hasError = false;
        let segmentsData = responses.map((response) => {
            // for cases when promise is null
            if (isNil(response)) return response;

            const { HasError, Data } = response;
            if (!HasError) {
                return Data;
            }
            // case when one of one promise has error
            hasError = true;
            return null;
        });
        return { Data: segmentsData, HasError: hasError };
    };

    const mapChildReports = (reportsData) => {
        return reportsData.map((report) => {
            const reportType = compositeConfiguration.Children.find((child) => child.ReportId === report.ReportId)
                .ReportType;
            return { ...report, reportType };
        });
    };

    const tabChangeHandler = (tabIndex) => {
        if (!isNil(childReports) && isNil(childReports[tabIndex].usedIn)) {
            doPostRequest(getReportsByReport.request, {
                requestBody: {
                    reportId: childReports[tabIndex].ReportId,
                    reportType: childReports[tabIndex].reportType,
                },
                successCallback: (Data) => {
                    const reportsWithUsedIn = [...childReports];
                    reportsWithUsedIn[tabIndex] = { ...reportsWithUsedIn[tabIndex], usedIn: Data };
                    setChildReports(reportsWithUsedIn);
                },
            });
        }
    };

    const init = () => {
        setIsLoading(true);
        Promise.all(getReports(compositeConfiguration.Children)).then((responses) => {
            const mapReportsData = mapReportsInfo(responses);
            const data = mapChildReports(mapReportsData.Data);
            setChildReports(data);
            setIsLoading(mapReportsData.HasError);
        });
    };

    const cleanUp = () => {
        return () => {
            getReportById.cancel('CompositeReportInfo:getReportById');
        };
    };

    useEffect(() => {
        if (!isLoading && !isNil(childReports)) {
            tabChangeHandler(0); //for first time when loading
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(init, []);

    useEffect(cleanUp, []);

    return (
        <div className="composite-report-tabs">
            <BusyLoader isBusy={isLoading || isNil(childReports)} type="spinner" spinnerSize="medium">
                {!isLoading && !isNil(childReports) && (
                    <Tabs type="box" position="top" onChange={tabChangeHandler}>
                        {childReports.map((report, index) => {
                            return (
                                <Tab key={index} title={report.Name}>
                                    <CompositeReportInfoTab key={index} report={report} />
                                </Tab>
                            );
                        })}
                    </Tabs>
                )}
            </BusyLoader>
        </div>
    );
};

CompositeReportInfoTabs.propTypes = {
    compositeConfiguration: PropTypes.object.isRequired,
};

export default memo(CompositeReportInfoTabs);
