import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Button, ExtendedInput } from '@geneui/components';
// Import validators
import { validateSignInForm } from 'validators';
// Import constants
import { l } from 'constants/common';

const SignInForm = (props) => {
    const { t } = useTranslation();
    const { signInHandler } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const onSubmitHandler = (e) => {
        e.preventDefault();

        signInHandler({
            Username: username,
            Password: password,
        });
    };

    const isFormValid = () => {
        const { isValid } = validateSignInForm({ username, password });

        return isValid;
    };

    return (
        <form onSubmit={(e) => onSubmitHandler(e)} className="crm-form-card">
            <div className="crm-form-item">
                <ExtendedInput
                    placeholder={t(l.UserName)}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div className="crm-form-item">
                <ExtendedInput
                    type="password"
                    placeholder={t(l.Password)}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="crm-form-item">
                <Button disabled={!isFormValid()} flexibility="full-width">
                    {t(l.SignIn)}
                </Button>
            </div>
        </form>
    );
};

SignInForm.propTypes = {
    signInHandler: PropTypes.func.isRequired,
    signInFormState: PropTypes.object.isRequired,
};

export default memo(SignInForm);
