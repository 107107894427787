import React, { memo } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const PrivateRoute = ({ path, titleKey, component }) => {
    const { pathname, search } = useLocation();
    const { isLoggedIn } = useSelector((state) => state.auth);

    const Component = component;
    const token = localStorage.getItem('accessToken');

    if (isLoggedIn === true) {
        return (
            <Route path={path}>
                <Component titleKey={titleKey} />
            </Route>
        );
    } else if (isLoggedIn !== null || isEmpty(token)) {
        if (isEmpty(token)) {
            localStorage.setItem('directUrl', pathname + search);
        }
        return <Redirect to="/signin" />;
    }
};

PrivateRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.object.isRequired,
    titleKey: PropTypes.string,
};

export default memo(PrivateRoute);
