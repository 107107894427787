import React, { memo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import CompositeSegmentInfoTab from './CompositeSegmentInfoTab';
//Import UI Components
import { Tabs, Tab, BusyLoader, Tooltip } from '@geneui/components';
// Import Services
import { SegmentHttpService } from 'services/http';
import { getSegmentClientCount, getCampaignsBySegment, getStaticSegmentTop200Client } from './service';
import { getQueryConfigurationsList } from 'services/segment';
// Import Constants
import { AlertTypes, SegmentTypesLabels } from 'constants/common';
import { cloneDeep, isNil } from 'lodash';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Actions
import { DynamicSegmentActions } from 'actions';
// Import SCSS
import 'assets/scss/compositeSegmentInfo.scss';

const { error } = AlertTypes;

const CompositeSegmentInfo = ({ compositeConfiguration }) => {
    const { t } = useTranslation();
    const { setDefaultFilterList } = DynamicSegmentActions;
    const { showToaster } = useToaster();
    const { doGetRequest } = useRequest();
    const errorHandler = (alertMessage) => showToaster(error, alertMessage);

    const dispatch = useDispatch();
    const queryConfigurations = useSelector((state) => state.queryConfigurations);

    const [isLoading, setIsLoading] = useState(false);
    const [childSegments, setChildSegments] = useState();

    const { isSendedTabsRequest } = useRef({ isSendedTabsRequest: [] }).current;

    const {
        getSegmentClientCountRequest,
        getCampaignsBySegmentRequest,
        getSegmentById,
        getQueryConfigurationsListRequest,
        getStaticSegmentTop200ClientRequest,
    } = useRef({
        getSegmentById: SegmentHttpService.getSegmentById(),
        getSegmentClientCountRequest: getSegmentClientCount(),
        getCampaignsBySegmentRequest: getCampaignsBySegment(),
        getQueryConfigurationsListRequest: getQueryConfigurationsList(),
        getStaticSegmentTop200ClientRequest: getStaticSegmentTop200Client(),
    }).current;

    const getSegments = (segmentIds) => {
        return segmentIds.map((element) => {
            return doGetRequest(getSegmentById.request, { queryString: { segmentId: element } });
        });
    };

    const getCampaignsBySegmentData = (segmentId, index) => {
        getCampaignsBySegmentRequest.request(
            segmentId,
            (data) => {
                setChildSegments((prev) => {
                    const tmpResult = cloneDeep(prev);
                    tmpResult[index].usedInCampaigns = data;
                    return tmpResult;
                });
            },
            errorHandler,
        );
    };

    const mapSegmentsInfo = (responses) => {
        // for promise all need has error but case dows not cover
        let hasError = false;
        const segmentsData = responses.map((response) => {
            // for cases when promise is null
            if (isNil(response)) return response;

            const { HasError, Data } = response;
            if (!HasError) {
                return Data;
            }
            // case when one of one promise has error
            hasError = true;
            return null;
        });
        return { Data: segmentsData, HasError: hasError };
    };

    const mapChildSegments = (segmentsData) => {
        return segmentsData.map((element, index) => {
            isSendedTabsRequest[index] = false;
            return {
                ...element,
                numberOfCustomers: { Total: 0, Count: 0, isLoading: false },
                usedInCampaigns: null,
                segmentData: null,
            };
        });
    };

    const getSegmentClientCountData = (segmentId, index) => {
        setChildSegments((prev) => {
            const tmpResult = cloneDeep(prev);
            tmpResult[index].numberOfCustomers.isLoading = true;
            return tmpResult;
        });

        getSegmentClientCountRequest.request(
            segmentId,
            (data) => {
                setChildSegments((prev) => {
                    const tmpResult = cloneDeep(prev);
                    tmpResult[index].numberOfCustomers = { ...data, isLoading: false };
                    return tmpResult;
                });
            },
            errorHandler,
        );
    };

    const tabChangeHandler = (tabIndex) => {
        if (childSegments[tabIndex].Type === SegmentTypesLabels.Dynamic) {
            getQueryConfigurationsListRequest.request(
                dispatch,
                queryConfigurations,
                childSegments[tabIndex],
                setDefaultFilterList,
                t,
            );
        }

        if (!isSendedTabsRequest[tabIndex]) {
            getCampaignsBySegmentData(childSegments[tabIndex].SegmentId, tabIndex);
            if (childSegments[tabIndex].Type === SegmentTypesLabels.Static) {
                getStaticSegmentTop200ClientRequest.request(
                    childSegments[tabIndex].SegmentId,
                    (data) => {
                        setChildSegments((prev) => {
                            const tmpResult = cloneDeep(prev);

                            tmpResult[tabIndex].segmentData = {
                                ...data,
                                SegmentId: tmpResult[tabIndex].SegmentId,
                                Name: tmpResult[tabIndex].Name,
                            };
                            return tmpResult;
                        });
                    },
                    errorHandler,
                );
            }
            isSendedTabsRequest[tabIndex] = true;
        }
    };

    const init = () => {
        setIsLoading(true);
        Promise.all(getSegments(compositeConfiguration.SegmentIds)).then((responses) => {
            const mapSegmentsData = mapSegmentsInfo(responses);
            const data = mapChildSegments(mapSegmentsData.Data);
            setChildSegments(data);
            setIsLoading(mapSegmentsData.HasError);
        });
    };

    const cleanUp = () => {
        return () => {
            getSegmentById.cancel('CompositeSegmentInfo:getSegmentById');
            getSegmentClientCountRequest.cancel('CompositeSegmentInfo:getSegmentClientCountRequest');
            getCampaignsBySegmentRequest.cancel('CompositeSegmentInfo:getCampaignsBySegmentRequest');
            getQueryConfigurationsListRequest.cancel('CompositeSegmentInfo:getQueryConfigurationsListRequest');
            getStaticSegmentTop200ClientRequest.cancel('CompositeSegmentInfo:getStaticSegmentTop200ClientRequest');
        };
    };

    useEffect(() => {
        if (!isLoading && !isNil(childSegments)) {
            tabChangeHandler(0); //for first time when loading
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(init, []);

    useEffect(cleanUp, []);

    return (
        <>
            <BusyLoader
                isBusy={isLoading || isNil(childSegments)}
                type="spinner"
                spinnerSize="medium"
                className="crm-justify-content-center"
            >
                <>
                    {!isLoading && !isNil(childSegments) && (
                        <Tabs
                            type="box"
                            position="top"
                            onChange={tabChangeHandler}
                            className="composite-segment-info-tabs-container"
                        >
                            {childSegments.map((item, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        title={
                                            <Tooltip text={item.Name}>
                                                <div>
                                                    <p className="ellipsis-text">{item.Name}</p>
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <CompositeSegmentInfoTab
                                            key={index}
                                            segmentId={item.SegmentId}
                                            name={item.Name}
                                            description={item.Description}
                                            type={item.Type}
                                            numberOfCustomers={item.numberOfCustomers}
                                            usedInCampaigns={item.usedInCampaigns}
                                            compositeConfiguration={item.CompositeConfiguration}
                                            segmentData={item.segmentData}
                                            targetObject={item.TargetObject}
                                            onClickNumberOfCustomers={() =>
                                                getSegmentClientCountData(item.SegmentId, index)
                                            }
                                        />
                                    </Tab>
                                );
                            })}
                        </Tabs>
                    )}
                </>
            </BusyLoader>
        </>
    );
};

CompositeSegmentInfo.propTypes = {
    compositeConfiguration: PropTypes.object,
};

export default memo(CompositeSegmentInfo);
