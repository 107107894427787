import React, { memo } from 'react';
import PropTypes from 'prop-types';
// Import UI Components
import { Option, Icon } from '@geneui/components';

const ParentOption = ({ label, value, childrenList, onSelect, optionIndex, isDisabled }) => {
    return (
        <Option
            title={label}
            rightCustomElement={<Icon type="bc-icon-arrow-right" />}
            onClick={onSelect(label, value, childrenList, optionIndex)}
        />
    );
};

ParentOption.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    optionIndex: PropTypes.number.isRequired,
    childrenList: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};

ParentOption.defaultProps = {
    isDisabled: false,
};

export default memo(ParentOption);
