import { SignOutActionTypes as Types } from './types';

const signOutFetch = (status) => ({
    type: Types.SIGN_OUT_FETCH,
    payload: status,
});

const signOutSuccessed = (status) => ({
    type: Types.SIGN_OUT_SUCCESSED,
    payload: status,
});

const signOutFailed = (error) => ({
    type: Types.SIGN_OUT_FAILED,
    payload: error,
});

export default {
    signOutFetch,
    signOutSuccessed,
    signOutFailed,
};
