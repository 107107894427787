import { useCallback, useRef } from 'react';
import { isNil } from 'lodash';

const useAutocompleteRequest = () => {
    const getRequestRef = useRef();

    const cancelRequest = useCallback((message) => {
        if (!isNil(getRequestRef.current)) {
            getRequestRef.current.cancel(message ?? 'useAutocompleteRequest: cancelRequest');
        }
    }, []);

    const getAutocompleteRequest = useCallback((getRequest) => {
        cancelRequest();
        getRequestRef.current = getRequest();

        return getRequestRef.current;
    }, [cancelRequest]);

    return { getAutocompleteRequest, cancelRequest };
};

export default useAutocompleteRequest;
