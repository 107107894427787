/* eslint-disable react/display-name */
import React from 'react';
// Import Components
import { TittleLink } from 'components';
import { AdminProviderPartnersInfo } from 'components/AdminProviderList';
// Import Constants
import { l, BooleanTypeLabels, EmailProviderTypeLabels } from 'constants/common';

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: l.ProviderId,
                dataKey: 'EmailProviderId',
                sortable: true,
                colRenderer: (id) => <TittleLink isClickable={false} name={id} />,
            },
            {
                text: l.Name,
                dataKey: 'Name',
                sortable: true,
                colRenderer: (name) => <TittleLink isClickable={false} name={name} />,
            },
            {
                text: l.FromAddress,
                dataKey: 'FromAddress',
                sortable: true,
                colRenderer: (address) => <TittleLink isClickable={false} name={address} />,
            },
            {
                text: l.Host,
                dataKey: 'Host',
                sortable: true,
                colRenderer: (host) => <TittleLink isClickable={false} name={host} />,
            },
            {
                text: l.Port,
                dataKey: 'Port',
                sortable: true,
            },
            {
                text: t(l.Type),
                dataKey: 'Type',
                sortable: true,
                colRenderer: (type) => <>{EmailProviderTypeLabels[type]}</>,
            },
            {
                text: l.UserName,
                dataKey: 'UserName',
                sortable: true,
                colRenderer: (userName) => <TittleLink isClickable={false} name={userName} />,
            },
            {
                text: l.Parallelism,
                dataKey: 'MaxDegreeOfParallelism',
                sortable: true,
            },
            {
                text: l.DelayTimeout,
                dataKey: 'DelayTimout',
                sortable: true,
            },
            {
                text: l.Partners,
                dataKey: 'Partners',
                sortable: false,
                colRenderer: (partners) => <AdminProviderPartnersInfo partners={partners} />,
            },
            {
                text: l.IsDefault,
                dataKey: 'IsDefault',
                sortable: true,
                colRenderer: (IsDefault) => <>{BooleanTypeLabels[IsDefault]}</>,
            },
            {
                text: l.SSL,
                dataKey: 'EnableSsl',
                sortable: true,
                colRenderer: (isEnable) => <>{BooleanTypeLabels[isEnable]}</>,
            },
            {
                text: l.NotInjectUnsubscribeLink,
                dataKey: 'NotInjectUnsubscribeLink',
                sortable: false,
                colRenderer: (isEnable) => <>{BooleanTypeLabels[isEnable ?? false]}</>,
            },
            {
                text: l.Description,
                dataKey: 'Description',
                sortable: true,
                colRenderer: (description) => <TittleLink isClickable={false} name={description ?? l.NA} />,
            },
            {
                text: l.PartnerEmail,
                dataKey: 'PartnerEmail',
                sortable: true,
                colRenderer: (partnerEmail) => <TittleLink isClickable={false} name={partnerEmail ?? l.NA} />,
            },
        ],
    };
};

export { DataTableColumnsConfigs };
