import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop, isNil, isEmpty } from 'lodash';
// Import UI components
import { Icon, ExtendedInput, SkeletonLoader } from '@geneui/components';
// Import Components
import { PreSaveValidationModal } from 'components';
import PageHeadlineActionBar from './PageHeadlineActionBar';
// Import Hooks
import { useGoToRoute, useValidator } from 'hooks';
// Import Constants
import { l, ModalsActions } from 'constants/common';
import { redirectMetaData } from './config';
// Import SCSS
import 'assets/scss/pageHeadline.scss';

const PageHeadline = ({
    isDataReady,
    titleKey,
    defaultName,
    defaultDescription,
    isLoading,
    handleReset,
    handleSave,
    handleSaved,
    handleNameChange,
    handleDescriptionChange,
    additionalSettings,
    getCustomValidationTypeErrorText,
    doValidation,
    preSaveModalContent,
    isValidPreSaveModalContent,
    isViewPage,
    isTitleAndDescriptionEditable,
    isVisibleTitleAndDescription,
    preSaveModalAlertContent,
    withWarningsAlertContent,
    visibleLeavePageOption,
    preSaveInfoLabel,
    ...rest
}) => {
    const { t } = useTranslation();
    const { goToPreviousRoute } = useGoToRoute();
    const validator = useValidator(getCustomValidationTypeErrorText);

    const [isPreSaveModalOpen, setPreSaveModalOpen] = useState(false);
    const [name, setName] = useState(defaultName);
    const [isVisibleLeavePageOption, setIsVisibleLeavePageOption] = useState(false); // TODO: remove this when in all pages is visible leave page option
    const [description, setDescription] = useState(defaultDescription);
    const [saveButtonLabel, setSaveButtonLabel] = useState(l.SaveAsDraft);

    const buttonWithDropDownConfig = { SaveAsDraft: handleSave, ...additionalSettings };

    useEffect(() => {
        // TODO temp solution for reset and leave page
        setIsVisibleLeavePageOption(
            titleKey.includes('Report') ||
                titleKey.endsWith('CustomerJourney') ||
                titleKey.includes('DynamicSegment') ||
                titleKey.includes('ConvertToReport') ||
                titleKey.includes('CompositeSegment') ||
                titleKey.includes('NotificationCampaign') ||
                titleKey.includes('CustomerJourneyVisualization') ||
                (titleKey.includes('ActionCampaign') && visibleLeavePageOption),
        );
    }, [titleKey, visibleLeavePageOption]);

    useEffect(() => {
        setName(defaultName);
        handleNameChange(defaultName);
        setDescription(defaultDescription);
        handleDescriptionChange(defaultDescription);
        validator({ Name: defaultName, Description: defaultDescription });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultName, defaultDescription]);

    const backButtonClickHandler = () => {
        goToPreviousRoute();
    };

    const nameChangeHandler = (e, value) => {
        const name = !isNil(value) ? value : e?.target?.value?.trimStart();
        const nameValue = !isEmpty(name) ? name : null;
        setName(nameValue);
        validator({ Name: nameValue });
        handleNameChange(nameValue);
    };

    const descriptionChangeHandler = (e, value) => {
        const description = !isNil(value) ? value : e?.target?.value?.trimStart();
        const descriptionValue = !isEmpty(description) ? description : null;
        setDescription(descriptionValue);
        validator({ Description: descriptionValue });
        handleDescriptionChange(descriptionValue);
    };

    const nameBlurHandler = (e) => {
        nameChangeHandler(null, e.target.value.trimEnd());
    };

    const descriptionBlurHandler = (e) => {
        descriptionChangeHandler(null, e.target.value.trimEnd());
    };

    const resetHandler = () => {
        // TODO temp solution for reset
        if (isVisibleLeavePageOption) {
            setName(defaultName);
            handleNameChange(defaultName);
            setDescription(defaultDescription);
            handleDescriptionChange(defaultDescription);
        } else {
            if (isTitleAndDescriptionEditable) {
                setName(null);
                handleNameChange(null);
                setDescription(null);
                handleDescriptionChange(null);
            }
        }
        handleReset();
    };

    const onPreSavePopUpCancel = (action, data) => {
        if (action === ModalsActions.SAVE) {
            handleSaved(data);
        }
        setPreSaveModalOpen(false);
    };

    const saveButtonHandler = (mode) => {
        const isPageValid = doValidation(mode) ? validator() : false;
        if (!isPageValid && !isLoading && isDataReady !== false) {
            setPreSaveModalOpen(true);
        }
    };

    const saveHandler = (pageName, pageDescription, leavePage) => {
        // TODO: need reset existing data from page.
        return buttonWithDropDownConfig[saveButtonLabel](pageName, pageDescription, saveButtonLabel, leavePage);
    };

    return (
        <div className="crm-page-headline">
            {isPreSaveModalOpen && !isViewPage && (
                <PreSaveValidationModal
                    defaultName={name}
                    defaultDescription={description}
                    title={redirectMetaData[titleKey].title}
                    route={redirectMetaData[titleKey].route}
                    pageName={redirectMetaData[titleKey].pageName}
                    onCancel={onPreSavePopUpCancel}
                    handleDescriptionChange={descriptionChangeHandler}
                    handleNameChange={nameChangeHandler}
                    onOk={saveHandler}
                    handleReset={resetHandler}
                    saveButtonLabel={saveButtonLabel}
                    bodyContent={preSaveModalContent}
                    isValidBodyContent={isValidPreSaveModalContent}
                    isVisibleLeavePageOption={isVisibleLeavePageOption}
                    isVisibleTitleAndDescription={isVisibleTitleAndDescription}
                    alertContent={preSaveModalAlertContent}
                    withWarningsAlertContent={withWarningsAlertContent}
                    infoLabel={preSaveInfoLabel}
                />
            )}
            <div className="crm-page-headline_back-button-part">
                <Icon type="bc-icon-close" onClick={backButtonClickHandler} />
            </div>

            <div className="crm-page-headline_page-name-part crm-page-headline-name-col">
                <p>{t(titleKey)}</p>
            </div>

            <div className="crm-page-headline_content-part">
                {!isViewPage && (
                    <>
                        <div className="page-h-left">
                            <div>
                                {/* TODO: move to scss */}
                                <SkeletonLoader isBusy={isLoading} height={'20px'} width={'150px'}>
                                    <ExtendedInput
                                        className="crm-page-headline_description"
                                        inputSize="small"
                                        placeholder={t(l.Untitled)}
                                        defaultValue={defaultName}
                                        value={name}
                                        onChange={nameChangeHandler}
                                        onBlur={nameBlurHandler}
                                        appearance="outline"
                                        readOnly={!isTitleAndDescriptionEditable}
                                        tooltipText={!isTitleAndDescriptionEditable && name}
                                    />
                                </SkeletonLoader>
                                {/* TODO: move to scss */}
                                <SkeletonLoader isBusy={isLoading} height={'20px'} width={'100px'}>
                                    <ExtendedInput
                                        className="crm-page-headline_name"
                                        inputSize="small"
                                        placeholder={t(l.TypeDescription)}
                                        defaultValue={defaultDescription}
                                        value={description}
                                        onBlur={descriptionBlurHandler}
                                        onChange={descriptionChangeHandler}
                                        readOnly={!isTitleAndDescriptionEditable}
                                        tooltipText={!isTitleAndDescriptionEditable && description}
                                    />
                                </SkeletonLoader>
                            </div>
                        </div>
                        <div className="page-h-right">
                            <PageHeadlineActionBar
                                handleReset={resetHandler}
                                handleSave={saveButtonHandler}
                                setSaveButtonLabel={setSaveButtonLabel}
                                hasAdditionalSettings={!isEmpty(additionalSettings)}
                                buttonWithDropDownConfig={additionalSettings}
                                disableSave={isNil(isDataReady) ? false : !isDataReady}
                                disableReset={isNil(isDataReady) ? false : !isDataReady}
                                {...rest}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

PageHeadline.propTypes = {
    isDataReady: PropTypes.bool,
    titleKey: PropTypes.string.isRequired,
    defaultName: PropTypes.string,
    defaultDescription: PropTypes.string,
    isLoading: PropTypes.bool,
    handleReset: PropTypes.func,
    handleSave: PropTypes.func,
    handleNameChange: PropTypes.func,
    handleDescriptionChange: PropTypes.func,
    validator: PropTypes.func,
    additionalSettings: PropTypes.object,
    getCustomValidationTypeErrorText: PropTypes.any,
    doValidation: PropTypes.func,
    handleSaved: PropTypes.func,
    preSaveModalContent: PropTypes.node,
    isValidPreSaveModalContent: PropTypes.bool,
    isViewPage: PropTypes.bool,
    isTitleAndDescriptionEditable: PropTypes.bool,
    isVisibleTitleAndDescription: PropTypes.bool,
    preSaveModalAlertContent: PropTypes.node,
    withWarningsAlertContent: PropTypes.bool,
    visibleLeavePageOption: PropTypes.bool,
    preSaveInfoLabel: PropTypes.string,
};

PageHeadline.defaultProps = {
    defaultName: null,
    defaultDescription: null,
    isLoading: false,
    isDataReady: null, // TODO: should be change default value isDataReady null -> false
    handleNameChange: noop,
    handleDescriptionChange: noop,
    handleSaved: noop,
    handleReset: noop,
    handleSave: noop,
    additionalSettings: {},
    doValidation: () => true,
    isViewPage: false,
    isVisibleTitleAndDescription: true,
    isTitleAndDescriptionEditable: true,
    isValidPreSaveModalContent: true,
    withWarningsAlertContent: false,
    visibleLeavePageOption: true,
};

export default memo(PageHeadline);
