import { TemplateTypesLabels, l } from 'constants/common';
import * as Yup from 'yup';

const DropDownRequestTypes = {
    GET: 'GET',
};

const TestSendToInputElementsConfig = {
    [TemplateTypesLabels.Internal]: {
        placeholder: l.InputClientId,
        fieldName: 'Id',
        initialValues: {
            Id: '',
        },
        validationSchema: (t) =>
            Yup.object({
                Id: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.InputClientId) })),
            }),
    },
    [TemplateTypesLabels.Email]: {
        placeholder: l.EmailAddress,
        fieldName: 'Email',
        initialValues: {
            Email: '',
        },
        validationSchema: (t) =>
            Yup.object({
                Email: Yup.string()
                    .required(t(l.CannotBeEmpty, { fieldName: t(l.EmailAddress) }))
                    .matches(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        t(l.EmailIsNotInCorrectFormat),
                    ),
            }),
    },
    [TemplateTypesLabels.Telegram]: {
        placeholder: l.TelegramChatId,
        fieldName: 'TelegramChatId',
        initialValues: {
            TelegramChatId: '',
        },
        validationSchema: (t) =>
            Yup.object({
                TelegramChatId: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.TelegramChatId) })),
            }),
    },
    [TemplateTypesLabels.SMS]: {
        placeholder: l.PhoneNumber,
        fieldName: 'MobilePhone',
        initialValues: {
            MobilePhone: '',
        },
        validationSchema: (t) =>
            Yup.object({
                MobilePhone: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.PhoneNumbe) })),
            }),
    },
    [TemplateTypesLabels.PushNotification]: {
        placeholder: l.InputClientId,
        fieldName: 'Id',
        initialValues: {
            Id: '',
        },
        validationSchema: (t) =>
            Yup.object({
                Id: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.InputClientId) })),
            }),
    },
    [TemplateTypesLabels.Viber]: {
        placeholder: l.PhoneNumber,
        fieldName: 'PhoneNumber',
        initialValues: {
            PhoneNumber: '',
        },
        validationSchema: (t) =>
            Yup.object({
                PhoneNumber: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.PhoneNumber) })),
            }),
    },
};

export { DropDownRequestTypes, TestSendToInputElementsConfig };
