import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { List } from 'react-virtualized';
// Import UI Components
import { Empty, Option } from '@geneui/components';
import PartnerRow from './PartnerRow';
// Import Constants
import { l } from 'constants/common';
//Import SCSS
import 'assets/scss/partners.scss';

const PartnersListWithPartnerGroup = ({
    data,
    listWidth,
    listHeight,
    listHeightWithGroupLabel,
    listRowHeight,
    partnerSelectHandler,
    withGroupLabel,
    selectedPropertyKey,
}) => {
    const { t } = useTranslation();
    const { settings } = useSelector((state) => state.header);
    const { ActivePartner } = settings;
    const PartnerId = ActivePartner.PartnerId;

    const getGroupRow = useMemo(() => {
        return <span>{`${data.partnerGroupName} (${data.partners.length})`}</span>;
    }, [data]);

    const calculatedListHeight = useMemo(() => {
        if (data.partners.length >= 5) {
            if (withGroupLabel) {
                return listHeightWithGroupLabel;
            } else {
                return listHeight;
            }
        } else {
            return listRowHeight * data.partners.length;
        }
    }, [data.partners.length, listHeight, listHeightWithGroupLabel, listRowHeight, withGroupLabel]);

    return (
        <div className="partnerGroup">
            {withGroupLabel && !isEmpty(data.partners) && (
                <Option leftCustomElement={getGroupRow} className="partnerGroupLabel" sticky="top" />
            )}
            {isEmpty(data.partners) ? (
                <Empty title={t(l.NoDataToDisplay)} size="small" />
            ) : (
                <List
                    width={listWidth}
                    height={calculatedListHeight}
                    rowCount={data.partners.length}
                    rowHeight={listRowHeight}
                    className="virtualized-partner-list"
                    rowRenderer={({ index, style }) => {
                        const currentPartner = data.partners[index];
                        return (
                            <Option
                                style={style}
                                key={`${currentPartner[selectedPropertyKey]}_${currentPartner.label}`}
                                leftCustomElement={<PartnerRow data={currentPartner} />}
                                onClick={() => partnerSelectHandler(currentPartner)}
                                active={currentPartner[selectedPropertyKey] === PartnerId}
                            />
                        );
                    }}
                />
            )}
        </div>
    );
};

PartnersListWithPartnerGroup.propTypes = {
    data: PropTypes.object,
    selectedPropertyKey: PropTypes.string,
    listWidth: PropTypes.number,
    listHeight: PropTypes.number,
    listHeightWithGroupLabel: PropTypes.number,
    listRowHeight: PropTypes.number,
    partnerSelectHandler: PropTypes.func.isRequired,
    withGroupLabel: PropTypes.bool,
};

PartnersListWithPartnerGroup.defaultProps = {
    listWidth: 258,
    listHeightWithGroupLabel: 165,
    listHeight: 250,
    listRowHeight: 40,
    selectedPropertyKey: 'value',
    withGroupLabel: true,
};

export default PartnersListWithPartnerGroup;
