/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { isEmpty, isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { SkeletonLoader, Tooltip, Icon, Popover } from '@geneui/components';
// Import Components
import { SegmentModal, TemplateInfoModal, BonusModal, AccessControl } from 'components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import {
    l,
    SegmentTypesLabels,
    TemplateTypesLabels,
    DateTimeWithoutSecondsFormat,
    DateFormat,
    TimeZonePack,
    PERMISSIONS,
} from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

const CampaignGeneralDetails = ({
    campaignData,
    templateData,
    schedule,
    segmentData,
    bonus,
    bonusesSent,
    sent,
    goal,
    hasGoal,
    recurrenceData,
}) => {
    const { t } = useTranslation();

    const [isVisibleSegmentInfo, setIsVisibleSegmentInfo] = useState(false);
    const [isVisibleTemplateInfo, setIsVisibleTemplateInfo] = useState(false);
    const [isVisibleSBonusInfo, setIsVisibleBonusInfo] = useState(false);

    const getContent = (data) => {
        return isNil(data) ? l.NA : data;
    };

    const openSegmentModal = () => {
        setIsVisibleSegmentInfo(true);
    };

    const openTemplateModal = () => {
        setIsVisibleTemplateInfo(true);
    };

    const openBonusModal = () => {
        setIsVisibleBonusInfo(true);
    };

    return (
        <>
            {isVisibleSegmentInfo && (
                <SegmentModal
                    targetObject={segmentData?.TargetObject}
                    segmentId={segmentData?.SegmentId}
                    segmentType={segmentData?.Type}
                    handleClose={() => setIsVisibleSegmentInfo(false)}
                    isVisible={isVisibleSegmentInfo}
                />
            )}
            {isVisibleTemplateInfo && (
                <TemplateInfoModal
                    templateId={templateData?.TemplateId}
                    childItemsInfo={templateData?.ChildItemsInfo}
                    handleClose={() => setIsVisibleTemplateInfo(false)}
                    isVisible={isVisibleTemplateInfo}
                />
            )}
            {isVisibleSBonusInfo && (
                <BonusModal
                    title={bonus?.Name}
                    description={bonus?.Description}
                    startDate={bonus?.StartDate}
                    endDate={bonus?.EndDate}
                    type={bonus.Type}
                    bonusDetails={bonus.BonusDetails}
                    handleClose={() => setIsVisibleBonusInfo(false)}
                    isVisible={isVisibleSBonusInfo}
                    id={bonus?.Id}
                    state={bonus?.State}
                    wallet={bonus?.BalanceType}
                />
            )}
            <div className="details-section">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name">{t(l.GeneralDetails)}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="general-details-content">
                    <div className="general-details-column">
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.Name)}</p>
                            <SkeletonLoader isBusy={isNil(campaignData)}>
                                {!isNil(campaignData) && <p className="g-details-subtitle">{campaignData.Name}</p>}
                            </SkeletonLoader>
                        </div>
                        {!isNil(schedule) ? (
                            <>
                                <div className="general-details-column-info">
                                    <p className="g-details-title">{t(l.StartDate)}</p>
                                    <SkeletonLoader isBusy={isNil(schedule?.StartDate)}>
                                        {!isNil(schedule?.StartDate) && (
                                            <p className="g-details-subtitle">
                                                {isEmpty(schedule?.CronTime)
                                                    ? customMomentWithoutTimezoneConversion(schedule?.StartDate).format(
                                                          DateTimeWithoutSecondsFormat,
                                                      )
                                                    : customMomentWithoutTimezoneConversion(schedule?.StartDate).format(
                                                          DateFormat,
                                                      )}
                                            </p>
                                        )}
                                    </SkeletonLoader>
                                </div>
                                <div className="general-details-column-info">
                                    <p className="g-details-title">{t(l.EndDate)}</p>
                                    <SkeletonLoader isBusy={isNil(schedule?.EndDate)}>
                                        {!isNil(schedule?.EndDate) && (
                                            <p className="g-details-subtitle">
                                                {isEmpty(schedule?.CronTime)
                                                    ? customMomentWithoutTimezoneConversion(schedule?.EndDate).format(
                                                          DateTimeWithoutSecondsFormat,
                                                      )
                                                    : customMomentWithoutTimezoneConversion(schedule?.EndDate).format(
                                                          DateFormat,
                                                      )}
                                            </p>
                                        )}
                                    </SkeletonLoader>
                                </div>
                                {!isEmpty(schedule?.CronTime) && (
                                    <>
                                        <div className="general-details-column-info">
                                            <p className="g-details-title">
                                                {t(l.StartTime)}
                                                <Tooltip text={schedule?.CronSummary} position="top">
                                                    <Icon type="bc-icon-info" />
                                                </Tooltip>
                                            </p>
                                            <SkeletonLoader isBusy={isNil(schedule?.CronTime)}>
                                                {!isNil(schedule?.CronTime) && (
                                                    <>
                                                        <p className="g-details-subtitle">{schedule?.CronTime}</p>
                                                    </>
                                                )}
                                            </SkeletonLoader>
                                        </div>
                                        <div className="general-details-column-info">
                                            <p className="g-details-title">{t(l.ScheduleType)}</p>
                                            <SkeletonLoader isBusy={isNil(schedule?.ScheduleType)}>
                                                {!isNil(schedule?.ScheduleType) && (
                                                    <p className="g-details-subtitle">{t(schedule?.ScheduleType)}</p>
                                                )}
                                            </SkeletonLoader>
                                        </div>
                                        <div className="general-details-column-info">
                                            <p className="g-details-title">{t(l.RecurrenceCount)}</p>
                                            <SkeletonLoader isBusy={isNil(recurrenceData)}>
                                                {!isNil(recurrenceData) && (
                                                    <Popover
                                                        className="next-run-date-popover-container"
                                                        extendTargetWidth={false}
                                                        Content={recurrenceData?.UpcomingExecutions?.map(
                                                            (executionDate, i) => {
                                                                return (
                                                                    <p key={i}>
                                                                        {moment(executionDate).format(DateFormat)}
                                                                    </p>
                                                                );
                                                            },
                                                        )}
                                                        align="end"
                                                        position="right"
                                                    >
                                                        <button
                                                            disabled={recurrenceData?.Count === 0}
                                                            className="blue-color-button"
                                                        >
                                                            {recurrenceData?.Count}
                                                        </button>
                                                    </Popover>
                                                )}
                                            </SkeletonLoader>
                                        </div>
                                    </>
                                )}
                                <div className="general-details-column-info">
                                    <p className="g-details-title">{t(l.TimeZone)}</p>
                                    <SkeletonLoader isBusy={isNil(schedule?.TimeZone)}>
                                        {!isNil(schedule?.TimeZone) && (
                                            <p className="g-details-subtitle">{TimeZonePack[schedule?.TimeZone]}</p>
                                        )}
                                    </SkeletonLoader>
                                </div>
                            </>
                        ) : (
                            <div className="general-details-column-info">
                                <p className="g-details-title">{t(l.Schedule)}</p>
                                <SkeletonLoader isBusy={isNil(campaignData)}>
                                    {!isNil(campaignData) && (
                                        <p className="g-details-subtitle">{t(l.ScheduleOffImmediately)}</p>
                                    )}
                                </SkeletonLoader>
                            </div>
                        )}
                        {hasGoal && (
                            <div className="general-details-column-info">
                                <p className="g-details-title">{t(l.Goal)}</p>
                                <SkeletonLoader isBusy={isNil(goal?.Name)}>
                                    {!isNil(goal?.Name) && <p className="g-details-subtitle">{goal.Name}</p>}
                                </SkeletonLoader>
                            </div>
                        )}
                    </div>
                    <div className="general-details-column">
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.AttachedSegment)}</p>
                            <SkeletonLoader isBusy={isNil(segmentData?.Name)}>
                                {!isNil(segmentData) && (
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <p
                                        className={`g-details-subtitle ${!isNil(segmentData?.Name) && 'active'}`}
                                        onClick={!isNil(segmentData?.Name) ? openSegmentModal : noop}
                                    >
                                        {getContent(segmentData.Name)}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.SegmentType)}</p>
                            <SkeletonLoader isBusy={isNil(segmentData?.Type)}>
                                {!isNil(segmentData) && (
                                    <p className="g-details-subtitle">
                                        {getContent(SegmentTypesLabels[segmentData.Type])}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.AttachedTemplate)}</p>
                            <SkeletonLoader isBusy={isNil(templateData)}>
                                {!isNil(templateData) && (
                                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                    <p
                                        className={`g-details-subtitle ${!isNil(templateData?.Name) && 'active'}`}
                                        onClick={!isNil(templateData?.Name) ? openTemplateModal : noop}
                                    >
                                        {getContent(templateData.Name)}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <AccessControl permissions={PERMISSIONS.ViewAwards}>
                            <div className="general-details-column-info">
                                <p className="g-details-title">{t(l.AttachedBonus)}</p>
                                <SkeletonLoader isBusy={isNil(bonus)}>
                                    {!isNil(bonus) && (
                                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                                        <p
                                            className={`g-details-subtitle ${!isNil(bonus?.Name) && 'active'}`}
                                            onClick={!isNil(bonus?.Name) ? openBonusModal : noop}
                                        >
                                            {getContent(bonus?.Name)}
                                        </p>
                                    )}
                                </SkeletonLoader>
                            </div>
                        </AccessControl>
                        {hasGoal && (
                            <div className="general-details-column-info">
                                <p className="g-details-title">{t(l.GoalProgress)}</p>
                                <SkeletonLoader isBusy={isNil(goal?.GoalValue)}>
                                    {!isNil(goal?.GoalValue) && (
                                        <p className="g-details-subtitle">
                                            {+goal.GoalValue !== 0
                                                ? Math.ceil((goal.GoalReachedClientCount / goal.GoalValue) * 100)
                                                : 0}
                                            {' %'}
                                        </p>
                                    )}
                                </SkeletonLoader>
                            </div>
                        )}
                    </div>
                    <div className="general-details-column">
                        <AccessControl permissions={PERMISSIONS.ViewAwards}>
                            <div className="general-details-column-info">
                                <p className="g-details-title">{t(l.BonusesSent)}</p>
                                <SkeletonLoader isBusy={isNil(bonusesSent)}>
                                    {!isNil(bonusesSent) && (
                                        <p className="g-details-subtitle">
                                            {isNil(bonusesSent?.count)
                                                ? getContent(null)
                                                : `${bonusesSent?.count}/${bonusesSent?.total}`}
                                        </p>
                                    )}
                                </SkeletonLoader>
                            </div>
                        </AccessControl>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.Sent)}</p>
                            <SkeletonLoader isBusy={isNil(sent)}>
                                {!isNil(sent) && (
                                    <p className="g-details-subtitle">
                                        {isNil(sent?.count) ? getContent(null) : `${sent?.count}/${sent?.total}`}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.ProviderName)}</p>
                            <SkeletonLoader isBusy={isNil(campaignData)}>
                                {!isNil(campaignData) && (
                                    <p className="g-details-subtitle">
                                        {getContent(campaignData.MessageProvider?.Name)}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>

                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.ProviderType)}</p>
                            <SkeletonLoader isBusy={isNil(campaignData)}>
                                {!isNil(campaignData) && (
                                    <p className="g-details-subtitle">
                                        {getContent(campaignData.MessageProvider?.Type)}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>
                        <div className="general-details-column-info">
                            <p className="g-details-title">{t(l.Channel)}</p>
                            <SkeletonLoader isBusy={isNil(templateData)}>
                                {!isNil(templateData) && (
                                    <p className="g-details-subtitle">
                                        {getContent(TemplateTypesLabels[templateData.DeliveryMethod])}
                                    </p>
                                )}
                            </SkeletonLoader>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

CampaignGeneralDetails.propTypes = {
    schedule: PropTypes.object.isRequired,
    segmentData: PropTypes.object.isRequired,
    campaignData: PropTypes.object.isRequired,
    templateData: PropTypes.object.isRequired,
    bonus: PropTypes.object.isRequired,
    bonusesSent: PropTypes.object.isRequired,
    sent: PropTypes.object.isRequired,
    goal: PropTypes.object.isRequired,
    hasGoal: PropTypes.bool.isRequired,
    recurrenceData: PropTypes.object,
};

export default memo(CampaignGeneralDetails);
