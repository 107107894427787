import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
// Import UI components
import { DatePickerInput } from '@geneui/components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { DateFormat, MonthDayYearFormat } from 'constants/common';
import moment from 'moment';

const { customMomentWithoutTimezoneConversion } = Helpers;
const ValidatableDatePickerInput = ({ errorText, isTouched, onBlur, onChange, value, ...rest }) => {
    if (!isEmpty(rest.value)) {
        customMomentWithoutTimezoneConversion(rest.value).format(DateFormat);
    }

    const changeHandler = useCallback(
        (newDate) => {
            const newDateString = moment(newDate).format(MonthDayYearFormat);
            const valueString = typeof value === "string" ? value : moment(value).format(MonthDayYearFormat);

            if (newDateString !== valueString || isNil(value)) {
                onChange(newDate);
            }
        },
        [value, onChange],
    );

    return (
        <div className="c-date-picker">
            <DatePickerInput
                autocomplete="off"
                isValid={!(isTouched && errorText)}
                onBlur={onBlur}
                value={value}
                onChange={changeHandler}
                {...rest}
            />
            <p className="information-message color-danger">{isTouched && errorText}</p>
        </div>
    );
};

ValidatableDatePickerInput.propTypes = {
    isTouched: PropTypes.bool,
    errorText: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
};

export default memo(ValidatableDatePickerInput);
