import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useGoToRoute, useQueryParams } from 'hooks';
//Import Components
import ClientPreview from './ClientPreview';
// Import UI components
import { Popover } from '@geneui/components';
// Import Constants
import { RoutesList } from 'routes';
// Import SCSS
import 'assets/scss/clientPage.scss';

const { CLIENT_VIEW } = RoutesList;

const ClientId = ({ clientId }) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const { goToRoute, getRoutePath } = useGoToRoute();
    const { search } = useLocation();
    const goToRouteQueryParams = useQueryParams();

    const buttonOnClick = useCallback(
        (e) => {
            e.preventDefault();
            goToRoute(CLIENT_VIEW, { clientId: clientId }, goToRouteQueryParams.encode({ backParams: search }));
        },
        [goToRoute, clientId, goToRouteQueryParams, search],
    );

    const link = useMemo(() => getRoutePath(CLIENT_VIEW, { clientId: clientId }, null), [getRoutePath, clientId]);

    return (
        <Popover
            className="crm-client-preview-popover"
            isOpen={isPopoverVisible}
            Content={<ClientPreview clientId={clientId} />}
        >
            <a
                className="tittle-text ellipsis-text"
                onMouseEnter={() => setIsPopoverVisible(true)}
                onMouseLeave={() => setIsPopoverVisible(false)}
                onClick={buttonOnClick}
                href={link}
            >
                {clientId}
            </a>
        </Popover>
    );
};

ClientId.propTypes = {
    clientId: PropTypes.number.isRequired,
};

export default memo(ClientId);
