import { isObject } from 'lodash';
// Import constants
import { HeaderActionTypes as Types } from 'actions/types';
import { TimeFormat } from 'constants/common';

const initialState = {
    isLoading: false,
    navigation: {
        route: '',
        displayNameKey: '',
        url: '',
        previousRoutes: [],
    },
    timezone: null,
    settings: {},
    languages: [],
    partnerCurrencies: [],
    partners: [],
    colorTheme: 'light',
    timeFormat: TimeFormat,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_SETTINGS_FETCH:
            state.isLoading = true;
            return Object.assign({}, state, {});

        case Types.GET_SETTINGS_SUCCESSED:
            state.isLoading = false;
            if (isObject(action.payload)) {
                state.partners = action.payload.partners.map(
                    ({
                        CurrencyId,
                        PartnerId,
                        Name,
                        TimeZone,
                        PartnerGroup,
                        PartnerExternalConnections,
                        IsCrmActivated,
                        Status,
                    }) => ({
                        CurrencyId,

                        value: PartnerId,
                        label: Name,

                        TimeZone,
                        PartnerGroup,
                        PartnerExternalConnections,
                        IsCrmActivated,
                        Status,
                    }),
                );
                state.partnerCurrencies = action.payload.currencies.map(
                    ({ CurrencyId, Name, Precision, IsVirtual, IsSelected }) => ({
                        value: CurrencyId,
                        label: `${Name} (${CurrencyId})`,
                        Precision,
                        IsVirtual,
                        IsSelected,
                    }),
                );
            }
            return Object.assign({}, state, {});

        case Types.GET_SETTINGS_FAILED:
            state.isLoading = false;
            return Object.assign({}, state, {});

        case Types.SET_SETTINGS_FETCH:
            state.isLoading = true;
            return Object.assign({}, state, {});

        case Types.SET_SETTINGS_SUCCESSED:
            state.isLoading = false;
            state.settings = action.payload;
            state.timezone = action.payload.PartnerTimeZone;

            return Object.assign({}, state, {});
        case Types.SET_APPLICATION_MODES:
            state.isLoading = false;
            state.settings.Settings.ViewMode = action.payload.ViewMode;
            state.settings.Settings.Appearance = action.payload.Appearance;

            return Object.assign({}, state, {});

        case Types.SET_SETTINGS_FAILED:
            state.isLoading = false;
            return Object.assign({}, state, {});

        case Types.SET_NAVIGATION:
            if (action.payload) {
                state.navigation.route = action.payload.route;
                state.navigation.displayNameKey = action.payload.name;
            }
            return Object.assign({}, state, {});

        case Types.PUSH_TO_PREVIOUS_ROUTES:
            state.navigation.previousRoutes.push(action.payload);
            return Object.assign({}, state, {});

        case Types.POP_FROM_PREVIOUS_ROUTES:
            state.navigation.previousRoutes.pop();
            return Object.assign({}, state, {});

        case Types.CLEAR_PREVIOUS_ROUTES:
            state.navigation.previousRoutes = [];
            return Object.assign({}, state, {});

        case Types.SET_APP_URL:
            state.navigation.url = action.payload;
            return Object.assign({}, state, {});

        case Types.SET_LANGUAGES_LIST:
            state.languages = action.payload;
            return Object.assign({}, state, {});

        case Types.SET_LANGID:
            // TODO: change to Language
            state.settings.LangId = action.payload;
            return Object.assign({}, state, {});

        default:
            return state;
    }
};
