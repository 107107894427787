import { HeaderActionTypes as Types } from '../actions/types';

const getSettingsFetch = (status) => ({
    type: Types.GET_SETTINGS_FETCH,
    payload: status,
});

const getSettingsSuccessed = (settings) => ({
    type: Types.GET_SETTINGS_SUCCESSED,
    payload: settings,
});

const getSettingsFailed = (error) => ({
    type: Types.GET_SETTINGS_FAILED,
    payload: error,
});

const setSettingsFetch = (status) => ({
    type: Types.SET_SETTINGS_FETCH,
    payload: status,
});

const setSettingsSuccessed = (settings) => ({
    type: Types.SET_SETTINGS_SUCCESSED,
    payload: settings,
});

const setApplicationModes = (settings) => ({
    type: Types.SET_APPLICATION_MODES,
    payload: settings,
});

const setSettingsFailed = (error) => ({
    type: Types.SET_SETTINGS_FAILED,
    payload: error,
});

const setNavigation = (navigation) => ({
    type: Types.SET_NAVIGATION,
    payload: navigation,
});

const clearPreviousRoutes = () => ({
    type: Types.CLEAR_PREVIOUS_ROUTES,
});

const setAppUrl = (url) => ({
    type: Types.SET_APP_URL,
    payload: url,
});

const setLanguagesList = (languagesList) => ({
    type: Types.SET_LANGUAGES_LIST,
    payload: languagesList,
});

const setLangId = (LangId) => ({
    type: Types.SET_LANGID,
    payload: LangId,
});

export default {
    getSettingsFetch,
    getSettingsSuccessed,
    getSettingsFailed,
    setSettingsFetch,
    setSettingsSuccessed,
    setSettingsFailed,
    setNavigation,
    clearPreviousRoutes,
    setAppUrl,
    setLanguagesList,
    setApplicationModes,
    setLangId,
};
