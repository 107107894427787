import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Modal, BusyLoader, Table, Section, Button, Icon } from '@geneui/components';
// Import constants
import { l, ActionsIcons, ReportPageType } from 'constants/common';

const messageText = {
    [ReportPageType.Segment]: l.SaveSegmentChangeIntoReport,
    [ReportPageType.Report]: l.SaveTheReportAndExecute,
};

const SegmentReportPreviewModal = ({ isVisible, closeHandler, clientsData, isVisibleInfoMessage, pageType }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const [loadingText, setLoadingText] = useState(l.WaitDataIsProcessed);

    setTimeout(function () {
        setLoadingText(l.DataTooBig);
    }, 5000);

    useEffect(() => {
        init();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [clientsData]);

    function init() {
        clientsData && setDataInReportTable(clientsData);
    }

    const setDataInReportTable = (data) => {
        const columns = data.Columns.map((elem) => {
            return {
                text: t(elem.DisplayName),
                sortable: false,
                resizable: false,
                draggable: false,
                dataKey: elem.DisplayName,
            };
        });
        const rows = data.Data.map((elem) => {
            let data = {};
            columns.forEach((element, index) => {
                data[element.dataKey] = elem[index];
            });
            return {
                actionBar: false,
                dragDisable: true,
                hasHover: true,
                data,
            };
        });
        setData({
            ...data,
            Columns: columns,
            Data: rows,
        });
    };

    return (
        <Modal
            className="custom-modal-content report-popup-wrap"
            visible={isVisible}
            width="70%"
            title={t(l.Preview)}
            background="dark-background"
            closable={false}
            closeOnClickOutside={true}
            onCancel={closeHandler}
            footer={<Button onClick={closeHandler}>{t(l.Close)}</Button>}
        >
            {!data ? (
                <BusyLoader
                    isBusy={!isVisibleInfoMessage}
                    type="spinner"
                    spinnerSize="big"
                    loadingText={<span className="preview-modal-loader-message">{t(loadingText)}</span>}
                >
                    <div className="preview-modal-contents">
                        <Icon type={ActionsIcons.UsedIn} className="preview-modal-info-icon" />
                        <span className="preview-modal-info-message">{t(messageText[pageType])}</span>
                    </div>
                </BusyLoader>
            ) : (
                <Section text={t(l.First100Clients)}>
                    <Table
                        withPagination={false}
                        hasSearch={false}
                        rowKey={'Client Id'}
                        rows={data.Data}
                        columns={data.Columns}
                    />
                </Section>
            )}
        </Modal>
    );
};

SegmentReportPreviewModal.propTypes = {
    clientsData: PropTypes.object.isRequired,
    pageType: PropTypes.string.isRequired,
    isVisible: PropTypes.bool,
    closeHandler: PropTypes.func,
    isVisibleInfoMessage: PropTypes.bool,
};

SegmentReportPreviewModal.defaultProps = {
    isVisible: false,
    isVisibleInfoMessage: false,
    closeHandler: noop,
};

export default memo(SegmentReportPreviewModal);
