import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty, noop } from 'lodash';
import { useFormik } from 'formik';
import { CJSegmentIdValidationSchema } from 'validators/schemas.validation';
// Import Components
import { OptionList } from 'components';
// Import Services
import { SegmentHttpService } from 'services/http';
import { Helpers } from 'services';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Hooks
import { useRequest, useAutocompleteRequest } from 'hooks';
// Import Constants
import { DateTimeWithoutSecondsFormat, l, SegmentTypesLabels } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

const CJSegmentId = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedSegmentData, setSelectedSegmentData] = useState({ SegmentId: defaultValue?.value });
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);

    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
        },
        onSubmit: noop,
        validationSchema: CJSegmentIdValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm } = formik;
    const { value } = values;
    const { getSegmentList, getSegmentById } = useRef({
        getSegmentList: SegmentHttpService.getSegmentList,
        getSegmentById: SegmentHttpService.getSegmentById(),
    }).current;

    useEffect(() => {
        getUpdate({ value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const segmentListGetterMethod = (searchValue = '') => {
        setIsLoadingList(true);

        const requestObj = getAutocompleteRequest(getSegmentList);

        doPostRequest(requestObj.request, {
            requestBody: {
                Filters: [
                    { Name: 'State', Comparision: 0, Values: [1] },
                    { Name: 'Name', Comparision: 2, Values: [searchValue] },
                ],
                Pageing: { PageSize: 100, PageNumber: 1 },
                Sorting: { Name: 'CreatedDate', Direction: 'desc' },
            },
            successCallback: (data) => {
                setListData(
                    !isNil(data?.Data)
                        ? data.Data?.map((item) => ({
                              ...item,
                              formattedCreatedDate: customMomentWithoutTimezoneConversion(item.CreatedDate).format(
                                  DateTimeWithoutSecondsFormat,
                              ),
                              segmentType: SegmentTypesLabels[item.Type],
                          }))
                        : [],
                );
                setIsLoadingList(false);
            },
            errorCallback: () => setIsLoadingList(false),
        });
    };

    const searchSegmentHandler = ({ target }) => {
        setSearchValue(target.value);
        segmentListGetterMethod(target.value);
    };

    const init = () => {
        submitForm();
        segmentListGetterMethod('');

        if (!isNil(defaultValue?.value) && !isEmpty(defaultValue.value.toString())) {
            setIsLoading(true);
            doGetRequest(getSegmentById.request, {
                queryString: { segmentId: defaultValue.value },
                successCallback: (Data) => {
                    const { SegmentId, Name } = Data;
                    setSelectedSegmentData({ SegmentId, Name });
                },
            }).then(() => {
                setIsLoading(false);
            });
        }
    };

    const cleanUp = () => {
        return () => {
            getSegmentById.cancel('CJSegmentId:getSegmentById');
            cancelRequest('CJSegmentId:getSegmentList');
        };
    };

    useEffect(init, []);

    useEffect(cleanUp, []);

    const popoverToggler = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    const selectSegmentHandler = (data) => {
        setSelectedSegmentData(data);
        setFieldValue('value', data.SegmentId);
        setIsVisiblePopover(false);
    };

    return (
        <OptionList
            optionLabel={selectedSegmentData?.Name}
            toggleHandler={popoverToggler}
            isVisible={isVisiblePopover}
            setIsVisible={setIsVisiblePopover}
            keys={['Name', 'CreatorName', 'formattedCreatedDate', 'segmentType']}
            titles={[t(l.SegmentName), t(l.Creator), t(l.CreatedDate), t(l.Type)]}
            selectedPropertyKey="SegmentId"
            list={listData}
            rowCount={listData.length}
            searchHandler={searchSegmentHandler}
            optionValue={value}
            optionSelectHandler={selectSegmentHandler}
            contentTop={45}
            contentWrapperClassName="cj-option-list-content-wrapper"
            searchValue={searchValue}
            isLoading={isLoading}
            isLoadingList={isLoadingList}
            isValid={!errors.value}
            errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
        />
    );
};

CJSegmentId.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJSegmentId.defaultProps = {
    defaultValue: { value: '' },
};

export default memo(CJSegmentId);
