import { BackofficeRequestService, CRMRequestService } from './http.config';

const AuthHttpService = {
    logoutWithPlatform: () => {
        return CRMRequestService.post('/User/LogoutWithPlatform');
    },
    checkAuthentication: () => {
        return BackofficeRequestService.get('/Account/CheckAuthentication');
    },
    loginWithPlatform: (token) => {
        return CRMRequestService.post('/User/LoginWithPlatform', JSON.stringify(token));
    },
    getPartners: () => {
        return CRMRequestService.get('/User/GetPartners');
    },
    getCurrencies: () => {
        return CRMRequestService.get('/OptionsList/GetCurrencies');
    },
    getUserSettings: () => {
        return CRMRequestService.get('/User/Settings');
    },
    changePartner: (partnerId) => {
        return CRMRequestService.post(`/User/ChangePartner?partnerId=${partnerId}`);
    },
    updateSettings: (settings) => {
        return CRMRequestService.post('/User/UpdateSettings', settings);
    },
    saveLanguageSetting: (langId) => {
        return CRMRequestService.post(`/User/ChangeLanguage?newLanguage=${langId}`);
    },
};

export default AuthHttpService;
