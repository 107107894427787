import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// Import UI components
import { Icon, Tooltip } from '@geneui/components';

const MenuItemCircle = ({ clickHandler, name, iconClass, displayNameKey }) => {
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);

    const textRef = useRef(null);
    useEffect(() => {
        if (textRef?.current?.offsetWidth < textRef?.current?.scrollWidth) {
            setIsEllipsisActive(true);
        }
    }, [displayNameKey]);
    return (
        <>
            {isEllipsisActive ? (
                <Tooltip text={displayNameKey}>
                    <button onClick={clickHandler} className="crm-menu-item-circle">
                        <Icon className={`${iconClass}`} />
                        <span ref={textRef}>{displayNameKey}</span>
                    </button>
                </Tooltip>
            ) : (
                <button onClick={clickHandler} className="crm-menu-item-circle">
                    <Icon className={`${iconClass}`} />
                    <span ref={textRef}>{displayNameKey}</span>
                </button>
            )}
        </>
    );
};

MenuItemCircle.propTypes = {
    clickHandler: PropTypes.func,
    iconClass: PropTypes.string,
    name: PropTypes.string,
    displayNameKey: PropTypes.string,
};

export default memo(MenuItemCircle);
