import l from './localizationLabels';
import PERMISSIONS from './permissions';
import PARTNER_SETTINGS from './partnerSettings';
import TimeZonePack from './timezonePack';
import { isNil } from 'lodash';

const DateTimeFormat = 'DD-MM-YYYY  HH:mm:ss';
const DateTimeWithoutSecondsFormat = 'DD-MM-YYYY  HH:mm';
const DateFormat = 'DD/MM/YYYY';
const MonthDayYearFormat = 'MM/DD/YYYY';
const MonthDayFormat = 'MM/DD';
const MonthDayYearWithTimeFormat = 'MM/DD/YYYY HH:mm';
const MonthDayYearWithTime = 'DD/MM/YYYY HH:mm';
const TimeFormat = 'HH:mm:ss';
const TimeFormatWithoutSeconds = 'HH:mm';
const DateToISOStringFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
const DateToISOStringWithoutTimezoneFormat = 'YYYY-MM-DDTHH:mm:ss';

const oneDayMilliseconds = 86400000;
const validDaysCount = 92;
const dailyViewLimitInWeeks = 5;

const FilterHolderFields = {
    textInput: {
        type: 'textInput',
        defaultValue: '',
    },
    select: {
        type: 'select',
        defaultValue: undefined, // core default value is undefined
    },
    rangeDate: {
        type: 'rangeDate',
        defaultValue: [null, null],
    },
    singleDate: {
        type: 'singleDate',
        defaultValue: [null],
    },
    multiSelect: {
        type: 'multiSelect',
        defaultValue: [],
    },
    checkbox: {
        type: 'checkbox',
        defaultValue: null,
    },
    multiSelectAutocomplete: {
        type: 'multiSelectAutocomplete',
        defaultValue: [],
    },
    autocompleteOptionList: {
        type: 'autocompleteOptionList',
        defaultValue: [],
    },
    menuItemMultiSelect: {
        type: 'menuItemMultiSelect',
        defaultValue: [],
    },
};

const ExportFileFormat = [
    { label: l.Excel, value: 0 },
    { label: l.CSV, value: 1 },
];

const ExportFileFormatLabels = {
    0: 'Excel',
    1: 'CSV',
    Excel: 0,
    CSV: 1,
};

const SegmentTypesLabels = {
    0: 'Dynamic',
    1: 'Static',
    3: 'Composite',
    Dynamic: 0,
    Static: 1,
    Composite: 3,
};

const SegmentTypeIcons = {
    [SegmentTypesLabels.Dynamic]: 'bc-icon-dynamic',
    [SegmentTypesLabels.Static]: 'bc-icon-static',
    [SegmentTypesLabels.Composite]: 'bc-icon-composite',
};

const MenuItemsTypes = {
    0: 'Query',
    1: 'Classifier',
    2: 'Custom',
    3: 'Multifield',
    4: 'Provider',
    Query: 0,
    Classifier: 1,
    Custom: 2,
    Multifield: 3,
    Provider: 4,
};

const MenuItemsLabels = [
    {
        label: 'Query',
        value: 0,
    },
    {
        label: 'Classifier',
        value: 1,
    },
    {
        label: 'Custom',
        value: 2,
    },
    {
        label: 'Multifield',
        value: 3,
    },
    {
        label: 'Provider',
        value: 4,
    },
];

const EventLabels = {
    1: l.BetWin,
    2: l.WithdrawAprove,
    3: l.WithdrawRejected,
    4: l.MatchStarted,
    5: l.MatchStartedWithPrices,
    6: l.MatchAboutStart,
    7: l.MatchAboutStartWithPrices,
    8: l.MatchEnded,
    9: l.FootballGoal,
    10: l.FootballPenaltyStarted,
    11: l.Withdrawal,
    12: l.Deposit,
    13: l.FootballCorner,
    14: l.FootballYellowCard,
    15: l.FootballRedCard,
    16: l.LoyaltyPointsEarned,
};

const SegmentTypes = [
    {
        label: 'Dynamic',
        value: 0,
    },
    {
        label: 'Static',
        value: 1,
    },
    {
        label: 'Composite',
        value: 3,
    },
];

const CompositeSegmentOperation = {
    1: 'Union',
    2: 'Intersect',
    3: 'Difference',
    Union: 1,
    Intersect: 2,
    Difference: 3,
};

const TemplateTypesLabels = {
    0: 'Email',
    1: 'Internal',
    2: 'SMS',
    3: 'PopUp',
    4: 'Telegram',
    7: 'PushNotification',
    8: 'CMSPopUp',
    9: 'Viber',
    10: 'Hoory',
    11: 'FastexNotification',
    12: 'DynamicPopUp',
    Email: 0,
    Internal: 1,
    SMS: 2,
    PopUp: 3,
    Telegram: 4,
    PushNotification: 7,
    CMSPopUp: 8,
    Viber: 9,
    Hoory: 10,
    FastexNotification: 11,
    DynamicPopUp: 12,
};

const TemplateTypeIcons = {
    [TemplateTypesLabels.Email]: 'bc-icon-send-email-block',
    [TemplateTypesLabels.Internal]: 'bc-icon-sms',
    [TemplateTypesLabels.SMS]: 'bc-icon-message-closed',
    [TemplateTypesLabels.PopUp]: 'bc-icon-send-pop-up-block',
    [TemplateTypesLabels.DynamicPopUp]: 'bc-icon-send-pop-up-block',
    [TemplateTypesLabels.Telegram]: 'bc-icon-send-telegram-message-block',
    [TemplateTypesLabels.PushNotification]: 'bc-icon-bell-outline',
    [TemplateTypesLabels.CMSPopUp]: 'bc-icon-send-pop-up-block',
    [TemplateTypesLabels.Viber]: 'bc-icon-viber',
    [TemplateTypesLabels.Hoory]: 'bc-icon-hoory',
    [TemplateTypesLabels.FastexNotification]: 'bc-icon-publish-48',
};

const DeliveryMethodTypes = { ...TemplateTypesLabels, 5: 'FTP', FTP: 5, 6: 'SFTP', SFTP: 6 };

const PopUpUrlTypes = {
    Any: 0,
    Is: 1,
    IsNot: 2,
    StartsWith: 3,
    EndsWith: 4,
    Contains: 5,
    DoesNotContain: 6,
    0: 'Any',
    1: 'Is',
    2: 'IsNot',
    3: 'StartsWith',
    4: 'EndsWith',
    5: 'Contains',
    6: 'DoesNotContain',
};

const BonusTypes = [
    { label: l.DepositBonus, value: 2 },
    { label: l.NoDepositBonus, value: 3 },
    { label: l.CorrectionBonus, value: 4 },
    { label: l.FreeSpin, value: 5 },
    { label: l.FreeBet, value: 6 },
    { label: l.SkillGames, value: 8 },
    { label: l.BoostBonus, value: 9 },
    { label: l.CasinoFreeSpin, value: 10 },
    { label: l.LoyaltyPoint, value: 12 },
    { label: l.CashBonus, value: 13 },
    { label: l.NFTBonus, value: 14 },
    { label: l.FrenchFreeBet, value: 15 },
    { label: l.TradingPlan, value: 16 },
    { label: l.LoyaltyBenefit, value: 17 },
];

const BonusTypesLabels = {
    2: l.DepositBonus,
    3: l.NoDepositBonus,
    4: l.CorrectionBonus,
    5: l.FreeSpin,
    6: l.FreeBet,
    8: l.SkillGames,
    9: l.BoostBonus,
    10: l.CasinoFreeSpin,
    12: l.LoyaltyPoint,
    13: l.CashBonus,
    14: l.NFTBonus,
    15: l.FrenchFreeBet,
    16: l.TradingPlan,
    17: l.LoyaltyBenefit,
    DepositBonus: 2,
    NoDepositBonus: 3,
    CorrectionBonus: 4,
    FreeSpin: 5,
    FreeBet: 6,
    SkillGames: 8,
    BoostBonus: 9,
    CasinoFreeSpin: 10,
    LoyaltyPoint: 12,
    CashBonus: 13,
    NFTBonus: 14,
    FrenchFreeBet: 15,
    TradingPlan: 16,
    LoyaltyBenefit: 17,
};

const BonusTypesIcons = {
    [BonusTypesLabels.DepositBonus]: 'bc-icon-crm-deposit-bonus',
    [BonusTypesLabels.SkillGames]: 'bc-icon-crm-skill-games',
    [BonusTypesLabels.FreeBet]: 'bc-icon-crm-free-bet',
    [BonusTypesLabels.FreeSpin]: 'bc-icon-crm-free-spin',
    [BonusTypesLabels.LoyaltyBenefit]: 'bc-icon-loyalty-point',
};

const EntityStatesLabels = {
    1: 'Used',
    0: 'NotUsed',
    Used: 1,
    NotUsed: 0,
};

const EntityStates = [
    {
        label: 'Used',
        value: '1',
    },
    {
        label: 'NotUsed',
        value: '0',
    },
];

const ArchiveUnarchiveTypes = {
    Archive: false,
    UnArchive: true,
};

const StateModesLabels = {
    0: l.All,
    1: l.Live,
    2: l.Test,
    3: l.Draft,
    5: l.Emulate,
    All: 0,
    Live: 1,
    Test: 2,
    Draft: 3,
    Emulate: 5,
};

const StateModes = [
    {
        label: l.Live,
        value: '1',
    },
];

const ArchiveStates = [
    {
        label: l.Yes,
        value: false,
    },
    {
        label: l.No,
        value: true,
    },
];

const FullStateModes = [
    ...StateModes,
    {
        label: l.Test,
        value: '2',
    },
    {
        label: l.Draft,
        value: '3',
    },
];

const CustomersTypesLabels = {
    All: null,
    Customers: '0',
    NonCustomers: '1',
};

const CustomersTypes = [
    {
        label: l.Customers,
        value: CustomersTypesLabels.Customers,
    },
    {
        label: l.NonCustomers,
        value: CustomersTypesLabels.NonCustomers,
    },
];

const SupportedLanguages = [
    {
        DisplayName: 'English',
        Id: 'en',
        IsSelected: false,
        Name: 'English',
    },
    {
        DisplayName: 'Russian',
        Id: 'ru',
        IsSelected: false,
        Name: 'Russian',
    },
    {
        DisplayName: 'Spanish',
        Id: 'es',
        IsSelected: false,
        Name: 'Spanish',
    },
    {
        DisplayName: 'Turkish',
        Id: 'tr',
        IsSelected: false,
        Name: 'Turkish',
    },
    {
        DisplayName: 'Chinese',
        Id: 'zh',
        IsSelected: false,
        Name: 'Chinese',
    },
    {
        DisplayName: 'Korean',
        Id: 'ko',
        IsSelected: false,
        Name: 'Korean',
    },
    {
        DisplayName: 'German',
        Id: 'de',
        IsSelected: false,
        Name: 'German',
    },
    {
        DisplayName: 'France',
        Id: 'fr',
        IsSelected: false,
        Name: 'France',
    },
    {
        DisplayName: 'Hindi',
        Id: 'hi',
        IsSelected: false,
        Name: 'Hindi',
    },
    {
        DisplayName: 'Portuguese',
        Id: 'pt',
        IsSelected: false,
        Name: 'Portuguese',
    },
    {
        DisplayName: 'Brazilian Portuguese',
        Id: 'pb',
        IsSelected: false,
        Name: 'Brazilian Portuguese',
    },
];

const AlertTypes = {
    warning: 'warning',
    error: 'error',
    success: 'success',
    info: 'info',
    danger: 'danger',
};

const DefinitionTypes = {
    predefined: 1,
    notPredefined: 0,
};

const ModalsActions = {
    CONVERT_TO_REPORT: 'CONVERT_TO_REPORT',
    DELETE: 'DELETE',
    ARCHIVE: 'ARCHIVE',
    UNARCHIVE: 'UNARCHIVE',
    CLONE: 'CLONE',
    EDIT: 'EDIT',
    CLOSE: 'CLOSE',
    TEST_TEMPLATE: 'TEST_TEMPLATE',
    STOP: 'STOP',
    CUSTOMER_JOURNEY_STOP: 'CUSTOMER_JOURNEY_STOP',
    CHANGE_MODE_CAMPAIGN: 'CHANGE_MODE_CAMPAIGN',
    UNSCHEDULE_REPORT: 'UNSCHEDULE_REPORT',
    EXECUTE_REPORT: 'EXECUTE_REPORT',
    SET_GOAL: 'SET_GOAL',
    DELETE_GOAL: 'DELETE_GOAL',
    START: 'START',
    RESUME: 'RESUME',
    SETDEFAULT: 'SETDEFAULT',
    SAVE: 'SAVE',
    RESEND: 'RESEND',
    CHANGE_PARTNER_ACTIVATION: 'CHANGE_PARTNER_ACTIVATION',
    SET_TAG: 'SET_TAG',
    REMOVE_TAG: 'REMOVE_TAG',
};

const FilterComparisions = {
    Equals: 0,
    NotEquals: 1,
    Like: 2,
    NotLike: 3,
    GreaterThan: 4,
    GreaterOrEquals: 5,
    LessThan: 6,
    LessOrEquals: 7,
    In: 8,
    Between: 9,
    BeginWith: 10,
    EndWith: 11,
    NotIn: 12,
    NotBetween: 13,
    NotBeginWith: 14,
    NotEndWith: 15,
    Exclude: 16,
    IsBlank: 18,
};

const CreatePopoverViews = {
    mainView: 'mainView',
    segmentView: 'segmentView',
    predefinedSegmentView: 'predefinedSegmentView',
    templateView: 'templateView',
    predefinedTemplateView: 'predefinedTemplateView',
    customerJourneyView: 'customerJourneyView',
    predefinedCustomerJourneyView: 'predefinedCustomerJourneyView',
    awardView: 'awardView',
    reportView: 'reportView',
    socialTemplateView: 'socialTemplateView',
};

const LabelsModalViews = {
    manageLabelsView: 'manageLabelsView',
    setLabelView: 'setLabelView',
    editLabelView: 'editLabelView',
    createLabelView: 'createLabelView',
};

const TagsModalViews = {
    setTagsView: 'setTagsView',
    createTagView: 'createTagView',
};

const ScheduleModalViews = {
    mainView: 'mainView',
    hourlyView: 'hourlyView',
    dailyView: 'dailyView',
    weeklyView: 'weeklyView',
    monthlyView: 'monthlyView',
    annuallyView: 'annuallyView',
    notRepeatView: 'notRepeatView',
};

const PageTypes = {
    create: 'create',
    clone: 'clone',
    edit: 'edit',
    predefined: 'predefined',
    view: 'view',
    execute: 'execute',
    convert: 'convert',
};

const TargetTypeEnum = {
    None: 0,
    Template: 1,
    Segment: 2,
    Campaign: 3,
    AdHocReport: 4,
    OCampaign: 5,
    Schedule: 6,
    Bonus: 7,
    AdHocReportResult: 8,
    MessageSenderProfile: 9,
    CustomCampaign: 10,
    CustomReport: 11,
    PartnerSetting: 12,
    TargetTypeState: 13,
    CampaignGoal: 14,
    TriggeredCampaign: 15,
    NonCustomer: 16,
    PushCampaign: 17,
    Workflow: 18,
    Report: 19,
};

const DeviceTypes = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    DESKTOP: 'DESKTOP',
    FULL_SCREEN: 'FULL_SCREEN',
};

const DynamicPopUpDeviceTypesLabels = {
    Desktop: '1',
    Mobile: '2',
    Both: '3',
    1: 'Desktop',
    2: 'Mobile',
    3: 'Both',
};

const DisplayModeTypes = {
    LIGHT_MODE: 'LIGHT_MODE',
    DARK_MODE: 'DARK_MODE',
};

const DisplayModeStyles = {
    [DisplayModeTypes.LIGHT_MODE]: 'body {color:#000000}',
    [DisplayModeTypes.DARK_MODE]: 'body {color:#ffffff}',
};

const DisplayModeClassNames = {
    [DisplayModeTypes.LIGHT_MODE]: 'for-device-light-mode',
    [DisplayModeTypes.DARK_MODE]: 'for-device-dark-mode',
};

const DeviceTypeClassNames = {
    [DeviceTypes.MOBILE]: 'mobile-view',
    [DeviceTypes.TABLET]: 'tablet-view',
    [DeviceTypes.DESKTOP]: 'desktop-view',
};

const ErrorShowType = {
    Toaster: 'Toaster',
    Default: 'Default',
};

const ListRequestDefaultParams = {
    filters: [],
    paging: {
        PageSize: 10,
        PageNumber: 1,
    },
    sorting: {
        Name: 'CreatedDate',
        Direction: 'desc',
    },
};

const CustomerJourneyStatusLabels = {
    All: '0',
    InProgress: '1',
    Completed: '2',
    Suspended: '3',
    NonStarted: '4',
    Failed: '5',
    Invalid: '7',
    Starting: '8',
    Updating: '9',
    Resuming: '10',
    Suspending: '11',
    0: l.All,
    1: l.InProgress,
    2: l.Completed,
    3: l.Suspended,
    4: l.NonStarted,
    5: l.Failed,
    7: l.Invalid,
    8: l.Starting,
    9: l.Updating,
    10: l.Resuming,
    11: l.Suspending,
};

const CustomerJourneyWorkingState = {
    Disabled: 1 << 17,
};

const CustomerJourneyStatus = [
    {
        label: l.InProgress,
        value: CustomerJourneyStatusLabels.InProgress,
    },
    {
        label: l.Completed,
        value: CustomerJourneyStatusLabels.Completed,
    },
    {
        label: l.Suspended,
        value: CustomerJourneyStatusLabels.Suspended,
    },
    {
        label: l.NonStarted,
        value: CustomerJourneyStatusLabels.NonStarted,
    },
    {
        label: l.Failed,
        value: CustomerJourneyStatusLabels.Failed,
    },
    {
        label: l.Invalid,
        value: CustomerJourneyStatusLabels.Invalid,
    },
    {
        label: l.Starting,
        value: CustomerJourneyStatusLabels.Starting,
    },
    {
        label: l.Updating,
        value: CustomerJourneyStatusLabels.Updating,
    },
    {
        label: l.Resuming,
        value: CustomerJourneyStatusLabels.Resuming,
    },
    {
        label: l.Suspending,
        value: CustomerJourneyStatusLabels.Suspending,
    },
];

const CampaignType = {
    AddHoc: '0',
    Ongoing: '1',
    Custom: '2',
    Triggered: '3',
    Push: '4',
    WorkFlow: '5',
    0: 'AddHoc',
    1: 'Ongoing',
    2: 'Custom',
    3: 'Triggered',
    4: 'Push',
    5: 'WorkFlow',
};

const PromotionTypeLabels = {
    0: l.Campaign,
    5: l.CustomerJourney,
    8: l.ActionCampaign,
};

const CampaignStatusLabels = {
    Scheduled: '0',
    Idle: '1',
    InProgress: '2',
    Completed: '3',
    Stoped: '5',
    All: '6',
    Error: '7',
    0: l.Scheduled,
    1: l.Idle,
    2: l.InProgress,
    3: l.Completed,
    5: l.Stoped,
    6: l.All,
    7: l.Error,
};

const CampaignStatusColors = {
    0: '--status-label-scheduled-color',
    1: '--status-label-idle-color',
    2: '--status-label-in-progress-color',
    3: '--status-label-completed-color',
    4: '--status-label-sent-color',
    5: '--status-label-stopped-color',
    6: '--status-label-error-color',
    7: '--status-label-error-color',
};

const ColorVars = {
    danger: 'var(--danger)',
};

const CampaignStatus = [
    {
        label: l.Scheduled,
        value: CampaignStatusLabels.Scheduled,
    },
    {
        label: l.Idle,
        value: CampaignStatusLabels.Idle,
    },
    {
        label: l.InProgress,
        value: CampaignStatusLabels.InProgress,
    },
    {
        label: l.Completed,
        value: CampaignStatusLabels.Completed,
    },
    {
        label: l.Stoped,
        value: CampaignStatusLabels.Stoped,
    },
    {
        label: l.Error,
        value: CampaignStatusLabels.Error,
    },
];

const PageValidationTypes = {
    DynamicSegmentPage: 'DynamicSegmentPage',
    StaticSegmentPage: 'StaticSegmentPage',
    NonCustomerSegmentPage: 'NonCustomerSegmentPage',
    CompositeSegmentPage: 'CompositeSegmentPage',
    SMSTemplatePage: 'SMSTemplatePage',
    HooryTemplatePage: 'HooryTemplatePage',
    FastexNotificationTemplatePage: 'FastexNotificationTemplatePage',
    TelegramTemplatePage: 'TelegramTemplatePage',
    InternalMessageTemplatePage: 'InternalMessageTemplatePage',
    EmailTemplatePage: 'EmailTemplatePage',
    DynamicPopUpTemplatePage: 'DynamicPopUpTemplatePage',
    CampaignPage: 'CampaignPage',
    JourneyPage: 'JourneyPage',
    ReportPage: 'ReportPage',
    PushNotificationTemplatePage: 'PushNotificationTemplatePage',
    CustomerJourneyVisualizationPage: 'CustomerJourneyVisualizationPage',
    PopUpNotificationTemplatePage: 'PopUpNotificationTemplatePage',
    CMSPopUpNotificationTemplatePage: 'CMSPopUpNotificationTemplatePage',
    ReportExecuteSchedulePage: 'ReportExecuteSchedulePage',
    CompositeReportPage: 'CompositeReportPage',
    CustomerJourneyReportPage: 'CustomerJourneyReportPage',
    ViberTemplatePage: 'ViberTemplatePage',
};

const ReportStatuses = [
    {
        label: 'Scheduled',
        value: '1',
    },
    {
        label: 'Standart',
        value: '0',
    },
];

const ReportType = [
    {
        label: 'CustomReport',
        value: '1',
    },
    {
        label: 'Report',
        value: '0',
    },
    {
        label: 'CompositeReport',
        value: '2',
    },
    {
        label: 'CustomerJourneyReport',
        value: '3',
    },
];

const ReportPageType = {
    Segment: l.Segment,
    Report: l.Report,
};

const ReportTypesLabels = {
    0: 'Report',
    1: 'CustomReport',
    2: 'CompositeReport',
    3: 'CustomerJourneyReport',
    Report: 0,
    CustomReport: 1,
    CompositeReport: 2,
    CustomerJourneyReport: 3,
};

const ReportTypeIcons = {
    [ReportTypesLabels.Report]: 'bc-icon-crm-standart-report',
    [ReportTypesLabels.CustomReport]: 'bc-icon-crm-custom-report',
    [ReportTypesLabels.CompositeReport]: 'bc-icon-composite-report',
    [ReportTypesLabels.CustomerJourneyReport]: 'bc-icon-cj-report',
};

const MonthsAndDays = {
    Jan: 31,
    Feb: 29,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
};

const ReportStateLabels = {
    0: 'Standart',
    1: 'Scheduled',
    Standart: 0,
    Scheduled: 1,
};

const WeekDaysKeyList = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const Months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const Days = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
];

const Hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];

const ReportsResultStatus = {
    0: 'Done',
    1: 'InProgress',
    2: 'Fail',
    3: 'Stop',
    4: 'NotStarted',
    5: 'Expired',
    Done: 0,
    InProgress: 1,
    Fail: 2,
    Stop: 3,
    NotStarted: 4,
    Expired: 5,
};

const EventLogType = {
    1: 'Error',
    2: 'Warning',
    3: 'Information',
    Error: 1,
    Warning: 2,
    Information: 3,
};

const BonusCalculationTypes = {
    Static: 'Static',
    Dynamic: 'Dynamic',
};

const DynamicBonusTypes = {
    Object: 1,
    KPI: 2,
    FromFile: 3,
    1: 'Object',
    2: 'KPI',
    3: 'FromFile',
};

const KPISources = {
    Object: 'Object',
    KPI: 'KPI',
    FromFile: 'FromFile',
};

const CustomerJourneyGroupBlockTypes = {
    1: 'target',
    2: 'action',
    3: 'trigger',
    5: 'condition',
    target: 'target',
    action: 'action',
    trigger: 'trigger',
    condition: 'condition',
};

const CustomerJourneyGroupBlockTypesLabels = {
    target: l.Target,
    trigger: l.Trigger,
    condition: l.Condition,
    action: l.Action,
};

const CustomerJourneyGroupBlockTypesValues = {
    3: l.Trigger,
    1: l.Target,
    2: l.Action,
    5: l.Condition,
};

const CJModelRelationScopes = {
    None: 0,
    Self: 1,
    Previous: 2,
    All: 3,
};

const CJAutoMapMode = {
    None: 0,
    Closest: 1,
    Single: 2,
};

const CJArgumentValuesTypes = {
    DefaultInArgument: 'DefaultInArgument',
    StaticInArgument: 'StaticInArgument',
    ReferenceInArgument: 'ReferenceInArgument',
    DynamicDateInArgument: 'DynamicDateInArgument',
    FormulaInArgument: 'FormulaInArgument',
};

const CJReferenceInArgumentType =
    'BetConstruct.AGP.CRM.Workflow.Configuration.ReferenceInArgumentConfig, BetConstruct.AGP.CRM.Workflow';

const CJDynamicDateInArgumentType =
    'BetConstruct.AGP.CRM.Workflow.Configuration.DynamicDateInArgumentConfig, BetConstruct.AGP.CRM.Workflow';

const CJFormulaInArgumentType =
    'BetConstruct.AGP.CRM.Workflow.Configuration.FormulaInArgumentConfig, BetConstruct.AGP.CRM.Workflow';

const defaultLogicFunction = 'none';

const clientIdTypeName = 'WfClientId';

const ExpirationBonusModelTypes = {
    expirationDate: 'BetConstruct.AGP.CRM.Models.Bonus.ExpirationDate, BetConstruct.AGP.CRM.Models',
    expirationPeriod: 'BetConstruct.AGP.CRM.Models.Bonus.ExpirationPeriod, BetConstruct.AGP.CRM.Models',
    'BetConstruct.AGP.CRM.Models.Bonus.ExpirationDate, BetConstruct.AGP.CRM.Models': 'expirationDate',
    'BetConstruct.AGP.CRM.Models.Bonus.ExpirationPeriod, BetConstruct.AGP.CRM.Models': 'expirationPeriod',
};

const ExpirationTypes = {
    ExpirationDate: 'expirationDate',
    ExpirationPeriod: 'expirationPeriod',
};

const GoalOptions = {
    0: 'Bet',
    1: 'Deposit',
    Bet: 0,
    Deposit: 1,
};

const DynamicInputTypes = {
    INPUT: 0,
    DROP_DOWN: 1,
    0: 'INPUT',
    1: 'DROP_DOWN',
};

const SegmentObjectType = {
    Client: 0,
    NonCustomer: 1,
    0: 'Client',
    1: 'NonCustomer',
};

const layoutDirections = {
    LR: 'LR',
    TB: 'TB',
};

const ViewModeTypes = {
    0: 'view-mode-small',
    1: 'view-mode-normal',
    2: 'view-mode-large',
};

const ViewThemeTypes = {
    0: 'view-theme-light',
    1: 'view-theme-dark',
};

const Widgets = {
    1: 'ActiveUsers',
    2: 'RetentionRate',
    3: 'ConversionRate',
    28: 'MoneyRetentionRate',
    29: 'DAU_MAU',
    31: 'DAU_WAU',
    32: 'CampaignsInAction',
    33: 'CustomerRelationshipMetrics',
    34: 'DailyMetricsForNewClients',
    35: 'DailyActivityMetricsByProducts',
    37: 'ConversionTimeAnalysis',
    39: 'ARPU',
    40: 'ReactivationRate',
    41: 'CustomerLifetimeValue',
    ActiveUsers: 1,
    RetentionRate: 2,
    ConversionRate: 3,
    MoneyRetentionRate: 28,
    DAU_MAU: 29,
    DAU_WAU: 31,
    CampaignsInAction: 32,
    CustomerRelationshipMetrics: 33,
    DailyMetricsForNewClients: 34,
    DailyActivityMetricsByProducts: 35,
    ConversionTimeAnalysis: 37,
    ARPU: 39,
    ReactivationRate: 40,
    CustomerLifetimeValue: 41,
};

const WidgetsCategory = {
    0: 'KPIs',
    1: 'Charts',
    2: 'Campaigns',
    KPIs: 0,
    Charts: 1,
    Campaigns: 2,
};

const WidgetsCompStatus = {
    initial: 'initial',
    up: 'up',
    down: 'down',
};

const SaveModes = {
    SaveAsLive: 'SaveAsLive',
    SaveAsTest: 'SaveAsTest',
    SaveAsDraft: 'SaveAsDraft',
};

const SaveModesLabels = {
    1: 'SaveAsLive',
    2: 'SaveAsTest',
    3: 'SaveAsDraft',
    SaveAsLive: 1,
    SaveAsTest: 2,
    SaveAsDraft: 3,
};

const GenderLabels = {
    0: '',
    1: 'Male',
    2: 'Female',
};

const BonusTypeLabels = {
    2: 'WageringBonus',
    3: 'NoDepositBonus',
    4: 'CorrectionBonus',
    5: 'FreeSpin',
    6: 'FreeBet',
    8: 'SkillGames',
    9: 'BoostBonus',
    10: 'CasinoFreeSpin',
    12: 'LoyaltyPoint',
    13: 'CashBonus',
    14: 'NFTBonus',
};

const BonusAcceptanceTypeLabels = {
    0: 'None',
    1: 'Accepted',
    2: 'Activated',
    3: 'Rejected',
    4: 'Expired',
};

const BonusResultTypeLabels = {
    0: 'None',
    1: 'Paid',
    2: 'Lost',
    3: 'Canceled',
    4: 'Expired',
    6: 'Converted',
};
const ConfigurationStatusLabels = {
    All: '0',
    Live: '1',
    Draft: '2',
    0: 'All',
    1: 'Live',
    2: 'Draft',
};

const ConfigurationEventStatus = [
    {
        label: l.All,
        value: ConfigurationStatusLabels.All,
    },
    {
        label: l.Live,
        value: ConfigurationStatusLabels.Live,
    },
    {
        label: l.Draft,
        value: ConfigurationStatusLabels.Draft,
    },
];

const CustomerJourneyErrorCodes = {
    minimumTwoValidBlocksWithValidConnection: {
        label: l.MinimumTwoValidBlocksWithValidConnection,
        code: 0,
    },
    leastOneTargetBlock: {
        label: l.LeastOneTargetBlock,
        code: 1,
    },
    allBlocksMustBeConnected: {
        label: l.AllBlocksMustBeConnected,
        code: 2,
    },
    targetHasNotInput: {
        label: l.TargetHasNotInput,
        code: 3,
    },
    blockNameCanNotBeEmpty: {
        label: l.BlockNameCanNotBeEmpty,
        code: 4,
    },
    blockInvalid: {
        label: l.BlockInvalid,
        code: 5,
    },
    0: 'minimumTwoValidBlocksWithValidConnection',
    1: 'leastOneTargetBlock',
    2: 'allBlocksMustBeConnected',
    3: 'targetHasNotInput',
    4: 'blockNameCanNotBeEmpty',
    5: 'blockInvalid',
};

const DefaultDatesDict = {
    dailyView: {
        day: '',
        days: [],
        isActivePicker: false,
        month: '',
        months: [],
        viewType: 'dailyView',
        weeks: [],
        year: '',
        years: [],
    },
};

const FlowEditorElementsSetterActionTypes = {
    None: 'None',
    RemoveElements: 'RemoveElements',
};

const CJUITypes = {
    DropDown: 1,
    AutoComplete: 2,
    1: 'DropDown',
    2: 'AutoComplete',
};

const CJMenuItemDropDownTypes = {
    Integer: 'Integer',
    String: 'String',
};

const MenuItemTypes = {
    Query: 0,
    Classifier: 1,
    Custom: 2,
    MultiField: 3,
};

const excludeRightValueContentVisibilitiesOperators = ['isempty', 'isnotempty'];

const DownloadFileTypes = {
    xlsx: {
        fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        fileExtension: '.xlsx',
    },
    pdf: {
        fileType: 'application/pdf',
        fileExtension: '.pdf',
    },
    csv: {
        fileType: 'text/csv',
        fileExtension: '.csv',
    },
};
const newVersionBuilderConfig = '{"version":"2.0"}';

const timeSpanDefaultValue = '0.00:00:00';

const DownloadPopoverViews = {
    typesView: 'typesView',
    countView: 'countView',
};

const DownloadExtensions = {
    csv: 'csv',
    xlsx: 'xlsx',
};

const partnerIdQueryNamespace = 'partnerId';

const DataTableColumnsCustomTypes = {
    Date: 'Date',
};

const SortDirectionTypes = {
    ASC: 'asc',
    DESC: 'desc',
};

//TODO need translate
const GoalDropdownOptions = [
    { label: l.Bet, value: 0 },
    { label: l.Deposit, value: 1 },
];
const Appearance = {
    Light: 0,
    Dark: 1,
    0: 'Light',
    1: 'Dark',
};

const ViewMode = {
    Small: 0,
    Normal: 1,
    Large: 2,
    0: 'Small',
    1: 'Normal',
    2: 'Large',
};

const CommonIcons = {
    Info: 'bc-icon-info',
};

const ActionsIcons = {
    Delete: 'bc-icon-delete-2',
    Archive: 'bc-icon-archive',
    UnArchive: 'bc-icon-unarchive',
    Start: 'bc-icon-status-play',
    Stop: 'bc-icon-info-48',
    UsedIn: 'bc-icon-info',
    Resume: 'bc-icon-resume',
    Profit: 'bc-icon-profit',
    Clone: 'bc-icon-clone',
    Edit: 'bc-icon-edit',
    TransferBold: 'bc-icon-transfer-bold',
    StatusStop: 'bc-icon-status-stop',
    ViewDoc: 'bc-icon-view-doc',
    Settings: 'bc-icon-settings',
    Rerun: 'bc-icon-crm-rerun',
    Unlink: 'bc-icon-unlink',
    Link: 'bc-icon-link',
    Add: 'bc-icon-add',
    PlusCrm: 'bc-icon-plus-crm',
    Schedule: 'bc-icon-crm-schedule',
    Unschedule: 'bc-icon-crm-unschedule',
    ConvertToReport: 'bc-icon-convert-to-report',
    MessageClosed: 'bc-icon-message-closed',
    SetDefault: 'bc-icon-success-fill',
    Resend: 'bc-icon-resend',
    ViewReport: 'bc-icon-analytic-data-rmt-36',
    Visualization: 'bc-icon-visualization',
    ChangePartnerActivation: 'bc-icon-switcher',
    ManageTag: 'bc-icon-set-tag-block',
};

const ModalsClassNames = {
    Delete: 'crm-delete-modal',
    Archive: 'crm-archive-modal',
    UnArchive: 'crm-unarchive-modal',
    Start: 'crm-archive-modal',
    Stop: 'crm-stop-modal campaign-stop-modal',
    UsedIn: 'crm-delete-modal',
    Warning: 'crm-warning-modal',
};

const ObjectTypesMap = {
    0: DynamicBonusTypes.KPI,
    9999: DynamicBonusTypes.Object,
    1003: DynamicBonusTypes.FromFile,
    FromFile: 1003,
    Object: 9999,
    KPI: 0,
};

const CampaignLogStatusLabels = {
    1: 'Failed',
    0: 'Succes',
    Failed: 1,
    Succes: 0,
};

const CascadeRequestTypes = {
    GET: 'GET',
    POST: 'POST',
};

const CascadeUITypes = {
    DropDown: 1,
    Tree: 17,
    1: 'DropDown',
    17: 'Tree',
};

const ModalActionsConfig = {
    [ModalsActions.ARCHIVE]: {
        dataKey: 'ArchivedDate',
        typeKey: ArchiveUnarchiveTypes.Archive,
    },
    [ModalsActions.UNARCHIVE]: {
        dataKey: 'ArchivedDate',
        typeKey: ArchiveUnarchiveTypes.UnArchive,
    },
    [ModalsActions.DELETE]: {
        independent: true,
    },
    [ModalsActions.START]: {
        dataKey: 'Status',
        typeKey: [CustomerJourneyStatusLabels.InProgress, CustomerJourneyStatusLabels.All],
    },
    [ModalsActions.RESUME]: {
        dataKey: 'Status',
        typeKey: [CustomerJourneyStatusLabels.InProgress, CustomerJourneyStatusLabels.All],
    },
    [ModalsActions.CONVERT_TO_REPORT]: {
        dataKey: 'IsUsed',
        typeKey: EntityStatesLabels.Used,
    },
    [ModalsActions.CHANGE_MODE_CAMPAIGN]: {
        dataKey: 'State',
        typeKey: StateModesLabels.Live,
    },
    [ModalsActions.STOP]: {
        dataKey: 'Status',
        typeKey: [CampaignStatusLabels.Stoped, CampaignStatusLabels.All],
    },
    [ModalsActions.CUSTOMER_JOURNEY_STOP]: {
        dataKey: 'Status',
        typeKey: [CustomerJourneyStatusLabels.Suspended, CustomerJourneyStatusLabels.All],
    },
    [ModalsActions.SCHEDULE_REPORT]: {
        dataKey: 'State',
        typeKey: ReportStateLabels.Scheduled,
    },
    [ModalsActions.UNSCHEDULE_REPORT]: {
        dataKey: 'State',
        typeKey: ReportStateLabels.Standart,
    },
    [ModalsActions.SET_GOAL]: {
        dataKey: 'GoalName',
        typeKey: null, // filter does not exist because typeKey is null
    },
    [ModalsActions.DELETE_GOAL]: {
        dataKey: 'GoalName',
        typeKey: null, // filter does not exist because typeKey is null
    },
};

const CommunicationTypeLabels = {
    1: 'Email',
    2: 'SMS',
    3: 'PushNotification',
    4: 'PopUpNotification',
    5: 'InternalMessage',
    6: 'Telegram',
    7: 'Bonus',
    8: 'CMSPopUpNotification',
    9: 'Viber',
    10: 'Hoory',
    11: 'FastexNotification',
    12: 'DynamicPopUp',
    Email: 1,
    SMS: 2,
    PushNotification: 3,
    PopUpNotification: 4,
    InternalMessage: 5,
    Telegram: 6,
    Bonus: 7,
    CMSPopUpNotification: 8,
    Viber: 9,
    Hoory: 10,
    FastexNotification: 11,
    DynamicPopUp: 12,
};

const CommunicationTypeColors = {
    [CommunicationTypeLabels.Email]: '#b763c6',
    [CommunicationTypeLabels.SMS]: '#7dc681',
    [CommunicationTypeLabels.PushNotification]: '#f7776e',
    [CommunicationTypeLabels.PopUpNotification]: '#DA5CAC',
    [CommunicationTypeLabels.InternalMessage]: '#ffca5f',
    [CommunicationTypeLabels.Telegram]: '#5fb4f6',
    [CommunicationTypeLabels.Bonus]: '#39972b',
    [CommunicationTypeLabels.CMSPopUpNotification]: '#f75990',
    [CommunicationTypeLabels.Viber]: '#7360f2',
    [CommunicationTypeLabels.Hoory]: '#9441ee',
    [CommunicationTypeLabels.FastexNotification]: '#c75593',
    [CommunicationTypeLabels.DynamicPopUp]: '#47CFE0',
};

const CommunicationStatusLabels = {
    0: 'Success',
    1: 'Failed',
    Success: 0,
    Failed: 1,
};

const CommunicationPromotionTypeLabels = {
    1: 'Campaign',
    2: 'CustomerJourney',
    3: 'NotificationCampaign',
    4: 'Manual',
    5: 'TestSend',
    6: 'ActionCampaign',
    Campaign: 1,
    CustomerJourney: 2,
    NotificationCampaign: 3,
    Manual: 4,
    TestSend: 5,
    ActionCampaign: 6,
};

const BonusStateLabels = {
    0: l.Enabled,
    1: l.Disabled,
    Enabled: 0,
    Disabled: 1,
};

const CommunicationType = [
    {
        label: l.Email,
        value: CommunicationTypeLabels.Email,
    },
    {
        label: l.SMS,
        value: CommunicationTypeLabels.SMS,
    },
    {
        label: l.PushNotification,
        value: CommunicationTypeLabels.PushNotification,
    },
    {
        label: l.PopUpNotification,
        value: CommunicationTypeLabels.PopUpNotification,
    },
    {
        label: l.InternalMessage,
        value: CommunicationTypeLabels.InternalMessage,
    },
    {
        label: l.Telegram,
        value: CommunicationTypeLabels.Telegram,
    },
    {
        label: l.Bonus,
        value: CommunicationTypeLabels.Bonus,
    },
    {
        label: l.CMSPopUpNotification,
        value: CommunicationTypeLabels.CMSPopUpNotification,
    },
    {
        label: l.Viber,
        value: CommunicationTypeLabels.Viber,
    },
    {
        label: l.Hoory,
        value: CommunicationTypeLabels.Hoory,
    },
    {
        label: l.FastexNotification,
        value: CommunicationTypeLabels.FastexNotification,
    },
    {
        label: l.DynamicPopUp,
        value: CommunicationTypeLabels.DynamicPopUp,
    },
];

const CommunicationStatus = [
    {
        label: l.Success,
        value: CommunicationStatusLabels.Success,
    },
    {
        label: l.Failed,
        value: CommunicationStatusLabels.Failed,
    },
];

const CommunicationPromotionType = [
    {
        label: l.Campaign,
        value: CommunicationPromotionTypeLabels.Campaign,
    },
    {
        label: l.CustomerJourney,
        value: CommunicationPromotionTypeLabels.CustomerJourney,
    },
    {
        label: l.NotificationCampaign,
        value: CommunicationPromotionTypeLabels.NotificationCampaign,
    },
    {
        label: l.Manual,
        value: CommunicationPromotionTypeLabels.Manual,
    },
    {
        label: l.TestSend,
        value: CommunicationPromotionTypeLabels.TestSend,
    },
    {
        label: l.ActionCampaign,
        value: CommunicationPromotionTypeLabels.ActionCampaign,
    },
];
const RouteLeaveConfirmationType = {
    Always: 0,
    ChangeRelated: 1,
};

const EventNotifications = {
    BET_WIN_NOTIFICATION: 1,
    WITHDRAW_APPROVAL_NOTIFICATION: 2,
    WITHDRAW_REJECTED_NOTIFICATION: 3,
    MATCH_STARTED_NOTIFICATION: 4,
    MATCH_STARTED_NOTIFICATION_WITH_PRICES: 5,
    MATCH_ABOUT_TO_START_NOTIFICATION: 6,
    MATCH_ABOUT_TO_START_NOTIFICATION_WITH_PRICES: 7,
    MATCH_ENDED_NOTIFICATION: 8,
    FOOTBALL_GOAL_EVENT_NOTIFICATION: 9,
    FOOTBALL_PENALTY_STARTED_EVENT_NOTIFICATION: 10,
    1: 'BET_WIN_NOTIFICATION',
    2: 'WITHDRAW_APPROVAL_NOTIFICATION',
    3: 'WITHDRAW_REJECTED_NOTIFICATION',
    4: 'MATCH_STARTED_NOTIFICATION',
    5: 'MATCH_STARTED_NOTIFICATION_WITH_PRICES',
    6: 'MATCH_ABOUT_TO_START_NOTIFICATION',
    7: 'MATCH_ABOUT_TO_START_NOTIFICATION_WITH_PRICES',
    8: 'MATCH_ENDED_NOTIFICATION',
    9: 'FOOTBALL_GOAL_EVENT_NOTIFICATION',
    10: 'FOOTBALL_PENALTY_STARTED_EVENT_NOTIFICATION',
};

const NotificationFields = {
    ProviderId: 'ProviderId',
    MessageId: 'MessageId',
    IsEnabled: 'IsEnabled',
};
const LogicTypesValues = { and: 'and', or: 'or' };

const FilterGroupLimit = 1;

const EmailProviderTypeLabels = {
    0: 'SMTP',
    1: 'UniSender',
    2: 'HttpAPI',
    3: 'MailChimp',
    4: 'MailUp',
    SMTP: 0,
    UniSender: 1,
    HttpAPI: 2,
    MailChimp: 3,
    MailUp: 4,
};

const EmailProviderTypes = [
    {
        label: 'SMTP',
        value: '0',
    },
    {
        label: 'UniSender',
        value: '1',
    },
    {
        label: 'HttpAPI',
        value: '2',
    },
    {
        label: 'MailChimp',
        value: '3',
    },
    {
        label: 'MailUp',
        value: '4',
    },
];

const NavigationActionTypes = {
    PUSH: 'PUSH',
    POP: 'POP',
    REPLACE: 'REPLACE',
};

const ErrorMessageSymbolsCount = 120;

const NotificationsDescriptionsLabels = {
    1: l.BetWinDescription,
    2: l.WithdrawAproveDescription,
    3: l.WithdrawRejectedDescription,
    4: l.MatchStartedDescription,
    5: l.MatchStartedWithPricesDescription,
    6: l.MatchAboutStartDescription,
    7: l.MatchAboutStartWithPricesDescription,
    8: l.MatchEndedDescription,
    9: l.FootballGoalDescription,
    10: l.FootballPenaltyStartedDescription,
    11: l.WithdrawalDescription,
    12: l.DepositDescription,
};

const DefaultLangId = 'en';

const PromotionTypesLabels = {
    Campaign: 1,
    CustomerJourney: 2,
    NotificationCampaign: 3,
    ActionCampaign: 6,
    1: l.Campaign,
    2: l.CustomerJourney,
    3: l.NotificationCampaign,
    6: l.ActionCampaign,
};

const NotificationCampaignTypesLabels = {
    0: l.Enabled,
    1: l.Disabled,
    Enabled: 0,
    Disabled: 1,
};

const TemplateInputTypes = {
    TitleOfPushNotification: 'TitleOfPushNotification',
    Subject: 'Subject',
    Body: 'Body',
    SMSBody: 'SMSBody',
    ButtonText: 'ButtonText',
    ButtonUrl: 'ButtonUrl',
    Url: 'Url',
    UrlType: 'UrlType',
    DeviceType: 'DeviceType',
    UploadedImageUrl: 'UploadedImageUrl',
    FileName: 'FileName',
    ValidityPeriod: 'ValidityPeriod',
    SectionUrl: 'SectionUrl',
    NotificationCategory: 'NotificationCategory',
    IsImportant: 'IsImportant',
};

const CommunicationAvailabilityScheduleTypeLabels = {
    0: 'Always',
    1: 'SameEveryDay',
    2: 'WeekdaysAndWeekend',
    7: 'DaysOfTheWeek',
    Always: 0,
    SameEveryDay: 1,
    WeekdaysAndWeekend: 2,
    DaysOfTheWeek: 7,
};

const CommunicationAvailabilityScheduleTypes = [
    { label: l.CommunicationAvailabilityAlways, value: 0 },
    { label: l.CommunicationAvailabilitySameEveryDay, value: 1 },
    { label: l.CommunicationAvailabilityWeekdaysAndWeekend, value: 2 },
    { label: l.CommunicationAvailabilityDaysOfTheWeek, value: 7 },
];
const DataTablePagingOptions = [
    {
        label: '20',
        value: 20,
        defaultSelected: true,
    },
    {
        label: '30',
        value: 30,
    },
    {
        label: '100',
        value: 100,
    },
];

const ExecutedReportParametersDataTypes = {
    1: 'Date',
    2: 'Int',
    3: 'String',
    4: 'MenuItem',
    Date: 1,
    Int: 2,
    String: 3,
    MenuItem: 4,
};
const DeviceTabInfos = {
    [DeviceTypes.FULL_SCREEN]: {
        type: DeviceTypes.FULL_SCREEN,
        titleKey: l.Desktop,
        iconType: 'bc-icon-desktop',
    },
    [DeviceTypes.MOBILE]: {
        type: DeviceTypes.MOBILE,
        titleKey: l.Mobile,
        iconType: 'bc-icon-mobile',
    },
    [DeviceTypes.TABLET]: {
        type: DeviceTypes.TABLET,
        titleKey: l.Tablet,
        iconType: 'bc-icon-tablet',
    },
};

const ModeTabInfos = {
    [DisplayModeTypes.LIGHT_MODE]: {
        type: DisplayModeTypes.LIGHT_MODE,
        titleKey: l.Light,
        iconType: 'bc-icon-light-mode',
    },
    [DisplayModeTypes.DARK_MODE]: {
        type: DisplayModeTypes.DARK_MODE,
        titleKey: l.Dark,
        iconType: 'bc-icon-dark-mode',
    },
};

const CJDependencyTypes = {
    Bonus: 'Bonus',
    Provider: 'Provider',
    Template: 'Template',
    KpiConfig: 'KpiConfig',
};

const CompositeReportOperation = {
    1: 'Union',
    2: 'Intersect',
    3: 'FirstPriority',
    Union: 1,
    Intersect: 2,
    FirstPriority: 3,
};

const ClientsCountType = {
    Target: 0,
    Total: 1,
};

const BooleanTypeLabels = {
    [true]: l.Yes,
    [false]: l.No,
};

const CJBlockListActionModalTypes = {
    Add: 'Add',
    Remove: 'Remove',
};

const PartnerSettingsBaseKindTypes = {
    0: 'Application',
    1: 'Partner',
    3: 'PartnerGroup',
    null: 'Hierarchical',
    Application: 0,
    Partner: 1,
    Hierarchical: null,
    PartnerGroup: 3,
};

const PartnerKindsRadioGroupOptions = [
    {
        label: l.Partner,
        value: 1,
    },
    {
        label: l.PartnerGroup,
        value: 3,
    },
];

const PartnerSettingsValueTypes = {
    0: 'Text',
    1: 'Number',
    2: 'CustomList',
    3: 'JSON',
    Text: 0,
    Number: 1,
    CustomList: 2,
    JSON: 3,
};

const PartnerStatusLabels = {
    0: 'Unknown',
    1: 'Trial',
    2: 'Production',
    3: 'Inactive',
    4: 'Lost',
    5: 'Terminated',
    6: 'Deleted',
    Unknown: '0',
    Trial: '1',
    Production: '2',
    Inactive: '3',
    Lost: '4',
    Terminated: '5',
    Deleted: '6',
};

const PartnerStatus = [
    {
        label: l.Unknown,
        value: PartnerStatusLabels.Unknown,
    },
    {
        label: l.Trial,
        value: PartnerStatusLabels.Trial,
    },
    {
        label: l.Production,
        value: PartnerStatusLabels.Production,
    },
    {
        label: l.Inactive,
        value: PartnerStatusLabels.Inactive,
    },
    {
        label: l.Lost,
        value: PartnerStatusLabels.Lost,
    },
    {
        label: l.Terminated,
        value: PartnerStatusLabels.Terminated,
    },
    {
        label: l.Deleted,
        value: PartnerStatusLabels.Deleted,
    },
];

const DependencyObjectTypeLabels = {
    0: 'Campaign',
    1: 'Workflow',
    2: 'Template',
    3: 'Segment',
    4: 'EmailProvider',
    5: 'AdHocReport',
    6: 'SmsProvider',
    7: 'CompositeReport',
    8: 'WorkflowReport',
    Campaign: 0,
    Workflow: 1,
    Template: 2,
    Segment: 3,
    EmailProvider: 4,
    AdHocReport: 5,
    SmsProvider: 6,
    CompositeReport: 7,
    WorkflowReport: 8,
};

const MenuItemIds = {
    CMSPopUpNotification: 3005,
    PopUpNotification: 84,
    ColumnInfoType: 3007,
    UIType: 3008,
    FieldType: 3010,
    ExportType: 3011,
    SegmantationCategory: 3012,
    ClassifierGroupId: 3018,
    FastexNotificationCategoryGroup: 3060,
};

const GlobalEvents = {
    PartnerChanged: 'PartnerChanged',
    UserSettingsChange: 'UserSettingsChange',
    ClientTagsChange: 'ClientTagsChange',
};

const SubscriberSources = {
    MenuItem: 'MenuItem',
    PartnerLanguage: 'PartnerLanguage',
    QueryConfiguration: 'QueryConfiguration',
    UserInfo: 'UserInfo',
    ReportColumn: 'ReportColumn',
    ClientInfoPreview: 'ClientInfoPreview',
    PartnerCurrencies: 'PartnerCurrencies',
};

const SegmentFilterApplicationColumnId = 5147;

const ClientColumnSets = {
    previewableColumns: (columns) =>
        columns
            .filter((item) => !isNil(item.PreviewColumnOrder))
            .sort((a, b) => a.PreviewColumnOrder - b.PreviewColumnOrder),
};

const ApplicationColumnIds = {
    Client: 38,
    MainCurrency: 40,
    KPICurrency: 7492,
};

const RealtimePromotionTypes = {
    ActionCampaign: '6',
    CustomerJourney: '2',
    6: 'ActionCampaign',
    2: 'CustomerJourney',
};

const RealtimePromotionTypeIcons = {
    [RealtimePromotionTypes.CustomerJourney]: 'bc-icon-customer-journey',
    [RealtimePromotionTypes.ActionCampaign]: 'bc-icon-action-compaign',
};

const PromotionTypesDropdownData = [
    { label: l.ActionCampaign, value: RealtimePromotionTypes.ActionCampaign },
    { label: l.CustomerJourney, value: RealtimePromotionTypes.CustomerJourney },
];

const CustomerJourneyInfoModalTitleLabels = {
    [RealtimePromotionTypes.CustomerJourney]: 'CJInfo',
    [RealtimePromotionTypes.ActionCampaign]: 'ActionCampaignInfo',
};

const SupportedComparisonFilterFieldsTypes = {
    0: FilterHolderFields.textInput.type,
    1: FilterHolderFields.select.type,
    5: FilterHolderFields.textInput.type,
    14: FilterHolderFields.menuItemMultiSelect.type,
};

const ManageTagActionTypes = {
    SetTag: '1',
    RemoveTag: '2',
    SetOnlyForSelection: '3',
    1: l.SetTag,
    2: l.RemoveTag,
    3: l.SetOnlyForSelection,
};

const ManageTagActionList = [
    {
        label: l.SetTag,
        value: ManageTagActionTypes.SetTag,
        icon: 'bc-icon-plus-crm',
    },
    {
        label: l.RemoveTag,
        value: ManageTagActionTypes.RemoveTag,
        icon: 'bc-icon-close',
    },
    {
        label: l.SetOnlyForSelection,
        value: ManageTagActionTypes.SetOnlyForSelection,
        icon: 'bc-icon-partner',
    },
];

const ClientKPIColumnTypes = {
    None: 0,
    Aggregate: 1 << 0,
    Currency: 1 << 1,
    Count: 1 << 2,
    Computed: 1 << 3,
    Formula: 1 << 5,
    get ComputedCurrency() {
        return this.Computed | this.Currency;
    },
    get AggregatedCurrency() {
        return this.Aggregate | this.Currency;
    },
    get FormulaCurrency() {
        return this.Formula | this.Currency;
    },
};

const DeniedTags = {
    dynamicPopUp: [],
    email: ['script', 'form', 'input', 'select', 'textarea', 'button'],
    internal: [
        'html',
        'body',
        'head',
        'script',
        'meta',
        'base',
        'title',
        'form',
        'input',
        'select',
        'textarea',
        'button',
    ],
};

const TemplateEditorTypes = {
    HtmlCodeEditor: 'HtmlCodeEditor',
    JoditEditor: 'TextEditor',
    BlockEditor: 'BlockEditor',
    MosaicoEditor: 'BlockEditorLegacy',
};

const TemplateEditorModes = {
    Internal: [
        {
            label: l.BlockEditorLegacy,
            value: TemplateEditorTypes.MosaicoEditor,
        },
        {
            label: l.BlockEditor,
            value: TemplateEditorTypes.BlockEditor,
        },
        {
            label: l.TextEditor,
            value: TemplateEditorTypes.JoditEditor,
        },
        {
            label: l.HtmlCodeEditor,
            value: TemplateEditorTypes.HtmlCodeEditor,
        },
    ],
    Email: [
        {
            label: l.BlockEditorLegacy,
            value: TemplateEditorTypes.MosaicoEditor,
        },
        {
            label: l.BlockEditor,
            value: TemplateEditorTypes.BlockEditor,
        },
        {
            label: l.TextEditor,
            value: TemplateEditorTypes.JoditEditor,
        },
        {
            label: l.HtmlCodeEditor,
            value: TemplateEditorTypes.HtmlCodeEditor,
        },
    ],
    DynamicPopUp: [
        {
            label: l.BlockEditorLegacy,
            value: TemplateEditorTypes.MosaicoEditor,
        },
        {
            label: l.BlockEditor,
            value: TemplateEditorTypes.BlockEditor,
        },
        {
            label: l.TextEditor,
            value: TemplateEditorTypes.JoditEditor,
        },
        {
            label: l.HtmlCodeEditor,
            value: TemplateEditorTypes.HtmlCodeEditor,
        },
    ],
};

const PushNotificationProviderKeys = {
    FireBase: 2,
    OneSignal: 3,
};

export {
    l,
    PERMISSIONS,
    DownloadFileTypes,
    PARTNER_SETTINGS,
    ViewModeTypes,
    ViewThemeTypes,
    FilterHolderFields,
    SegmentTypes,
    SegmentTypesLabels,
    SegmentTypeIcons,
    EntityStates,
    EntityStatesLabels,
    SupportedLanguages,
    AlertTypes,
    DefinitionTypes,
    ModalsActions,
    StateModesLabels,
    StateModes,
    FullStateModes,
    FilterComparisions,
    CreatePopoverViews,
    LabelsModalViews,
    TagsModalViews,
    CustomersTypes,
    PageTypes,
    TemplateTypesLabels,
    TemplateTypeIcons,
    CommunicationTypeColors,
    TargetTypeEnum,
    ListRequestDefaultParams,
    DeviceTypes,
    CampaignType,
    CampaignStatus,
    CampaignStatusLabels,
    PageValidationTypes,
    ErrorShowType,
    ReportStatuses,
    ReportType,
    ReportTypesLabels,
    ReportTypeIcons,
    ReportStateLabels,
    CompositeSegmentOperation,
    ScheduleModalViews,
    Hours,
    Days,
    Months,
    WeekDaysKeyList,
    ReportsResultStatus,
    EventLogType,
    BonusTypes,
    BonusTypesLabels,
    BonusTypesIcons,
    BonusCalculationTypes,
    DynamicBonusTypes,
    CustomerJourneyStatus,
    CustomerJourneyStatusLabels,
    CustomerJourneyWorkingState,
    MonthsAndDays,
    CustomerJourneyGroupBlockTypes,
    CustomerJourneyGroupBlockTypesLabels,
    TimeZonePack,
    ExportFileFormat,
    GoalOptions,
    DynamicInputTypes,
    SegmentObjectType,
    layoutDirections,
    Widgets,
    WidgetsCategory,
    SaveModes,
    SaveModesLabels,
    DateTimeFormat,
    DateFormat,
    TimeFormat,
    DateTimeWithoutSecondsFormat,
    TimeFormatWithoutSeconds,
    MonthDayYearFormat,
    MonthDayFormat,
    oneDayMilliseconds,
    validDaysCount,
    dailyViewLimitInWeeks,
    GenderLabels,
    BonusTypeLabels,
    BonusAcceptanceTypeLabels,
    BonusResultTypeLabels,
    WidgetsCompStatus,
    CJAutoMapMode,
    CJModelRelationScopes,
    CJArgumentValuesTypes,
    CustomerJourneyErrorCodes,
    CJReferenceInArgumentType,
    CJFormulaInArgumentType,
    DefaultDatesDict,
    MonthDayYearWithTimeFormat,
    DateToISOStringFormat,
    FlowEditorElementsSetterActionTypes,
    CJUITypes,
    CJMenuItemDropDownTypes,
    excludeRightValueContentVisibilitiesOperators,
    MonthDayYearWithTime,
    CJDynamicDateInArgumentType,
    newVersionBuilderConfig,
    timeSpanDefaultValue,
    DownloadPopoverViews,
    DownloadExtensions,
    partnerIdQueryNamespace,
    CustomersTypesLabels,
    DataTableColumnsCustomTypes,
    SortDirectionTypes,
    GoalDropdownOptions,
    Appearance,
    ViewMode,
    ActionsIcons,
    CommonIcons,
    ModalsClassNames,
    ObjectTypesMap,
    CampaignLogStatusLabels,
    ModalActionsConfig,
    CampaignStatusColors,
    ExportFileFormatLabels,
    CommunicationType,
    CommunicationStatus,
    CommunicationPromotionType,
    CommunicationTypeLabels,
    CommunicationStatusLabels,
    CommunicationPromotionTypeLabels,
    RouteLeaveConfirmationType,
    EventNotifications,
    NotificationFields,
    PopUpUrlTypes,
    LogicTypesValues,
    FilterGroupLimit,
    CascadeRequestTypes,
    CascadeUITypes,
    EventLabels,
    EmailProviderTypeLabels,
    PromotionTypeLabels,
    NavigationActionTypes,
    ErrorMessageSymbolsCount,
    NotificationsDescriptionsLabels,
    DefaultLangId,
    defaultLogicFunction,
    clientIdTypeName,
    DeliveryMethodTypes,
    PromotionTypesLabels,
    NotificationCampaignTypesLabels,
    BonusStateLabels,
    TemplateInputTypes,
    DateToISOStringWithoutTimezoneFormat,
    ReportPageType,
    ConfigurationEventStatus,
    ConfigurationStatusLabels,
    CommunicationAvailabilityScheduleTypeLabels,
    CommunicationAvailabilityScheduleTypes,
    DisplayModeTypes,
    MenuItemTypes,
    ExecutedReportParametersDataTypes,
    DisplayModeStyles,
    DeviceTabInfos,
    ModeTabInfos,
    DataTablePagingOptions,
    CJDependencyTypes,
    CompositeReportOperation,
    ClientsCountType,
    BooleanTypeLabels,
    CustomerJourneyGroupBlockTypesValues,
    CJBlockListActionModalTypes,
    PartnerSettingsBaseKindTypes,
    PartnerSettingsValueTypes,
    PartnerStatusLabels,
    PartnerStatus,
    EmailProviderTypes,
    DependencyObjectTypeLabels,
    MenuItemIds,
    GlobalEvents,
    SubscriberSources,
    SegmentFilterApplicationColumnId,
    DisplayModeClassNames,
    DeviceTypeClassNames,
    ArchiveStates,
    ArchiveUnarchiveTypes,
    ClientColumnSets,
    MenuItemsTypes,
    MenuItemsLabels,
    RealtimePromotionTypes,
    RealtimePromotionTypeIcons,
    PromotionTypesDropdownData,
    CustomerJourneyInfoModalTitleLabels,
    SupportedComparisonFilterFieldsTypes,
    KPISources,
    ManageTagActionTypes,
    ManageTagActionList,
    ClientKPIColumnTypes,
    ApplicationColumnIds,
    TemplateEditorModes,
    TemplateEditorTypes,
    PartnerKindsRadioGroupOptions,
    ColorVars,
    DeniedTags,
    DynamicPopUpDeviceTypesLabels,
    ExpirationBonusModelTypes,
    ExpirationTypes,
    PushNotificationProviderKeys,
};
