import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, has, isEmpty, isNil, isObject } from 'lodash';
import classNames from 'classnames';
// Import Services
import { ReportHttpService, CustomerJourneyHttpService, SegmentHttpService, UtilsHttpService } from 'services/http';
import { getQueryConfigurationsList } from 'services/segment';
// Import Hooks
import { useGoToRoute, useRequest } from 'hooks';
// Import UI Components
import { Modal, Button, BusyLoader, Tooltip } from '@geneui/components';
// Import Components
import { KeyValueItemsGroup } from 'components';
import ColumnKPIsAndFiltersInfo from './ColumnKPIsAndFiltersInfo';
import CustomerJourneyFiltersAndKPIs from './CustomerJourneyFiltersAndKPIs';
import CompositeReportInfoTabs from './CompositeReportInfoTabs';
import CompositeReportGeneralInfoTabs from './CompositeReportGeneralInfoTabs';
// Import Constants
import { ModalsActions, l, ReportTypesLabels, ReportStateLabels, TimeZonePack } from 'constants/common';
import { GeneralDetailsGroupData } from './config';
import { RoutesList } from 'routes';
// Import Actions
import { DynamicSegmentActions } from 'actions';
//Import SCSS
import 'assets/scss/reportInfoModal.scss';

const { clearFilterList, setDefaultFilterList } = DynamicSegmentActions;

const ReportInfoModal = ({ reportInfo, isVisibleModal, onCancel }) => {
    const { t } = useTranslation();
    const { goToRoute } = useGoToRoute();
    const { doGetRequest, doPostRequest } = useRequest();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUsedIn, setIsLoadingUsedIn] = useState(false);
    const [isLoadingCustomerJourney, setIsLoadingCustomerJourney] = useState(true);
    const [reportAdditionalInfo, setReportAdditionalInfo] = useState(null);
    const [reportUsedInData, setReportUsedInData] = useState(null);
    const [keyValueItemsGroupData, setKeyValueItemsGroupData] = useState(cloneDeep(GeneralDetailsGroupData(t)));
    const [customerJourney, setCustomerJourney] = useState({});
    const [numberOfCustomersData, setNumberOfCustomersData] = useState({ Count: 0, Total: 0, isLoading: false });
    const [recurrenceDataLocal, setRecurrenceDataLocal] = useState({});

    const queryConfigurations = useSelector((state) => state.queryConfigurations);
    const dispatch = useDispatch();

    const { ReportId, isViewMode, ReportType, ReportState, SelfExecutionCount, CompositeExecutionCount } = reportInfo;
    const isExecuted = SelfExecutionCount + CompositeExecutionCount > 0;
    const isReport = ReportType === ReportTypesLabels.Report;
    const isCompositeReport = ReportType === ReportTypesLabels.CompositeReport;
    const isCustomerJourneyReport = ReportType === ReportTypesLabels.CustomerJourneyReport;
    const isVisibleClone =
        reportAdditionalInfo && (isReport || isCompositeReport || isCustomerJourneyReport) && !isViewMode;
    const isVisibleEdit =
        reportAdditionalInfo &&
        (isReport || isCompositeReport || isCustomerJourneyReport) &&
        !isExecuted &&
        !isViewMode;

    const executionCount = useMemo(() => {
        switch (ReportType) {
            case ReportTypesLabels.Report:
                return `${SelfExecutionCount}/${CompositeExecutionCount}`;
            case ReportTypesLabels.CompositeReport:
                return `${SelfExecutionCount}`;
            case ReportTypesLabels.CustomerJourneyReport:
                return `${SelfExecutionCount}`;
            default:
                return '';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const title = useMemo(() => {
        switch (ReportType) {
            case ReportTypesLabels.Report:
                return t(l.ReportInfo);
            case ReportTypesLabels.CompositeReport:
                return t(l.CompositeReportInfo);
            case ReportTypesLabels.CustomerJourneyReport:
                return t(l.CustomerJourneyReportInfo);
            default:
                return '';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        getReportById,
        getReportsByReport,
        getCustomerJourneyPreviewById,
        getSegmentCount,
        getQueryConfigurationsListRequest,
        getScheduleRunDatesRequest,
    } = useMemo(
        () => ({
            getReportById: ReportHttpService.getReportById(),
            getReportsByReport: ReportHttpService.getReportsByReport(),
            getCustomerJourneyPreviewById: CustomerJourneyHttpService.getCustomerJourneyPreviewById(),
            getSegmentCount: SegmentHttpService.getSegmentCount(),
            getQueryConfigurationsListRequest: getQueryConfigurationsList(),
            getScheduleRunDatesRequest: UtilsHttpService.getScheduleRunDates(),
        }),
        [],
    );

    const cloneClickHandler = () => {
        goToRoute(isReport ? RoutesList.REPORT_CLONE : RoutesList.REPORT_COMPOSITE_CLONE, { reportId: ReportId });
        onCancel(ModalsActions.CLONE);
    };

    const editClickHandler = () => {
        goToRoute(isReport ? RoutesList.REPORT_EDIT : RoutesList.REPORT_COMPOSITE_EDIT, { reportId: ReportId });
        onCancel(ModalsActions.EDIT);
    };

    const keyValueItemsDataSetter = (key, value, data, isLoading = false) => {
        setKeyValueItemsGroupData((prev) => {
            let tmpResult = cloneDeep(prev).map((group) => {
                if (group.keyValueItemsData[key]) {
                    let item = group.keyValueItemsData[key];
                    if (has(item, 'renderer') && isObject(value)) {
                        item.value = item.renderer(value);
                    } else {
                        item.value = value;
                    }
                    if (has(item, 'labelRenderer')) {
                        item.label = item.labelRenderer(data);
                    }
                    if (has(item, 'format')) {
                        if (!isNil(data)) {
                            item.value = item.format(data);
                        } else {
                            item.value = item.format(value);
                        }
                    }
                    item.isLoading = isLoading;
                    group.keyValueItemsData[key] = item;
                }
                return group;
            });
            return tmpResult;
        });
    };

    const getSegmentsCount = useCallback(() => {
        setNumberOfCustomersData((prev) => ({ ...prev, isLoading: true }));
        doPostRequest(getSegmentCount.request, {
            requestBody: reportAdditionalInfo?.QueryConfiguration,
            successCallback: (data) => {
                setNumberOfCustomersData({ ...data, isLoading: false });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportAdditionalInfo]);

    useEffect(() => {
        if (reportAdditionalInfo && ReportType === ReportTypesLabels.Report) {
            keyValueItemsDataSetter('numberOfCustomers', {
                numberOfCustomersData: numberOfCustomersData,
                getSegmentsCount: getSegmentsCount,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfCustomersData, reportAdditionalInfo]);

    useEffect(() => {
        if (!isNil(reportAdditionalInfo)) {
            keyValueItemsDataSetter('reportId', reportAdditionalInfo.ReportId);
            keyValueItemsDataSetter('name', reportAdditionalInfo.Name);
            keyValueItemsDataSetter('description', reportAdditionalInfo.Description);
            keyValueItemsDataSetter('executionCount', executionCount);
        }
    }, [reportAdditionalInfo, executionCount]);

    useEffect(() => {
        if (!isNil(ReportType)) {
            keyValueItemsDataSetter('type', { type: ReportType });
        }
    }, [ReportType]);

    useEffect(() => {
        if (isReport) {
            keyValueItemsDataSetter('usedIn', reportUsedInData, null, isLoadingUsedIn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportUsedInData, isLoadingUsedIn]);

    useEffect(() => {
        if (isCustomerJourneyReport) {
            keyValueItemsDataSetter('blockName', customerJourney.blockName, null, isLoadingCustomerJourney);
            keyValueItemsDataSetter('customerJourneyName', customerJourney.name, null, isLoadingCustomerJourney);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerJourney, isLoadingCustomerJourney]);

    useEffect(() => {
        if (!isNil(reportAdditionalInfo?.CompositeConfiguration)) {
            keyValueItemsDataSetter('operation', {
                operation: reportAdditionalInfo.CompositeConfiguration.Operation,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportAdditionalInfo]);

    useEffect(() => {
        if (!isNil(ReportState)) {
            keyValueItemsDataSetter('reportState', t(ReportStateLabels[ReportState]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ReportState]);

    useEffect(() => {
        setKeyValueItemsGroupData((prev) => {
            return prev.map((item) => ({
                ...item,
                isVisible: item.key !== 'schedule' || !isNil(reportAdditionalInfo?.Schedule),
            }));
        });
        if (!isNil(reportAdditionalInfo?.Schedule)) {
            keyValueItemsDataSetter(
                'startDate',
                reportAdditionalInfo.Schedule?.StartDate,
                reportAdditionalInfo?.Schedule,
            );
            keyValueItemsDataSetter('endDate', reportAdditionalInfo.Schedule?.EndDate, reportAdditionalInfo?.Schedule);
            if (reportAdditionalInfo.Schedule.RecurrenceCount !== 0) {
                keyValueItemsDataSetter('recurrenceCount', recurrenceDataLocal);
            }
            keyValueItemsDataSetter('scheduleType', reportAdditionalInfo.Schedule.ScheduleType);
            keyValueItemsDataSetter('timeZone', TimeZonePack[reportAdditionalInfo.Schedule?.TimeZone]);
            if (!isNil(reportAdditionalInfo.Schedule?.CronTime) && !isEmpty(reportAdditionalInfo.Schedule?.CronTime)) {
                keyValueItemsDataSetter(
                    'startTime',
                    reportAdditionalInfo.Schedule?.CronTime,
                    reportAdditionalInfo?.Schedule,
                );
            }
        }
    }, [recurrenceDataLocal, reportAdditionalInfo]);

    const getContent = (ReportType) => {
        // eslint-disable-next-line default-case
        switch (ReportType) {
            case ReportTypesLabels.Report:
                return (
                    <>
                        <KeyValueItemsGroup
                            itemsGroup={keyValueItemsGroupData.filter(({ isVisible }) => isVisible === true)}
                        />
                        <ColumnKPIsAndFiltersInfo reportType={ReportType} reportInfo={reportAdditionalInfo} />
                    </>
                );
            case ReportTypesLabels.CompositeReport:
                return (
                    <>
                        <CompositeReportGeneralInfoTabs
                            generalInfo={keyValueItemsGroupData.filter(({ isVisible }) => isVisible === true)}
                        />
                        <CompositeReportInfoTabs compositeConfiguration={reportAdditionalInfo.CompositeConfiguration} />
                    </>
                );
            case ReportTypesLabels.CustomerJourneyReport:
                return (
                    <>
                        <KeyValueItemsGroup
                            itemsGroup={keyValueItemsGroupData.filter(({ isVisible }) => isVisible === true)}
                        />
                        <CustomerJourneyFiltersAndKPIs report={reportAdditionalInfo} blocks={customerJourney.blocks} />
                    </>
                );
        }
    };

    const init = () => {
        setIsLoading(true);
        doGetRequest(getReportById.request, {
            queryString: { reportId: ReportId, reportType: ReportType },
            successCallback: (data) => {
                let reportInfoData = cloneDeep(data);
                if (!isNil(data.Schedule)) {
                    if (!isNil(data.Schedule?.CronSchedule) && data.Schedule?.RecurrenceCount) {
                        reportInfoData.Schedule.ScheduleType = l.Repeat;
                        doPostRequest(getScheduleRunDatesRequest.request, {
                            requestBody: {
                                cronExpression: data.Schedule.CronSchedule,
                                startDate: data.Schedule.StartDate,
                                endDate: data.Schedule.EndDate,
                                timeZone: data.Schedule.Timezone,
                            },
                            successCallback: (Data) => {
                                setRecurrenceDataLocal(Data);
                            },
                        });
                    } else {
                        reportInfoData.Schedule.ScheduleType = l.OneTime;
                    }
                }
                setReportAdditionalInfo(reportInfoData);
                setIsLoading(false);

                if (isCustomerJourneyReport) {
                    const workflowId = data.WorkflowReportConfiguration.WorkflowId;
                    const blockName = data.WorkflowReportConfiguration.BlockName;

                    doGetRequest(getCustomerJourneyPreviewById.request, {
                        queryString: { workflowId },
                        successCallback: (data) => {
                            const tmpConfig = JSON.parse(data?.Config);
                            const blockDisplayName = tmpConfig.Blocks.find(
                                (block) => block.Name === blockName,
                            )?.DisplayName;

                            setCustomerJourney({
                                blockName: blockDisplayName,
                                name: data.Name,
                                blocks: tmpConfig.Blocks ?? [],
                            });
                        },
                    }).then(() => {
                        setIsLoadingCustomerJourney(false);
                    });
                }
                if (isReport) {
                    getQueryConfigurationsListRequest.request(
                        dispatch,
                        queryConfigurations,
                        data,
                        setDefaultFilterList,
                        t,
                    );
                }
            },
        });

        if (isReport) {
            setIsLoadingUsedIn(true);
            doPostRequest(getReportsByReport.request, {
                requestBody: {
                    reportId: ReportId,
                    reportType: ReportType,
                },
                successCallback: (Data) => {
                    setReportUsedInData(Data);
                    setIsLoadingUsedIn(false);
                },
            });
        }
    };

    const cleanUp = () => {
        return () => {
            dispatch(clearFilterList());
            getReportById.cancel('ReportInfoModal:getReportById');
            getReportsByReport.cancel('ReportInfoModal:getReportsByReport');
            getCustomerJourneyPreviewById.cancel('ReportInfoModal:getCustomerJourneyPreviewById');
            getSegmentCount.cancel('ReportInfoModal:getSegmentCount');
            getQueryConfigurationsListRequest.cancel('ReportInfoModal:getQueryConfigurationsList');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <Modal
            className={classNames('report-info-modal-c', {
                'composite-report-info-modal': ReportType === ReportTypesLabels.CompositeReport,
                'cj-report-info-modal': ReportType === ReportTypesLabels.CustomerJourneyReport,
            })}
            background="dark-background"
            position="center"
            title={
                <div className="segment-list-modal-head">
                    <span className="segment-list-modal-h-text">{title}</span>
                    <div className="segment-list-modal-h-icons">
                        {isVisibleClone && (
                            <Tooltip text={t(l.CloneReport)}>
                                <Button
                                    icon="bc-icon-clone"
                                    appearance="minimal"
                                    color="primary"
                                    onClick={() => cloneClickHandler()}
                                />
                            </Tooltip>
                        )}
                        {isVisibleEdit && (
                            <Tooltip text={t(l.EditReport)}>
                                <Button
                                    icon="bc-icon-edit"
                                    appearance="minimal"
                                    color="primary"
                                    onClick={editClickHandler}
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>
            }
            closable={false}
            onCancel={() => onCancel(ModalsActions.CLOSE)}
            closeOnClickOutside={true}
            visible={isVisibleModal}
            footer={
                <Button appearance="minimal" onClick={() => onCancel(ModalsActions.CLOSE)}>
                    {t(l.Close)}
                </Button>
            }
        >
            <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
                {!isNil(reportAdditionalInfo) && getContent(ReportType)}
            </BusyLoader>
        </Modal>
    );
};

ReportInfoModal.propTypes = {
    reportInfo: PropTypes.object.isRequired,
    isVisibleModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default memo(ReportInfoModal);
