import React, { memo, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, isFunction, isNil, keys, noop, first } from 'lodash';

import { ReactFlowProvider, isNode } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RoutesList } from 'routes';
import { CJPreSaveModalBodyContentValidationSchema } from 'validators/schemas.validation';
import { yupSchemaValidate } from 'validators/service.validator';
import classNames from 'classnames';
// Import UI Components
import { BusyLoader, Button, Tooltip } from '@geneui/components';
// Import Components
import { PageHeadline, FlowEditor, FlowEditorSidebar, CJBlockInfoSidebar, AccessControl } from 'components';
import { CJPreSaveModalBodyContent, CJViewPageHeadline, ActivityTimeLineModal } from 'components/CustomerJourney';
// Import Constants
import {
    CustomerJourneyStatusLabels,
    PageTypes,
    PageValidationTypes,
    SaveModes,
    newVersionBuilderConfig,
    CustomerJourneyGroupBlockTypes,
    AlertTypes,
    l,
    ClientsCountType,
    RealtimePromotionTypes,
    PERMISSIONS,
} from 'constants/common';
// Import Hook
import { useValidation, useValidator, useRequest, useToaster, useGoToRoute, useQueryParams } from 'hooks';
// Import Actions
import { CustomerJourneyAction, RouteLeaveConfirmationAction } from 'actions';
// Import Services
import { CustomerJourneyHttpService } from 'services/http';
import { Helpers } from 'services';
import {
    groupBlocksBuilder,
    mapFlowEditorModelToConfigModel,
    mapConfigToFlowEditorModel,
    getCustomerJourneyPageCustomValidationTypeErrorText,
    getDependencies,
    elementsCorrection,
    isEditOnlyLive,
    calculateTotalClientsRemoveCount,
    getTargetClientsData,
} from 'services/customerJourney';
// Import SCSS
import 'assets/scss/customerJourneyPage.scss';

const { isObjectsEquals } = Helpers;

const { incrementBlockCount, setMaxBlocksCounts } = CustomerJourneyAction;
const { setHasDataChange: setRouteLeaveHasDataChange, setIsChangeSaved: setRouteLeaveIsChangeSaved } =
    RouteLeaveConfirmationAction;

const pageInitialDataShape = {
    Name: null,
    Description: null,
    Elements: [],
};
const { warning } = AlertTypes;

const { REALTIME_JOURNEY_EDIT, REALTIME_ACTION_EDIT, REALTIME_PROMOTIONS_LIST } = RoutesList;

const preSaveBodyContentValidationStatesShape = {
    startDate: { isValid: true, errorMessage: null },
    endDate: { isValid: true, errorMessage: null },
    startTime: { isValid: true, errorMessage: null },
    endTime: { isValid: true, errorMessage: null },
};

// eslint-disable-next-line react/display-name
const JourneyPage = forwardRef(
    (
        {
            titleKey,
            type,
            initialData,
            apiEndpoint,
            workflowId,
            blockActions,
            isLoading,
            defaultName,
            defaultDescription,
            defaultSchedule,
            status,
            blocksCounts,
            blockInfo,
            viewPageHeadlineData,
            pushElementsTree,
            isInfoSideBarOpened,
            infoSidebarToggleHandler,
            onFlowEditorSelectionChange,
            filters,
            hasVisualization,
            customActions,
            promotionType,
            exportAllExceededCellsCount,
        },
        cjPageRef,
    ) => {
        const { t } = useTranslation();
        const { goToRoute, goToPreviousRoute } = useGoToRoute();
        const goToRouteQueryParams = useQueryParams();
        const decodedQueryParams = goToRouteQueryParams.decode();

        const dispatch = useDispatch();
        const { showToaster } = useToaster();

        const [isBlocksSidebarDisabled, setIsBlocksSidebarDisabled] = useState(true);
        const [groupBlocks, setGroupBlocks] = useState([]);
        const [elements, setElements] = useState([]);
        const [initialElements, setInitialElements] = useState([]);
        const [cjSchedule, setCjSchedule] = useState(defaultSchedule);
        const [isVisibleTimeLine, setIsVisibleTimeLine] = useState(false);

        const [pageInitialData, setPageInitialData] = useState({ ...pageInitialDataShape });

        const [cjName, setCjName] = useState(pageInitialData?.Name);
        const [cjDescription, setCjDescription] = useState(pageInitialData?.Description);
        const [isDataReady, setIsDataReady] = useState(false);
        const [clientsCountType, setClientsCountType] = useState(ClientsCountType.Total);
        const [showRemovedClientsCount, setShowRemovedClientsCount] = useState(
            promotionType === RealtimePromotionTypes.ActionCampaign,
        );

        const modeRef = useRef();

        const [isVisibleCJSchedule, setIsVisibleCJSchedule] = useState({});
        const [preSaveBodyContentValidationStates, setPreSaveBodyContentValidationStates] = useState(
            cloneDeep(preSaveBodyContentValidationStatesShape),
        );

        const isValidPreSaveModalBodyContent = (isVisibleCJSchedule, preSaveBodyContentValidationStates) =>
            keys(isVisibleCJSchedule).reduce((acc, item) => {
                if (isVisibleCJSchedule[item]) {
                    acc = acc && preSaveBodyContentValidationStates[item].isValid;
                }
                return acc;
            }, true);

        const SetIsVisibleCJSchedule = (cjStatus = status) => {
            setIsVisibleCJSchedule({
                startDate:
                    modeRef.current !== SaveModes.SaveAsDraft && +cjStatus !== +CustomerJourneyStatusLabels.InProgress,
                endDate: modeRef.current !== SaveModes.SaveAsDraft,
                startTime:
                    modeRef.current !== SaveModes.SaveAsDraft && +cjStatus !== +CustomerJourneyStatusLabels.InProgress,
                endTime: modeRef.current !== SaveModes.SaveAsDraft,
            });
        };

        useEffect(() => {
            SetIsVisibleCJSchedule(status);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [status]);

        const { doGetRequest, doPostRequest } = useRequest();
        const { clearValidationScenario, resetValidationScenario, addValidationProperties } = useValidation(
            PageValidationTypes.JourneyPage,
            false,
            type,
        );

        const readOnly = PageTypes.view === type;

        const resetClicked = useRef(false);

        const flowEditorRef = useRef({});

        const validator = useValidator(getCustomerJourneyPageCustomValidationTypeErrorText(t));

        useImperativeHandle(cjPageRef, () => ({
            setClientsCountsByBlock: flowEditorRef.current?.setClientsCountsByBlock,
        }));

        const { getCustomerJourneySchema, getCustomerJourneyLimits } = useRef({
            getCustomerJourneySchema: CustomerJourneyHttpService.getCustomerJourneySchema(),
            getCustomerJourneyLimits: CustomerJourneyHttpService.getCustomerJourneyLimits(),
        }).current;

        const handleReset = () => {
            resetClicked.current = true;
            if (!isNil(flowEditorRef.current) && isFunction(flowEditorRef.current.resetFlowEditor)) {
                flowEditorRef.current.resetFlowEditor();
            }
            setPageInitialData((prev) => ({
                ...prev,
                Elements: mapFlowEditorModelToConfigModel(initialElements),
            }));
            resetValidationScenario();
        };

        const closeTimeLineModal = () => {
            setIsVisibleTimeLine(false);
        };

        const savedCustomerJourneyHandler = (data) => {
            const responseData = data?.Data;

            if ([PageTypes.create, PageTypes.clone, PageTypes.predefined].includes(type)) {
                if (isNil(responseData?.WorkflowId)) {
                    // case when response does not contain workflowId
                    // must be never - corner case and
                    goToRoute(REALTIME_PROMOTIONS_LIST);
                } else {
                    const params = { workflowId: responseData.WorkflowId };
                    const route =
                        responseData.PromotionType === RealtimePromotionTypes.ActionCampaign
                            ? REALTIME_ACTION_EDIT
                            : REALTIME_JOURNEY_EDIT;
                    goToRoute(route, params);
                }
            } else if (type === PageTypes.edit) {
                const tmpElements = elements.map((element) => {
                    if (isNode(element) && isEditOnlyLive(type, status)) {
                        element.data.allClientsCounts = blocksCounts[element.id]?.OutputModelsCount ?? 0;
                        element.data.isDeletable = element.type !== CustomerJourneyGroupBlockTypes.target;
                        element.data.isEditOnlyLive = true;
                    }
                    return element;
                });
                if (!isNil(flowEditorRef.current) && isFunction(flowEditorRef.current.setInitialElements)) {
                    flowEditorRef.current.setInitialElements(tmpElements);
                }
                setInitialElements(tmpElements);
            } else {
                goToRoute(REALTIME_PROMOTIONS_LIST);
            }
        };

        const saveCustomerJourneyHandler = (name, description, mode, leavePage) => {
            if (!validateCJSchedule()) {
                return new Promise((resolve) => {
                    resolve({ HasError: true });
                });
            }
            const configObj = JSON.stringify({ Blocks: mapFlowEditorModelToConfigModel(elements) });
            let requestMetadata = {};
            let tmpStatus = isEditOnlyLive(type, status)
                ? status
                : mode === SaveModes.SaveAsDraft
                ? CustomerJourneyStatusLabels.Invalid
                : CustomerJourneyStatusLabels.NonStarted;

            const requestBody = {
                Name: name,
                Description: description,
                Status: tmpStatus,
                Config: configObj,
                BuilderConfig: newVersionBuilderConfig,
                Schedule: cjSchedule, // TODO: add schedule component
                Promotion: null,
                PromotionType: promotionType,
            };

            if (PageTypes.edit === type) {
                requestMetadata.queryString = { workflowId };
                if (hasVisualization) {
                    requestMetadata.successCallback = () => {
                        showToaster(warning, t(l.CheckVisualizationMessage));
                    };
                }
            }

            requestMetadata.requestBody = requestBody;

            requestMetadata.successCallback = () => {
                if (leavePage === false) {
                    setPageInitialData((prev) => ({
                        ...prev,
                        Name: name,
                        Description: description,
                        Elements: mapFlowEditorModelToConfigModel(elements),
                    }));
                }

                dispatch(setRouteLeaveIsChangeSaved(leavePage || type !== PageTypes.edit));
            };

            return doPostRequest(apiEndpoint?.request, requestMetadata).then((data) => {
                if (decodedQueryParams?.fromViewPage === true) {
                    return {
                        ...(data ?? {}),
                        customRoute: () => {
                            goToPreviousRoute();
                        },
                    };
                }
                return data;
            });
        };

        const validateCJSchedule = (schedule = cjSchedule) => {
            const tmpPreSaveBodyContentValidationStatesShape = cloneDeep(preSaveBodyContentValidationStatesShape);

            const schema = CJPreSaveModalBodyContentValidationSchema(t, elements);
            yupSchemaValidate(
                schema,
                {
                    isVisibleStartDate: isVisibleCJSchedule?.startDate,
                    isVisibleEndDate: isVisibleCJSchedule?.endDate,
                    isActiveStartDate: schedule?.isActiveStartDate,
                    isActiveEndDate: schedule?.isActiveEndDate,
                    startDate: schedule?.StartDate,
                    endDate: schedule?.EndDate,
                    timeZone: schedule?.TimeZone,
                    startTime: schedule?.startTime,
                    endTime: schedule?.endTime,
                },
                (e) => {
                    if (
                        !isNil(tmpPreSaveBodyContentValidationStatesShape[e.path]) &&
                        isNil(tmpPreSaveBodyContentValidationStatesShape[e.path].errorMessage)
                    ) {
                        tmpPreSaveBodyContentValidationStatesShape[e.path].isValid = false;
                        tmpPreSaveBodyContentValidationStatesShape[e.path].errorMessage = e.message;
                    }
                },
            );
            setPreSaveBodyContentValidationStates(tmpPreSaveBodyContentValidationStatesShape);
            return isValidPreSaveModalBodyContent(isVisibleCJSchedule, tmpPreSaveBodyContentValidationStatesShape);
        };

        const cjScheduleUpdateHandler = (schedule) => {
            setCjSchedule(schedule);
            validateCJSchedule(schedule);
        };

        const init = () => {
            doGetRequest(getCustomerJourneySchema.request, {
                queryString: {
                    promotionType: promotionType,
                },
                successCallback: (data) => {
                    setGroupBlocks(groupBlocksBuilder(data));
                    if (type !== PageTypes.view) {
                        doGetRequest(getCustomerJourneyLimits.request, {
                            successCallback: (data) => {
                                let limit, count;

                                if (promotionType === RealtimePromotionTypes.CustomerJourney) {
                                    limit = data.WorkflowLimit;
                                    count = data.WorkflowCount;
                                } else if (promotionType === RealtimePromotionTypes.ActionCampaign) {
                                    limit = data.InstantWorkflowLimit;
                                    count = data.InstantWorkflowCount;
                                }
                                addValidationProperties({
                                    CustomerJourneyLimit: { limit: limit, count: count },
                                });
                                dispatch(setMaxBlocksCounts(data.BlockLimit));
                            },
                        }).then(() => setIsBlocksSidebarDisabled(false));
                    }
                },
            });
        };

        useEffect(init, []);

        const getInitialElements = (initialData, groupBlocks, blocksCounts) => {
            const tmpInitialElements = mapConfigToFlowEditorModel(initialData, groupBlocks).map(
                (element, _index, allElements) => {
                    if (isNode(element)) {
                        element.data.readOnly = readOnly;
                        if (readOnly) {
                            const { targetClientsCount, targetClientsRemoveCount } = getTargetClientsData(
                                blocksCounts,
                                allElements,
                            );

                            element.data.actions = blockActions;
                            element.data.allClientsCounts = blocksCounts[element.id]?.OutputModelsCount;
                            element.data.removeClientsCounts = blocksCounts[element.id]?.RemovedModelsCount;
                            element.data.currentClientsCounts = blocksCounts[element.id]?.OutputModelsCount;
                            element.data.workingState = blocksCounts[element.id]?.WorkingState;
                            element.data.customerJourneyStatus = viewPageHeadlineData?.Status;
                            element.data.totalClientsCount = blocksCounts[element.id]?.TotalModelsCount;
                            element.data.totalClientsRemoveCount = calculateTotalClientsRemoveCount(
                                element,
                                allElements,
                                blocksCounts,
                            );
                            element.data.isFiltered = false;
                            element.data.clientsCountType = clientsCountType;
                            element.data.showRemovedClientsCount = showRemovedClientsCount;
                            element.data.targetClientsCount = targetClientsCount;
                            element.data.targetClientsRemoveCount = targetClientsRemoveCount;
                        } else {
                            element.data.actions = {
                                editNameAction(args) {
                                    flowEditorRef.current.editNameAction(args);
                                },
                                deleteAction(args) {
                                    flowEditorRef.current.deleteAction(args);
                                },
                                editAction(args) {
                                    flowEditorRef.current.editAction(args);
                                },
                                cloneAction(args) {
                                    flowEditorRef.current.cloneAction(args);
                                },
                            };
                            if (isEditOnlyLive(type, status)) {
                                element.data.allClientsCounts = blocksCounts[element.id]?.OutputModelsCount;
                                element.data.isDeletable = element.type !== CustomerJourneyGroupBlockTypes.target;
                                element.data.isEditOnlyLive = true;
                            } else {
                                element.data.isDeletable = true;
                            }
                            element.data.isCloneable = element.type !== CustomerJourneyGroupBlockTypes.target;
                        }
                    }
                    return element;
                },
            );

            return getDependencies(tmpInitialElements, type).then(() => tmpInitialElements);
        };

        const setInitialVisibleBlocksCounts = (initialElements) => {
            const initialVisibleBlocksCounts = initialElements
                .filter((el) => isNode(el))
                .reduce((acc, item) => {
                    if (isNil(acc[item.type])) {
                        acc[item.type] = 1;
                    } else {
                        acc[item.type]++;
                    }
                    return acc;
                }, {});
            dispatch(incrementBlockCount(initialVisibleBlocksCounts));
        };

        useEffect(() => {
            if (!isEmpty(groupBlocks) && !isEmpty(initialData)) {
                const tmpIsEditOnlyLive = isEditOnlyLive(type, status);
                if (type === PageTypes.edit || type === PageTypes.clone) {
                    if (!tmpIsEditOnlyLive || (tmpIsEditOnlyLive && !isNil(blocksCounts))) {
                        getInitialElements(initialData, groupBlocks, blocksCounts).then((initialElements) => {
                            setInitialVisibleBlocksCounts(initialElements);
                            setElements(initialElements);
                            setInitialElements(initialElements);
                            setPageInitialData((prev) => ({
                                ...prev,
                                Elements: mapFlowEditorModelToConfigModel(initialElements),
                            }));
                            setIsDataReady(true);
                        });
                    }
                } else {
                    // TODO: need review boolean value
                    if (!(type === PageTypes.view && isNil(blocksCounts))) {
                        getInitialElements(initialData, groupBlocks, blocksCounts).then((initialElements) => {
                            setElements(initialElements);
                            setInitialElements(initialElements);
                            setPageInitialData((prev) => ({
                                ...prev,
                                Elements: mapFlowEditorModelToConfigModel(initialElements),
                            }));
                            setIsDataReady(true);
                        });
                    }
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [initialData, groupBlocks, blocksCounts]);

        useEffect(() => {
            if (!isNil(flowEditorRef.current) && isFunction(flowEditorRef.current.setBlockCountCalculationTypes)) {
                flowEditorRef.current.setBlockCountCalculationTypes(clientsCountType, showRemovedClientsCount);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [clientsCountType, showRemovedClientsCount]);

        useEffect(() => {
            if (type === PageTypes.create && !isEmpty(groupBlocks)) {
                setIsDataReady(true);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [groupBlocks]);

        const cleanUp = () => {
            return () => {
                setElements([]);
                clearValidationScenario();
                setPageInitialData({ ...pageInitialDataShape });
                getCustomerJourneySchema.cancel('JourneyPage:getCustomerJourneySchema');
                getCustomerJourneyLimits.cancel('JourneyPage:getCustomerJourneyLimits');
            };
        };
        useEffect(cleanUp, []);

        const getElementsUpdate = (newData) => {
            if (type !== PageTypes.view) {
                validator({ CustomerJourney: newData });
            }
            setElements(newData);
            pushElementsTree(newData);
        };

        const getAdditionalSettings = () => {
            const result = {
                [l.SaveAsLive]: (name, description, mode, leavePage) =>
                    saveCustomerJourneyHandler(name, description, SaveModes.SaveAsLive, leavePage),
            };

            if (!isEditOnlyLive(type, status)) {
                result[l.SaveAsDraft] = (name, description, mode, leavePage) => {
                    return saveCustomerJourneyHandler(name, description, SaveModes.SaveAsDraft, leavePage);
                };
            }

            return result;
        };

        const isDisabledDownload = (elements) => {
            const target = first(
                elements.filter((element) => {
                    return isNode(element) && element.type === CustomerJourneyGroupBlockTypes.target;
                }),
            );
            return isNil(target?.data?.currentClientsCounts) || +target.data.currentClientsCounts === 0;
        };

        useEffect(() => {
            setPageInitialData((prev) => ({ ...prev, Name: defaultName }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [defaultName]);

        useEffect(() => {
            setPageInitialData((prev) => ({ ...prev, Description: defaultDescription }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [defaultDescription]);

        useEffect(() => {
            const hasDataChange = !isObjectsEquals(
                {
                    Name: cjName,
                    Description: cjDescription,
                    Elements: mapFlowEditorModelToConfigModel(elements),
                },
                pageInitialData,
            );

            dispatch(setRouteLeaveHasDataChange(hasDataChange));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [cjName, cjDescription, elements, pageInitialData]);

        return (
            <div className="crm-customer-journey-cecp-page">
                {isVisibleTimeLine && (
                    <ActivityTimeLineModal
                        isVisible={isVisibleTimeLine}
                        handleClose={closeTimeLineModal}
                        workflowId={workflowId}
                    />
                )}
                {type === PageTypes.view ? (
                    <CJViewPageHeadline
                        isDisabledDownload={isDisabledDownload(elements)}
                        titleKey={titleKey}
                        isLoading={type !== PageTypes.create && isEmpty(initialData)}
                        viewData={viewPageHeadlineData}
                        setIsVisibleTimeLine={setIsVisibleTimeLine}
                        downloadFilters={filters}
                        promotionType={promotionType}
                        exportAllExceededCellsCount={exportAllExceededCellsCount}
                    />
                ) : (
                    <PageHeadline
                        isDataReady={isDataReady && !(type !== PageTypes.create && isLoading)}
                        titleKey={titleKey}
                        handleReset={handleReset}
                        handleSave={saveCustomerJourneyHandler}
                        handleSaved={savedCustomerJourneyHandler}
                        isLoading={type !== PageTypes.create && isLoading}
                        validationListLabel="CustomerJourneyCreateModal"
                        defaultName={pageInitialData.Name}
                        handleNameChange={setCjName}
                        defaultDescription={pageInitialData.Description}
                        handleDescriptionChange={setCjDescription}
                        getCustomValidationTypeErrorText={getCustomerJourneyPageCustomValidationTypeErrorText(t)}
                        doValidation={(mode) => {
                            modeRef.current = mode;
                            SetIsVisibleCJSchedule();
                            return mode !== SaveModes.SaveAsDraft;
                        }}
                        additionalSettings={getAdditionalSettings()}
                        preSaveModalContent={
                            <AccessControl permissions={[PERMISSIONS.ExecuteWorkflow]}>
                                <CJPreSaveModalBodyContent
                                    defaultSchedule={defaultSchedule}
                                    isVisibleStates={isVisibleCJSchedule}
                                    isValidStates={preSaveBodyContentValidationStates}
                                    onChange={cjScheduleUpdateHandler}
                                />
                            </AccessControl>
                        }
                        isValidPreSaveModalContent={isValidPreSaveModalBodyContent(
                            isVisibleCJSchedule,
                            preSaveBodyContentValidationStates,
                        )}
                        withWarningsAlertContent={modeRef.current !== SaveModes.SaveAsDraft}
                        visibleLeavePageOption={
                            isNil(decodedQueryParams?.fromViewPage) || !decodedQueryParams?.fromViewPage
                        }
                    />
                )}

                <div
                    className={classNames('crm-page-wrapper', {
                        'crm-customer-journey-view-page-wrapper': type === PageTypes.view,
                        'crm-page-wrapper-with-client-list':
                            !isEmpty(viewPageHeadlineData) &&
                            viewPageHeadlineData.Status !== +CustomerJourneyStatusLabels.NonStarted &&
                            promotionType !== RealtimePromotionTypes.ActionCampaign,
                    })}
                >
                    {type !== PageTypes.view ? (
                        <FlowEditorSidebar
                            ref={flowEditorRef}
                            isDisabled={isBlocksSidebarDisabled}
                            groupBlocks={groupBlocks}
                            defaultOpened={type === PageTypes.create}
                        />
                    ) : (
                        <CJBlockInfoSidebar
                            elements={elements}
                            blockInfoData={blockInfo}
                            isDisabled={isEmpty(blockInfo)}
                            isInfoSideBarOpened={isInfoSideBarOpened}
                            infoSidebarToggleHandler={infoSidebarToggleHandler}
                        />
                    )}

                    <div className="crm-section">
                        {PageTypes.create === type ? (
                            <ReactFlowProvider>
                                <FlowEditor
                                    pushElementsUpdate={getElementsUpdate}
                                    initialElements={elements}
                                    elementsCorrection={elementsCorrection}
                                    onSelectionChange={onFlowEditorSelectionChange}
                                    customActions={customActions}
                                    ref={flowEditorRef}
                                />
                            </ReactFlowProvider>
                        ) : (
                            <BusyLoader
                                // TODO: create new state for initial elements loading and replace initial elements isEmpty checking
                                isBusy={(isEmpty(initialElements) && !resetClicked.current) || isLoading}
                                type="spinner"
                                spinnerSize="big"
                            >
                                {(resetClicked.current || !isEmpty(initialElements)) && (
                                    <ReactFlowProvider>
                                        <FlowEditor
                                            pushElementsUpdate={getElementsUpdate}
                                            initialElements={initialElements}
                                            elementsCorrection={elementsCorrection}
                                            readOnly={readOnly}
                                            onSelectionChange={onFlowEditorSelectionChange}
                                            customActions={
                                                viewPageHeadlineData?.Status ===
                                                +CustomerJourneyStatusLabels.NonStarted ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {customActions}
                                                        {readOnly &&
                                                            viewPageHeadlineData?.Status !==
                                                                +CustomerJourneyStatusLabels.NonStarted && (
                                                                <>
                                                                    {promotionType !==
                                                                        RealtimePromotionTypes.ActionCampaign && (
                                                                        <Tooltip
                                                                            text={t(l.ShowTotalClientsIncludeRemoved)}
                                                                        >
                                                                            <Button
                                                                                icon="bc-icon-review"
                                                                                className={classNames({
                                                                                    active: showRemovedClientsCount,
                                                                                    'new-section': true,
                                                                                })}
                                                                                onClick={() => {
                                                                                    setShowRemovedClientsCount(
                                                                                        !showRemovedClientsCount,
                                                                                    );
                                                                                }}
                                                                                disabled={!isNil(filters)}
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip text={t(l.TotalPercentageCalculation)}>
                                                                        <Button
                                                                            icon="bc-icon-percentage-left"
                                                                            className={classNames({
                                                                                active:
                                                                                    clientsCountType ===
                                                                                    ClientsCountType.Total,
                                                                                'new-section': true,
                                                                            })}
                                                                            onClick={() => {
                                                                                setClientsCountType(
                                                                                    ClientsCountType.Total,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                    <Tooltip text={t(l.TargetPercentageCalculation)}>
                                                                        <Button
                                                                            icon="bc-icon-persentage-right"
                                                                            className={classNames({
                                                                                active:
                                                                                    clientsCountType ===
                                                                                    ClientsCountType.Target,
                                                                            })}
                                                                            onClick={() => {
                                                                                setClientsCountType(
                                                                                    ClientsCountType.Target,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                    </>
                                                )
                                            }
                                            customerJourneyStatus={viewPageHeadlineData?.Status}
                                            ref={flowEditorRef}
                                        />
                                    </ReactFlowProvider>
                                )}
                            </BusyLoader>
                        )}
                    </div>
                </div>
            </div>
        );
    },
);

JourneyPage.propTypes = {
    titleKey: PropTypes.string.isRequired,
    type: PropTypes.string,
    apiEndpoint: PropTypes.object,
    workflowId: PropTypes.number,
    initialData: PropTypes.array,
    isLoading: PropTypes.bool,
    blockActions: PropTypes.object,
    defaultName: PropTypes.string,
    defaultDescription: PropTypes.string,
    defaultSchedule: PropTypes.object,
    status: PropTypes.number,
    blocksCounts: PropTypes.object,
    blockInfo: PropTypes.object,
    viewPageHeadlineData: PropTypes.object,
    pushElementsTree: PropTypes.func,
    isInfoSideBarOpened: PropTypes.bool,
    infoSidebarToggleHandler: PropTypes.func.isRequired,
    onFlowEditorSelectionChange: PropTypes.func,
    filters: PropTypes.object,
    hasVisualization: PropTypes.bool,
    customActions: PropTypes.node,
    promotionType: PropTypes.string,
    exportAllExceededCellsCount: PropTypes.number,
};

JourneyPage.defaultProps = {
    type: PageTypes.create,
    apiEndpoint: {},
    initialData: [],
    isLoading: false,
    status: null,
    blockActions: {},
    defaultName: null,
    defaultDescription: null,
    defaultSchedule: { StartDate: null, EndDate: null, CronSchedule: null },
    blocksCounts: null,
    blockInfo: null,
    pushElementsTree: noop,
    isInfoSideBarOpened: false,
    onFlowEditorSelectionChange: noop,
    hasVisualization: false,
    customActions: <></>,
};

export default memo(JourneyPage);
