import * as Yup from 'yup';
import moment from 'moment';
import { isEmpty, isNil, noop } from 'lodash';
import { yupSchemaValidate } from 'validators/service.validator';
// Import Constants
import {
    l,
    MonthDayYearWithTimeFormat,
    MonthDayYearFormat,
    CommunicationAvailabilityScheduleTypeLabels,
    DynamicBonusTypes,
    ScheduleModalViews,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';
import { isSchedulerUnitPairsInvalid } from 'services/schedular';

const { getDateFormat, customMomentWithoutTimezoneConversion } = Helpers;

// In all schema can not be all like together.
// Which message must to get or object white params.
// In CJ case we are usage only field name label but also can use or change logic like label:{params:[]}.
// Now CJ logic {label: l.[property], fieldName: string | {value: string, isTranslated: boolean}}.

const yupSchemaExtender = (yupSchema, { Validation, CustomAttributes }) => {
    if (!isNil(yupSchema) && (yupSchema.type === Yup.string().type || yupSchema.type === Yup.number().type)) {
        const message = {};
        if (!isNil(CustomAttributes?.DisplayName)) {
            message.fieldName = CustomAttributes.DisplayName;
        }
        if (!isNil(Validation?.DecimalPoints)) {
            yupSchema = yupSchema.decimalPointsValidation(Validation.DecimalPoints, message);
        }
        if (!isNil(Validation?.MaxValue)) {
            yupSchema = yupSchema.maxValueValidation(Validation.MaxValue, message);
        }
        if (!isNil(Validation?.MinValue)) {
            yupSchema = yupSchema.minValueValidation(Validation.MinValue, message);
        }
        if (!isNil(Validation?.StringPattern)) {
            yupSchema = yupSchema.stringPatternValidation(Validation.StringPattern, message);
        }
    }

    if (!isNil(yupSchema) && yupSchema.type === Yup.array().type) {
        const message = {};
        if (!isNil(CustomAttributes?.DisplayName)) {
            message.fieldName = CustomAttributes.DisplayName;
        }
        if (!isNil(Validation?.MaxLength)) {
            yupSchema = yupSchema.maxLengthValidation(Validation.MaxLength, message);
        }
    }
    return yupSchema;
};

const CJStaticTypeSchemaCreator = (option) => {
    const { Required, CustomAttributes } = option;
    return Required
        ? Yup.object({
              value: yupSchemaExtender(
                  Yup.string().required({
                      label: l.CannotBeEmpty,
                      fieldName: { value: CustomAttributes?.DisplayName },
                  }),
                  option,
              ).nullable(true),
          })
        : Yup.object({
              value: yupSchemaExtender(Yup.string(), option).nullable(true),
          }).nullable(true);
};

const CJDoubleValidationSchema = (option) => {
    const { Required, CustomAttributes } = option;
    return Required
        ? Yup.object({
              value: yupSchemaExtender(
                  Yup.string()
                      .required({
                          label: l.CannotBeEmpty,
                          fieldName: { value: CustomAttributes?.DisplayName },
                      })
                      .negativeValueValidation({
                          fieldName: { value: CustomAttributes?.DisplayName, isTranslated: true },
                      }),
                  option,
              ).nullable(true),
          })
        : Yup.object({
              value: yupSchemaExtender(Yup.string(), option).nullable(true),
          }).nullable(true);
};

const CJVisualizationStepTemplateValidationSchema = () => {
    return Yup.object({
        name: Yup.string()
            .required({
                label: l.CannotBeEmpty,
                fieldName: l.Name,
            })
            .min(2, { label: l.MustContainMinimumCharacters, fieldName: l.Name, count: 2 })
            .max(250, { label: l.MustContainMaximumCharacters, fieldName: l.Name, count: 250 })
            .nullable(true),
    }).nullable(true);
};

const CJVisualizationStepIconValidationSchema = () => {
    return Yup.object({
        icon: Yup.string()
            .required({
                label: l.CannotBeEmpty,
                fieldName: l.Icon,
            })
            .nullable(true),
    }).nullable(true);
};

const IntegerValidationSchema = (option) => {
    const { CustomAttributes, Required } = option;
    return Required
        ? Yup.object({
              value: yupSchemaExtender(
                  Yup.number().required({
                      label: l.CannotBeEmpty,
                      fieldName: { value: CustomAttributes?.DisplayName, isTranslated: true },
                  }),
                  option,
              ).nullable(true),
          })
        : Yup.object({
              value: yupSchemaExtender(Yup.number(), option).nullable(true),
          }).nullable(true);
};

const CJTimeStampValidationSchema = () => {
    return Yup.object({
        value: Yup.string().test(
            'value',
            {
                label: l.InCorrectDuration,
            },
            function (value) {
                const regex = /(\d*)\.(\d*):(\d*):(\d*)/;
                const matches = value.match(regex);

                if (isNil(matches)) return false;

                const hours = +matches[2];
                const minutes = +matches[3];

                return hours < 24 && minutes < 60;
            },
        ),
    });
};

const CJIntegerValidationSchema = (option) => CJDoubleValidationSchema(option);

const CJTemplateIdValidationSchema = (option) => IntegerValidationSchema(option);

const CJStringValidationSchema = (option) => CJStaticTypeSchemaCreator(option);

const CJMoneyValidationSchema = (option) => {
    const { Required } = option;
    return Required
        ? Yup.object({
              amount: yupSchemaExtender(
                  Yup.string().required({ label: l.CannotBeEmpty, fieldName: l.Amount }).negativeValueValidation({
                      fieldName: l.Amount,
                  }),
                  {
                      ...option,
                      CustomAttributes: { DisplayName: l.Amount },
                  },
              ),
              currencyCode: Yup.string().required({
                  label: l.CannotBeEmpty,
                  fieldName: l.Currency,
              }),
          })
        : Yup.object({
              amount: yupSchemaExtender(
                  Yup.string()
                      .test(
                          'amount',
                          {
                              label: l.CannotBeEmpty,
                              fieldName: l.Amount,
                          },
                          function (value) {
                              const { currencyCode } = this.parent;

                              return !(
                                  !isNil(currencyCode) &&
                                  !isEmpty(currencyCode) &&
                                  (isNil(value) || isEmpty(value))
                              );
                          },
                      )
                      .negativeValueValidation({
                          fieldName: l.Amount,
                      }),
                  {
                      ...option,
                      CustomAttributes: { DisplayName: l.Amount },
                  },
              ).nullable(true),

              currencyCode: Yup.string().test(
                  'currencyCode',
                  {
                      label: l.CannotBeEmpty,
                      fieldName: l.Currency,
                  },
                  function (value) {
                      const { amount } = this.parent;

                      return !(!isNil(amount) && !isEmpty(amount) && (isNil(value) || isEmpty(value)));
                  },
              ),
          });
};

const CJProviderIdValidationSchema = (option) => IntegerValidationSchema(option);

const CJSegmentIdValidationSchema = (option) => IntegerValidationSchema(option);

const CJPartnerBonusIdValidationSchema = (option) => IntegerValidationSchema(option);

const CJBonusAmountInputValidationSchema = (bonusDetail, isRequired) => {
    const { MinAmount, MaxAmount, CurrencyCode } = bonusDetail;

    return isRequired
        ? Yup.object({
              amount: Yup.number()
                  .required({ label: l.CannotBeEmpty, fieldName: { value: CurrencyCode, isTranslated: true } }) // TODO: maybe l.Amount
                  .minValueValidation(
                      { Value: MinAmount, IsIncluded: MinAmount > 0 },
                      {
                          fieldName: { value: CurrencyCode, isTranslated: true },
                          count: { value: +MinAmount, isTranslated: true },
                      },
                  )
                  .max(MaxAmount, {
                      label: l.MustBeSmallerThan,
                      fieldName: { value: CurrencyCode, isTranslated: true },
                      count: { value: +MaxAmount, isTranslated: true },
                  })
                  .nullable(true),
          })
        : Yup.object({
              amount: Yup.number()
                  .minValueValidation(
                      { Value: MinAmount, IsIncluded: MinAmount > 0 },
                      {
                          fieldName: { value: CurrencyCode, isTranslated: true },
                          count: { value: +MinAmount, isTranslated: true },
                      },
                  )
                  .max(MaxAmount, {
                      label: l.MustBeSmallerThan,
                      fieldName: { value: CurrencyCode, isTranslated: true },
                      count: { value: +MaxAmount, isTranslated: true },
                  }),
          });
};

const CJMenuItemDropDownValidationSchema = (option) => CJStaticTypeSchemaCreator(option);

const CJTagValidationSchema = (option) => CJStaticTypeSchemaCreator(option);

const CJScheduleValidationSchema = (option, node) => {
    const { Required } = option;
    return Yup.object({
        startDate: Yup.string()
            .test('startDate', { label: l.StartDateMustBeBiggerThanCurrentDate }, function (value) {
                const { startTime, timeZone, isActiveSchedule } = this.parent;

                if (
                    node?.data?.isDeletable === false ||
                    (!isActiveSchedule && !Required) ||
                    node?.data?.isEditOnlyLive === true
                ) {
                    return true;
                }

                const currentDate = new Date(
                    customMomentWithoutTimezoneConversion().format(MonthDayYearWithTimeFormat),
                );
                const chosenDate = new Date(
                    getDateFormat(
                        `${moment(value).format(MonthDayYearFormat)} ${startTime}`,
                        -timeZone,
                        MonthDayYearWithTimeFormat,
                    ),
                );
                return chosenDate.getTime() > currentDate.getTime();
            })
            .test('startDate', { label: l.StartDateMustBeSmallerThanEndDate }, function (value) {
                const { endDate, isActiveSchedule } = this.parent;
                if (!isActiveSchedule && !Required) {
                    return true;
                }
                const chosenStartDate = new Date(moment(value).format(MonthDayYearFormat));
                const chosenEndDate = new Date(`${moment(endDate).format(MonthDayYearFormat)} 23:59`);
                return chosenStartDate.getTime() < chosenEndDate.getTime();
            }),
        endDate: Yup.string().test('endDate', { label: l.EndDateMustBeBiggerThanStartDate }, function (value) {
            const { startDate, isActiveSchedule } = this.parent;
            if (!isActiveSchedule && !Required) {
                return true;
            }
            const chosenStartDate = new Date(moment(startDate).format(MonthDayYearFormat));
            const chosenEndDate = new Date(`${moment(value).format(MonthDayYearFormat)} 23:59`);
            return chosenStartDate.getTime() < chosenEndDate.getTime();
        }),
        datesDict: Yup.object().test('datesDict', { label: l.SelectAtLeastOneUnit }, function (value) {
            const { isActiveSchedule, modalView } = this.parent;
            if (!isActiveSchedule && !Required) {
                return true;
            }
            if (modalView === ScheduleModalViews.notRepeatView) return true;

            const isInvalid = isSchedulerUnitPairsInvalid(value[modalView]);

            return !isInvalid;
        }),
        startTime: Yup.string().test('startTime', { label: l.InvalidTime }, function (value) {
            const { isActiveSchedule } = this.parent;
            if (!isActiveSchedule && !Required) {
                return true;
            }
            const result = /^([0-2]?[0-9]):[0-5][0-9]$/.test(value);
            return result;
        }),
    });
};

const CJReferenceOptionValidationSchema = (
    elements,
    node,
    optionType,
    modelRelationScope,
    propertySettings,
    withoutType = false,
) =>
    Yup.object()
        .optionReference(elements, node, optionType, modelRelationScope, propertySettings, withoutType, '')
        .nullable(true);

const CJPreSaveModalBodyContentValidationSchema = (t, elements) => {
    const minute = 60000;
    const offset = 15;
    const getDate = (value, timeZone) => {
        return new Date(
            getDateFormat(
                customMomentWithoutTimezoneConversion(value).format(MonthDayYearWithTimeFormat),
                -timeZone,
                MonthDayYearWithTimeFormat,
            ),
        );
    };

    return Yup.object({
        isVisibleStartDate: Yup.boolean().nullable(),
        isActiveStartDate: Yup.boolean().nullable(),
        timeZone: Yup.string().required(),
        startDate: Yup.string()
            .nullable()
            .when(['isVisibleStartDate', 'isActiveStartDate'], {
                is: (isVisible, isActive) => isVisible === true && isActive === true,
                then: Yup.string()
                    .nullable()
                    .required(t(l.CannotBeEmpty, { fieldName: t(l.AutoStart) }))
                    .test(
                        'startDateMustBeBiggerThanCurrentDate',
                        t(l.StartDateMustBeBiggerThanCurrentDate),
                        function (value) {
                            if (isEmpty(value)) return true;

                            const { timeZone } = this.parent;
                            const currentDate = new Date(
                                customMomentWithoutTimezoneConversion().format(MonthDayYearWithTimeFormat),
                            );
                            const chosenDate = getDate(value, timeZone);

                            return chosenDate.getTime() > currentDate.getTime();
                        },
                    )
                    .when(['isVisibleEndDate', 'isActiveEndDate'], {
                        is: (isVisible, isActive) => isVisible === true && isActive === true,
                        then: Yup.string()
                            .nullable()
                            .test(
                                'startDateMustBeSmallerThanEndDateWithOffset',
                                t(l.StartDateMustBeSmallerThanEndDateWithOffset, { offset: offset }),
                                function (value) {
                                    const { endDate, timeZone } = this.parent;
                                    if (isEmpty(value) || isEmpty(endDate)) return true;

                                    const chosenStartDate = getDate(value, timeZone);
                                    const chosenEndDate = getDate(endDate, timeZone);

                                    return chosenStartDate.getTime() + offset * minute < chosenEndDate.getTime();
                                },
                            )
                            .startDateCJScheduleValidation(t, elements),
                        otherwise: Yup.string().nullable().startDateCJScheduleValidation(t, elements),
                    }),
            }),
        isVisibleEndDate: Yup.boolean(),
        isActiveEndDate: Yup.boolean().nullable(),
        endDate: Yup.string()
            .nullable()
            .when(['isVisibleEndDate', 'isActiveEndDate'], {
                is: (isVisible, isActive) => isVisible === true && isActive === true,
                then: Yup.string()
                    .nullable()
                    .required(t(l.CannotBeEmpty, { fieldName: t(l.AutoEnd) }))
                    .when(['isVisibleStartDate', 'isActiveStartDate'], {
                        is: (isVisible, isActive) => isVisible === false || isActive === false,
                        then: Yup.string()
                            .nullable()
                            .test(
                                'endDateMustBeBiggerThanCurrentDateWithOffset',
                                t(l.EndDateMustBeBiggerThanCurrentDateWithOffset, { offset: offset }),
                                function (value) {
                                    if (isEmpty(value)) return true;
                                    const { timeZone } = this.parent;
                                    const currentDate = new Date(
                                        customMomentWithoutTimezoneConversion().format(MonthDayYearWithTimeFormat),
                                    );
                                    const chosenDate = getDate(value, timeZone);

                                    return chosenDate.getTime() > currentDate.getTime() + offset * minute;
                                },
                            )
                            .endDateCJScheduleValidation(t, elements),
                    })
                    .when(['isVisibleStartDate', 'isActiveStartDate'], {
                        is: (isVisible, isActive) => isVisible === true && isActive === true,
                        then: Yup.string()
                            .nullable()
                            .test(
                                'endDateMustBeBiggerThanStartDateWithOffset',
                                t(l.EndDateMustBeBiggerThanStartDateWithOffset, { offset: offset }),
                                function (value) {
                                    const { startDate, timeZone } = this.parent;
                                    if (isEmpty(value) || isEmpty(startDate)) return true;

                                    // also can remove because timezone shifting
                                    const chosenStartDate = getDate(startDate, timeZone);
                                    const chosenEndDate = getDate(value, timeZone);

                                    return chosenStartDate.getTime() + offset * minute < chosenEndDate.getTime();
                                },
                            )
                            .endDateCJScheduleValidation(t, elements),
                    }),
            }),
        startTime: Yup.string()
            .nullable()
            .when(['isVisibleStartDate', 'isActiveStartDate'], {
                is: (isVisible, isActive) => isVisible === true && isActive === true,
                then: Yup.string().test('startTime', l.InvalidTime, function (value) {
                    const result = /^([0-2]?[0-9]):[0-5][0-9]$/.test(value);
                    return result;
                }),
            }),
        endTime: Yup.string()
            .nullable()
            .when(['isVisibleEndDate', 'isActiveEndDate'], {
                is: (isVisible, isActive) => isVisible === true && isActive === true,
                then: Yup.string().test('endTime', l.InvalidTime, function (value) {
                    const result = /^([0-2]?[0-9]):[0-5][0-9]$/.test(value);
                    return result;
                }),
            }),
    });
};

const CJListOptionValidationSchema = (option) => {
    const { CustomAttributes, Required } = option;
    return Required
        ? Yup.object({
              value: yupSchemaExtender(
                  Yup.array()
                      .required({
                          label: l.CannotBeEmpty,
                          fieldName: CustomAttributes?.DisplayName,
                      })
                      .min(1, {
                          label: l.CannotBeEmpty,
                          fieldName: CustomAttributes?.DisplayName,
                      })
                      .nullable(true),
                  option,
              ),
          })
        : null;
};

const CJDateTimeOptionValidationSchema = (option) => {
    const { Required } = option;
    return Required
        ? Yup.object({
              date: Yup.string()
                  .required({
                      label: l.CannotBeEmpty,
                      fieldName: l.OptionValue,
                  })
                  .nullable(true), // schema for object
          })
        : null;
};

const CJDaysLaterOptionValidationSchema = (option) => {
    const { Required } = option;
    return Required
        ? Yup.object({
              offset: Yup.number()
                  .required({
                      label: l.CannotBeEmpty,
                      fieldName: l.Offset,
                  })
                  .nullable(true),
          })
        : null;
};

const CJBlockNameOptionValidationSchema = (elements, node) =>
    Yup.object().blockIdValidation(elements, node).nullable(true);

const ReportScheduleValidationSchema = () => {
    return Yup.object({
        startDate: Yup.string()
            .test('startDate', { label: l.StartDateMustBeBiggerThanCurrentDate }, function (value) {
                const { startTime, timeZone } = this.parent;
                const currentDate = new Date(
                    customMomentWithoutTimezoneConversion().format(MonthDayYearWithTimeFormat),
                );
                const chosenDate = new Date(
                    getDateFormat(
                        `${moment(value).format(MonthDayYearFormat)} ${startTime}`,
                        -timeZone,
                        MonthDayYearWithTimeFormat,
                    ),
                );
                return chosenDate.getTime() > currentDate.getTime();
            })
            .test('startDate', { label: l.StartDateMustBeSmallerThanEndDate }, function (value) {
                const { endDate } = this.parent;

                const chosenStartDate = new Date(moment(value).format(MonthDayYearFormat));
                const chosenEndDate = new Date(`${moment(endDate).format(MonthDayYearFormat)} 23:59`);
                return chosenStartDate.getTime() < chosenEndDate.getTime();
            }),
        endDate: Yup.string().test('endDate', { label: l.EndDateMustBeBiggerThanStartDate }, function (value) {
            const { startDate } = this.parent;
            const chosenStartDate = new Date(moment(startDate).format(MonthDayYearFormat));
            const chosenEndDate = new Date(`${moment(value).format(MonthDayYearFormat)} 23:59`);
            return chosenStartDate.getTime() < chosenEndDate.getTime();
        }),
        selectedFileFormats: Yup.array().test(
            'selectedFileFormats',
            { label: l.CannotBeEmpty, fieldName: l.ExportFileFormat },
            function (value) {
                const { selectedFtps, selectedEmails } = this.parent;
                if (isEmpty(selectedFtps) && isEmpty(selectedEmails)) {
                    return true;
                }
                return !isEmpty(value);
            },
        ),
    });
};

const isValidTime = (value) => {
    if (!isEmpty(value)) {
        // value must be like '00:00'
        const data = value.split(':');
        if (data.length !== 2) {
            return false;
        }

        if (isNil(data[0].match('^(2[0-3]|[01]?[0-9])$')) || isNil(data[1].match('^([0-5]?[0-9])$'))) {
            return false;
        }
    }
    return true;
};

const CJCommunicationAvailabilityConditionTimePickerValidationSchema = (fieldName, checkValue) => {
    const minute = 60000;
    const offset = 15;

    const getDate = (time) => {
        const date = new Date();
        const data = time.split(':');

        date.setHours(+data[0], +data[1]);
        return date;
    };

    const checkTimes = (value, checkValue) => {
        if (!isEmpty(value) && !isEmpty(checkValue) && isValidTime(value) && isValidTime(checkValue)) {
            const chosenValue = getDate(value);
            const chosenCheckValue = getDate(checkValue);

            return chosenValue.getTime() + offset * minute <= chosenCheckValue.getTime();
        }

        return true;
    };

    return Yup.object({
        value: Yup.string()
            .required({
                label: l.CannotBeEmpty,
                fieldName:
                    fieldName === 'StartTime'
                        ? l.CommunicationAvailabilityStartTime
                        : l.CommunicationAvailabilityEndTime,
            })
            .test('communicationAvailabilityConditionTimeFormat', { label: l.InvalidTime }, isValidTime)
            .when([], {
                is: () => fieldName === 'StartTime',
                then: Yup.string()
                    .test(
                        'communicationAvailabilityConditionStartTimeMustBeSmallerThenEndTime',
                        { label: l.StartTimeMustBeSmallerThenEndTimeWithOffset, offset: { value: offset } },
                        (value) => {
                            return checkTimes(value, checkValue);
                        },
                    )
                    .nullable(true),
                otherwise: Yup.string()
                    .test(
                        'communicationAvailabilityConditionEndTimeMustBeBiggerThenStartTime',
                        { label: l.EndTimeMustBeBiggerThenStartTimeWithOffset, offset: { value: offset } },
                        (value) => {
                            return checkTimes(checkValue, value);
                        },
                    )
                    .nullable(true),
            })
            .nullable(true),
    }).nullable(true);
};

const CJCommunicationAvailabilityConditionOptionValidationSchema = (option) => {
    const { Required } = option;
    return Yup.object({
        timeZone: Yup.number()
            .when(['scheduleType'], {
                is: (scheduleType) =>
                    scheduleType !== CommunicationAvailabilityScheduleTypeLabels.Always && !isNil(scheduleType),
                then: Yup.number().required({
                    label: l.CannotBeEmpty,
                    fieldName: l.TimeZone,
                }),
            })
            .nullable(true),
        scheduleType: Yup.number().nullable(true),
        schedule: Yup.array()
            .when(['scheduleType'], {
                is: (scheduleType) => scheduleType !== CommunicationAvailabilityScheduleTypeLabels.Always,
                then: Yup.array().test(
                    'communicationAvailabilityConditionSchedule',
                    { label: l.CheckCommunicationAvailabilityScheduleDates },
                    function (value) {
                        if (Required && isEmpty(value)) {
                            return false;
                        }

                        for (let index = 0; index < value.length; index++) {
                            const item = value[index];
                            if (item.isEnable) {
                                let isValid = yupSchemaValidate(
                                    CJCommunicationAvailabilityConditionTimePickerValidationSchema(
                                        'StartTime',
                                        item.value.endTime?.value,
                                    ),
                                    { value: item.value.startTime?.value },
                                    noop,
                                );

                                if (!isValid) {
                                    return false;
                                }
                                isValid = yupSchemaValidate(
                                    CJCommunicationAvailabilityConditionTimePickerValidationSchema(
                                        'EndTime',
                                        item.value.startTime?.value,
                                    ),
                                    { value: item.value.endTime?.value },
                                    noop,
                                );

                                if (!isValid) {
                                    return false;
                                }
                            }
                        }

                        return true;
                    },
                ),
            })
            .nullable(true),
    }).nullable(true);
};

const CJCalculateKPIValidationSchema = (option) => {
    const { Required } = option;

    return Required
        ? Yup.object({
              segmentId: Yup.string().required({
                  label: l.CannotBeEmpty,
                  fieldName: l.Segment,
              }),
              objectType: Yup.string().when(['amountSourceType'], {
                  is: (amountSourceType) => amountSourceType === DynamicBonusTypes.Object,
                  then: Yup.string().required({
                      label: l.CannotBeEmpty,
                      fieldName: l.CJBlockCalculateKPIKind,
                  }),
              }),
              columnInfoId: Yup.string().when(['amountSourceType'], {
                  is: (amountSourceType) => amountSourceType === DynamicBonusTypes.KPI,
                  then: Yup.string().required({
                      label: l.CannotBeEmpty,
                      fieldName: l.CJBlockCalculateKPIKind,
                  }),
              }),
              calculationMethod: Yup.string().when(['amountSourceType'], {
                  is: (amountSourceType) => amountSourceType === DynamicBonusTypes.Object,
                  then: Yup.string().required({
                      label: l.CannotBeEmpty,
                      fieldName: l.CJBlockCalculateKPICalculateMethod,
                  }),
              }),
          })
        : Yup.object({
              segmentId: Yup.string().nullable(true),
              objectType: Yup.string().when(['segmentId', 'amountSourceType'], {
                  is: (segmentId, amountSourceType) =>
                      !isEmpty(segmentId) && amountSourceType === DynamicBonusTypes.Object,
                  then: Yup.string().required({
                      label: l.CannotBeEmpty,
                      fieldName: l.CJBlockCalculateKPIKind,
                  }),
              }),
              columnInfoId: Yup.string().when(['segmentId', 'amountSourceType'], {
                  is: (segmentId, amountSourceType) =>
                      !isEmpty(segmentId) && amountSourceType === DynamicBonusTypes.KPI,
                  then: Yup.string().required({
                      label: l.CannotBeEmpty,
                      fieldName: l.CJBlockCalculateKPIKind,
                  }),
              }),
              calculationMethod: Yup.string().when(['segmentId', 'amountSourceType'], {
                  is: (segmentId, amountSourceType) =>
                      !isEmpty(segmentId) && amountSourceType === DynamicBonusTypes.Object,
                  then: Yup.string().required({
                      label: l.CannotBeEmpty,
                      fieldName: l.CJBlockCalculateKPICalculateMethod,
                  }),
              }),
          });
};

export {
    CJDoubleValidationSchema,
    CJIntegerValidationSchema,
    CJTemplateIdValidationSchema,
    CJStringValidationSchema,
    CJTimeStampValidationSchema,
    CJMoneyValidationSchema,
    CJProviderIdValidationSchema,
    CJSegmentIdValidationSchema,
    CJPartnerBonusIdValidationSchema,
    CJBonusAmountInputValidationSchema,
    CJMenuItemDropDownValidationSchema,
    CJTagValidationSchema,
    CJScheduleValidationSchema,
    CJReferenceOptionValidationSchema,
    CJListOptionValidationSchema,
    CJDateTimeOptionValidationSchema,
    CJDaysLaterOptionValidationSchema,
    CJVisualizationStepTemplateValidationSchema,
    CJVisualizationStepIconValidationSchema,
    CJBlockNameOptionValidationSchema,
    ReportScheduleValidationSchema,
    CJPreSaveModalBodyContentValidationSchema,
    CJCommunicationAvailabilityConditionTimePickerValidationSchema,
    CJCommunicationAvailabilityConditionOptionValidationSchema,
    CJCalculateKPIValidationSchema,
};
