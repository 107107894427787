import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty, noop } from 'lodash';
import { CJPartnerBonusIdValidationSchema } from 'validators/schemas.validation';
// Import Components
import { OptionList } from 'components';
// Import Services
import { BonusHttpService } from 'services/http';
import { Helpers } from 'services';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Hooks
import { useRequest, useAutocompleteRequest } from 'hooks';
// Import Constants
import { DateTimeWithoutSecondsFormat, l, BonusTypesLabels } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

const CJPartnerBonusId = ({ defaultValue, getUpdate, option, updateDependency, setBonusDetails }) => {
    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();
    const { CustomAttributes } = option;
    const { SupportedBonusTypes = [] } = CustomAttributes;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [listData, setListData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedBonusData, setSelectedBonusData] = useState({ Id: defaultValue?.value, Name: null });
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);

    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
            triggerType: defaultValue?.triggerType,
        },
        onSubmit: noop,
        validationSchema: CJPartnerBonusIdValidationSchema(option),
    });
    const { values, setValues, errors, submitForm } = formik;
    const { value, triggerType } = values;
    const { getBonusList, getBonusById } = useRef({
        getBonusList: BonusHttpService.getBonusList,
        getBonusById: BonusHttpService.getBonusById(),
    }).current;

    useEffect(() => {
        getUpdate({ value, triggerType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    // TODO: config
    const bonusListGetterMethod = (searchValue = '') => {
        setIsLoadingList(true);
        const getSearchValueType = (value) => {
            return /^( )*\d+( )*$/.test(value) ? 'Id' : 'Name';
        };

        const requestObj = getAutocompleteRequest(getBonusList);

        doPostRequest(requestObj.request, {
            requestBody: {
                Filters: [
                    { Name: 'TargetObject', Comparision: 0, Values: [0] },
                    { Name: getSearchValueType(searchValue), Comparision: 2, Values: [searchValue] },
                    { Name: 'Type', Comparision: 8, Values: [...SupportedBonusTypes] },
                ],
                Pageing: { PageSize: 100, PageNumber: 1 },
                Sorting: { Name: 'StartDate', Direction: 'desc' },
            },
            successCallback: (data) => {
                setListData(
                    !isNil(data?.Data)
                        ? data.Data?.map((item) => ({
                              ...item,
                              formattedStartDate: customMomentWithoutTimezoneConversion(item.StartDate).format(
                                  DateTimeWithoutSecondsFormat,
                              ),
                              formattedEndDate: customMomentWithoutTimezoneConversion(item.EndDate).format(
                                  DateTimeWithoutSecondsFormat,
                              ),
                              bonusType: BonusTypesLabels[item.Type],
                          }))
                        : [],
                );
                setIsLoadingList(false);
            },
            errorCallback: () => setIsLoadingList(false),
        });
    };

    const searchBonusHandler = ({ target }) => {
        setSearchValue(target.value);
        bonusListGetterMethod(target.value);
    };

    const init = () => {
        submitForm();
        bonusListGetterMethod('');

        if (!isNil(defaultValue?.value) && !isEmpty(defaultValue.value.toString())) {
            setIsLoading(true);
            setBonusDetails(
                new Promise((resolve) => {
                    doGetRequest(getBonusById.request, {
                        queryString: { bonusId: defaultValue.value },
                        successCallback: (data) => {
                            const { Id, Name } = data;
                            updateDependency({ ...data, dependencyItemId: data.Id });
                            setSelectedBonusData({ Id, Name });
                            setIsLoading(false);
                            resolve(data);
                        },
                        errorCallback: () => {
                            setIsLoading(false);
                            resolve({});
                        },
                    });
                }),
            );
        }
    };

    const cleanUp = () => {
        return () => {
            getBonusById.cancel('CJPartnerBonusId:getBonusById');
            cancelRequest('CJPartnerBonusId:getBonusList');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    const popoverToggler = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    const selectBonusHandler = (data) => {
        setSelectedBonusData(data);
        setBonusDetails(
            new Promise((resolve) => {
                doGetRequest(getBonusById.request, {
                    queryString: { bonusId: data.Id },
                    successCallback: (data) => {
                        updateDependency({ ...data, dependencyItemId: data.Id });
                        resolve(data);
                    },
                    errorCallback: () => {
                        resolve({});
                    },
                });
            }),
        );

        setValues({ value: data?.Id, triggerType: data?.TriggerType });
        setIsVisiblePopover(false);
    };

    return (
        <OptionList
            optionLabel={selectedBonusData?.Name}
            toggleHandler={popoverToggler}
            isVisible={isVisiblePopover}
            setIsVisible={setIsVisiblePopover}
            keys={['Name', 'formattedStartDate', 'formattedEndDate', 'bonusType', 'Id']}
            titles={[t(l.BonusName), t(l.StartDate), t(l.EndDate), t(l.Type), t(l.ID)]}
            selectedPropertyKey="Id"
            list={listData}
            rowCount={listData.length}
            searchHandler={searchBonusHandler}
            optionValue={value}
            optionSelectHandler={selectBonusHandler}
            contentTop={45}
            contentWrapperClassName="cj-option-list-content-wrapper"
            searchValue={searchValue}
            isLoading={isLoading}
            isLoadingList={isLoadingList}
            isValid={!errors.value}
            errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
        />
    );
};

CJPartnerBonusId.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    updateDependency: PropTypes.func.isRequired,
    setBonusDetails: PropTypes.func,
};

CJPartnerBonusId.defaultProps = {
    defaultValue: { value: '' },
    setBonusDetails: noop,
};

export default memo(CJPartnerBonusId);
