import { PartnerSettingsDataActionTypes as Types } from 'actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case Types.GET_PARTNER_SETTINGS_SUCCESSED:
            return Object.assign({}, state, action.payload);
        case Types.GET_PARTNER_SETTINGS_FAILED:
            return Object.assign({}, state, {});
        case Types.SET_PARTNER_CURRENCIES:
            state.partnerCurrencies = action.payload;
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
