import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// Import Hooks
import { useGoToRoute, useToaster } from 'hooks';
//Import Components
import StaticSegmentInfo from './StaticSegmentInfo';
import DynamicSegmentInfo from 'components/DynamicSegment/DynamicSegmentInfo';
import CompositeSegmentInfo from './CompositeSegmentInfo';
import SegmentInfoModalBody from './SegmentInfoModalBody';
//Import UI components
import { Modal, Button, Tooltip } from '@geneui/components';
// Import Services
import {
    getSegmentClientCount,
    getCampaignsBySegment,
    getStaticSegmentTop200Client,
    getSegmentById,
    getNonCustomerSegmentPreview,
} from './service';
import { getQueryConfigurationsList } from 'services/segment';
//Import Constants
import { SegmentTypesLabels, ModalsActions, AlertTypes, l, SegmentObjectType, ActionsIcons } from 'constants/common';
import { RoutesList } from 'routes';
// Import Actions
import { DynamicSegmentActions } from 'actions';
//Import SCSS
import 'assets/scss/segmentModal.scss';

const { clearFilterList, setDefaultFilterList } = DynamicSegmentActions;
const { error } = AlertTypes;

const SegmentModal = ({
    segmentId,
    segmentType,
    isPredefined,
    handleClose,
    isVisible,
    fromCampaignModal,
    targetObject,
    handleInfoModalActionClick,
}) => {
    const { t } = useTranslation();
    const { goToRoute } = useGoToRoute();
    const { showToaster } = useToaster();
    const queryConfigurations = useSelector((state) => state.queryConfigurations);

    const errorHandler = (alertMessage) => showToaster(error, alertMessage);
    const [segmentInfoType, setSegmentInfoType] = useState(segmentType);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [segmentInfo, setSegmentInfo] = useState(null);

    const [numberOfCustomersData, setNumberOfCustomersData] = useState({ Count: 0, Total: 0, isLoading: false });
    const [usedInCampaigns, setUsedInCampaigns] = useState(null);

    const [staticSegmentInfo, setStaticSegmentInfo] = useState(null);

    const isStatic = segmentInfoType === SegmentTypesLabels.Static;

    const isVisibleEdit = () => segmentInfo && !isStatic && !isPredefined;
    const isVisibleCreateReport = () => segmentInfo && targetObject !== SegmentObjectType.NonCustomer;
    const isVisibleClone = () => segmentInfo && !isStatic;

    const createReportClickHandler = () => {
        goToRoute(RoutesList.CONVERT_TO_REPORT, { segmentId });
        handleClose(ModalsActions.CONVERT_TO_REPORT);
    };

    const cloneClickHandler = () => {
        if (segmentInfoType === SegmentTypesLabels.Dynamic) {
            goToRoute(isPredefined ? RoutesList.SEGMENT_DYNAMIC_PREDEFINED : RoutesList.SEGMENT_DYNAMIC_CLONE, {
                segmentId,
            });
        } else {
            goToRoute(RoutesList.SEGMENT_COMPOSITE_CLONE, { segmentId });
        }
        handleClose(ModalsActions.CLONE);
    };

    const editClickHandler = () => {
        if (segmentInfoType === SegmentTypesLabels.Dynamic) {
            goToRoute(RoutesList.SEGMENT_DYNAMIC_EDIT, { segmentId });
        } else {
            goToRoute(RoutesList.SEGMENT_COMPOSITE_EDIT, { segmentId });
        }
        handleClose(ModalsActions.EDIT);
    };

    const getTitleKey = () => {
        return isPredefined ? l['PredefinedSegmentInfo'] : l[`${SegmentTypesLabels[segmentInfoType]}SegmentInfo`];
    };

    const {
        getSegmentClientCountRequest,
        getCampaignsBySegmentRequest,
        getSegmentByIdRequest,
        getStaticSegmentTop200ClientRequest,
        getQueryConfigurationsListRequest,
        getNonCustomerSegmentPreviewRequest,
    } = useRef({
        getSegmentByIdRequest: getSegmentById(),
        getSegmentClientCountRequest: getSegmentClientCount(),
        getCampaignsBySegmentRequest: getCampaignsBySegment(),
        getStaticSegmentTop200ClientRequest: getStaticSegmentTop200Client(),
        getQueryConfigurationsListRequest: getQueryConfigurationsList(),
        getNonCustomerSegmentPreviewRequest: getNonCustomerSegmentPreview(),
    }).current;

    const renderSegmentInfo = () => {
        switch (segmentInfoType) {
            case SegmentTypesLabels.Static:
                return <StaticSegmentInfo staticSegmentInfoData={staticSegmentInfo} />;
            case SegmentTypesLabels.Dynamic:
                return <DynamicSegmentInfo />;
            case SegmentTypesLabels.Composite:
                return <CompositeSegmentInfo compositeConfiguration={segmentInfo.CompositeConfiguration} />;
            default:
                return '';
        }
    };

    const requestSegmentInfo = () => {
        if (targetObject === SegmentObjectType.NonCustomer) {
            getNonCustomerSegmentPreviewRequest.request(segmentId, setStaticSegmentInfo, errorHandler);
            return;
        }
        switch (segmentInfoType) {
            case SegmentTypesLabels.Static:
                getStaticSegmentTop200ClientRequest.request(
                    segmentId,
                    (data) => setStaticSegmentInfo({ ...data, SegmentId: segmentId, Name: segmentInfo.Name }),
                    errorHandler,
                );
                return;
            case SegmentTypesLabels.Dynamic:
                getQueryConfigurationsListRequest.request(
                    dispatch,
                    queryConfigurations,
                    segmentInfo,
                    setDefaultFilterList,
                    t,
                );
                return;
            default:
                return;
        }
    };

    const getSegmentInfoCallBack = (data) => {
        setSegmentInfo(data);
        setIsLoading(false);
        if (isNil(segmentInfoType)) {
            setSegmentInfoType(data.Type);
        }
    };

    const getSegmentInfo = () => {
        setIsLoading(true);
        getSegmentByIdRequest.request(segmentId, getSegmentInfoCallBack, errorHandler);
    };

    const numberOfCustomersClickHandler = () => {
        setNumberOfCustomersData({ ...numberOfCustomersData, isLoading: true });
        getSegmentClientCountRequest.request(
            segmentId,
            (data) => setNumberOfCustomersData({ ...data, isLoading: false }),
            errorHandler,
        );
    };

    useEffect(() => {
        if (!isNil(segmentInfo) && !isNil(segmentInfoType)) {
            requestSegmentInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentInfo, segmentInfoType]);

    useEffect(() => {
        getSegmentInfo();

        if (!isPredefined) {
            getCampaignsBySegmentRequest.request(segmentId, setUsedInCampaigns, errorHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentId]);

    const cleanUp = () => {
        return () => {
            setSegmentInfo(null);
            setNumberOfCustomersData(null);
            setUsedInCampaigns(null);
            setIsLoading(true);
            dispatch(clearFilterList());
            getSegmentByIdRequest.cancel('SegmentModal:getSegment');
            getSegmentClientCountRequest.cancel('SegmentModal:getSegmentClientCount');
            getCampaignsBySegmentRequest.cancel('SegmentModal:getCampaignsBySegment');
            getStaticSegmentTop200ClientRequest.cancel('SegmentModal:getStaticSegmentTop200Client');
            getQueryConfigurationsListRequest.cancel('SegmentModal:getQueryConfigurationsList');
        };
    };

    useEffect(cleanUp, []);

    return (
        <>
            {fromCampaignModal ? (
                <SegmentInfoModalBody
                    isLoading={isLoading}
                    segmentInfo={segmentInfo}
                    segmentInfoType={segmentInfoType}
                    isPredefined={isPredefined}
                    numberOfCustomersData={numberOfCustomersData}
                    renderSegmentInfo={renderSegmentInfo}
                    usedInCampaigns={usedInCampaigns}
                    onClickNumberOfCustomers={numberOfCustomersClickHandler}
                    handleInfoModalActionClick={handleInfoModalActionClick}
                    withActionButtons={true}
                />
            ) : (
                <Modal
                    closeOnClickOutside={true}
                    background="dark-background"
                    withPortal={true}
                    position="center"
                    visible={isVisible}
                    closable={false}
                    onCancel={() => handleClose(ModalsActions.CLOSE)}
                    className="segment-list-modal"
                    title={
                        <div className="segment-list-modal-head">
                            <span className="segment-list-modal-h-text">{t(getTitleKey())}</span>
                            <div className="segment-list-modal-h-icons">
                                {isVisibleEdit() && (
                                    <Tooltip text={t(l.EditSegment)}>
                                        <Button
                                            icon={ActionsIcons.Edit}
                                            appearance="minimal"
                                            color="primary"
                                            onClick={editClickHandler}
                                        />
                                    </Tooltip>
                                )}
                                {isVisibleCreateReport() && (
                                    <Tooltip text={t(l.CreateReport)}>
                                        <Button
                                            icon={ActionsIcons.ConvertToReport}
                                            appearance="minimal"
                                            color="primary"
                                            onClick={createReportClickHandler}
                                        />
                                    </Tooltip>
                                )}
                                {isVisibleClone() && (
                                    <Tooltip text={t(l.CloneSegment)}>
                                        <Button
                                            icon={ActionsIcons.Clone}
                                            appearance="minimal"
                                            color="primary"
                                            onClick={cloneClickHandler}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    }
                    footer={
                        <Button color="primary" onClick={() => handleClose(ModalsActions.CLOSE)}>
                            {t(l.Close)}
                        </Button>
                    }
                >
                    <SegmentInfoModalBody
                        isLoading={isLoading}
                        segmentInfo={segmentInfo}
                        segmentInfoType={segmentInfoType}
                        isPredefined={isPredefined}
                        numberOfCustomersData={numberOfCustomersData}
                        renderSegmentInfo={renderSegmentInfo}
                        usedInCampaigns={usedInCampaigns}
                        onClickNumberOfCustomers={numberOfCustomersClickHandler}
                    />
                </Modal>
            )}
        </>
    );
};

SegmentModal.propTypes = {
    segmentId: PropTypes.number.isRequired,
    segmentType: PropTypes.number,
    isVisible: PropTypes.bool,
    isPredefined: PropTypes.bool,
    handleClose: PropTypes.func,
    fromCampaignModal: PropTypes.bool,
    targetObject: PropTypes.number.isRequired,
    handleInfoModalActionClick: PropTypes.func,
};

SegmentModal.defaultProps = {
    handleClose: noop,
    isVisible: false,
    isPredefined: false,
    fromCampaignModal: false,
    segmentType: null,
};

export default memo(SegmentModal);
