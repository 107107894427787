import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { PublicRoutes } from 'routes';
// Import actions
import { AuthAction } from 'actions';
import { useGoToRoute } from 'hooks';

const App = () => {
    const dispatch = useDispatch();
    const { doLogin } = AuthAction;
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { listen } = useGoToRoute();

    const init = () => {
        const token = localStorage.getItem('accessToken');

        if (!isEmpty(token) && !isLoggedIn) {
            dispatch(doLogin(token));
        }

        listen();
    };

    useEffect(init, []);
    return (
        <div className="crm-wrapper">
            <PublicRoutes />
        </div>
    );
};

export default memo(App);
