import React, { memo } from 'react';
import { isEmpty, values, first } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// Import UI Components
import { BusyLoader, Tabs, Tab, Tooltip } from '@geneui/components';
// Import Components
import FilterSchemaList from './FiltersContainer/FilterSchemaList';
// Import Reducers
import { DynamicSegmentTabActiveIndexAction } from 'actions';

const { changeTabActiveIndex } = DynamicSegmentTabActiveIndexAction;

const DynamicSegmentInfo = () => {
    const dispatch = useDispatch();

    const queryConfigurations = useSelector((state) => state.queryConfigurations);
    const dynamicSegmentFiltersData = useSelector((state) => state.dynamicSegment);
    const tabActiveIndex = useSelector((state) => state.dynamicSegmentTabActiveIndex.id);

    const tabChangeHandler = (id) => {
        dispatch(changeTabActiveIndex({ id: `${id}` }));
    };

    return (
        <BusyLoader
            isBusy={isEmpty(queryConfigurations) || isEmpty(dynamicSegmentFiltersData)}
            type="spinner"
            spinnerSize="medium"
            className="crm-justify-content-center"
        >
            {values(dynamicSegmentFiltersData).length > 1 ? (
                <Tabs type="box" activeKey={tabActiveIndex} onChange={tabChangeHandler}>
                    {values(dynamicSegmentFiltersData).map((subSegment) => {
                        return (
                            <Tab
                                key={subSegment.seqId}
                                className={`${+tabActiveIndex === subSegment.seqId ? 'active' : ''}`}
                                title={
                                    <Tooltip text={subSegment.name}>
                                        <div className="ellipsis-text">
                                            <button onClick={() => tabChangeHandler(subSegment.seqId, subSegment.id)}>
                                                <p>
                                                    {+tabActiveIndex === subSegment.seqId
                                                        ? subSegment.name
                                                        : subSegment.name.slice(0, 4)}
                                                </p>
                                            </button>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <FilterSchemaList
                                    filtersData={subSegment}
                                    queryConfigurations={queryConfigurations}
                                    withoutActions
                                />
                            </Tab>
                        );
                    })}
                </Tabs>
            ) : (
                <div>
                    <FilterSchemaList
                        filtersData={first(values(dynamicSegmentFiltersData))}
                        queryConfigurations={queryConfigurations}
                        withoutActions
                    />
                </div>
            )}
        </BusyLoader>
    );
};

export default memo(DynamicSegmentInfo);
