import { SignInActionTypes as Types } from './types';

const signInFetch = (status) => ({
    type: Types.SIGN_IN_FETCH,
    payload: status,
});

const signInSuccessed = (status) => ({
    type: Types.SIGN_IN_SUCCESSED,
    payload: status,
});

const signInFailed = (error) => ({
    type: Types.SIGN_IN_FAILED,
    payload: error,
});

export default {
    signInFetch,
    signInSuccessed,
    signInFailed,
};
