/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { keys, groupBy, isEmpty, isNil } from 'lodash';
// Import UI Components
import { Alert, Icon, Title } from '@geneui/components';
// Import Components
import FilterGroup from './FilterGroup';
import { ConfirmationModal } from 'components';
// Import actions
import { DynamicSegmentActions } from 'actions';
// Import Services
import { getByHashKey } from '../service';
// Import Constants
import { l, ActionsIcons, ModalsClassNames, FilterComparisions } from 'constants/common';

const { deleteFilterSchemaList } = DynamicSegmentActions;

function FilterSchema({
    handleChangeActiveList,
    readOnly,
    setReadOnly,
    resetActiveList,
    editingFilter,
    setEditingFilter,
    filtersData,
    setClientsCount,
    queryConfigurations,
    withoutActions,
    groupName,
}) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    function setDeleteModalOpen() {
        setIsOpenDeleteModal((state) => !state);
    }

    function handleDeleteGroupFilters() {
        dispatch(
            deleteFilterSchemaList({
                parentHashKeys: filtersData.filters.map((el) => el[0].parentHashKey),
                tabId: filtersData.id,
            }),
        );
        setClientsCount({ Total: 0, Count: 0 });
        setDeleteModalOpen();
    }

    const { aggregated, notAggregated } = useMemo(() => {
        const filtersGroup = keys(filtersData.filters)
            .filter((key) => !isNil(filtersData.filters[key]))
            .map((key) => {
                const elem = filtersData.filters[key];
                const groupDisplayName = getByHashKey(elem[0].parentHashKey, queryConfigurations).parentDisplayName;
                const color = elem[0].comparison.colorUI;

                return {
                    elem: elem.map((item, index) => ({
                        filter: item,
                        orderId: index,
                    })),
                    color,
                    groupDisplayName,
                    filterComparisionId: key,
                };
            });

        const { groupByAggregated = [], groupByNotAggregated = [] } = groupBy(filtersGroup, (item) =>
            item.elem[0].filter.comparison.isAggregated ? 'groupByAggregated' : 'groupByNotAggregated',
        );

        const regroupByAggregated = groupByNotAggregated.reduce(
            (acc, item) => {
                const { excludeList = [], notExcludeList = [] } = groupBy(item.elem, (item) => {
                    return item.filter.comparison.comparison === FilterComparisions.Exclude
                        ? 'excludeList'
                        : 'notExcludeList';
                });

                if (excludeList.length) {
                    acc.aggregated.push({ ...item, elem: excludeList });
                }

                if (notExcludeList.length) {
                    acc.notAggregated.push({ ...item, elem: notExcludeList });
                }

                return acc;
            },
            { aggregated: [], notAggregated: [] },
        );

        return {
            aggregated: [...groupByAggregated, ...regroupByAggregated.aggregated],
            notAggregated: [...regroupByAggregated.notAggregated],
        };
    }, [queryConfigurations, filtersData]);

    return (
        <>
            <div className="crm-dynamic-segment-filter-schema-wrapper">
                <div
                    className="crm-dynamic-segment-filter-schema"
                    style={{
                        '--kpi-group-rgb-color': 'rgba(60, 64, 67, 1)',
                        '--kpi-group-rgb-color-disabled': 'rgba(60, 64, 67, 0.5)',
                    }}
                >
                    <div className="crm-dynamic-segment-filter-schema__header crm-flex crm-justify-content-between crm-align-items-center">
                        <div className="crm-dynamic-segment-filter-schema__header__title">
                            {t(`Schema${groupName}`)}
                        </div>
                        <div
                            className={`crm-dynamic-segment-filter-schema__header__icons crm-flex crm-justify-content-between ${groupName
                                .replace(/\s+/g, '-')
                                .toLowerCase()}`}
                        >
                            {!withoutActions && (
                                <Icon type="bc-icon-trash" onClick={setDeleteModalOpen} disabled={readOnly} />
                            )}
                        </div>
                    </div>

                    {isEmpty(notAggregated) && (
                        <Alert
                            className="crm-dynamic-segment-filters-container-warning-message"
                            title={
                                withoutActions
                                    ? t(l.NotContainBasicFilters)
                                    : t(l.DynamicSegmentNotContainBasicFiltersWarningMessage)
                            }
                            type={withoutActions ? 'info' : 'warning'}
                            iconProps={{ type: 'bc-icon-info' }}
                        />
                    )}
                    {notAggregated.map(({ elem, color, groupDisplayName, filterComparisionId }) => (
                        <FilterGroup
                            key={`${filterComparisionId}_${filtersData.id}_${filtersData.seqId}`}
                            editingFilter={editingFilter}
                            setEditingFilter={setEditingFilter}
                            groupDisplayName={groupDisplayName}
                            resetActiveList={resetActiveList}
                            color={color}
                            readOnly={readOnly}
                            withoutActions={withoutActions}
                            setReadOnly={setReadOnly}
                            setClientsCount={setClientsCount}
                            list={elem}
                            handleChangeActiveList={handleChangeActiveList}
                            tabActiveIndex={filtersData.id}
                        />
                    ))}

                    <Title text={t(l.AggregatedFilters)} color={'base'} withLine />

                    {isEmpty(aggregated) && (
                        <Alert
                            className="crm-dynamic-segment-filters-container-warning-message"
                            title={
                                withoutActions
                                    ? t(l.NotContainAggregatedFilters)
                                    : t(l.DynamicSegmentNotContainAggregatedFiltersWarningMessage)
                            }
                            type={withoutActions ? 'info' : 'warning'}
                            iconProps={{ type: 'bc-icon-info' }}
                        />
                    )}
                    {aggregated.map(({ elem, color, groupDisplayName, filterComparisionId }) => (
                        <FilterGroup
                            key={`${filterComparisionId}_${filtersData.id}_${filtersData.seqId}`}
                            editingFilter={editingFilter}
                            setEditingFilter={setEditingFilter}
                            groupDisplayName={groupDisplayName}
                            resetActiveList={resetActiveList}
                            color={color}
                            readOnly={readOnly}
                            withoutActions={withoutActions}
                            setReadOnly={setReadOnly}
                            setClientsCount={setClientsCount}
                            list={elem}
                            handleChangeActiveList={handleChangeActiveList}
                            tabActiveIndex={filtersData.id}
                        />
                    ))}
                </div>
            </div>

            <ConfirmationModal
                isVisibleModal={isOpenDeleteModal}
                onOk={handleDeleteGroupFilters}
                onCancel={setDeleteModalOpen}
                questionLabel={t(l.DoYouWantToDeleteThisFilterSchemaFromDynamicSegmentFilterContainer)}
                titleText={t(l.DeleteFilterSchemaFromDynamicSegmentFilterContainer)}
                iconType={ActionsIcons.Delete}
                actionLabel={t(l.Delete)}
                className={ModalsClassNames.Delete}
            />
        </>
    );
}

FilterSchema.propTypes = {
    handleChangeActiveList: PropTypes.func,
    readOnly: PropTypes.bool,
    setReadOnly: PropTypes.func,
    resetActiveList: PropTypes.func,
    editingFilter: PropTypes.string,
    setEditingFilter: PropTypes.func,
    filtersData: PropTypes.object.isRequired,
    queryConfigurations: PropTypes.object.isRequired,
    setClientsCount: PropTypes.func.isRequired,
    withoutActions: PropTypes.bool,
    groupName: PropTypes.string,
};

FilterSchema.defaultProps = {
    readOnly: false,
    withoutActions: false,
    groupName: '',
};

export default FilterSchema;
