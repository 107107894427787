function loadGA4(measurementId) {
    const script = document.createElement('script');

    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;

    window.dataLayer = window.dataLayer || [];
    document.head.appendChild(script);

    // Initializing GA4 after the script is loaded
    script.onload = () => {

        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', measurementId, { send_page_view: false });
    };
}

loadGA4(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
