import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty, keys, isFunction, isString, trim } from 'lodash';
// Import UI Components
import { Modal, Button } from '@geneui/components';
// Import Components
import ResultInfoTab from './ResultInfoTab';
import HistoryInfoTab from './HistoryInfoTab';
import { CustomTabs, KeyValueItemsGroup } from 'components';
// Import Constants
import { l, ReportsResultStatus, ExecutedReportParametersDataTypes } from 'constants/common';
import { InfoItemsConfig, parametersInfoConfig } from './config';
// Import SCSS
import 'assets/scss/executedReport.scss';
import 'assets/scss/campaignReport.scss';

const ExecutedReportInfo = ({
    isVisibleModal,
    titleText,
    onOk,
    adHocReportResultId,
    reportType,
    adHocReportVersion,
    reportName,
    generalDetailsInfo,
    reportParametersInfo,
    parametersConfig,
}) => {
    const { t } = useTranslation();
    const { generalDetailsInfoItemsConfig } = InfoItemsConfig(t);
    const { parametersInfoItemsConfig } = parametersInfoConfig(t);

    const createGroupData = useMemo(() => {
        const result = [];
        const getValue = (key, val) => {
            const configValue = generalDetailsInfoItemsConfig.keyValueItemsData[key];
            return isNil(configValue) || (isString(configValue) && isEmpty(trim(configValue)))
                ? t(l.NA)
                : isFunction(configValue.format)
                ? configValue.format(val)
                : val;
        };
        const generalDetailsGroupInfo = {
            title: generalDetailsInfoItemsConfig.title,
            keyValueItemsData: keys(generalDetailsInfo).map((key) => {
                return {
                    label: generalDetailsInfoItemsConfig.keyValueItemsData[key].label,
                    value: getValue(key, generalDetailsInfo[key]),
                    isLoading: false,
                };
            }),
        };
        result.push(generalDetailsGroupInfo);
        if (!isEmpty(parametersConfig) && !isEmpty(reportParametersInfo)) {
            const keyValueItemsData = parametersConfig.map((parameterConfig, index) => {
                const label = t(l[parameterConfig.Name]);
                const value = reportParametersInfo[parameterConfig.Name];
                const config = parameterConfig;

                const { Component, dataType } = parametersInfoItemsConfig[
                    ExecutedReportParametersDataTypes[config.DataType]
                ];
                const dynamicProps = {};
                if (dataType === ExecutedReportParametersDataTypes.MenuItem) {
                    const id = JSON.parse(config.ComparisionUI.Source).MenuItemId;
                    dynamicProps.menuItemId = id;
                }
                return {
                    label: l[label],
                    value: <Component key={index} value={value} {...dynamicProps} />,
                };
            });
            const parameterKeyValuesGroup = {
                title: t(l.ExecutedReportParameters),
                keyValueItemsData: keyValueItemsData,
            };

            result.push(parameterKeyValuesGroup);
        }

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tabsConfig = useMemo(() => {
        const result = [];
        if (generalDetailsInfo.status !== ReportsResultStatus.Expired) {
            result.push({
                tabTitle: <p>{t(l.Result)}</p>,
                tabKey: result.length.toString(),
                tabContent: (
                    <div className="tab-content-cnt-wrapper">
                        <div className="tab-content-cnt">
                            <ResultInfoTab
                                adHocReportResultId={adHocReportResultId}
                                reportType={reportType}
                                adHocReportVersion={adHocReportVersion}
                                reportName={reportName}
                            />
                        </div>
                    </div>
                ),
            });
        }
        result.push({
            tabTitle: <p>{t(l.History)}</p>,
            tabKey: result.length.toString(),
            tabContent: (
                <div className="tab-content-cnt-wrapper">
                    <div className="tab-content-cnt">
                        <HistoryInfoTab adHocReportResultId={adHocReportResultId} />
                    </div>
                </div>
            ),
        });

        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            className="executed-modal-inner"
            background="dark-background"
            position="center"
            title={titleText}
            closable={false}
            onCancel={onOk}
            closeOnClickOutside={true}
            visible={isVisibleModal}
            footer={
                <Button color="primary" onClick={onOk}>
                    {t(l.Close)}
                </Button>
            }
        >
            <div className="executed-report-info-modal-content-cnt">
                <div className="key-value-items-group">
                    <KeyValueItemsGroup itemsGroup={createGroupData} />
                </div>
                {generalDetailsInfo.status !== ReportsResultStatus.Fail && (
                    <div className="custom-tabs-cnt">
                        <CustomTabs tabsConfig={tabsConfig} customCssClassNames="crm-campaign-report-block" />
                    </div>
                )}
            </div>
        </Modal>
    );
};

ExecutedReportInfo.propTypes = {
    isVisibleModal: PropTypes.bool.isRequired,
    titleText: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    adHocReportResultId: PropTypes.number.isRequired,
    reportType: PropTypes.number.isRequired,
    adHocReportVersion: PropTypes.number.isRequired,
    reportName: PropTypes.string.isRequired,
    generalDetailsInfo: PropTypes.object,
    reportParametersInfo: PropTypes.object,
    parametersConfig: PropTypes.array,
};

export default memo(ExecutedReportInfo);
