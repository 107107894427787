/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useState, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEmpty, isNil, noop } from 'lodash';
// Import UI Components
import { BusyLoader } from '@geneui/components';
// Import Components
import { CustomReportParameterWidget } from 'components';
// Import Configs
import DynamicSegmentForms from 'components/DynamicSegment/DynamicSegmentForms';
import { getFiltersElementView } from 'components/DynamicSegment/FiltersContainer/service/helpers';
// Import Hooks
import { useValidator } from 'hooks';
import { useSelector } from 'react-redux';
import 'assets/scss/customReport.scss';

const CustomReportParametersSection = ({
    params,
    setRequestParams,
    addValidationProperties,
    removeValidationProperties,
}) => {
    const { t } = useTranslation();
    const validator = useValidator();
    const pageValidation = useSelector((state) => state.pageValidation);
    const [selectedParameter, setSelectedParameter] = useState({});
    const [isVisibleEditor, setIsVisibleEditor] = useState(false);

    const [configs, setConfigs] = useState(cloneDeep(params));

    const setAdditionalData = (value) => {
        setConfigs((prev) => {
            let tmpData = cloneDeep(prev);
            let res = tmpData.find((r) => r.Id === selectedParameter.Id);
            res._additionalData = value;
            setSelectedParameter(res);
            return tmpData;
        });
    };

    const widgetOnClickHandler = (id) => {
        setIsVisibleEditor(true);
        const config = configs.find((r) => r.Id === id);
        setSelectedParameter(config);
        validateWidget(id, config);
    };

    const validateWidget = (id, config) => {
        validator({
            ['parameter' + id]: config.ValueLabel,
        });
    };

    const applyOnClickHandler = () => {
        setConfigs((prev) => {
            let tmpData = cloneDeep(prev);
            let res = tmpData.find((r) => r.Id === selectedParameter.Id);
            res.id = res.Id;
            res.ValueLabel = getFiltersElementView(t, res, res._additionalData);
            validateWidget(selectedParameter.Id, res);
            setSelectedParameter(res);
            setRequestParameters(tmpData);

            return tmpData;
        });
        setIsVisibleEditor(false);
    };

    const setRequestParameters = (tmpData) => {
        setRequestParams(
            tmpData
                .filter((r) => r.isChecked)
                .map(({ Column, Value }) => {
                    return {
                        Value,
                        Column,
                    };
                }),
        );
    };

    const switcherChangeHandler = (id) => {
        setConfigs((prev) => {
            let tmpData = cloneDeep(prev);
            let res = tmpData.find((r) => r.Id === id);
            if (!res.isChecked) {
                addValidationProperties({
                    parameter: true,
                    property: { key: 'parameter' + res.Id, value: res.Value },
                });
                validateWidget(id, res);
            } else {
                removeValidationProperties(['parameter' + res.Id]);
            }
            res.isChecked = !res.isChecked;
            return tmpData;
        });
    };

    const setFormData = (data) => {
        if (
            !isNil(data) &&
            (!isEmpty(data.Argument?.Value) || !isEmpty(data.Data?.Value) || isEmpty(data.Data?.OriginalValue))
        ) {
            setConfigs((prev) => {
                let tmpData = cloneDeep(prev);
                let res = tmpData.find((r) => r.Id === selectedParameter.Id);
                res.Value = data;
                return tmpData;
            });
        }
    };

    useEffect(() => {
        const newParams = params.map((config) => {
            if (config.hasDefaultValue) {
                config.id = config.Id;
                config.ValueLabel = getFiltersElementView(t, config, config._additionalData);
            }
            if (config.isChecked) {
                addValidationProperties({
                    parameter: true,
                    property: { key: 'parameter' + config.Id, value: config.ValueLabel },
                });
            }
            return config;
        });

        setConfigs(cloneDeep(newParams));
        setRequestParameters(newParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const getParametersEditor = () => {
        const { Component, config } = DynamicSegmentForms[selectedParameter.Type];
        const value = configs.find((r) => r.Id === selectedParameter.Id);
        const hashKey = selectedParameter.parent.hashKey;
        const filterList = { [hashKey]: { [selectedParameter.Id]: value } };
        selectedParameter.setAdditionalData = setAdditionalData;

        return (
            <Suspense fallback={<BusyLoader isBusy type="spinner" spinnerSize="medium" />}>
                <div className="crm-dynamic-segment-form">
                    <div className="crm-dynamic-segment-form-head">{selectedParameter.Name}</div>
                    <Component
                        data={selectedParameter}
                        config={config}
                        setValidation={noop}
                        setError={noop}
                        setFormData={setFormData}
                        handleApply={applyOnClickHandler}
                        editModeCancelHandler={() => setIsVisibleEditor(false)}
                        filterList={filterList}
                    />
                </div>
            </Suspense>
        );
    };

    return (
        <div className="custom-report-parameters-section-container">
            <div className="custom-report-inner-container">
                {!isEmpty(configs) &&
                    configs.map((parameter) => {
                        const { Id } = parameter;
                        const pValidation = pageValidation['parameter' + Id];
                        return (
                            <CustomReportParameterWidget
                                parameterName={parameter.Name}
                                parameterValue={configs.find((r) => r.id === Id)?.ValueLabel}
                                key={Id}
                                isValid={!pValidation?.errorText}
                                isSelected={Id === selectedParameter.Id}
                                onClickHandler={() => widgetOnClickHandler(Id)}
                                isRequired={parameter.IsRequired}
                                isChecked={parameter.isChecked}
                                switcherChangeHandler={() => switcherChangeHandler(Id)}
                            />
                        );
                    })}
            </div>
            <div className="parameters-editor"> {isVisibleEditor && getParametersEditor()}</div>
        </div>
    );
};

CustomReportParametersSection.propTypes = {
    params: PropTypes.array,
    setRequestParams: PropTypes.func,
    addValidationProperties: PropTypes.func,
    removeValidationProperties: PropTypes.func,
};
CustomReportParametersSection.defaultProps = {
    params: [],
    setRequestParams: noop,
    addValidationProperties: noop,
    removeValidationProperties: noop,
};

export default memo(CustomReportParametersSection);
