import { isEmpty } from 'lodash';
// Import Constants
import { ErrorShowType, PageTypes, l } from 'constants/common';

const customerJourneyValidationScenario = (data, pageType) => {
    if (pageType !== PageTypes.view) {
        return {
            Name: {
                isValid: null,
                errorText: '',
                value: '',
                paramValue: '',
                type: ErrorShowType.Default,
                validationCredentials: [
                    {
                        ValidationType: 'required',
                        ErrorMessage: l.CannotBeEmpty,
                        ParametersValues: null,
                    },
                    {
                        ValidationType: 'firstSymbol',
                        ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                        ParametersValues: /^[\p{L}0-9]/gu,
                    },
                    {
                        ValidationType: 'minLength',
                        ErrorMessage: l.MustContainMinimumCharacters,
                        ParametersValues: '3',
                    },
                    {
                        ValidationType: 'maxLength',
                        ErrorMessage: l.MustContainMaximumCharacters,
                        ParametersValues: '50',
                    },
                ],
            },
            Description: {
                isValid: null,
                errorText: '',
                value: '',
                paramValue: '',
                type: ErrorShowType.Default,
                validationCredentials: [
                    {
                        ValidationType: 'maxLength',
                        ErrorMessage: l.MustContainMaximumCharacters,
                        ParametersValues: '250',
                    },
                ],
            },
            CustomerJourney: {
                isValid: null,
                forceValidation: true,
                errorText: '',
                value: '',
                paramValue: '',
                type: ErrorShowType.Toaster,
                validationCredentials: [
                    {
                        ValidationType: 'customerJourney',
                        ErrorMessage: '',
                        ParametersValues: null,
                    },
                ],
            },
        };
    }
    return {};
};

const customerJourneyLimitScenario = ({ limit, count }) => ({
    CustomerJourneyLimit: {
        isValid: null,
        errorText: '',
        value: {},
        validationCredentials: [
            {
                ValidationType: 'customerJourneyLimitValidation',
                ErrorMessage: '',
                ParametersValues: { limit: limit, count: count },
            },
        ],
    },
});

const customerJourneyScenarioPropertiesCreator = (data) => {
    if (!isEmpty(data?.CustomerJourneyLimit)) {
        return customerJourneyLimitScenario(data.CustomerJourneyLimit);
    }
    return null;
};

export { customerJourneyValidationScenario, customerJourneyScenarioPropertiesCreator };
