import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import Components
import { TemplateInfoModal, ReportInfoModal, CampaignInfoModal, CustomerJourneyInfoModal, SegmentModal } from 'components';
// Import Constants
import { DefinitionTypes } from 'constants/common';

const GlobalSearchActionsModals = (props) => {
    const {
        openedModalData,
        isSegmentInfoModalOpen,
        isTemplateInfoModalOpen,
        isCampaignInfoModalOpen,
        isRealtimePromotionInfoModalOpen,
        isReportInfoModalOpen,
        onCloseModalHandler,
        modalsStateSetDict,
    } = props;
    const { segment, template, campaign, realtimePromotion } = openedModalData;

    return (
        <>
            {isSegmentInfoModalOpen && (
                <SegmentModal
                    targetObject={segment.targetObject}
                    segmentId={segment.id}
                    segmentType={segment.type}
                    handleClose={() => onCloseModalHandler(modalsStateSetDict.infoSegment.key)}
                    isVisible={isSegmentInfoModalOpen}
                    isPredefined={segment.definitionType === DefinitionTypes.predefined}
                />
            )}
            {isTemplateInfoModalOpen && (
                <TemplateInfoModal
                    templateId={template.id}
                    handleClose={() => onCloseModalHandler(modalsStateSetDict.infoTemplate.key)}
                    isVisible={isTemplateInfoModalOpen}
                    childItemsInfo={template.childItemsInfo}
                    isGlobal={template.isGlobal}
                />
            )}
            {isCampaignInfoModalOpen && (
                <CampaignInfoModal
                    campaignId={campaign.id}
                    handleClose={() => onCloseModalHandler(modalsStateSetDict.infoCampaign.key)}
                    isVisible={isCampaignInfoModalOpen}
                />
            )}
            {isRealtimePromotionInfoModalOpen && (
                <CustomerJourneyInfoModal
                    workflowId={realtimePromotion.id}
                    promotionType={realtimePromotion.promotionType}
                    handleClose={() => onCloseModalHandler(modalsStateSetDict.infoRealtimePromotion.key)}
                    isVisible={isRealtimePromotionInfoModalOpen}
                    isLoading={false}
                    hasVisualization={realtimePromotion.hasVisualization}
                />
            )}
            {isReportInfoModalOpen && (
                <ReportInfoModal
                    onOk={() => noop()}
                    isVisibleModal={isReportInfoModalOpen}
                    reportInfo={openedModalData.report}
                    onCancel={() => onCloseModalHandler(modalsStateSetDict.infoReport.key)}
                />
            )}
        </>
    );
};

GlobalSearchActionsModals.propTypes = {
    isSegmentInfoModalOpen: PropTypes.bool.isRequired,
    isTemplateInfoModalOpen: PropTypes.bool.isRequired,
    isCampaignInfoModalOpen: PropTypes.bool.isRequired,
    isRealtimePromotionInfoModalOpen: PropTypes.bool.isRequired,
    isReportInfoModalOpen: PropTypes.bool.isRequired,
    openedModalData: PropTypes.object.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
};

export default memo(GlobalSearchActionsModals);
