import React from 'react';
import PropTypes from 'prop-types';
// Import configs
import { KPIWidgetsConfigs } from './config';
// Import components
import KPIWidgetWrapper from './KPIWidgetWrapper';

const KPIWidgetsSection = ({ possibleWidgets, date }) => {
    return (
        <div className="widgets-wrap">
            {KPIWidgetsConfigs.map((item, index) => {
                if (possibleWidgets.includes(item.id)) {
                    return (
                        <KPIWidgetWrapper
                            key={index}
                            item={item}
                            parentDate={date}
                            widgets={possibleWidgets}
                        />
                    );
                }
                return <></>;
            })}
        </div>
    );
};

KPIWidgetsSection.propTypes = {
    date: PropTypes.array.isRequired,
    possibleWidgets: PropTypes.array,
};

KPIWidgetsSection.defaultProps = {
    possibleWidgets: [],
};

export default KPIWidgetsSection;
