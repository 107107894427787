import React, { useState, memo, useEffect, useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    isNil,
    forEach,
    has,
    isBoolean,
    isObject,
    noop,
    isUndefined,
    isArray,
    isEmpty,
    isNumber,
    isEqual,
    first,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import * as conditionalClassNames from 'classnames';
//  Import Services
import { UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest, useDataTable, useHasPermission } from 'hooks';
// Import UI components
import { Button, ComboTable, Popover, Option, Checkbox, Radio, Tooltip, Icon } from '@geneui/components';
import { LabelsModal, LabelTag, ConfirmationModal } from 'components';
import SelectedItemsModal from './SelectedItemsModal';
import ColumnsChooser from './ColumnsChooser';
// Import Constants
import { CustomTypes } from './constants';
import {
    l,
    LabelsModalViews,
    ActionsIcons,
    ModalsClassNames,
    SortDirectionTypes,
    DataTablePagingOptions,
} from 'constants/common';
// Import SCSS
import 'assets/scss/dataTable.scss';

const getDefaultPagingSize = (pagingOptions) => {
    return first(pagingOptions.filter(({ defaultSelected }) => defaultSelected === true))?.value;
};

// eslint-disable-next-line react/display-name
const DataTable = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { doDeleteRequest } = useRequest();
    const { hasPermission } = useHasPermission();

    const {
        getTitle,
        rowKey,
        columnKey,
        isColumnsSortable,
        isColumnsResizable,
        isColumnsDraggable,
        data,
        dataCount,
        rowActionBar,
        columns: allColumns,
        name,
        tableKey,
        withSearch,
        hideSearchDropdown,
        withManageLabels,
        setLabelObjectIdKey,
        labelNameKey,
        labelColorKey,
        labelObjectType,
        withPagination,
        withPageSelector,
        withOverlay,
        overlayContent,
        isLoading,
        isShowRefreshButton,
        onRefreshClick,
        isShowColumnChooser,
        isShowVerticalDots,
        verticalDotsContentList,
        verticalDotsFn,
        setPagingPageSize,
        setPagingPageNumber,
        recevedCurrentPageNumber,
        recevedCurrentPageSize,
        sorting,
        setSorting,
        classNames,
        isRowExpandable,
        isVisibleOnlyDataTable,
        rowToggleHandler,
        getRowNestedContent,
        headerActions,
        isHasRowMultiSelect,
        isHasRowRadioSelect,
        isHasBulkActions,
        isHasArchiveActions,
        isHasUnarchiveActions,
        archiveSelectedRows,
        unarchiveSelectedRows,
        isHasDeleteAction,
        deleteSelectedRows,
        getSelectedRowsList,
        getRadioSelectedRow,
        defaultRadioSelectedRow,
        isBackendPagination,
        defaultMultiSelectedRows,
        getDragAcceptType,
        CustomPreview,
        TitleActions,
        withQueryParams,
        footerValues,
        isSortControlled,
        pagingOptions,
        customBulkActions,
    } = props;

    const { deleteObjectTagRequest } = useRef({
        deleteObjectTagRequest: UtilsHttpService.deleteObjectTag(),
    }).current;

    const dataTableData = useDataTable(
        recevedCurrentPageNumber,
        recevedCurrentPageSize,
        sorting?.name,
        sorting?.direction,
        withQueryParams,
        tableKey,
    );

    const [isModalOpened, setModalOpenedState] = useState(false);
    const [isVisibleVerticalDotsContent, setIsVisibleVerticalDotsContent] = useState(false);
    const [verticalDotsContentSelectedOption, setVerticalDotsContentSelectedOption] = useState();

    const [multiSelectedRows, setMultiSelectedRows] = useState([]);
    const [multiSelectedRowsCount, setMultiSelectedRowsCount] = useState(0);

    const [radioSelectedRow, setRadioSelectedRow] = useState(null);

    const [isAllVisibleRowsSelected, setIsAllVisibleRowsSelected] = useState(false);
    const [isIndeterminateVisibleRowsSelected, setIsIndeterminateVisibleRowsSelected] = useState(false);

    const [isVisibleLabelsModal, setIsVisibleLabelsModal] = useState(false);
    const [labelsModalView, setLabelsModalView] = useState('');
    const [labelObjectId, setLabelObjectId] = useState();
    const [selectedLabelId, setSelectedLabelId] = useState();
    const [isVisibleDeleteLabelModal, setIsVisibleDeleteLabelModal] = useState(false);

    const [isVisibleOverlay, setIsVisibleOverlay] = useState(false);
    const [filteredColumns, setFilteredColumns] = useState([]);

    const [isDataTableReady, setIsDataTableReady] = useState(!isShowColumnChooser);
    const [isVisibleSelectedModal, setIsVisibleSelectedModal] = useState(false);
    const [deleteLabelObjectId, setDeleteLabelObjectId] = useState();
    const [isDeleteLabelBtnLoading, setIsDeleteLabelBtnLoading] = useState(false);
    const [deleteLabelName, setDeleteLabelName] = useState('');
    const [deleteLabelTitle, setDeleteLabelTitle] = useState('');

    const columns = useMemo(() => {
        return allColumns.filter((column) => {
            if (column.mandatoryPermissions) {
                return hasPermission(column.mandatoryPermissions, true);
            }

            if (column.permissions) {
                return hasPermission(column.permissions, false);
            }

            return true;
        });
    }, [allColumns, hasPermission]);

    // Manage labels logic
    const openLabelsModal = (view, labelObjectId, selectedLabelId) => {
        setLabelsModalView(view);
        setIsVisibleLabelsModal(true);

        if (!isUndefined(setLabelObjectId)) {
            setLabelObjectId(labelObjectId);
            setSelectedLabelId(selectedLabelId);
        }
    };

    const handleDeleteLabel = (objectId, columnKey, title) => {
        setDeleteLabelTitle(title);
        setDeleteLabelName(columnKey);
        setIsVisibleDeleteLabelModal(true);
        setDeleteLabelObjectId(objectId);
    };

    const labelsModalCloseHandler = (isRefreshTableData) => {
        setIsVisibleLabelsModal(false);
        if (isRefreshTableData === true) {
            onRefreshClick();
        }
    };

    // Row multi select logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const rowSelectionToggleHandler = (event, row) => {
        const { checked } = event.currentTarget;
        if (checked) {
            setMultiSelectedRows([...multiSelectedRows, row]);
        } else {
            const selectedRow = getMultiSelectedRow(row);
            setMultiSelectedRows(multiSelectedRows.filter((item) => !comparatorSelectedRows(item, selectedRow)));
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const rowRadioSelectionToggleHandler = (event, row) => {
        if (!isNil(radioSelectedRow)) {
            radioSelectedRow.data.isRowSelected = false;
        }
        row.data.isRowSelected = true;
        setRadioSelectedRow(row);
        if (withOverlay) {
            setIsVisibleOverlay(true);
        }
    };

    const setAllHandler = () => {
        const resultLength = multiSelectedRows.length;

        let tmpIndeterminate = false;
        const tmpData = data.filter((row) => !row.isUnselectable);

        const tmpIsVisibleRowsSelected = tmpData.reduce((acc, item) => {
            const tmpHasInMultiselected = hasInMultiSelectedRows(item);
            tmpIndeterminate = tmpIndeterminate || tmpHasInMultiselected;
            return (acc = acc && tmpHasInMultiselected);
        }, resultLength !== 0 && tmpData.length !== 0);

        setIsAllVisibleRowsSelected(tmpIsVisibleRowsSelected);
        setIsIndeterminateVisibleRowsSelected(!tmpIsVisibleRowsSelected && tmpIndeterminate);
    };

    useEffect(() => {
        setMultiSelectedRowsCount(multiSelectedRows.length);
        getSelectedRowsList(multiSelectedRows);
        setAllHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiSelectedRows]);

    useEffect(() => {
        getRadioSelectedRow(radioSelectedRow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radioSelectedRow]);

    useEffect(() => {
        if (isHasRowMultiSelect) {
            setAllHandler();
        } else if (isHasRowRadioSelect) {
            data.forEach((row) => {
                if (!isNil(radioSelectedRow) && comparatorSelectedRows(radioSelectedRow, row)) {
                    row.data.isRowSelected = true;
                    setRadioSelectedRow(row);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const allRowsSelectionToggleHandler = (event, isSelected) => {
        const checked = isBoolean(isSelected) ? isSelected : event.currentTarget.checked;

        setIsAllVisibleRowsSelected(checked);

        let newPushedMultiSelectedRows = [];
        let newPulledMultiSelectedRowKeys = [];
        const tmpData = data.filter((row) => !row.isUnselectable);

        forEach(tmpData, (row) => {
            if (checked) {
                if (!hasInMultiSelectedRows(row)) {
                    newPushedMultiSelectedRows.push(row);
                }
            } else {
                const selectedRow = getMultiSelectedRow(row);
                newPulledMultiSelectedRowKeys.push(selectedRow.data[rowKey]);
            }
        });
        setMultiSelectedRows(
            multiSelectedRows
                .filter((item) => !newPulledMultiSelectedRowKeys.includes(item.data[rowKey]))
                .concat(newPushedMultiSelectedRows),
        );
    };

    const selectedRowClickHandler = () => {
        setIsVisibleSelectedModal(true);
    };

    const bulkActionClickHandler = (bulkAction) => {
        bulkAction.onClick(multiSelectedRows);
    };

    const bulkActions = [];

    if (multiSelectedRowsCount > 0 && isHasDeleteAction) {
        bulkActions.push({
            onClick: deleteSelectedRows,
            text: t(l.Delete),
            icon: 'bc-icon-delete-2',
        });
    }

    if (multiSelectedRowsCount > 0 && isHasArchiveActions) {
        bulkActions.push({
            onClick: archiveSelectedRows,
            text: t(l.Archive),
            icon: 'bc-icon-archive',
        });
    }

    if (multiSelectedRowsCount > 0 && isHasUnarchiveActions) {
        bulkActions.push({
            onClick: unarchiveSelectedRows,
            text: t(l.Unarchive),
            icon: 'bc-icon-unarchive',
        });
    }

    if (multiSelectedRowsCount > 0 && !isEmpty(customBulkActions)) {
        bulkActions.push(...customBulkActions);
    }

    const getHeaderBulkActions = () => {
        if (isHasBulkActions !== true) return;

        return (
            multiSelectedRowsCount > 0 && (
                <div className="crm-data-table-header-bulk-actions">
                    {bulkActions.map((bulkAction, index) => (
                        <Button
                            key={index}
                            onClick={() => bulkActionClickHandler(bulkAction)}
                            icon={bulkAction.icon}
                            appearance="outline"
                            color="primary"
                            itemsDirection="start"
                        >
                            {bulkAction.text}
                        </Button>
                    ))}

                    <Button
                        onClick={selectedRowClickHandler}
                        appearance="minimal"
                        color="primary"
                        itemsDirection="start"
                        className="crm-data-table-header-selected"
                    >
                        {`${multiSelectedRowsCount} ${t(l.Selected)}`}
                    </Button>
                </div>
            )
        );
    };

    const resetSelectedRows = () => {
        setIsVisibleOverlay(false);
    };

    // Header actions bar
    const getLabelsActionBtn = () => {
        return (
            <Button
                appearance="minimal"
                icon="bc-icon-category"
                onClick={() => openLabelsModal(LabelsModalViews.manageLabelsView)}
            >
                {t(l.ManageLabels)}
            </Button>
        );
    };

    const getCombinedHeaderActions = () => {
        return (
            <>
                <div className="crm-data-table-header-action-bar-left-actions">
                    {withManageLabels === true && getLabelsActionBtn()}
                    {isHasRowMultiSelect === true && getHeaderBulkActions()}
                </div>
                <div className="crm-data-table-header-action-bar-right-actions">{headerActions}</div>
            </>
        );
    };

    const comparatorSelectedRows = (row1, row2) => {
        return isEqual(row1.data[rowKey], row2.data[rowKey]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hasInMultiSelectedRows = (row) => {
        return multiSelectedRows.reduce((acc, item) => (acc = acc || comparatorSelectedRows(item, row)), false);
    };

    const getMultiSelectedRow = (row) => {
        return multiSelectedRows.filter((item) => comparatorSelectedRows(item, row))[0];
    };

    const addSortingFunctionInColumns = (columns) => {
        columns.forEach((column) => {
            if (column.sortable === true && !isNil(column.type)) {
                if (!isNil(CustomTypes[column.type])) {
                    column.sortFn = CustomTypes[column.type].sortFn;
                }
            }
        });
    };

    const getColumns = useCallback(
        (fromColumnsChooser = false, receivedColumns = [...columns], fromSelectedItemsModal = false) => {
            if (!isDataTableReady && !fromColumnsChooser) {
                return [];
            }

            let clonedColumns = receivedColumns;
            if (!fromSelectedItemsModal === true) {
                if (
                    withManageLabels === true &&
                    !isUndefined(setLabelObjectIdKey) &&
                    !isUndefined(labelColorKey) &&
                    !isUndefined(labelNameKey)
                ) {
                    clonedColumns = [
                        {
                            text: t(l.Labels),
                            dataKey: labelNameKey,
                            sortable: false,
                            // eslint-disable-next-line react/display-name
                            colRenderer: (columnKey, index, row) => {
                                const labelColor = row.data[labelColorKey];
                                const objectId = row.data[setLabelObjectIdKey];
                                const categoryId = row.data['CategoryId'];
                                const title = getTitle(row);
                                return (
                                    <div className="set-category">
                                        {!isNil(columnKey) ? (
                                            <LabelTag
                                                color={labelColor}
                                                labelName={columnKey}
                                                onClick={() =>
                                                    openLabelsModal(LabelsModalViews.setLabelView, objectId, categoryId)
                                                }
                                                icon={
                                                    <button
                                                        className="remove-btn"
                                                        onClick={() => handleDeleteLabel(objectId, columnKey, title)}
                                                    >
                                                        <Icon type="bc-icon-is-not" />
                                                    </button>
                                                }
                                            />
                                        ) : (
                                            <button
                                                className="set-category-text"
                                                onClick={() => openLabelsModal(LabelsModalViews.setLabelView, objectId)}
                                            >
                                                {t(l.SetLabel)}
                                            </button>
                                        )}
                                    </div>
                                );
                            },
                        },
                        ...clonedColumns,
                    ];
                }
            }

            if (!isEmpty(filteredColumns)) {
                const tmpColumns = clonedColumns.reduce((acc, item) => {
                    acc[item.dataKey] = item;
                    return acc;
                }, {});
                clonedColumns = [];
                filteredColumns.forEach((item) => {
                    if (!isNil(tmpColumns[item])) {
                        clonedColumns.push(tmpColumns[item]);
                    }
                });
            }

            addSortingFunctionInColumns(clonedColumns);

            if (!fromSelectedItemsModal === true) {
                if (isHasRowMultiSelect === true) {
                    // if (first(clonedColumns).dataKey !== columnKey) {
                    clonedColumns = [
                        {
                            width: 50,
                            // eslint-disable-next-line react/display-name
                            render: () => (
                                <Checkbox
                                    checked={isAllVisibleRowsSelected}
                                    indeterminate={isIndeterminateVisibleRowsSelected}
                                    onChange={(e) => {
                                        allRowsSelectionToggleHandler(e);
                                    }}
                                />
                            ),
                            dataKey: columnKey,
                            sortable: false,
                            hasOptions: false,
                            resizable: false,
                            draggable: false,
                            // eslint-disable-next-line react/display-name
                            colRenderer: (columnKey, index, row) => {
                                return (
                                    <Checkbox
                                        disabled={row.isUnselectable}
                                        checked={hasInMultiSelectedRows(row)}
                                        onChange={(e) => {
                                            rowSelectionToggleHandler(e, row);
                                        }}
                                    />
                                );
                            },
                        },
                        ...clonedColumns,
                    ];
                } else {
                    if (isHasRowRadioSelect === true) {
                        clonedColumns = [
                            {
                                width: 50,
                                // eslint-disable-next-line react/display-name
                                render: () => <></>,
                                dataKey: columnKey,
                                sortable: false,
                                hasOptions: false,
                                resizable: false,
                                draggable: false,
                                // eslint-disable-next-line react/display-name
                                colRenderer: (columnKey, index, row) => {
                                    return (
                                        <Radio
                                            checked={row.data.isRowSelected}
                                            onChange={(e) => {
                                                rowRadioSelectionToggleHandler(e, row);
                                            }}
                                        />
                                    );
                                },
                            },
                            ...clonedColumns,
                        ];
                    }
                }
            }

            if (isEmpty(clonedColumns)) {
                clonedColumns = [...columns];
            }

            return clonedColumns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            columns,
            withManageLabels,
            setLabelObjectIdKey,
            labelColorKey,
            labelNameKey,
            isHasRowMultiSelect,
            t,
            columnKey,
            isAllVisibleRowsSelected,
            isIndeterminateVisibleRowsSelected,
            allRowsSelectionToggleHandler,
            hasInMultiSelectedRows,
            rowSelectionToggleHandler,
            isHasRowRadioSelect,
            rowRadioSelectionToggleHandler,
            filteredColumns,
        ],
    );

    const getRows = () => {
        return isDataTableReady ? data : [];
    };

    const onPaginationChange = (page, selectorValue) => {
        resetSelectedRows();
        dataTableData.setData({ pageNumber: page });
    };

    const onSelectorChange = (count) => {
        resetSelectedRows();
        dataTableData.setData({ pageNumber: 1, pageSize: count === 'all' ? 10000 : count });
    };

    // TODO implement backend sorting functional
    const onSortingChange = (isSortable, columnName, index, dataKey, element, sortingDirection) => {
        resetSelectedRows();
        dataTableData.setData({ sortingName: columnName, sortingDirection: sortingDirection });
    };

    // Title actions bar logic
    const verticalDotsClickHandler = (value) => {
        setIsVisibleVerticalDotsContent(false);
        if ((isNumber(value) || isNil(value)) && verticalDotsContentSelectedOption !== value) {
            verticalDotsFn(value);
            setVerticalDotsContentSelectedOption(value);
        }
    };

    const deleteObjectTag = (objectId, labelObjectType) => {
        setIsDeleteLabelBtnLoading(true);
        doDeleteRequest(deleteObjectTagRequest.request, {
            queryString: { id: objectId, objectType: labelObjectType },
            successCallback: () => {
                onRefreshClick();
                setIsDeleteLabelBtnLoading(false);
                setIsVisibleDeleteLabelModal(false);
            },
        });
    };

    const getTitleActions = () => {
        return (
            <>
                {TitleActions}
                {isShowColumnChooser && (
                    <Tooltip text={t(l.ColumnsChooser)}>
                        <Button
                            onClick={() => setModalOpenedState(true)}
                            icon="bc-icon-settings"
                            appearance="minimal"
                            color="primary"
                        />
                    </Tooltip>
                )}
                {isShowRefreshButton && (
                    <Tooltip text={t(l.ReloadData)}>
                        <Button onClick={onRefreshClick} icon="bc-icon-reset" appearance="minimal" color="primary" />
                    </Tooltip>
                )}
                {isShowVerticalDots && (
                    <Popover
                        toggleHandler={() => setIsVisibleVerticalDotsContent(!isVisibleVerticalDotsContent)}
                        isOpen={isVisibleVerticalDotsContent}
                        extendTargetWidth={false}
                        className="crm-data-table-top-action-bar-popover"
                        position="bottom"
                        align="end"
                        maxHeight={120}
                        Content={
                            <>
                                {verticalDotsContentList.map(({ label, value }, index) => (
                                    <Option
                                        key={index}
                                        title={t(label)}
                                        onClick={() => verticalDotsClickHandler(value)}
                                        active={value === verticalDotsContentSelectedOption}
                                    />
                                ))}
                            </>
                        }
                        scrollbarNeeded={true}
                    >
                        <Tooltip text={t(l.Mode)}>
                            <Button
                                onClick={verticalDotsClickHandler}
                                icon="bc-icon-more-vertical"
                                appearance="minimal"
                                color="primary"
                            />
                        </Tooltip>
                    </Popover>
                )}
            </>
        );
    };

    const getExpandIconDisableState = (row) => {
        return !(has(row, 'isRowExpandable') && row.isRowExpandable);
    };

    const resetDataTable = () => {
        resetSelectedRows();
        dataTableData.setData({ pageNumber: 1 });
    };

    const changePaginationPageNumberDataTable = (pageNumber = 1) => {
        resetSelectedRows();
        dataTableData.setData({ pageNumber: pageNumber });
    };

    const onSaveColumns = (newColumns) => {
        !isDataTableReady && setIsDataTableReady(true);
        setFilteredColumns(newColumns);
    };

    const SetRadioSelectedRow = (newRow) => {
        if (!isNil(radioSelectedRow)) {
            radioSelectedRow.data.isRowSelected = false;
        }
        setRadioSelectedRow(newRow);
        data.forEach((row) => {
            if (comparatorSelectedRows(row, newRow)) {
                row.data.isRowSelected = true;
                setRadioSelectedRow(row);
                if (withOverlay) {
                    setIsVisibleOverlay(true);
                }
            }
        });
    };

    useEffect(() => {
        if (isHasRowRadioSelect && !isNil(defaultRadioSelectedRow) && isNil(radioSelectedRow)) {
            SetRadioSelectedRow(defaultRadioSelectedRow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultRadioSelectedRow]);

    const init = () => {
        if (
            isArray(verticalDotsContentList) &&
            isObject(verticalDotsContentList[0]) &&
            has(verticalDotsContentList[0], 'value')
        ) {
            setVerticalDotsContentSelectedOption(verticalDotsContentList[0].value);
        }
        setMultiSelectedRows(defaultMultiSelectedRows);
    };

    const clickOverlayHandler = () => {
        if (isHasRowRadioSelect && isNil(radioSelectedRow) && !isVisibleOverlay) {
            return;
        }
        setIsVisibleOverlay(!isVisibleOverlay);
    };

    let backendPagingProps = {};
    if (isBackendPagination) {
        backendPagingProps.selectorValue = withPageSelector === true ? dataTableData.data.pageSize : dataCount;
        backendPagingProps.currentPage = dataTableData.data.pageNumber;
        backendPagingProps.onPaginationChange = onPaginationChange;
        backendPagingProps.onSelectorChange = onSelectorChange;
    }

    useEffect(init, []);

    useImperativeHandle(ref, () => ({
        reset() {
            resetDataTable();
        },
        resetSelectedRows() {
            setMultiSelectedRows([]);
            if (!isNil(radioSelectedRow)) {
                radioSelectedRow.data.isRowSelected = false;
                if (withOverlay) {
                    setIsVisibleOverlay(false);
                }
            }
            setRadioSelectedRow(null);
        },
        setRadioSelectedRow(row) {
            SetRadioSelectedRow(row);
        },
        changePaginationPageNumber(pageNumber) {
            changePaginationPageNumberDataTable(pageNumber);
        },
        changeTableConfig(data) {
            dataTableData.setData(data);
        },
        changeDefaultSorting(data) {
            dataTableData.changeDefaultSorting(data);
        },
    }));

    useEffect(() => {
        const data = dataTableData.data;
        setPagingPageSize(data.pageSize);
        setPagingPageNumber(data.pageNumber);
        setSorting(data.sortingDirection, data.sortingName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTableData.data]);

    const dynamicProps = isSortControlled
        ? {
              handleColumnSorting(column) {
                  resetSelectedRows();
                  const sortConfig = {
                      sortingName: column?.dataKey,
                      sortingDirection:
                          column?.dataKey === dataTableData.data.sortingName
                              ? dataTableData.data.sortingDirection === SortDirectionTypes.ASC
                                  ? null
                                  : SortDirectionTypes.ASC
                              : SortDirectionTypes.DESC,
                  };
                  dataTableData.setData(sortConfig);
              },
          }
        : {};
    return (
        <div
            className={conditionalClassNames(
                `crm-common-table crm-data-table c-table-styles crm-data-table-header-action-bar ${classNames}`,
                {
                    'crm-data-table-hide-header-left-actions-bar': !withSearch,
                    'crm-data-table-hide-expand-icon': !isRowExpandable,
                    'crm-data-table-hide-header-footer': isVisibleOnlyDataTable,
                    'crm-data-table-hide-footer': !withPageSelector && !withPagination,
                },
            )}
        >
            <ComboTable
                name={name}
                rowKey={rowKey}
                columnKey={columnKey}
                sortableColumns={isColumnsSortable}
                resizableColumns={isColumnsResizable}
                draggableColumns={isColumnsDraggable}
                columns={getColumns()}
                onSortChange={onSortingChange}
                rows={getRows()}
                rowsCount={dataCount}
                rowActionBar={rowActionBar}
                getExpandIconDisableState={getExpandIconDisableState}
                withPagination={withPagination}
                withPageSelector={withPageSelector}
                selectorData={pagingOptions}
                {...backendPagingProps}
                withSearch={withSearch}
                hideSearchDropdown={hideSearchDropdown}
                loading={isLoading || !isDataTableReady}
                titleActions={getTitleActions()}
                onRefreshClick={onRefreshClick}
                renderRowNestedChildren={getRowNestedContent}
                rowToggleHandler={rowToggleHandler}
                headerActions={getCombinedHeaderActions()}
                getDragAcceptType={getDragAcceptType}
                CustomPreview={CustomPreview}
                defaultSortedColumn={dataTableData.data.sortingName}
                defaultSortType={dataTableData.data.sortingDirection}
                sortedColumn={dataTableData.data.sortingName}
                sortType={dataTableData.data.sortingDirection}
                footerValues={footerValues}
                {...dynamicProps}
            />

            {isShowColumnChooser && (
                <ColumnsChooser
                    tableName={tableKey}
                    modalOpenedState={isModalOpened}
                    onSave={onSaveColumns}
                    setModalOpenedState={setModalOpenedState}
                    possibleColumns={getColumns(true)}
                />
            )}

            {labelsModalView && isVisibleLabelsModal && (
                <LabelsModal
                    view={labelsModalView}
                    isVisible={isVisibleLabelsModal}
                    objectType={labelObjectType}
                    handleClose={labelsModalCloseHandler}
                    objectId={labelObjectId}
                    selectedLabelId={selectedLabelId}
                />
            )}
            {withOverlay && (
                <div className={`overlay-content-wrap ${isVisibleOverlay ? 'overlay-clear' : 'overlay-left'}`}>
                    <Button
                        icon={isVisibleOverlay ? 'bc-icon-clear' : 'bc-icon-arrow-left'}
                        appearance="minimal"
                        color="primary"
                        itemsDirection="start"
                        onClick={clickOverlayHandler}
                        className="overlay-button"
                    />
                    <div>{overlayContent}</div>
                </div>
            )}

            {isVisibleSelectedModal && (
                <SelectedItemsModal
                    rows={multiSelectedRows}
                    columns={getColumns(false, [...columns], true)}
                    handleClose={() => setIsVisibleSelectedModal(false)}
                    handleSave={setMultiSelectedRows}
                    isVisible={isVisibleSelectedModal}
                    rowKey={rowKey}
                    columnKey={columnKey}
                />
            )}

            {isVisibleDeleteLabelModal && (
                <ConfirmationModal
                    onOk={() => deleteObjectTag(deleteLabelObjectId, labelObjectType)}
                    onCancel={() => setIsVisibleDeleteLabelModal(false)}
                    isVisibleModal={isVisibleDeleteLabelModal}
                    titleText={t(l.RemoveLabel)}
                    questionLabel={t(l.AreYouSureWantToRemoveLabel, {
                        labelName: `"${deleteLabelName}"`,
                        title: `"${deleteLabelTitle}"`,
                        listName: `${name.props.children}`,
                    })}
                    isLoading={isDeleteLabelBtnLoading}
                    iconType={ActionsIcons.Delete}
                    actionLabel={t(l.Delete)}
                    className={ModalsClassNames.Delete}
                />
            )}
        </div>
    );
});

const withManageLabelsPropCheck = (props, propName) => {
    if (props['withManageLabels'] === true && props[propName] === undefined) {
        return new Error(`Please provide a ${propName} Id!`);
    }
};

DataTable.defaultProps = {
    tableKey: 'DataTable',
    isColumnsSortable: false,
    isColumnsResizable: false,
    isColumnsDraggable: false,
    withSearch: false,
    hideSearchDropdown: true,
    withManageLabels: false,
    withPagination: true,
    withPageSelector: true,
    withOverlay: false,
    classNames: 'default-height',
    isShowRefreshButton: false,
    isShowVerticalDots: false,
    isShowColumnChooser: false,
    verticalDotsContentList: [],
    TitleActions: <></>,
    verticalDotsFn: noop,
    onRefreshClick: noop,
    setPagingPageSize: noop,
    setPagingPageNumber: noop,
    recevedCurrentPageNumber: 1,
    recevedCurrentPageSize: getDefaultPagingSize(DataTablePagingOptions),
    sorting: null,
    setSorting: noop,
    isRowExpandable: false,
    isVisibleOnlyDataTable: false,
    rowNestedContent: noop,
    getRowNestedContent: noop,
    rowToggleHandler: noop,
    headerActions: React.node,
    overlayContent: React.node,
    rowActionBar: null,
    isHasRowMultiSelect: false,
    isHasRowRadioSelect: false,
    isHasBulkActions: false,
    isHasArchiveActions: true,
    isHasUnarchiveActions: true,
    archiveSelectedRows: noop,
    unarchiveSelectedRows: noop,
    isHasDeleteAction: true,
    deleteSelectedRows: noop,
    getSelectedRowsList: noop,
    getRadioSelectedRow: noop,
    defaultRadioSelectedRow: null,
    isBackendPagination: true,
    defaultMultiSelectedRows: [],
    getDragAcceptType: noop,
    withQueryParams: false,
    footerValues: React.node,
    isSortControlled: false,
    pagingOptions: DataTablePagingOptions,
};

DataTable.propTypes = {
    rowKey: PropTypes.string.isRequired,
    columnKey: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    dataCount: PropTypes.number.isRequired,
    rowActionBar: PropTypes.func,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            dataKey: PropTypes.string,
            sortable: PropTypes.bool,
            colRenderer: PropTypes.element,
            permissions: PropTypes.arrayOf(PropTypes.string),
            mandatoryPermissions: PropTypes.arrayOf(PropTypes.string),
        }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
    tableKey: PropTypes.string,
    name: PropTypes.node,
    setPagingPageSize: PropTypes.func,
    setPagingPageNumber: PropTypes.func,
    recevedCurrentPageNumber: PropTypes.number,
    recevedCurrentPageSize: PropTypes.number,
    sorting: PropTypes.object,
    setSorting: PropTypes.func,
    isColumnsSortable: PropTypes.bool,
    isColumnsResizable: PropTypes.bool,
    isColumnsDraggable: PropTypes.bool,
    withSearch: PropTypes.bool,
    hideSearchDropdown: PropTypes.bool,
    withManageLabels: PropTypes.bool,
    setLabelObjectIdKey: withManageLabelsPropCheck,
    labelNameKey: withManageLabelsPropCheck,
    labelColorKey: withManageLabelsPropCheck,
    labelObjectType: withManageLabelsPropCheck,
    withPagination: PropTypes.bool,
    withPageSelector: PropTypes.bool,
    withOverlay: PropTypes.bool,
    isShowRefreshButton: PropTypes.bool,
    isShowVerticalDots: PropTypes.bool,
    verticalDotsContentList: PropTypes.array,
    verticalDotsFn: PropTypes.func,
    isShowColumnChooser: PropTypes.bool,
    onRefreshClick: PropTypes.func,
    classNames: PropTypes.string,
    isRowExpandable: PropTypes.bool,
    isVisibleOnlyDataTable: PropTypes.bool,
    rowNestedContent: PropTypes.func,
    rowToggleHandler: PropTypes.func,
    getRowNestedContent: PropTypes.func,
    headerActions: PropTypes.node,
    overlayContent: PropTypes.node,
    onDoubleClick: PropTypes.func,
    isHasRowMultiSelect: PropTypes.bool,
    isHasRowRadioSelect: PropTypes.bool,
    isHasBulkActions: PropTypes.bool,
    isHasArchiveActions: PropTypes.bool,
    isHasUnarchiveActions: PropTypes.bool,
    archiveSelectedRows: PropTypes.func,
    unarchiveSelectedRows: PropTypes.func,
    isHasDeleteAction: PropTypes.bool,
    deleteSelectedRows: PropTypes.func,
    getSelectedRowsList: PropTypes.func,
    getRadioSelectedRow: PropTypes.func,
    defaultRadioSelectedRow: PropTypes.object,
    isBackendPagination: PropTypes.bool,
    defaultMultiSelectedRows: PropTypes.array,
    getDragAcceptType: PropTypes.func,
    CustomPreview: PropTypes.node,
    TitleActions: PropTypes.node,
    withQueryParams: PropTypes.bool,
    getTitle: PropTypes.func,
    footerValues: PropTypes.node,
    isSortControlled: PropTypes.bool,
    pagingOptions: PropTypes.array,
    customBulkActions: PropTypes.arrayOf(PropTypes.object),
};

export default memo(DataTable);
