import { CRMRequestService, getCancelableRequest, postCancelableRequest, deleteCancelableRequest } from './http.config';
import { ReportTypesLabels } from 'constants/common';

const ReportHttpService = {
    getReportList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/List',
        });
    },
    deleteReport: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ reportId, reportType }) => `/Report/Delete?ReportId=${reportId}&ReportType=${reportType}`,
        });
    },
    deleteReports: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/Delete',
        });
    },
    getReportById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false, reportType: ReportTypesLabels.Report },
            endpointPath: ({ reportId, isClone, reportType }) =>
                `/Report/Get?id=${reportId}&isClone=${isClone}&type=${reportType}`,
        });
    },
    saveReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/AdHocReport/Create`,
        });
    },

    scheduleReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ReportSchedule/Create',
        });
    },
    stopReportSchedule: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ReportSchedule/DeleteSchedule',
        });
    },
    getReportSchedule: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { type: 0 },
            endpointPath: ({ reportId, type }) => `/ReportSchedule/Get?id=${reportId}&type=${type}`,
        });
    },
    editReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ reportId }) => {
                return `/AdHocReport/Update?id=${reportId}`;
            },
        });
    },
    getReportConfigurationColumns: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Configuration/Columns',
        });
    },
    executeReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/Execute',
        });
    },
    getReportsByReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/ReportsByReport',
        });
    },
    getCompositeReportCount: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/CompositeReportCount',
        });
    },
    getCompositeReportPreview: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/CompositePreviewReportResult',
        });
    },
    saveCompositeReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/CompositeReport/Create',
        });
    },
    editCompositeReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/CompositeReport/Update',
        });
    },
    saveCustomerJourneyReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/WorkflowReport/Create',
        });
    },
    editCustomerJourneyReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/WorkflowReport/Update',
        });
    },
    archiveReports: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/Archive',
        });
    },
    unarchiveReports: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Report/Unarchive',
        });
    },
};

export default ReportHttpService;
