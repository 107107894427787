import React, { memo, useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isObject, isArray, noop, isEmpty, isNil, values } from 'lodash';
import { useLocation } from 'react-router-dom';
// Import UI Components
import { DataTable, TemplateActionsModals, TemplateActionsButtons } from 'components';
// Import Hooks
import { useListBaseRequestBody, useGoToRoute, useToaster, useRequest, useQueryParams } from 'hooks';
// Import Services
import { TemplateHttpService, UtilsHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Configs
import { DataTableColumnsConfigs } from './configs';
// Import Constants
import {
    ModalsActions,
    StateModes,
    TargetTypeEnum,
    l,
    TemplateTypesLabels,
    AlertTypes,
    FilterComparisions,
} from 'constants/common';
import { RoutesList } from 'routes';
// Import SCSS
import 'assets/scss/templateListPage.scss';
const { warning } = AlertTypes;

const {
    TEMPLATE_CLONE_EMAIL,
    TEMPLATE_CLONE_SMS,
    TEMPLATE_CLONE_HOORY,
    TEMPLATE_CLONE_FASTEX_NOTIFICATION,
    TEMPLATE_CLONE_INTERNAL_MESSAGE,
    TEMPLATE_CLONE_SOCIAL_TELEGRAM,
    TEMPLATE_CLONE_PUSH_NOTIFICATION,
    TEMPLATE_CLONE_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_DYNAMIC_POP_UP,
    TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_EDIT_EMAIL,
    TEMPLATE_EDIT_SMS,
    TEMPLATE_EDIT_HOORY,
    TEMPLATE_EDIT_FASTEX_NOTIFICATION,
    TEMPLATE_EDIT_INTERNAL_MESSAGE,
    TEMPLATE_EDIT_SOCIAL_TELEGRAM,
    TEMPLATE_EDIT_PUSH_NOTIFICATION,
    TEMPLATE_EDIT_POP_UP_NOTIFICATION,
    TEMPLATE_EDIT_DYNAMIC_POP_UP,
    TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_SOCIAL_VIBER,
    TEMPLATE_EDIT_SOCIAL_VIBER,
} = RoutesList;

const { getPageNumber } = Helpers;

// eslint-disable-next-line react/display-name
const TemplateList = forwardRef(
    (
        {
            getTitle,
            isViewMode,
            getHeaderActions,
            isShowColumnChooser,
            withManageLabels,
            isHasRowMultiSelect,
            isHasBulkActions,
            listAction,
            listSelectorKey,
            isHasRowRadioSelect,
            getRadioSelectedRow,
            defaultRadioSelectedRow,
            tableKey,
            withQueryParams,
            dataTableClassNames,
        },
        ref,
    ) => {
        const dispatch = useDispatch();

        const { t } = useTranslation();
        const { goToRoute } = useGoToRoute();
        const { search } = useLocation();
        const { showToaster } = useToaster();
        const { doPostRequest, doGetRequest } = useRequest();

        const { tableData } = useSelector((state) => state[listSelectorKey]);
        const { filters, paging, sorting, sortingThen } = tableData;
        const isInit = useRef(false);
        const dataTableRef = useRef();

        const [
            baseRequestBody,
            // eslint-disable-next-line no-unused-vars
            newFilterField,
            setBaseRequestBodyFilters,
            setBaseRequestBodyPagingPageNumber,
            setBaseRequestBodyPagingPageSize,
            setBaseRequestBodySorting,
        ] = useListBaseRequestBody(filters, paging, sorting, sortingThen);

        const {
            // eslint-disable-next-line no-unused-vars
            setTemplateListTableFilters,
            setTemplateListTablePagingPageNumber,
            setTemplateListTablePagingPageSize,
            setTemplateListTableSorting,
            resetTemplateListState,
        } = listAction;

        const [isTemplateListLoading, setIsTemplateListLoading] = useState(false);
        const [templateListFilters, setTemplateListFilters] = useState(baseRequestBody);
        const [templateListData, setTemplateListData] = useState([]);
        const [templateListDataCount, setTemplateListDataCount] = useState(0);

        const [isDeleteModalOpenedState, setIsDeleteModalOpenedState] = useState(false);
        const [isTestTemplateModalOpenedState, setIsTestTemplateModalOpenedState] = useState(false);
        const [isArchiveModalOpenedState, setIsArchiveModalOpenedState] = useState(false);
        const [isUnArchiveModalOpenedState, setIsUnArchiveModalOpenedState] = useState(false);
        const [isInfoModalOpenedState, setIsInfoModalOpenedState] = useState(false);
        const [isUsedInConfirmationModalOpenedState, setIsUsedInConfirmationModalOpenedState] = useState(false);
        const [openedModalData, setOpenedModalData] = useState({});
        const [providers, setProviders] = useState({});

        // Template fetch part
        const { getTemplatesRequest, getTagsListRequest, getCampaignsByTemplateRequest, getMessageProvidersRequest } =
            useRef({
                getTemplatesRequest: TemplateHttpService.getTemplateList(),
                getTagsListRequest: UtilsHttpService.getTagsList(),
                getCampaignsByTemplateRequest: TemplateHttpService.getCampaignsByTemplate(),
                getMessageProvidersRequest: UtilsHttpService.getPartnerMessageProviders(),
            }).current;

        const goToRouteQueryParams = useQueryParams();

        const getTemplates = useCallback(
            (filter = templateListFilters) => {
                // when the campaignExecutor supports Hoory, DynamicPopUp Template, this block must be deleted
                if (listSelectorKey === 'campaignPageTemplateList') {
                    const deliveryMethodField = filter.Filters.find((elem) => elem.Name === 'DeliveryMethod');
                    const notSupportedDeliveryMethods = [TemplateTypesLabels.Hoory, TemplateTypesLabels.DynamicPopUp];

                    if (isNil(deliveryMethodField)) {
                        filter.Filters.push({
                            Name: 'DeliveryMethod',
                            Comparision: FilterComparisions.NotIn,
                            Values: notSupportedDeliveryMethods,
                        });
                    } else {
                        deliveryMethodField.Values = deliveryMethodField.Values.filter(
                            (value) => !notSupportedDeliveryMethods.includes(value),
                        );
                    }

                    if (!isNil(deliveryMethodField) && isEmpty(deliveryMethodField.Values)) {
                        deliveryMethodField.Comparision = FilterComparisions.NotIn;
                        deliveryMethodField.Values = notSupportedDeliveryMethods;
                    }
                }

                setIsTemplateListLoading(true);
                doPostRequest(getTemplatesRequest.request, {
                    requestBody: filter,
                    successCallback: (Data) => {
                        setTemplateListData((isObject(Data) && getTableRows(Data.Data)) || []);
                        setTemplateListDataCount(isNil(Data?.Count) ? 0 : Data.Count);
                    },
                }).then(() => {
                    setIsTemplateListLoading(false);
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [templateListFilters],
        );

        const getProviders = (channel) => {
            doPostRequest(getMessageProvidersRequest.request, {
                requestBody: { DeliveryMethod: channel },
                successCallback: (Data) => {
                    setProviders((prev) => {
                        prev[channel] = (isNil(Data) ? [] : Data).reduce((acc, item) => {
                            acc[item.Id] = { label: item.Name, value: item.Id };
                            return acc;
                        }, {});
                        return { ...prev };
                    });
                },
            });
        };

        const goToEditTemplate = (template) => {
            const { DeliveryMethod, TemplateId } = template;
            const params = { templateId: TemplateId };
            let path;

            switch (DeliveryMethod) {
                case TemplateTypesLabels.Email:
                    path = TEMPLATE_EDIT_EMAIL;
                    break;
                case TemplateTypesLabels.SMS:
                    path = TEMPLATE_EDIT_SMS;
                    break;
                case TemplateTypesLabels.Hoory:
                    path = TEMPLATE_EDIT_HOORY;
                    break;
                case TemplateTypesLabels.FastexNotification:
                    path = TEMPLATE_EDIT_FASTEX_NOTIFICATION;
                    break;
                case TemplateTypesLabels.Internal:
                    path = TEMPLATE_EDIT_INTERNAL_MESSAGE;
                    break;
                case TemplateTypesLabels.Telegram:
                    path = TEMPLATE_EDIT_SOCIAL_TELEGRAM;
                    break;
                case TemplateTypesLabels.PushNotification:
                    path = TEMPLATE_EDIT_PUSH_NOTIFICATION;
                    break;
                case TemplateTypesLabels.PopUp:
                    path = TEMPLATE_EDIT_POP_UP_NOTIFICATION;
                    break;
                case TemplateTypesLabels.DynamicPopUp:
                    path = TEMPLATE_EDIT_DYNAMIC_POP_UP;
                    break;
                case TemplateTypesLabels.CMSPopUp:
                    path = TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION;
                    break;
                case TemplateTypesLabels.Viber:
                    path = TEMPLATE_EDIT_SOCIAL_VIBER;
                    break;
                default:
            }

            goToRoute(path, params, goToRouteQueryParams.encode({ backParams: search }));
        };

        // Actions part
        const deleteTemplateAction = (template) => {
            setOpenedModalData(template);
            setIsDeleteModalOpenedState(true);
        };

        const testTemplateAction = (template) => {
            if (
                (template.DeliveryMethod === TemplateTypesLabels.Email ||
                    template.DeliveryMethod === TemplateTypesLabels.SMS) &&
                isEmpty(providers[template.DeliveryMethod])
            ) {
                showToaster(warning, t(l.ProviderIsNotIntegrated));
            } else {
                setOpenedModalData(template);
                setIsTestTemplateModalOpenedState(true);
            }
        };

        const archiveTemplateAction = (template) => {
            setOpenedModalData(template);
            setIsArchiveModalOpenedState(true);
        };

        const unarchiveTemplateAction = (template) => {
            setOpenedModalData(template);
            setIsUnArchiveModalOpenedState(true);
        };

        const infoTemplateAction = (template) => {
            setOpenedModalData(template);
            setIsInfoModalOpenedState(true);
        };

        const editTemplateAction = (template) => {
            doGetRequest(getCampaignsByTemplateRequest.request, {
                queryString: { templateId: template.TemplateId },
                successCallback: (Data) => {
                    if (isEmpty(Data)) {
                        goToEditTemplate(template);
                    } else {
                        setOpenedModalData({ objects: Data.map(({ Name, Type }) => ({ Name, Type })), template });
                        setIsUsedInConfirmationModalOpenedState(true);
                    }
                },
            });
        };

        const cloneTemplateAction = (template) => {
            const { DeliveryMethod, TemplateId } = template;
            const params = { templateId: TemplateId };

            switch (DeliveryMethod) {
                case TemplateTypesLabels.Email:
                    goToRoute(TEMPLATE_CLONE_EMAIL, params);
                    break;
                case TemplateTypesLabels.SMS:
                    goToRoute(TEMPLATE_CLONE_SMS, params);
                    break;
                case TemplateTypesLabels.Hoory:
                    goToRoute(TEMPLATE_CLONE_HOORY, params);
                    break;
                case TemplateTypesLabels.FastexNotification:
                    goToRoute(TEMPLATE_CLONE_FASTEX_NOTIFICATION, params);
                    break;
                case TemplateTypesLabels.Internal:
                    goToRoute(TEMPLATE_CLONE_INTERNAL_MESSAGE, params);
                    break;
                case TemplateTypesLabels.Telegram:
                    goToRoute(TEMPLATE_CLONE_SOCIAL_TELEGRAM, params);
                    break;
                case TemplateTypesLabels.PushNotification:
                    goToRoute(TEMPLATE_CLONE_PUSH_NOTIFICATION, params);
                    break;
                case TemplateTypesLabels.PopUp:
                    goToRoute(TEMPLATE_CLONE_POP_UP_NOTIFICATION, params);
                    break;
                case TemplateTypesLabels.DynamicPopUp:
                    goToRoute(TEMPLATE_CLONE_DYNAMIC_POP_UP, params);
                    break;
                case TemplateTypesLabels.CMSPopUp:
                    goToRoute(TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION, params);
                    break;
                case TemplateTypesLabels.Viber:
                    goToRoute(TEMPLATE_CLONE_SOCIAL_VIBER, params);
                    break;
                default:
            }
        };

        const { columns } = DataTableColumnsConfigs(t, cloneTemplateAction);

        const modalsStateSetDict = {
            deleteTemplate: {
                key: 'deleteTemplate',
                fn: setIsDeleteModalOpenedState,
            },
            testTemplate: {
                key: 'testTemplate',
                fn: setIsTestTemplateModalOpenedState,
            },
            archiveTemplate: {
                key: 'archiveTemplate',
                fn: setIsArchiveModalOpenedState,
            },
            unarchiveTemplate: {
                key: 'unarchiveTemplate',
                fn: setIsUnArchiveModalOpenedState,
            },
            infoTemplate: {
                key: 'infoTemplate',
                fn: setIsInfoModalOpenedState,
            },
            editTemplate: {
                key: 'editTemplate',
                fn: setIsUsedInConfirmationModalOpenedState,
            },
        };

        const onCloseModalHandler = (modalStateKey, action, alertType, alertMessage, count = 1) => {
            const isTestTemplate = action === ModalsActions.TEST_TEMPLATE;

            if (
                action === ModalsActions.DELETE ||
                isTestTemplate ||
                action === ModalsActions.ARCHIVE ||
                action === ModalsActions.UNARCHIVE
            ) {
                if (!isTestTemplate) {
                    dataTableRef.current.resetSelectedRows();
                    dataTableRef.current.changePaginationPageNumber(
                        getPageNumber(
                            baseRequestBody.Filters,
                            action,
                            templateListDataCount,
                            templateListFilters.Pageing.PageSize,
                            templateListFilters.Pageing.PageNumber,
                            count,
                        ),
                    );
                }
                showToaster(alertType, alertMessage);
            }

            modalsStateSetDict[modalStateKey].fn(false);
        };

        const getRowActionBar = (row) => {
            const { data } = row;

            return (
                <>
                    <TemplateActionsButtons
                        data={data}
                        isInfoVisible={true}
                        isViewMode={isViewMode}
                        testTemplateAction={testTemplateAction}
                        unarchiveTemplateAction={unarchiveTemplateAction}
                        archiveTemplateAction={archiveTemplateAction}
                        deleteTemplateAction={deleteTemplateAction}
                        cloneTemplateAction={cloneTemplateAction}
                        editTemplateAction={editTemplateAction}
                        infoTemplateAction={infoTemplateAction}
                    />
                </>
            );
        };

        // Data table part
        const setPagingPageNumberHandler = (pageNumber) => {
            setBaseRequestBodyPagingPageNumber(pageNumber);
            dispatch(setTemplateListTablePagingPageNumber(pageNumber));
            setTemplateListFilters(baseRequestBody);
        };

        const setPagingPageSizeHandler = (pageSize) => {
            setBaseRequestBodyPagingPageSize(pageSize);
            dispatch(setTemplateListTablePagingPageSize(pageSize));
            setTemplateListFilters(baseRequestBody);
        };

        const setSortingHandler = (sortingDirection, columnName) => {
            setBaseRequestBodySorting(sortingDirection, columnName);
            dispatch(setTemplateListTableSorting(baseRequestBody.Sorting));
            setTemplateListFilters(baseRequestBody);
        };

        const getTableColumns = () => {
            return columns;
        };

        const getTableRows = (templateListData) => {
            return templateListData.map(
                ({
                    Name,
                    IsUsed,
                    CreatorName,
                    CreatedDate,
                    State,
                    TemplateId,
                    DeliveryMethod,
                    Language,
                    ModifiedDate,
                    Status,
                    CategoryId,
                    CategoryName,
                    CategoryColor,
                    ChildItemsInfo,
                    ArchivedDate,
                }) => {
                    return {
                        hasHover: true,
                        dragDisable: true,
                        nestedData: [],
                        className: !isNil(ArchivedDate) ? 'crm-data-table-row-archive' : '',
                        data: {
                            Name,
                            IsUsed,
                            'CreatedBy.Name': CreatorName,
                            CreatedDate,
                            State,
                            TemplateId,
                            DeliveryMethod,
                            Language,
                            ModifiedDate,
                            Status,
                            CategoryId,
                            CategoryName,
                            CategoryColor,
                            ChildItemsInfo,
                            ArchivedDate,
                        },
                    };
                },
            );
        };

        const getTableRefreshFn = () => {
            getTemplates(templateListFilters);
        };

        const getDataTableName = () => <span>{t(l.Templates)}</span>;

        // Filter update part
        useEffect(() => {
            if (isInit.current) {
                getTemplates(templateListFilters);
            }
            /* eslint-disable react-hooks/exhaustive-deps */
        }, [templateListFilters]);

        useEffect(() => {
            setBaseRequestBodyFilters(filters);
            setTemplateListFilters(baseRequestBody);

            isInit.current = true;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filters]);

        useImperativeHandle(ref, () => ({
            resetSelectedRows() {
                dataTableRef.current.resetSelectedRows();
            },
            reset() {
                dataTableRef.current.reset();
            },
        }));

        const cleanUp = () => {
            return () => {
                getTemplatesRequest.cancel('TemplateList:getTemplatesRequest');
                getTagsListRequest.cancel('TemplateList:getTagsListRequest');
                getMessageProvidersRequest.cancel('TemplateList:getMessageProvidersRequest');
                getCampaignsByTemplateRequest.cancel('TemplateList:getCampaignsByTemplateRequest');
                dispatch(resetTemplateListState());
            };
        };

        useEffect(cleanUp, []);

        const init = () => {
            getProviders(TemplateTypesLabels.SMS);
            getProviders(TemplateTypesLabels.Email);
        };

        useEffect(init, []);

        const isAllow = (template) => {
            const { IsUsed, ArchivedDate } = template;
            const isArchive = !isNil(ArchivedDate);

            return {
                delete: !IsUsed,
                archive: !isArchive,
                unarchive: isArchive,
            };
        };

        const getTemplatesNamesIds = (selectedRows) => {
            if (!isArray(selectedRows)) return;

            let allowDeleteTemplates = [];
            let notAllowDeleteTemplates = [];
            let allowArchiveTemplates = [];
            let notAllowArchiveTemplates = [];
            let allowUnarchiveTemplates = [];
            let notAllowUnarchiveTemplates = [];
            selectedRows.forEach(({ data }) => {
                const { TemplateId, Name } = data;
                const isAllowData = isAllow(data);
                if (isAllowData.delete) {
                    allowDeleteTemplates.push({ templateId: TemplateId, name: Name });
                } else {
                    notAllowDeleteTemplates.push({ templateId: TemplateId, name: Name });
                }
                if (isAllowData.archive) {
                    allowArchiveTemplates.push({ templateId: TemplateId, name: Name });
                } else {
                    notAllowArchiveTemplates.push({ templateId: TemplateId, name: Name });
                }

                if (isAllowData.unarchive) {
                    allowUnarchiveTemplates.push({ templateId: TemplateId, name: Name });
                } else {
                    notAllowUnarchiveTemplates.push({ templateId: TemplateId, name: Name });
                }
            });

            return {
                allowDeleteTemplates,
                notAllowDeleteTemplates,
                allowArchiveTemplates,
                notAllowArchiveTemplates,
                allowUnarchiveTemplates,
                notAllowUnarchiveTemplates,
            };
        };

        const deleteSelectedRows = (selectedRows) => {
            const templatesNamesIds = getTemplatesNamesIds(selectedRows);
            const namesIds = {
                allowDeleteTemplates: templatesNamesIds.allowDeleteTemplates,
                notAllowDeleteTemplates: templatesNamesIds.notAllowDeleteTemplates,
            };

            deleteTemplateAction(namesIds);
        };

        const archiveSelectedRows = (selectedRows) => {
            const templatesNamesIds = getTemplatesNamesIds(selectedRows);
            const namesIds = {
                allowArchiveTemplates: templatesNamesIds.allowArchiveTemplates,
                notAllowArchiveTemplates: templatesNamesIds.notAllowArchiveTemplates,
            };
            archiveTemplateAction(namesIds);
        };

        const unarchiveSelectedRows = (selectedRows) => {
            const templatesNamesIds = getTemplatesNamesIds(selectedRows);
            const namesIds = {
                allowUnarchiveTemplates: templatesNamesIds.allowUnarchiveTemplates,
                notAllowUnarchiveTemplates: templatesNamesIds.notAllowUnarchiveTemplates,
            };
            unarchiveTemplateAction(namesIds);
        };

        return (
            <>
                <DataTable
                    classNames={dataTableClassNames}
                    getTitle={getTitle}
                    rowKey="TemplateId"
                    columnKey="dataKey"
                    name={getDataTableName()}
                    tableKey={tableKey}
                    isColumnsSortable={true}
                    withPagination={true}
                    data={templateListData}
                    dataCount={templateListDataCount}
                    rowActionBar={getRowActionBar}
                    withManageLabels={withManageLabels}
                    setLabelObjectIdKey={'TemplateId'}
                    labelNameKey={'CategoryName'}
                    labelColorKey={'CategoryColor'}
                    labelObjectType={TargetTypeEnum.Template}
                    columns={getTableColumns()}
                    isLoading={isTemplateListLoading}
                    isShowRefreshButton={true}
                    isShowColumnChooser={isShowColumnChooser}
                    isShowVerticalDots={false}
                    verticalDotsContentList={StateModes}
                    setPagingPageSize={setPagingPageSizeHandler}
                    setPagingPageNumber={setPagingPageNumberHandler}
                    sorting={{
                        name: templateListFilters.Sorting?.Name,
                        direction: templateListFilters.Sorting?.Direction,
                    }}
                    setSorting={setSortingHandler}
                    onRefreshClick={getTableRefreshFn}
                    headerActions={getHeaderActions()}
                    isHasRowMultiSelect={isHasRowMultiSelect}
                    isHasBulkActions={isHasBulkActions}
                    isHasRowRadioSelect={isHasRowRadioSelect}
                    getRadioSelectedRow={getRadioSelectedRow}
                    archiveSelectedRows={archiveSelectedRows}
                    unarchiveSelectedRows={unarchiveSelectedRows}
                    deleteSelectedRows={deleteSelectedRows}
                    defaultRadioSelectedRow={defaultRadioSelectedRow}
                    recevedCurrentPageNumber={templateListFilters.Pageing.PageNumber}
                    withQueryParams={withQueryParams}
                    ref={dataTableRef}
                />

                <TemplateActionsModals
                    isDeleteModalOpenedState={isDeleteModalOpenedState}
                    isArchiveModalOpenedState={isArchiveModalOpenedState}
                    isUnArchiveModalOpenedState={isUnArchiveModalOpenedState}
                    isInfoModalOpenedState={isInfoModalOpenedState}
                    isUsedInConfirmationModalOpenedState={isUsedInConfirmationModalOpenedState}
                    isTestTemplateModalOpenedState={isTestTemplateModalOpenedState}
                    onCloseModalHandler={onCloseModalHandler}
                    modalsStateSetDict={modalsStateSetDict}
                    openedModalData={openedModalData}
                    goToEditTemplate={goToEditTemplate}
                    providers={values(providers[openedModalData.DeliveryMethod])}
                />
            </>
        );
    },
);

TemplateList.propTypes = {
    listSelectorKey: PropTypes.string.isRequired,
    listAction: PropTypes.shape({
        setTemplateListTableFilters: PropTypes.func.isRequired,
        setTemplateListTablePagingPageNumber: PropTypes.func.isRequired,
        setTemplateListTablePagingPageSize: PropTypes.func.isRequired,
        setTemplateListTableSorting: PropTypes.func.isRequired,
        resetTemplateListState: PropTypes.func.isRequired,
    }).isRequired,
    getRadioSelectedRow: PropTypes.func,
    isShowVerticalDots: PropTypes.bool,
    withManageLabels: PropTypes.bool,
    isViewMode: PropTypes.bool,
    getHeaderActions: PropTypes.func,
    isHasRowMultiSelect: PropTypes.bool,
    isHasRowRadioSelect: PropTypes.bool,
    isHasBulkActions: PropTypes.bool,
    isShowColumnChooser: PropTypes.bool,
    resetDataTableSelectedRowsHash: PropTypes.string,
    setResetDataTableSelectedRowsHash: PropTypes.func,
    defaultRadioSelectedRow: PropTypes.object,
    tableKey: PropTypes.string,
    withQueryParams: PropTypes.bool,
    dataTableClassNames: PropTypes.string,
    getTitle: PropTypes.func,
};

TemplateList.defaultProps = {
    getHeaderActions: noop,
    getRadioSelectedRow: noop,
    isShowVerticalDots: true,
    withManageLabels: false,
    isViewMode: false,
    isHasRowMultiSelect: false,
    isHasRowRadioSelect: false,
    isHasBulkActions: false,
    isShowColumnChooser: true,
    resetDataTableSelectedRowsHash: null,
    setResetDataTableSelectedRowsHash: noop,
    defaultRadioSelectedRow: null,
    tableKey: 'templateListDataTable',
    withQueryParams: false,
    dataTableClassNames: 'default-height',
    getTitle: noop,
};

export default memo(TemplateList);
