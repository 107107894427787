import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
import { AccessControl } from 'components';
// Import  Constants
import { ReportTypesLabels, ReportStateLabels, l, PERMISSIONS, ActionsIcons } from 'constants/common';

const ReportActionsButtons = ({
    data,
    getReportVersionListAction,
    scheduleReportAction,
    deleteReportAction,
    cloneReportAction,
    editReportAction,
    infoReportAction,
    stopScheduleReportAction,
    executeReportAction,
    archiveReportAction,
    unarchiveReportAction,
    isViewMode,
}) => {
    const { t } = useTranslation();

    const { State, IsUsed, ReportType, SelfExecutionCount, CompositeExecutionCount, ArchivedDate } = data;

    const IsAdHoc = ReportType === ReportTypesLabels.Report;
    const isArchive = !isNil(ArchivedDate);

    const IsScheduled = State === ReportStateLabels.Scheduled;
    const isExecuted = SelfExecutionCount + CompositeExecutionCount > 0;
    const isCompositeReport = ReportType === ReportTypesLabels.CompositeReport;
    const isCustomerJourneyReport = ReportType === ReportTypesLabels.CustomerJourneyReport;
    const isCustomReport = ReportType === ReportTypesLabels.CustomReport;

    const isVisibleDeleteReport = () =>
        (IsAdHoc || isCompositeReport || isCustomerJourneyReport) && !IsUsed && !isViewMode;
    const isVisibleEditReport = () =>
        (IsAdHoc || isCompositeReport || isCustomerJourneyReport) && !isExecuted && !isViewMode;
    const isVisibleExecutedReports = () => SelfExecutionCount > 0 && !isViewMode;
    const isVisibleStopScheduleReport = () => !isViewMode && IsScheduled;
    const isVisibleReportInfo = () => IsAdHoc || isCompositeReport || isCustomerJourneyReport;
    const isVisibleCloneReport = () => (IsAdHoc || isCompositeReport || isCustomerJourneyReport) && !isViewMode;
    const isVisibleStart = () => !isViewMode;
    const isVisibleSchedule = () => !isViewMode;
    const isVisibleArchive = () => !isArchive && !isCustomReport;
    const isVisibleUnarchive = () => isArchive && !isCustomReport;

    return (
        <>
            {isVisibleDeleteReport() && (
                <AccessControl permissions={PERMISSIONS.DeleteAddHocReport}>
                    <Tooltip text={t(l.DeleteReport)}>
                        <Button
                            icon={ActionsIcons.Delete}
                            appearance="minimal"
                            color="primary"
                            onClick={() => deleteReportAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleEditReport() && (
                <AccessControl permissions={PERMISSIONS.EditAddHocReport}>
                    <Tooltip text={t(l.EditReport)}>
                        <Button
                            icon={ActionsIcons.Edit}
                            appearance="minimal"
                            color="primary"
                            onClick={() => editReportAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleArchive() && (
                <Tooltip text={t(l.ArchiveReport)}>
                    <Button
                        icon={ActionsIcons.Archive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => archiveReportAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleUnarchive() && (
                <Tooltip text={t(l.UnarchiveReport)}>
                    <Button
                        icon={ActionsIcons.UnArchive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => unarchiveReportAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleExecutedReports() && (
                <Tooltip text={t(l.ExecutedReports)}>
                    <Button
                        icon={ActionsIcons.ViewDoc}
                        appearance="minimal"
                        color="primary"
                        onClick={() => getReportVersionListAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleStopScheduleReport() && (
                <Tooltip text={t(l.StopReportSchedule)}>
                    <Button
                        icon={ActionsIcons.Unschedule}
                        appearance="minimal"
                        color="primary"
                        onClick={() => stopScheduleReportAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleSchedule() && (
                <AccessControl permissions={PERMISSIONS.EditAddHocReport}>
                    <Tooltip text={t(l.ScheduleReport)}>
                        <Button
                            icon={ActionsIcons.Schedule}
                            appearance="minimal"
                            color="primary"
                            onClick={() => scheduleReportAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleStart() && (
                <AccessControl
                    permissions={
                        IsAdHoc || isCompositeReport ? PERMISSIONS.ExecuteAddHocReport : PERMISSIONS.ExecuteCustomReport
                    }
                >
                    <Tooltip text={t(l.ExecuteReport)}>
                        <Button
                            icon={ActionsIcons.Start}
                            appearance="minimal"
                            color="primary"
                            onClick={() => executeReportAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleCloneReport() && (
                <AccessControl permissions={PERMISSIONS.CreateAddHocReport}>
                    <Tooltip text={t(l.CloneReport)}>
                        <Button
                            icon={ActionsIcons.Clone}
                            appearance="minimal"
                            color="primary"
                            onClick={() => cloneReportAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleReportInfo() && (
                <Tooltip text={t(l.ReportInfo)}>
                    <Button
                        icon={ActionsIcons.Stop}
                        appearance="minimal"
                        color="primary"
                        onClick={() => infoReportAction(data)}
                    />
                </Tooltip>
            )}
        </>
    );
};

ReportActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    getReportVersionListAction: PropTypes.func,
    scheduleReportAction: PropTypes.func,
    deleteReportAction: PropTypes.func,
    cloneReportAction: PropTypes.func,
    editReportAction: PropTypes.func,
    infoReportAction: PropTypes.func,
    stopScheduleReportAction: PropTypes.func,
    executeReportAction: PropTypes.func,
    archiveReportAction: PropTypes.func,
    unarchiveReportAction: PropTypes.func,
    isViewMode: PropTypes.bool,
};

ReportActionsButtons.defaultProps = {
    getReportVersionListAction: noop,
    scheduleReportAction: noop,
    deleteReportAction: noop,
    cloneReportAction: noop,
    editReportAction: noop,
    infoReportAction: noop,
    stopScheduleReportAction: noop,
    executeReportAction: noop,
    archiveReportAction: noop,
    unarchiveReportAction: noop,
    isViewMode: false,
};

export default memo(ReportActionsButtons);
