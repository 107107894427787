import moment from 'moment';
import * as Yup from 'yup';
import { find, isArray, isEmpty, isNil, keys, noop, last, first, isNaN, trim, values, cloneDeep, uniq } from 'lodash';
import { getIncomers, isNode } from 'react-flow-renderer';
// Import Constants
import {
    l,
    MonthDayYearWithTimeFormat,
    PageTypes,
    MonthDayYearFormat,
    CustomerJourneyErrorCodes,
    CustomerJourneyGroupBlockTypes,
    CampaignStatusLabels,
    CJReferenceInArgumentType,
    CJFormulaInArgumentType,
    CJArgumentValuesTypes,
    excludeRightValueContentVisibilitiesOperators,
    CJDependencyTypes,
    TemplateInputTypes,
    ExpirationTypes,
} from 'constants/common';
import { formsTypes } from 'components/CustomerJourney/config';
import { dynamicDateData } from 'components/CustomerJourney/forms/CJDateTime/config';
// Import Services
import { Helpers } from 'services';
import { getFormContent, getMatchedProperties, getModalCollectedProperties } from 'services/customerJourney';
import { isSchedulerUnitPairsInvalid } from 'services/schedular';
import { htmlStringToDomElement } from 'services/template';
// Import Validation
import {
    CJBonusAmountInputValidationSchema,
    CJPartnerBonusIdValidationSchema,
    CJReferenceOptionValidationSchema,
    CJVisualizationStepTemplateValidationSchema,
    CJVisualizationStepIconValidationSchema,
    CJBlockNameOptionValidationSchema,
    CJTemplateIdValidationSchema,
} from './schemas.validation';

const { customMomentWithoutTimezoneConversion, isOnlyMinus, getDateFormat, calculateTemplateBodyLengthWithRegEx } =
    Helpers;

const messageTypes = {
    BlockIsRequired: 'BlockIsRequired',
    PropertyIsRequired: 'PropertyIsRequired',
    BlockDoesNotExist: 'BlockDoesNotExist',
    BlockPropertyDoesNotExist: 'BlockPropertyDoesNotExist',
    MatchedBlockDoesNotVisible: 'MatchedBlockDoesNotVisible',
    MatchedBlockPropertyDoesNotVisible: 'MatchedBlockPropertyDoesNotVisible',
    BlockCollectedPropertiesNotExist: 'BlockCollectedPropertiesNotExist',
};

const templateSubjectValidationLabels = {
    containKPIVariableWarningMessage: l.SubjectContainKPIDynamicVariable,
    childDoesNotContainVariableWarningMessage: l.ChildSubjectDoesNotContainDynamicVariable,
    defaultDoesNotContainDynamicVariableError: l.DefaultSubjectDoesNotContainDynamicVariable,
    dynamicVariableWithUnknownLengthWarning: l.SubjectContainDynamicVariableWithUnknownLength,
    defaultContainsUnknownDynamicVariableWarning: l.DefaultSubjectContainUnknownDynamicVariable,
};

const templateBodyValidationLabels = {
    containKPIVariableWarningMessage: l.TemplateContainKPIDynamicVariable,
    childDoesNotContainVariableWarningMessage: l.ChildTemplateDoesNotContainDynamicVariable,
    defaultDoesNotContainDynamicVariableError: l.DefaultTemplateDoesNotContainDynamicVariable,
    dynamicVariableWithUnknownLengthWarning: l.TemplateContainDynamicVariableWithUnknownLength,
    defaultContainsUnknownDynamicVariableWarning: l.DefaultTemplateContainUnknownDynamicVariable,
};

const templateCascadeSMSBodyValidationLabels = {
    containKPIVariableWarningMessage: l.TemplateContainKPIDynamicVariableCascadeSMS,
    childDoesNotContainVariableWarningMessage: l.ChildTemplateDoesNotContainDynamicVariableCascadeSMS,
    defaultDoesNotContainDynamicVariableError: l.DefaultTemplateDoesNotContainDynamicVariableCascadeSMS,
    dynamicVariableWithUnknownLengthWarning: l.TemplateContainDynamicVariableWithUnknownLengthCascadeSMS,
    defaultContainsUnknownDynamicVariableWarning: l.DefaultTemplateContainUnknownDynamicVariableCascadeSMS,
};

const validationTypeLabels = {
    [TemplateInputTypes.Body]: templateBodyValidationLabels,
    [TemplateInputTypes.SMSBody]: templateCascadeSMSBodyValidationLabels,
    [TemplateInputTypes.Subject]: templateSubjectValidationLabels,
    [TemplateInputTypes.TitleOfPushNotification]: templateSubjectValidationLabels,
};

Yup.addMethod(
    Yup.object,
    'optionReference',
    function (elements, node, optionType, modelRelationScope, propertySettings, withoutType, message) {
        return this.test('optionReferenceTest', message, function (value) {
            const { path, createError } = this;
            if (!isNil(value)) {
                const { blockId, propertyName } = value;
                if (isNil(blockId)) {
                    return createError({ path, message, type: messageTypes.BlockIsRequired, params: { blockId } });
                }
                if (isNil(propertyName)) {
                    // case is not possible
                    return createError({
                        path,
                        message,
                        type: messageTypes.PropertyIsRequired,
                        params: { propertyName },
                    });
                }
                if (!isNil(blockId) && !isNil(propertyName)) {
                    const block = find(elements, (el) => {
                        return isNode(el) && el.id === blockId;
                    });
                    if (isNil(block?.data?.metaData?.Properties)) {
                        return createError({
                            path,
                            message,
                            type: messageTypes.BlockDoesNotExist,
                            params: { blockId },
                        });
                    } else {
                        let property;
                        if (!isNil(propertySettings?.typeName)) {
                            property = find(propertySettings.properties, (item) => item.Name === propertyName);
                        } else {
                            property = find(block.data.metaData.Properties, (item) => item.Name === propertyName);
                        }
                        if (isNil(property)) {
                            // case is not possible
                            return createError({
                                path,
                                message,
                                type: messageTypes.BlockPropertyDoesNotExist,
                                params: { propertyName, block },
                            });
                        } else {
                            if (!isNil(node?.data?.collectedProperties)) {
                                const collectedProperties = getModalCollectedProperties(modelRelationScope, node, {
                                    SelfModelTypeName: propertySettings?.typeName,
                                    SelfModelProperties: { Properties: propertySettings?.properties },
                                });
                                const matchedProperties = withoutType
                                    ? collectedProperties
                                    : getMatchedProperties(optionType, collectedProperties);
                                const matchedBlock = find(matchedProperties, (item) => item.id === blockId);
                                if (isNil(matchedBlock)) {
                                    return createError({
                                        path,
                                        message,
                                        type: messageTypes.MatchedBlockDoesNotVisible,
                                        params: { blockId, block },
                                    });
                                } else {
                                    const matchedProperty = find(
                                        matchedBlock.properties,
                                        (item) => item.Name === propertyName,
                                    );
                                    if (isNil(matchedProperty)) {
                                        // case is not possible
                                        return createError({
                                            path,
                                            message,
                                            type: messageTypes.MatchedBlockPropertyDoesNotVisible,
                                            params: { propertyName, block },
                                        });
                                    } else {
                                        // It's all success
                                    }
                                }
                            } else {
                                // case is not possible
                                return createError({
                                    path,
                                    message,
                                    type: messageTypes.BlockCollectedPropertiesNotExist,
                                    params: { blockId, block },
                                });
                            }
                        }
                    }
                }
            } else {
                return createError({ path, message, type: messageTypes.ValueIsRequired });
            }

            return true;
        });
    },
);

Yup.addMethod(Yup.object, 'blockIdValidation', function (elements, node, message) {
    return this.test('blockIdValidationTest', message, function (value) {
        const { path, createError } = this;
        if (!isNil(value)) {
            const { value: blockId } = value;
            if (isNil(blockId)) {
                return createError({ path, message, type: messageTypes.BlockIsRequired, params: { blockId } });
            } else {
                const block = find(elements, (el) => {
                    return isNode(el) && el.id === blockId;
                });
                if (isNil(block)) {
                    return createError({
                        path,
                        message,
                        type: messageTypes.BlockDoesNotExist,
                        params: { blockId },
                    });
                } else {
                    if (!isNil(node?.data?.collectedProperties)) {
                        const collectedBlocks = node.data.collectedProperties.map(({ id, name }) => ({
                            id,
                            name,
                        }));
                        const matchedBlock = find(collectedBlocks, (item) => item.id === blockId);
                        if (isNil(matchedBlock)) {
                            return createError({
                                path,
                                message,
                                type: messageTypes.MatchedBlockDoesNotVisible,
                                params: { blockId, block },
                            });
                        } else {
                            // It's all success
                        }
                    } else {
                        // case is not possible
                        return createError({
                            path,
                            message,
                            type: messageTypes.BlockCollectedPropertiesNotExist,
                            params: { blockId, block },
                        });
                    }
                }
            }
        } else {
            return createError({ path, message, type: messageTypes.ValueIsRequired });
        }

        return true;
    });
});

Yup.addMethod(Yup.string, 'negativeValueValidation', function (message = {}) {
    return this.test('negativeValueValidationTest', message, function (value) {
        if (isOnlyMinus(value)) {
            const { path, createError } = this;
            return createError({
                path: path,
                message: {
                    label: l.CannotBeOnlyMinus,
                    fieldName: l.Value,
                    ...message,
                },
            });
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, 'maxValueValidation', function (validation, message = {}) {
    return this.test('maxValueValidationTest', message, function (value) {
        if (!isNil(validation?.Value) && !isNil(value)) {
            const { path, createError } = this;
            const isIncluded = validation.IsIncluded ?? false;
            let hasError = isIncluded ? +value > +validation.Value : +value >= +validation.Value;
            if (hasError) {
                return createError({
                    path: path,
                    message: {
                        label: isIncluded ? l.MustBeSmallerOrEqualThan : l.MustBeSmallerThan,
                        fieldName: l.Value,
                        count: validation.Value.toString(),
                        ...message,
                    },
                });
            }
        }
        return true;
    });
});
Yup.addMethod(Yup.mixed, 'minValueValidation', function (validation, message = {}) {
    return this.test('minValueValidationTest', message, function (value) {
        if (!isNil(validation?.Value) && !isNil(value)) {
            const { path, createError } = this;
            const isIncluded = validation.IsIncluded ?? false;
            let hasError = isIncluded ? +value < +validation.Value : +value <= +validation.Value;
            if (hasError) {
                return createError({
                    path: path,
                    message: {
                        label: isIncluded ? l.MustBeBiggerOrEqualThan : l.MustBeBiggerThan,
                        fieldName: l.Amount,
                        count: validation.Value.toString(),
                        ...message,
                    },
                });
            }
        }
        return true;
    });
});
Yup.addMethod(Yup.mixed, 'decimalPointsValidation', function (validation, message = {}) {
    return this.test('decimalPointsValidationTest', message, function (value) {
        if (!isNil(validation) && validation > 0 && !isNil(value) && !isNaN(+value)) {
            const { path, createError } = this;
            const splitValue = (+value).toString().split('.'); // value can be string also number
            if (
                (isNil(splitValue[1]) && splitValue[0].includes('e')) ||
                (!isNil(splitValue[1]) && splitValue[1].length > validation)
            ) {
                return createError({
                    path: path,
                    message: {
                        label: l.MaximumNumberDecimalDigit,
                        fieldName: l.Value,
                        count: validation.toString(),
                        ...message,
                    },
                });
            }
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, 'stringPatternValidation', function (validation, message = {}) {
    return this.test('stringPatternValidationTest', message, function (value) {
        if (!isNil(validation) && !isNil(value)) {
            const { path, createError } = this;
            if (isNil(value.toString().match(validation))) {
                return createError({
                    path: path,
                    message: { label: l.PatternDoesNotMatch, ...message },
                });
            }
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, 'maxLengthValidation', function (validation, message = {}) {
    return this.test('maxLengthValueValidationTest', message, function (value) {
        if (!isNil(validation?.Value) && !isNil(value) && isArray(value)) {
            const { path, createError } = this;
            const isIncluded = validation.IsIncluded ?? false;
            let hasError = isIncluded ? value.length > +validation.Value : value.length >= +validation.Value;
            if (hasError) {
                return createError({
                    path: path,
                    message: {
                        label: isIncluded ? l.MustBeLessOrEqualThan : l.MustBeLessThan,
                        fieldName: l.Value,
                        length: validation.Value.toString(),
                        ...message,
                    },
                });
            }
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, 'startDateCJScheduleValidation', function (t, elements, message = {}) {
    return this.test('startDateCJScheduleValidationTest', message, function (value) {
        const { path, createError } = this;
        const { timeZone } = this.parent;
        const validationResult = validateCJSchedule(
            value,
            timeZone,
            elements,
            (optionStartDate, optionEndDate, dateValue, node) => {
                return node?.data?.isEditOnlyLive !== true && optionStartDate.getTime() <= dateValue.getTime();
            },
        );
        if (!isNil(validationResult)) {
            return createError({
                path: path,
                message: t(l.StartDateMustBeSmallerThenBlockDate, {
                    optionName: t(validationResult.option?.CustomAttributes?.DisplayName),
                    blockName: validationResult.node.data.name,
                }),
            });
        }
        return true;
    });
});

Yup.addMethod(Yup.mixed, 'endDateCJScheduleValidation', function (t, elements, message = {}) {
    return this.test('endDateCJScheduleValidationTest', message, function (value) {
        const { path, createError } = this;
        const { timeZone } = this.parent;
        const validationResult = validateCJSchedule(
            value,
            timeZone,
            elements,
            (optionStartDate, optionEndDate, dateValue) => {
                return optionEndDate.getTime() > dateValue.getTime();
            },
        );
        if (!isNil(validationResult)) {
            return createError({
                path: path,
                message: t(l.EndDateMustBeBiggerThenBlockDate, {
                    optionName: t(validationResult.option?.CustomAttributes?.DisplayName),
                    blockName: validationResult.node.data.name,
                }),
            });
        }
        return true;
    });
});

function validateCJSchedule(value, timeZone, elements, comparisonHasError) {
    if (!isNil(value) && !isEmpty(elements)) {
        const blocks = elements.filter((el) => {
            return isNode(el);
        });

        for (let blockIndex = 0; blockIndex < blocks.length; blockIndex++) {
            const node = blocks[blockIndex];
            if (!isNil(node?.data?.metaData?.Options)) {
                for (let optionIndex = 0; optionIndex < node?.data?.metaData?.Options.length; optionIndex++) {
                    const option = node?.data?.metaData?.Options[optionIndex];
                    const config = getFormContent(option.BaseTypes, formsTypes);
                    if (config.optionType === formsTypes.WfSchedule.optionType) {
                        const val = node?.data?.apiModel[option.Name];
                        const uiModel = config.mapToUIModel(val);
                        if (
                            !isNil(uiModel) &&
                            uiModel.$type !== CJArgumentValuesTypes.DefaultInArgument &&
                            uiModel.$type !== CJArgumentValuesTypes.ReferenceInArgument &&
                            uiModel.$type !== CJArgumentValuesTypes.FormulaInArgument &&
                            uiModel.isActiveSchedule === true
                        ) {
                            const optionStartDate = new Date(
                                getDateFormat(
                                    `${moment.utc(uiModel.startDate).format(MonthDayYearFormat)} ${uiModel.startTime}`,
                                    -uiModel.timeZone,
                                    MonthDayYearWithTimeFormat,
                                ),
                            );

                            const optionEndDate = new Date(
                                getDateFormat(
                                    `${moment.utc(uiModel.endDate).format(MonthDayYearFormat)} ${uiModel.startTime}`,
                                    -uiModel.timeZone,
                                    MonthDayYearWithTimeFormat,
                                ),
                            );

                            const dateValue = new Date(
                                getDateFormat(
                                    customMomentWithoutTimezoneConversion(value).format(MonthDayYearWithTimeFormat),
                                    -timeZone,
                                    MonthDayYearWithTimeFormat,
                                ),
                            );

                            if (comparisonHasError(optionStartDate, optionEndDate, dateValue, node)) {
                                return { node: node, option: option };
                            }
                        }
                    }
                }
            }
        }
        return null;
    }
}

function isRequired(value) {
    return !value;
}

function isFileFormatValid(value, format) {
    return !format.split(',').includes(value.split('.').pop());
}

function isRequiredEditor(value) {
    if (!isNil(value)) {
        return value.trim() === '';
    }

    return true;
}

function isRequiredEmailBuilder(value) {
    return value === '' || value === '<body id="wrapper" style="box-sizing: border-box; margin: 0;"></body>';
}

function isLessThen(value, count) {
    return value.length < count;
}

function isMoreThen(value, count) {
    return value?.length > count;
}

function viberButtonTextMaxLength(value, count) {
    const { text } = value;
    return text?.length > count;
}

function telegramButtonTextMaxLength(value, count) {
    const { text } = value;
    return text?.length > count;
}

function isMoreThanRegex(value, { count, regExpConfig, fieldName }) {
    const result = calculateTemplateBodyLengthWithRegEx(regExpConfig, value);
    return Helpers.getByteLen(result) > count
        ? [
              {
                  errorMessage: l.MustContainMaximumCharacters,
                  errorParams: { count: count.toString(), fieldName: fieldName },
              },
          ]
        : false;
}

const customerJourneyLimitValidation = ({ limit, count }) => {
    return {
        errorMessages: [],
        warningMessages: [
            {
                errorMessage: l.CustomerJourneyLimitWarningMessage,
                errorParams: {
                    limit: limit.toString(),
                    count: count.toString(),
                },
            },
        ],
    };
};

function regexMatching(value, regex) {
    return value.match(new RegExp(regex)) === null;
}

function isGreaterThan(value, count) {
    return +value <= +count;
}

function isGreaterThanOrEqual(value, count) {
    return +value < +count;
}

function isLessThan(value, count) {
    return +value >= +count;
}

function isLessThanOrEqual(value, count) {
    return +value > +count;
}

function isEmptyValue(value) {
    return isEmpty(value);
}

function isBiggerThanCurrentDate(date, time, timeZone) {
    const currentDate = new Date(customMomentWithoutTimezoneConversion().format(MonthDayYearWithTimeFormat));
    const chosenDate = new Date(
        getDateFormat(`${moment(date).format(MonthDayYearFormat)} ${time}`, -timeZone, MonthDayYearWithTimeFormat),
    );
    return chosenDate.getTime() <= currentDate.getTime();
}

function isDateSmallerThanDate(fromDate, toDate) {
    const chosenStartDate = new Date(moment(fromDate).format(MonthDayYearFormat));
    const chosenEndDate = new Date(moment(toDate).format(MonthDayYearFormat));

    return chosenStartDate.getTime() > chosenEndDate.getTime();
}

function isValidCustomerJourney(elements) {
    const {
        minimumTwoValidBlocksWithValidConnection,
        leastOneTargetBlock,
        allBlocksMustBeConnected,
        targetHasNotInput,
        blockInvalid,
    } = CustomerJourneyErrorCodes;
    var errorMessages = [];

    if (isNil(elements) || !isArray(elements) || isEmpty(elements)) {
        errorMessages.push({ errorCode: minimumTwoValidBlocksWithValidConnection.code });
    } else {
        const nodeElements = elements.filter((element) => isNode(element));

        if (isEmpty(nodeElements)) {
            errorMessages.push({ errorCode: minimumTwoValidBlocksWithValidConnection.code });
        } else {
            const targetBlocks = nodeElements.filter(({ type }) => type === CustomerJourneyGroupBlockTypes.target);

            if (targetBlocks.length === 0 || targetBlocks.length > 1) {
                errorMessages.push({ errorCode: leastOneTargetBlock.code, errorParams: {} });
            }

            targetBlocks.forEach((target) => {
                const { data } = target;
                data.errorMessages = [];
                const incomers = getIncomers(target, elements);
                if (incomers.length > 0) {
                    data.errorMessages.push({ errorCode: targetHasNotInput.code });
                    errorMessages.push({ errorCode: targetHasNotInput.code, errorParams: {} });
                }
                customerJourneyNameValidation(target);
                customerJourneyOptionsValidation(target, elements);

                data.isValid = data.errorMessages.length === 0;
                if (!data.isValid) {
                    errorMessages.push({
                        errorCode: blockInvalid.code,
                        errorParams: { blockName: { isTranslated: true, value: target?.data?.name } },
                    });
                }
            });

            const nonTargetBlocks = nodeElements.filter(({ type }) => type !== CustomerJourneyGroupBlockTypes.target);

            if (nonTargetBlocks.length === 0) {
                errorMessages.push({ errorCode: minimumTwoValidBlocksWithValidConnection.code });
            } else {
                nonTargetBlocks.forEach((node) => {
                    const { data } = node;
                    data.errorMessages = [];
                    const incomers = getIncomers(node, elements);
                    if (incomers.length === 0) {
                        // TODO when nonTargetBlocks must be have input connection
                        data.errorMessages.push({ errorCode: allBlocksMustBeConnected.code });
                    }

                    customerJourneyNameValidation(node);
                    customerJourneyOptionsValidation(node, elements);

                    data.isValid = data.errorMessages.length === 0;
                    if (!data.isValid) {
                        errorMessages.push({
                            errorCode: blockInvalid.code,
                            errorParams: { blockName: { isTranslated: true, value: node?.data?.name } },
                        });
                    }
                });
            }
        }
    }
    return errorMessages.length > 0 ? errorMessages : false;
}

function getTemplateUsageDynamicValues(dynamicValues, element) {
    return !isEmpty(element)
        ? keys(dynamicValues).reduce((acc, key) => {
              if (element.includes(`{${key}}`)) {
                  acc[key] = dynamicValues[key];
              }
              return acc;
          }, {})
        : {};
}

function getFilteredDynamicValues(allPlaceholders, bodyWithRegEx) {
    const allDynamicValues = [...bodyWithRegEx.matchAll(/{(?<dynamicValue>[a-zA-Z]\w*)}/gm)].map(
        (value) => value.groups.dynamicValue,
    );

    return allDynamicValues.reduce(
        (acc, value) => {
            if (isNil(allPlaceholders[value])) {
                acc.unknownDynamicValues.push(value);
            } else {
                acc.dynamicValuesWithUnknownLength.push(allPlaceholders[value]);
            }

            return acc;
        },
        {
            dynamicValuesWithUnknownLength: [],
            unknownDynamicValues: [],
        },
    );
}

function doCrossValidation(value, parameter) {
    const errorMessages = [];
    const warningMessages = [];

    if (isEmpty(value?.dynamicValues) || isEmpty(value?.data)) {
        return { errorMessages: errorMessages, warningMessages: warningMessages };
    }

    const { data, dynamicValues, shortenerAndTrackerSettings, partnerLanguages } = value;
    const allPlaceholders = dynamicValues.reduce((acc, value) => {
        acc[value.FieldName] = value;
        return acc;
    }, {});

    const defaultPlaceholders = getTemplateUsageDynamicValues(allPlaceholders, data?.defaultLang[parameter]);

    const {
        containKPIVariableWarningMessage,
        childDoesNotContainVariableWarningMessage,
        defaultDoesNotContainDynamicVariableError,
        dynamicVariableWithUnknownLengthWarning,
        defaultContainsUnknownDynamicVariableWarning,
    } = validationTypeLabels[parameter];

    values(defaultPlaceholders).forEach((item) => {
        if (!isNil(item.ColumnInfoId)) {
            warningMessages.push({
                errorMessage: containKPIVariableWarningMessage,
                errorParams: {
                    dynamicVariable: item.DisplayNameKey,
                },
            });
        }
    });

    values(data).forEach((template) => {
        if (!isNil(template?.Language)) {
            const tmpPlaceholders = getTemplateUsageDynamicValues(allPlaceholders, template[parameter]);

            keys(defaultPlaceholders).forEach((key) => {
                if (isNil(tmpPlaceholders[key])) {
                    warningMessages.push({
                        errorMessage: childDoesNotContainVariableWarningMessage,
                        errorParams: {
                            dynamicVariable: defaultPlaceholders[key].DisplayNameKey,
                            language: partnerLanguages[template.Language]?.Name,
                        },
                    });
                }
            });

            keys(tmpPlaceholders).forEach((key) => {
                if (isNil(defaultPlaceholders[key])) {
                    warningMessages.push({
                        errorMessage: defaultDoesNotContainDynamicVariableError,
                        errorParams: {
                            dynamicVariable: tmpPlaceholders[key].DisplayNameKey,
                            language: partnerLanguages[template.Language]?.Name,
                        },
                    });

                    if (!isNil(tmpPlaceholders[key].ColumnInfoId)) {
                        errorMessages.push({
                            errorMessage: defaultDoesNotContainDynamicVariableError,
                            errorParams: {
                                dynamicVariable: tmpPlaceholders[key].DisplayNameKey,
                                language: partnerLanguages[template.Language]?.Name,
                            },
                        });
                    }
                }
            });
        }
    });

    values(data).forEach((template) => {
        const element = [TemplateInputTypes.Body, TemplateInputTypes.SMSBody].includes(parameter)
            ? calculateTemplateBodyLengthWithRegEx(shortenerAndTrackerSettings, cloneDeep(template[parameter]))
            : cloneDeep(template[parameter]);
        const { dynamicValuesWithUnknownLength, unknownDynamicValues } = getFilteredDynamicValues(
            allPlaceholders,
            element,
        );

        if (!isEmpty(dynamicValuesWithUnknownLength)) {
            dynamicValuesWithUnknownLength.forEach((value) => {
                warningMessages.push({
                    errorMessage: dynamicVariableWithUnknownLengthWarning,
                    errorParams: {
                        dynamicVariable: value.DisplayNameKey,
                        language: partnerLanguages[template.Language]?.Name ?? l.Default,
                    },
                });
            });
        }

        if (isNil(template.Language) && !isEmpty(unknownDynamicValues)) {
            unknownDynamicValues.forEach((value) => {
                warningMessages.push({
                    errorMessage: defaultContainsUnknownDynamicVariableWarning,
                    errorParams: {
                        dynamicVariable: value,
                    },
                });
            });
        }
    });

    return { errorMessages: errorMessages, warningMessages: warningMessages };
}

const templateCrossValidation = (value, params) => {
    let errorMessages = [];
    let warningMessages = [];

    params.forEach((parameter) => {
        const tmpResult = doCrossValidation(value, parameter);

        errorMessages = [...errorMessages, ...tmpResult.errorMessages];
        warningMessages = [...warningMessages, ...tmpResult.warningMessages];
    });
    return { errorMessages: errorMessages, warningMessages: warningMessages };
};

function customerJourneyNameValidation(node) {
    // TODO: name must be uniq, case is prevented in block name on change but full validation need checking
    if (!isNil(node?.data)) {
        const { data } = node;
        if (isNil(data.name) || isEmpty(data.name)) {
            data.errorMessages.push({ errorCode: CustomerJourneyErrorCodes.blockNameCanNotBeEmpty.code });
        }
    }
}

function cjStaticSchemaErrorMessageCreator(node) {
    return (e) => {
        if (!isNil(e?.message?.label)) {
            node.data.errorMessages.push({
                errorMessage: e.message?.label,
                errorParams: e.message,
            });
        } else {
            console.log(e?.message, e?.type, e?.params);
            node.data.errorMessages.push({
                errorMessage: l.SomethingIsWrong,
            });
        }
    };
}

const getReferencePropertyErrorMessage = (e) => {
    if (!isNil(e?.type)) {
        switch (e.type) {
            case messageTypes.BlockIsRequired:
                return {
                    errorMessage: l.CannotBeEmpty,
                    errorParams: { fieldName: l.Block },
                };
            case messageTypes.PropertyIsRequired: // case does not possible
                return {
                    errorMessage: l.CannotBeEmpty,
                    errorParams: { fieldName: l.Property },
                };

            case messageTypes.BlockDoesNotExist:
                return {
                    errorMessage: l.BlockDoesNotExist,
                };
            case messageTypes.BlockPropertyDoesNotExist: // case does not possible
                return {
                    errorMessage: l.BlockPropertyDoesNotExist,
                };
            case messageTypes.MatchedBlockDoesNotVisible:
                return {
                    errorMessage: l.MatchedBlockDoesNotVisible,
                    errorParams: { blockName: e?.params?.block?.data?.name },
                };

            case messageTypes.MatchedBlockPropertyDoesNotVisible: // case does not possible
                return {
                    errorMessage: l.MatchedBlockPropertyDoesNotVisible,
                };

            case messageTypes.BlockCollectedPropertiesNotExist: // case does not possible
                return {
                    errorMessage: l.BlockCollectedPropertiesNotExist,
                };

            default:
                // case does not possible
                return {
                    errorMessage: l.SomethingIsWrong,
                };
        }
    } else {
        // case does not possible
        return {
            errorMessage: l.SomethingIsWrong,
        };
    }
};

function cjReferencePropertySchemaErrorMessageCreator(node) {
    return (e) => {
        node.data.errorMessages.push(getReferencePropertyErrorMessage(e));
    };
}

function yupSchemaValidate(schema, data, errorMessageCreator = noop) {
    try {
        if (!isNil(schema)) {
            schema.validateSync(data, {
                abortEarly: false,
                strict: true,
            });
        }
        return true;
    } catch (error) {
        if (!isNil(error?.inner) && isArray(error.inner)) {
            error.inner.forEach((e) => {
                errorMessageCreator(e);
            });
        }
        return false;
    }
}

function cjVisualizationConditionValidation(data, elements) {
    data.node.data.errorMessages = [];
    cjFilterConditionValidation(elements, data.node, data.option, formsTypes.FilterCondition);
    return data.node.data.errorMessages.length === 0;
}

function cjVisualizationTemplatesValidation(templateData) {
    let isValid = true;
    values(templateData).forEach((item) => {
        item.isValid = yupSchemaValidate(CJVisualizationStepTemplateValidationSchema(), item);
        isValid = isValid && item.isValid;
    });
    return isValid;
}

function cjVisualizationStepValidation(stepData, elements, isTarget = false) {
    stepData.isValid = true;
    if (isTarget) {
        stepData.isValid = cjVisualizationConditionValidation(stepData, elements);
    }

    stepData.isValid = cjVisualizationTemplatesValidation(stepData.templates) && stepData.isValid;

    if (!isTarget) {
        stepData.isValid =
            stepData.isValid && yupSchemaValidate(CJVisualizationStepIconValidationSchema(), { icon: stepData.icon });

        stepData.subSteps.forEach((subStep) => {
            subStep.isValid =
                cjVisualizationConditionValidation(subStep, elements) &&
                cjVisualizationTemplatesValidation(subStep.templates);
            stepData.isValid = stepData.isValid && subStep.isValid;
        });
    }
}

function cjWfDateTimeValidation(val, node, option, config, elements) {
    const model = config.mapToUIModel(val);
    if (
        model?.value?.template === dynamicDateData.sameDay.value ||
        model?.value?.template === dynamicDateData.daysLater.value
    ) {
        const blockId = model?.value?.params?.property?.parentValue;
        yupSchemaValidate(
            CJReferenceOptionValidationSchema(
                elements,
                node,
                config.optionType,
                option.ModelRelationScope,
                blockId === node.id
                    ? {
                          typeName: option?.SelfModelTypeName,
                          properties: option?.SelfModelProperties?.Properties,
                      }
                    : {},
            ),
            {
                blockId: blockId,
                propertyName: model?.value?.params?.property?.value,
                logicFunction: model?.value?.params?.property?.logicFunction,
                argumentInType: CJArgumentValuesTypes.ReferenceInArgument,
            },
            cjReferencePropertySchemaErrorMessageCreator(node),
        );
    }
}

function cjWfGuidValidation(val, node, option, config, elements) {
    if (option?.Required === false) {
        return;
    }
    const model = config.mapToUIModel(val);
    yupSchemaValidate(
        CJReferenceOptionValidationSchema(
            elements,
            node,
            config.optionType,
            option.ModelRelationScope,
            model?.blockId === node.id
                ? {
                      typeName: option?.SelfModelTypeName,
                      properties: option?.SelfModelProperties?.Properties,
                  }
                : {},
        ),
        model,
        cjReferencePropertySchemaErrorMessageCreator(node),
    );
}

function cjWfBlockNameValidation(val, node, option, config, elements) {
    if (option?.Required === false) {
        // Case never possible
        // TODO: when option is optional need discuss, its same when in filter condition property is optional (non required)
        return;
    }
    const model = config.mapToUIModel(val);
    yupSchemaValidate(
        CJBlockNameOptionValidationSchema(elements, node),
        model,
        cjReferencePropertySchemaErrorMessageCreator(node),
    );
}

function optionApiModelValueValidation(val, config, option, node, elements) {
    if (val?.$type !== CJReferenceInArgumentType && val?.$type !== CJFormulaInArgumentType) {
        if (!isNil(config.validationSchema)) {
            const validationSchema = config.validationSchema(option, node);

            yupSchemaValidate(validationSchema, config.mapToUIModel(val), cjStaticSchemaErrorMessageCreator(node));
        } else {
            // checking dynamic render components, now only Bonus
            // and all dynamic render components has not validationSchema
            if (config.optionType === formsTypes.WfBonus.optionType) {
                cjWfBonusValidation(node, option, config);
            }
            if (config.optionType === formsTypes.WfDateTime.optionType) {
                cjWfDateTimeValidation(val, node, option, config, elements);
            }
            if (config.optionType === formsTypes.FilterCondition.optionType) {
                cjFilterConditionValidation(elements, node, option, config);
            }
            if (config.optionType === formsTypes.WfGuid.optionType) {
                cjWfGuidValidation(val, node, option, config, elements);
            }
            if (config.optionType === formsTypes.WfBlockName.optionType) {
                cjWfBlockNameValidation(val, node, option, config, elements);
            }
            if (config.optionType === formsTypes.TemplateConfig.optionType) {
                cjTemplateConfigValidation(val, node, option, config, elements);
            }
        }
    } else {
        // TODO: reference in argument type, move yup validation for validate also in modal
        if (!isEmpty(config) && !isNil(config?.optionType)) {
            const tmpModel = config.mapToUIModel(val);

            let tmpOptionType = config.optionType;
            if (val?.$type === CJFormulaInArgumentType && !isEmpty(tmpModel)) {
                let property = null;
                if (tmpModel?.blockId === node.id) {
                    property = option?.SelfModelProperties?.Properties;
                } else {
                    const block = elements.find((el) => el.id === tmpModel.blockId);
                    if (block?.data?.metaData?.Properties) {
                        property = block?.data?.metaData?.Properties.find(
                            (prop) => prop.Name === tmpModel.propertyName,
                        );
                    }
                }
                if (!isNil(property?.Functions)) {
                    const logicFunction = property?.Functions.find((func) => func?.value === tmpModel.logicFunction);
                    if (!isNil(logicFunction?.returnType?.Name)) {
                        tmpOptionType = isNil(logicFunction?.returnType?.Name)
                            ? ''
                            : last(logicFunction?.returnType?.Name.split('.'));
                    }
                }
            }

            yupSchemaValidate(
                CJReferenceOptionValidationSchema(
                    elements,
                    node,
                    tmpOptionType,
                    option.ModelRelationScope,
                    tmpModel?.blockId === node.id
                        ? {
                              typeName: option?.SelfModelTypeName,
                              properties: option?.SelfModelProperties?.Properties,
                          }
                        : {},
                ),
                tmpModel,
                cjReferencePropertySchemaErrorMessageCreator(node),
            );
        }
    }
}

function customerJourneyOptionsValidation(node, elements) {
    if (!isNil(node?.data?.metaData?.Options)) {
        node.data.metaData.Options.forEach((option) => {
            const config = getFormContent(option.BaseTypes, formsTypes);
            const val = node?.data?.apiModel[option.Name];
            optionApiModelValueValidation(val, config, option, node, elements);
        });
    }
}

function cjFilterConditionValidation(elements, node, option, config) {
    const val = node?.data?.apiModel[option.Name];
    const model = config.mapToUIModel(val);
    // TODO: Filter block must be have minimum one filter
    if (model?.argumentInType !== CJArgumentValuesTypes.DefaultInArgument) {
        if (isNil(model?.defaultSelectedLogic)) {
            // TODO: case does not possible, but need checking for full validation
        }
        if (isNil(model?.filters) || !isArray(model.filters)) {
            if (option.Required) {
                node.data.errorMessages.push({ errorMessage: l.CannotBeEmpty, errorParams: { fieldName: l.Block } });
            }
        } else {
            cjFilterGroupValidation(model, elements, node, option);
        }
    } else if (option.Required) {
        node.data.errorMessages.push({ errorMessage: l.CannotBeEmpty, errorParams: { fieldName: l.Block } });
    }
}

function cjFilterGroupValidation(filter, elements, node, option) {
    if (filter?.isGroupFilter === true) {
        if (!isNil(filter?.filters)) {
            filter.filters.forEach((filter) => {
                cjFilterGroupValidation(filter, elements, node, option);
            });
        }
    } else {
        const leftProperty = {
            blockId: filter?.property?.parentValue,
            propertyName: filter?.property?.value,
            logicFunction: filter?.property?.logicFunction,
        };
        let leftPropertyOption = null;
        if (!isNil(leftProperty.blockId) && !isNil(leftProperty.propertyName)) {
            if (!isNil(option?.SelfModelTypeName) && leftProperty.blockId === node.id) {
                if (!isNil(option?.SelfModelProperties?.Properties)) {
                    leftPropertyOption = option?.SelfModelProperties?.Properties.find(
                        (property) => property.Name === leftProperty.propertyName,
                    );
                }
            } else {
                const tmpBlock = elements.find((el) => el.id === leftProperty.blockId);
                if (!isNil(tmpBlock?.data?.metaData?.Properties)) {
                    leftPropertyOption = tmpBlock.data.metaData.Properties.find(
                        (property) => property.Name === leftProperty.propertyName,
                    );
                }
            }
        }

        if (!isNil(leftProperty?.logicFunction) && !isNil(leftPropertyOption?.Functions)) {
            const tmpFunction = leftPropertyOption.Functions.find((func) => func.value === leftProperty.logicFunction);
            if (!isNil(tmpFunction)) {
                leftPropertyOption = cloneDeep(leftPropertyOption);
                leftPropertyOption.BaseTypes = tmpFunction.returnType?.BaseTypes;
            }
        }

        const config = getFormContent(leftPropertyOption?.BaseTypes, formsTypes);

        yupSchemaValidate(
            CJReferenceOptionValidationSchema(
                elements,
                node,
                isNil(leftPropertyOption?.BaseTypes) ? '' : last(first(leftPropertyOption.BaseTypes).split('.')),
                option.ModelRelationScope,
                leftProperty.blockId === node.id
                    ? {
                          typeName: option?.SelfModelTypeName,
                          properties: option?.SelfModelProperties?.Properties,
                      }
                    : {},
            ),
            leftProperty,
            cjReferencePropertySchemaErrorMessageCreator(node),
        );

        if (isNil(filter.operator)) {
            // TODO: operator is required
        } else {
            // TODO: operator validation with leftProperty must be operators

            // TODO: right value validation
            if (
                !isNil(leftPropertyOption) &&
                !isNil(config?.optionType) &&
                !excludeRightValueContentVisibilitiesOperators.includes(filter.operator)
            ) {
                optionApiModelValueValidation(
                    filter.rightValue,
                    config,
                    {
                        ...leftPropertyOption,
                        ModelRelationScope: option.ModelRelationScope,
                        Required: true,
                        SelfModelTypeName: option?.SelfModelTypeName,
                        SelfModelProperties: option?.SelfModelProperties,
                    },
                    node,
                    elements,
                );
            }
        }
    }
}

function cjWfBonusValidation(node, option, config) {
    const val = node?.data?.apiModel[option.Name];
    const model = config.mapToUIModel(val);
    const validationSchema = CJPartnerBonusIdValidationSchema(option);

    yupSchemaValidate(validationSchema, { value: model?.bonusId }, cjStaticSchemaErrorMessageCreator(node));
    if (!isNil(node?.data?.dependencies) && !isNil(node?.data?.dependencies[CJDependencyTypes.Bonus])) {
        const bonus = find(node.data.dependencies[CJDependencyTypes.Bonus], (item) => +item.Id === +model.bonusId);
        // TODO: cannot be case when bonus is null but need when initial request is expired
        if (!isNil(bonus?.BonusDetails)) {
            bonus.BonusDetails.filter(
                (bonusDetail) => isNil(model?.wallet) || bonusDetail.CurrencyCode === model.wallet,
            ).forEach((bonusDetail) => {
                const amount = isNil(model?.amounts)
                    ? null
                    : find(model.amounts, (item) => item.CurrencyCode === bonusDetail.CurrencyCode)?.OriginalValue;
                const bonusAmountValidationSchema = CJBonusAmountInputValidationSchema(bonusDetail, option?.Required);

                yupSchemaValidate(bonusAmountValidationSchema, { amount }, cjStaticSchemaErrorMessageCreator(node));
            });
        }
    }
}

function cjTemplateConfigValidation(val, node, option, config, elements) {
    const model = config.mapToUIModel(val);
    const validationSchema = CJTemplateIdValidationSchema(option);
    yupSchemaValidate(validationSchema, { value: model?.templateId }, cjStaticSchemaErrorMessageCreator(node));

    if (
        !isNil(node?.data?.dependencies) &&
        !isNil(node?.data?.dependencies[CJDependencyTypes.Template]) &&
        !isNil(model?.templateId) &&
        !isNil(model?.dynamicVariables)
    ) {
        const dependencyObject = find(
            node.data.dependencies[CJDependencyTypes.Template],
            (item) => +item.templateId === +model.templateId,
        );

        if (!isEmpty(dependencyObject?.dynamicVariables)) {
            values(dependencyObject.dynamicVariables).forEach((item) => {
                const templateItemValue = model.dynamicVariables[item.fieldName];
                if (!isNil(templateItemValue)) {
                    yupSchemaValidate(
                        CJReferenceOptionValidationSchema(
                            elements,
                            node,
                            item?.wfType,
                            option.ModelRelationScope,
                            templateItemValue?.value?.blockId === node.id
                                ? {
                                      typeName: option?.SelfModelTypeName,
                                      properties: option?.SelfModelProperties?.Properties,
                                  }
                                : {},
                            isNil(item.wfType),
                        ),
                        templateItemValue?.value,
                        cjReferencePropertySchemaErrorMessageCreator(node),
                    );
                }
            });
        }
    }
}

const viberButtonTextValidation = (data) => {
    const { text, url } = data;
    return isEmpty(text.trim()) && !isEmpty(url.trim());
};

const viberButtonUrlValidation = (data) => {
    const { text, url } = data;
    return isEmpty(url.trim()) && !isEmpty(text.trim());
};

const telegramButtonTextValidation = (data) => {
    const { text, url } = data;
    return isEmpty(text.trim()) && !isEmpty(url.trim());
};

const telegramButtonUrlValidation = (data) => {
    const { text, url } = data;
    return isEmpty(url.trim()) && !isEmpty(text.trim());
};

const isUrlValidOrEmptyString = (data) => {
    const { url } = data;
    if (isEmpty(url)) {
        return false;
    }
    const res = url.match(
        // eslint-disable-next-line
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res === null;
};

const isValidQueryConfigurations = (data) => {
    let hasError = false;
    values(data).forEach((segment) => {
        if (isEmpty(segment.filters) || isEmpty(segment.name)) {
            hasError = true;
        }
    });
    return hasError;
};

const hasDeactivatedSelection = (data) => {
    return data.some((obj) => obj.isUnavailable);
};

const isDeniedTagExist = (value, parametersValues) => {
    const { tags, isPartial } = parametersValues;

    if (isPartial) {
        const specificTags = ['html', 'body', 'head', 'script', 'meta', 'base', 'title'];
        const tagGroup = tags.reduce((tagsString, tag) => {
            if (specificTags.includes(tag)) {
                tagsString += `${tagsString === '' ? '' : '|'}${tag}`;
            }

            return tagsString;
        }, '');
        const isTagsExist = new RegExp(`<(${tagGroup})(.*)>`).test(value);

        if (isTagsExist) return true;
    }

    const htmlDocument = htmlStringToDomElement(value);
    const targetElement = isPartial ? htmlDocument.body : htmlDocument;

    const foundTags = tags.reduce((foundTagsAcc, tag) => {
        const element = targetElement.querySelector(tag);

        if (element) foundTagsAcc.push(element.localName);

        return foundTagsAcc;
    }, []);

    return foundTags.length > 0;
};

const isPositiveInteger = ({ value, type }) => {
    if (isNil(type) || type === ExpirationTypes.ExpirationDate) {
        return false;
    }
    const pattern = new RegExp('^[1-9]\\d*$');

    return value?.match(pattern) === null;
};

const isDateBiggerThanCurrentDate = ({ value, type }) => {
    if (isNil(type) || type === ExpirationTypes.ExpirationPeriod) {
        return false;
    }
    const currentDate = new Date();
    const chosenDate = new Date(value);

    return chosenDate.getTime() <= currentDate.getTime();
};

const startTimeValidation = (startTime) => {
    const result = /^([0-2]?[0-9]):[0-5][0-9]$/.test(startTime);
    return !result;
};

function validationCases({ ValidationType, ParametersValues }, value) {
    switch (ValidationType) {
        case 'required':
            return isRequired(value);
        case 'requiredEditor':
            return isRequiredEditor(value);
        case 'requiredEmailBuilder':
            return isRequiredEmailBuilder(value);
        case 'deniedTagsNotAllowed':
            return isDeniedTagExist(value, ParametersValues);
        case 'minLength':
            return isLessThen(value, ParametersValues);
        case 'minLengthOptional':
            return !isEmpty(value) && isLessThen(value, ParametersValues);
        case 'maxLength':
            return isMoreThen(value, ParametersValues);
        case 'customExpression':
            return regexMatching(value, ParametersValues);
        case 'greaterThan':
            return isGreaterThan(value, ParametersValues);
        case 'greaterThanOrEqual':
            return isGreaterThanOrEqual(value, ParametersValues);
        case 'lessThan':
            return isLessThan(value, ParametersValues);
        case 'lessThanOrEqual':
            return isLessThanOrEqual(value, ParametersValues);
        case 'email':
            return regexMatching(value, ParametersValues);
        case 'phone':
            return regexMatching(value, ParametersValues);
        case 'maxCountCustomExpression':
            return regexMatching(value, ParametersValues);
        case 'queryConfigurations':
            return isValidQueryConfigurations(value);
        case 'isEmpty':
            return isEmptyValue(value);
        case 'firstSymbol':
            return regexMatching(value, ParametersValues);
        case 'fromDateBiggerThanCurrentDate':
            return (
                !(value.pageType === PageTypes.edit && +value.campaignStatus === +CampaignStatusLabels.Idle) &&
                isBiggerThanCurrentDate(value.fromDate, value.fromTime, value.timeZone)
            );

        case 'toDateBiggerThanCurrentDate':
            return isBiggerThanCurrentDate(value.toDate, '23:59', value.timeZone);
        case 'dateCompareThanDate':
            return isDateSmallerThanDate(value.fromDate, value.toDate);
        case 'customerJourney':
            return isValidCustomerJourney(value);
        case 'isFileFormatValid':
            return isFileFormatValid(value, ParametersValues);
        case 'requiredVisualizationTemplate':
            return (
                isNil(value?.name) ||
                isEmpty(trim(value.name)) ||
                !yupSchemaValidate(CJVisualizationStepTemplateValidationSchema(), { name: value.name })
            );
        case 'templateCrossValidation': {
            return templateCrossValidation(value, ParametersValues);
        }
        case 'templateCrossValidationViber': {
            return templateCrossValidation(
                { ...value, shortenerAndTrackerSettings: value.shortenerAndTrackerSettings.viber },
                ParametersValues,
            );
        }
        case 'templateCrossValidationSMS': {
            return templateCrossValidation(
                { ...value, shortenerAndTrackerSettings: value.shortenerAndTrackerSettings.sms },
                ParametersValues,
            );
        }
        case 'maxLengthWithRegEx':
            return isMoreThanRegex(value, ParametersValues);
        case 'customerJourneyLimitValidation':
            return customerJourneyLimitValidation(ParametersValues);
        case 'unique':
            return !isNil(value) && isArray(value) && value.length !== uniq(value).length;
        case 'viberButtonText':
            return viberButtonTextValidation(value);
        case 'viberButtonUrl':
            return viberButtonUrlValidation(value);
        case 'isUrlValidOrEmptyString':
            return isUrlValidOrEmptyString(value);
        case 'viberButtonTextMaxLength':
            return viberButtonTextMaxLength(value, ParametersValues);
        case 'telegramButtonText':
            return telegramButtonTextValidation(value);
        case 'telegramButtonUrl':
            return telegramButtonUrlValidation(value);
        case 'telegramButtonTextMaxLength':
            return telegramButtonTextMaxLength(value, ParametersValues);
        case 'hasDeactivatedSelection':
            return hasDeactivatedSelection(value);
        case 'schedulerUnitPairs':
            return isSchedulerUnitPairsInvalid(value);
        case 'schedulerWeeklyUnit':
            return isSchedulerUnitPairsInvalid(value);
        case 'isPositiveInteger':
            return isPositiveInteger(value);
        case 'isDateBiggerThanCurrentDate':
            return isDateBiggerThanCurrentDate(value);
        case 'startTimeValidation':
            return startTimeValidation(value);
        default:
            return false;
    }
}

// TODO: is not used, need review
function singleValidator(label, value, validationCredentials, data) {
    const foundValidationCredential = validationCredentials.find((validationCredential) => {
        return validationCases(validationCredential, value);
    });
    if (foundValidationCredential) {
        return {
            ...data,
            isValid: false,
            errorText: foundValidationCredential.ErrorMessage,
            value,
        };
    } else {
        return { ...data, isValid: true, errorText: '', value };
    }
}

// TODO: is not used, need review
function customValidator(data, validateAll, setErrorValidationScenario, errorValidationScenario) {
    const resultValidationScenario = keys(data).reduce((acc, key) => {
        acc[key] =
            validateAll && data[key].isValid
                ? data[key]
                : singleValidator(
                      key,
                      validateAll ? data[key].value : data[key],
                      errorValidationScenario[key].validationCredentials,
                      errorValidationScenario[key],
                  );
        return acc;
    }, {});

    setErrorValidationScenario({ ...errorValidationScenario, ...resultValidationScenario });
}

export {
    customValidator,
    validationCases,
    yupSchemaValidate,
    getReferencePropertyErrorMessage,
    cjVisualizationStepValidation,
};
