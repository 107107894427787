import { isArray, isNil, last } from 'lodash';
// Import constants
import { DateTimeFormat, DateFormat, l } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

function addOpacity(color) {
    return `${color}1a`;
}

const getStaticSingleData = (viewType, { Argument, Data, Operation }, additionalData) => {
    const format = Data.HasTime ? DateTimeFormat : DateFormat;
    if (Data.ValueType !== 0) {
        return viewType.replace(/\{(0|1)\}/, additionalData);
    }
    return viewType.replace(/\{(0|1)\}/, customMomentWithoutTimezoneConversion(Data.Value).format(format));
};

const getAmountSimpleFormView = (viewType, { Data }, t) => {
    const dataType = Data?.IsEquivalent ? t(l.Equivalent) : t(l.Exact);
    return viewType.replace(/\{(0|1|2)\}/, `${Data.OriginalValue} ${Data.CurrencyCode} (${dataType})`);
};

const getMonthDayFormView = (viewType, { Data }, additionalData) => {
    if (Data.ValueType !== 0) {
        return viewType.replace(/\{(0|1)\}/, additionalData);
    }
    return viewType.replace(/\{(0|1)\}/, `${additionalData?.month} ${additionalData?.day}`);
};

const getFromToView = (viewType, elementValues, t, singleViewFunc) => {
    let view = viewType;
    Object.values(elementValues).forEach((element) => {
        view = singleViewFunc(view, element, t);
    });
    return view;
};

const getFromToViewWithAdditionalData = (viewType, elementValues, additionalData, singleViewFunc) => {
    let view = viewType;
    Object.values(elementValues).forEach((element, i) => {
        view = singleViewFunc(view, element, additionalData ? additionalData[i] : '');
    });
    return view;
};

const getFormViewWithDataValue = (viewType, { Data }) => {
    return viewType.replace(/\{(0|1)\}/, isNil(Data.Value) ? 1 : Data.Value);
};

const getFormViewWithValueLabel = (viewType, value) => {
    return viewType.replace(/\{0}/, value ? value.label : '');
};

const getMultiSelectView = (viewType, additionalData) => {
    let result = '';

    isArray(additionalData) &&
        additionalData.forEach((data, i) => {
            result += `${data?.label}` + (i !== additionalData.length - 1 ? `, ` : '');
        });
    return viewType.replace(/\{0}/, result);
};

const getTreeFormView = (viewType, elementValues) => {
    let result = '';
    const { CascadeFiltersList } = elementValues.Data;
    const data = last(CascadeFiltersList);
    isArray(data) &&
        data.forEach(({ DisplayName }, index) => {
            result += `${DisplayName}${index !== data.length - 1 ? ', ' : ''}`;
        });
    return viewType.replace(/\{0}/, result);
};

function getFiltersElementView(t, item, additionalData) {
    const { elementType, Value: elementValues, comparison, parent } = item;

    const viewType = t(`Comparision_${comparison.comparison}`, {
        filterName: parent.placeholder,
    });

    switch (elementType) {
        case 0:
            return getFormViewWithDataValue(viewType, elementValues);
        case 1: {
            return getFormViewWithValueLabel(viewType, additionalData);
        }
        case 3:
            return getStaticSingleData(viewType, elementValues, additionalData);
        case 4:
            return getFromToViewWithAdditionalData(viewType, elementValues, additionalData, getStaticSingleData);
        case 5:
            return getFormViewWithDataValue(viewType, elementValues);
        case 6:
            return getFromToView(viewType, elementValues, null, getFormViewWithDataValue);
        case 7: {
            const { source } = comparison;
            const { DisplayName, Value } = JSON.parse(source).find((obj) => obj.Value === elementValues.Data.Value);

            return getFormViewWithValueLabel(viewType, {
                label: DisplayName,
                valueShouldBeSend: Value,
            });
        }
        case 8:
            return getAmountSimpleFormView(viewType, elementValues, t);
        case 9:
            return getFromToView(viewType, elementValues, t, getAmountSimpleFormView);
        case 12:
            return getMonthDayFormView(viewType, elementValues, additionalData);
        case 13:
            return getFromToViewWithAdditionalData(viewType, elementValues, additionalData, getMonthDayFormView);
        case 14:
            return getMultiSelectView(viewType, additionalData);
        case 15:
            return getTreeFormView(viewType, elementValues);
        case 16:
            return getTreeFormView(viewType, elementValues);
        case 18:
            return getStaticSingleData(viewType, elementValues, additionalData);
        case 19:
            return getFromToViewWithAdditionalData(viewType, elementValues, additionalData, getStaticSingleData);
        case 21:
            return getTreeFormView(viewType, elementValues);
        case 24:
            return getTreeFormView(viewType, elementValues);
        default:
            return viewType;
    }
}

export { addOpacity, getFiltersElementView, getFormViewWithValueLabel };
