import { isNil, keys } from 'lodash';
// Import constants
import {
    ErrorShowType,
    BonusCalculationTypes,
    l,
    MonthDayYearFormat,
    TimeFormatWithoutSeconds,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMoment } = Helpers;

const CustomFieldInputScenario = (value) => ({
    isValid: !!value || null,
    errorText: '',
    value: value || '',
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'minLength',
            ErrorMessage: l.MustContainMinimumCharacters,
            ParametersValues: '3',
        },
        {
            ValidationType: 'maxLength',
            ErrorMessage: l.MustContainMaximumCharacters,
            ParametersValues: '300',
        },
    ],
});

const ScheduleScenario = (schedule) => ({
    SchedulerStartDate: {
        isValid: null,
        value: {
            fromDate: customMoment(schedule.SchedulerStartDate).format(MonthDayYearFormat),
            fromTime: customMoment(schedule.SchedulerStartDate).format(TimeFormatWithoutSeconds),
            timeZone: schedule.TimeZone,
        },
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'fromDateBiggerThanCurrentDate',
                ErrorMessage: l.StartDateMustBeBiggerThanCurrentDate,
                ParametersValues: 'false',
            },
            {
                ValidationType: 'dateCompareThanDate',
                ErrorMessage: l.StartDateMustBeSmallerThanEndDate,
                ParametersValues: 'false',
            },
        ],
    },
    SchedulerEndDate: {
        isValid: null,
        value: {
            fromDate: customMoment(schedule.SchedulerStartDate).format(MonthDayYearFormat),
            toDate: customMoment(schedule.SchedulerEndDate).format(MonthDayYearFormat),
            fromTime: customMoment(schedule.SchedulerStartDate).format(TimeFormatWithoutSeconds),
            timeZone: schedule.TimeZone,
        },
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'toDateBiggerThanCurrentDate',
                ErrorMessage: l.EndDateMustBeBiggerThanCurrentDate,
                ParametersValues: 'false',
            },
            {
                ValidationType: 'dateCompareThanDate',
                ErrorMessage: l.EndDateMustBeBiggerThanStartDate,
                ParametersValues: 'false',
            },
        ],
    },
    SchedulerStartTime: {
        isValid: null,
        value: schedule.SchedulerStartDate.fromTime,
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'startTimeValidation',
                ErrorMessage: l.InvalidTime,
            },
        ],
    },
});

const CollectAnalysisByDateScenario = (property) => ({
    CollectDate: {
        isValid: null,
        value: property.CollectDate,
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'dateCompareThanDate',
                ErrorMessage: property.ErrorMessage,
                ParametersValues: 'false',
            },
        ],
    },
});

const campaignValidationScenario = (data, pageType) => ({
    Name: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.CannotBeEmpty,
                ParametersValues: null,
            },
            {
                ValidationType: 'firstSymbol',
                ErrorMessage: l.NameFirstCharacterCantBeASymbol,
                ParametersValues: /^[\p{L}0-9]/gu,
            },
            {
                ValidationType: 'minLength',
                ErrorMessage: l.MustContainMinimumCharacters,
                ParametersValues: '3',
            },
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '50',
            },
        ],
    },
    Description: {
        isValid: null,
        errorText: '',
        value: '',
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: [
            {
                ValidationType: 'maxLength',
                ErrorMessage: l.MustContainMaximumCharacters,
                ParametersValues: '250',
            },
        ],
    },
    ChosenSegment: {
        isValid: null,
        value: false,
        type: ErrorShowType.Toaster,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.PleaseChooseSegment,
                ParametersValues: 'false',
            },
        ],
    },
    ChosenTemplate: {
        isValid: null,
        value: false,
        type: ErrorShowType.Toaster,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.PleaseChooseTemplate,
                ParametersValues: 'false',
            },
        ],
    },
    ChosenBonus: {
        isValid: null,
        value: false,
        type: ErrorShowType.Toaster,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.PleaseChooseBonus,
                ParametersValues: 'false',
            },
        ],
    },
});

const staticBonusCalculationScenario = (minValue, maxValue, amount) => ({
    isValid: null,
    errorText: '',
    value: isNil(amount) ? '' : amount.toString(),
    paramValue: '',
    type: ErrorShowType.Default,
    validationCredentials: [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
        {
            ValidationType: 'greaterThanOrEqual',
            ErrorMessage: l.MustBeBiggerThan,
            ParametersValues: minValue,
        },
        {
            ValidationType: 'lessThanOrEqual',
            ErrorMessage: l.MustBeSmallerThan,
            ParametersValues: maxValue,
        },
        {
            ValidationType: 'greaterThan',
            ErrorMessage: l.MustBeBiggerThan,
            ParametersValues: 0,
        },
    ],
});

const dynamicBonusCalculationBonusKindScenarioProperies = (id, value) => {
    const validationCredentials = [
        {
            ValidationType: 'required',
            ErrorMessage: l.CannotBeEmpty,
            ParametersValues: null,
        },
    ];

    if (id === 'percentage') {
        validationCredentials.push({
            ValidationType: 'greaterThan',
            ErrorMessage: l.MustBeGreaterThenZero,
            ParametersValues: 0,
        });
    }

    return {
        isValid: null,
        errorText: '',
        value: isNil(value) ? '' : value.toString(),
        paramValue: '',
        type: ErrorShowType.Default,
        validationCredentials: validationCredentials,
    };
};

const ExpirationValueScenario = (property) => ({
    expirationValue: {
        isValid: null,
        value: property,
        type: ErrorShowType.Default,
        errorText: '',
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'isDateBiggerThanCurrentDate',
                ErrorMessage: l.ExpirationDateIsInvalid,
                ParametersValues: null,
            },
            {
                ValidationType: 'isPositiveInteger',
                ErrorMessage: l.ExpirationPeriodIsInvalid,
                ParametersValues: null,
            },
        ],
    },
});

const ExpirationTypeScenario = (property) => ({
    expirationType: {
        isValid: null,
        value: property.expirationType,
        type: ErrorShowType.Default,
        errorText: l.ExpirationTypeCannotBeEmpty,
        paramValue: '',
        validationCredentials: [
            {
                ValidationType: 'required',
                ErrorMessage: l.ExpirationTypeCannotBeEmpty,
                ParametersValues: null,
            },
        ],
    },
});

function bonusCalculationScenarioProperiesCreater(data) {
    if (!isNil(data?.info)) {
        const info = data.info;
        if (data.type === BonusCalculationTypes.Static) {
            return keys(info).reduce((acc, id) => {
                acc[id + BonusCalculationTypes.Static] = {
                    ...staticBonusCalculationScenario(
                        info[id].minValue,
                        info[id].maxValue,
                        isNil(data?.amounts) ? '' : data?.amounts[id],
                    ),
                };
                return acc;
            }, {});
        } else if (data.type === BonusCalculationTypes.Dynamic) {
            return keys(info).reduce((acc, id) => {
                acc[id] = {
                    ...dynamicBonusCalculationBonusKindScenarioProperies(
                        id,
                        isNil(data?.values) ? '' : data?.values[id],
                    ),
                };
                return acc;
            }, {});
        }
    } else if (data.customField) {
        const { key, value } = data.property;
        return { [key]: CustomFieldInputScenario(value) };
    } else if (data.schedule) {
        return ScheduleScenario(data.property);
    } else if (data.collectAnalysisByDate) {
        return CollectAnalysisByDateScenario(data.property);
    } else if (data.expirationValue) {
        return ExpirationValueScenario(data.property);
    } else if (data.expirationType) {
        return ExpirationTypeScenario(data.property);
    }

    return null;
}

export { campaignValidationScenario, bonusCalculationScenarioProperiesCreater };
