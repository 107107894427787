import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
import { getStartDate, getEndDate } from 'services/dashboard';
import { Helpers } from 'services';
// Import UI Components
import { DatePickerInput, BusyLoader, BarChart, Empty, ModuleTitle, Dropdown } from '@geneui/components';
// Import Constants
import {
    MonthDayFormat,
    oneDayMilliseconds,
    validDaysCount,
    l,
    CommunicationTypeLabels,
    CommunicationTypeColors,
    AlertTypes,
    dailyViewLimitInWeeks,
} from 'constants/common';
import { getCommunicationChartOptions } from './config';

const { getMultiselectDropdownValueAndMap } = Helpers;
const { warning } = AlertTypes;

const DailyCommunicationMetricsChart = ({ parentDate }) => {
    const { doGetRequest } = useRequest();
    const { t } = useTranslation();
    const { showToaster } = useToaster();
    const { defaultChannels, defaultStatuses, communicationTypes, communicationStatuses } =
        getCommunicationChartOptions(t);
    const PartnerFirstDayOfWeek = useSelector((state) => state.partnerSettings.PartnerFirstDayOfWeek);

    const [isLoading, setIsLoading] = useState(true);
    const [communicationMetricsData, setCommunicationMetricsData] = useState(null);
    const [date, setDate] = useState(parentDate);
    const [metricsMemo, setMetricsMemo] = useState({
        startTime: 0,
        endTime: 0,
        metricsData: [],
    });
    const [selectedChannels, setSelectedChannels] = useState(defaultChannels.values);
    const [selectedStatuses, setSelectedStatuses] = useState(defaultStatuses.values);

    const chartOptions = useRef({
        channel: defaultChannels.valueMap,
        status: defaultStatuses.valueMap,
        key: 1,
    });

    const { GetDashboardCommunicationMetrics } = useMemo(
        () => ({
            GetDashboardCommunicationMetrics: DashboardHttpService.GetDashboardCommunicationMetrics(),
        }),
        [],
    );

    const getDate = (date) => [getStartDate(date[0]), getEndDate(date[1])];

    const handleDatePickerChange = (e) => {
        setDate(getDate(e));
    };

    const getCategoryName = (categoryTime, isWeekly) => {
        if (!isWeekly) {
            return moment(categoryTime).format(MonthDayFormat);
        }

        return `${moment(categoryTime.startTime).format(MonthDayFormat)}-${moment(categoryTime.endTime).format(
            MonthDayFormat,
        )}`;
    };

    const getStartAndEndDateTime = (startDateString, endDateString) => {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        return {
            startDate,
            endDate,
            startTime: startDate.getTime(),
            endTime: endDate.getTime(),
        };
    };

    const isInvalidDateRange = () => {
        const { startTime, endTime } = getStartAndEndDateTime(date[0], date[1]);

        return endTime - startTime > oneDayMilliseconds * validDaysCount;
    };

    const getCategoryList = (startDateString, endDateString) => {
        const { startDate, endTime } = getStartAndEndDateTime(startDateString, endDateString);

        let timeTrack = startDate.getTime();
        let weeksCount = 1;

        const startDay = startDate.getDay();
        const daysToNextStartDay =
            startDay >= PartnerFirstDayOfWeek
                ? 6 - (startDay - PartnerFirstDayOfWeek)
                : PartnerFirstDayOfWeek - startDay - 1;
        const weeksGroupCategories = [
            { startTime: timeTrack, endTime: timeTrack + daysToNextStartDay * oneDayMilliseconds },
        ];
        const dailyCategories = [timeTrack];

        while (timeTrack < endTime) {
            timeTrack += oneDayMilliseconds;

            const nextDate = new Date(timeTrack);

            if (nextDate.getDay() === PartnerFirstDayOfWeek) {
                const endOfDaysRange = 6;

                weeksCount += 1;
                weeksGroupCategories.push({ startTime: timeTrack, endTime: timeTrack + endOfDaysRange * oneDayMilliseconds });
            }

            dailyCategories.push(timeTrack);
        }

        const isWeekly = weeksCount > dailyViewLimitInWeeks;

        return { categories: isWeekly ? weeksGroupCategories : dailyCategories, isWeekly };
    };

    const getMetricsData = async () => {
        const { startTime, endTime } = getStartAndEndDateTime(date[0], date[1]);

        if (startTime >= metricsMemo.startTime && endTime < metricsMemo.endTime) {
            return metricsMemo.metricsData.filter((data) => {
                const nextDateTime = new Date(data.Date).getTime();

                return nextDateTime >= startTime && nextDateTime <= endTime;
            });
        }

        setIsLoading(true);

        const { Data } = await doGetRequest(GetDashboardCommunicationMetrics.request, {
            queryString: { startDate: date[0], endDate: date[1] },
            successCallback: (metricsData) => {
                setMetricsMemo({
                    startTime: startTime,
                    endTime: endTime,
                    metricsData,
                });
                setIsLoading(false);
            },
        });

        return Data;
    };

    const getSeries = (metricsData, chartOptions) => {
        const { categories, isWeekly, status, channel } = chartOptions;
        const { successSeries, failedSeries } = metricsData.reduce(
            (acc, item) => {
                if (!status[+item.Success] || !channel[item.Channel]) {
                    return acc;
                }

                const isBothStatusFilterSelected = status[0] && status[1];
                const statusLabel = item.Success ? l.Success : l.Failed;
                const targetInfo = acc.arrayInfo[`${item.Channel}_${statusLabel}`];
                const nextDate = new Date(item.Date);
                const targetSeries = item.Success ? acc.successSeries : acc.failedSeries;
                // note: index can't be -1, if -1 then selected date and received data not matched
                const indexOfDate = categories.findIndex((categoryTime) => {
                    const nextTime = nextDate.getTime();

                    if (isWeekly) {
                        return categoryTime.startTime <= nextTime && nextTime <= categoryTime.endTime;
                    }

                    return nextTime === categoryTime;
                });

                if (targetInfo) {
                    targetSeries[targetInfo.index].data[indexOfDate] += item.Count;
                } else {
                    const newItem = {
                        name: `${t(CommunicationTypeLabels[item.Channel])} ${t(statusLabel)}`,
                        data: new Array(categories.length).fill(0),
                        ...(isBothStatusFilterSelected && { stack: statusLabel }),
                        color: CommunicationTypeColors[item.Channel],
                    };

                    newItem.data[indexOfDate] += item.Count;
                    acc.arrayInfo[`${item.Channel}_${statusLabel}`] = { index: targetSeries.length };
                    targetSeries.push(newItem);
                }

                return acc;
            },
            { arrayInfo: {}, successSeries: [], failedSeries: [] },
        );

        return [...successSeries, ...failedSeries];
    };

    const getAndSetCommunicationMetricsData = async () => {
        if (isInvalidDateRange()) {
            return showToaster(warning, l.DateRangeThreeMonthWarning);
        }

        const metricsData = await getMetricsData();
        const { categories, isWeekly } = getCategoryList(date[0], date[1]);

        chartOptions.current.categories = categories;
        chartOptions.current.isWeekly = isWeekly;

        const series = getSeries(metricsData, chartOptions.current);

        setCommunicationMetricsData({
            categories: categories.map((category) => getCategoryName(category, isWeekly)),
            series,
        });
        setIsLoading(false);
    };

    const rePaintChart = () => {
        const series = getSeries(metricsMemo.metricsData, chartOptions.current);

        chartOptions.current.key += 1;

        setCommunicationMetricsData({
            ...communicationMetricsData,
            series,
        });
    };

    const handleStatusChange = (selections) => {
        const { values, valueMap } = getMultiselectDropdownValueAndMap(selections);

        if (isEmpty(selections)) {
            showToaster(warning, t(l.SelectAtLeastOneUnit));
        }

        chartOptions.current.status = valueMap;

        setSelectedStatuses(values);
        rePaintChart();
    };

    const handleChannelChange = (selections) => {
        const { values, valueMap } = getMultiselectDropdownValueAndMap(selections);

        if (isEmpty(selections)) {
            showToaster(warning, t(l.SelectAtLeastOneUnit));
        }

        chartOptions.current.channel = valueMap;

        setSelectedChannels(values);
        rePaintChart();
    };

    useEffect(() => {
        getAndSetCommunicationMetricsData(date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date[0], date[1]]);

    useEffect(() => {
        setDate(parentDate);
    }, [parentDate]);

    return (
        <>
            <div className="charts-wrap-inner-head">
                <ModuleTitle title={t(l.DailyCommunicationMetrics)} />
                <div className="campaign-metrics-wrap-c-h-d">
                    <div className="campaign-metrics-wrap-c-h-d">
                        <Dropdown
                            checkAllText={l.SelectAll}
                            data={communicationStatuses}
                            value={selectedStatuses}
                            clearable
                            isMultiSelect
                            onChange={handleStatusChange}
                        />
                        <Dropdown
                            checkAllText={l.SelectAll}
                            data={communicationTypes.map((type) => ({
                                label: t(type.label),
                                value: type.value,
                            }))}
                            value={selectedChannels}
                            clearable
                            isMultiSelect
                            onChange={handleChannelChange}
                        />
                    </div>
                    <DatePickerInput.WithRange
                        onChange={handleDatePickerChange}
                        label={t(l.TimePeriod)}
                        labelAppearance="swap"
                        value={date}
                        max={new Date()}
                    />
                </div>
            </div>
            {isLoading ? (
                <BusyLoader spinnerSize="big" isBusy={true} />
            ) : isEmpty(communicationMetricsData?.categories) ? (
                <Empty title={t(l.NoDataToDisplay)} />
            ) : (
                <div className="charts-wrap-inner-content">
                    <BarChart
                        key={chartOptions.current.key}
                        series={communicationMetricsData?.series ?? []}
                        categories={communicationMetricsData?.categories ?? []}
                        plotOptions={{
                            column: {
                                stacking: 'normal',
                            },
                        }}
                        legend={{
                            enabled: true,
                        }}
                        yAxisRest={{
                            type: 'logarithmic',
                        }}
                        xAxisRest={{
                            labels: {
                                style: {
                                    fontSize: '1.1rem',
                                    color: '#3c4043',
                                    fontWeight: '600',
                                },
                                formatter() {
                                    return this.value;
                                },
                            },
                        }}
                    />
                </div>
            )}
        </>
    );
};

DailyCommunicationMetricsChart.propTypes = {
    parentDate: PropTypes.array.isRequired,
};

export default DailyCommunicationMetricsChart;
