import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { values } from 'lodash';
// Import Components
import GeneralInfo from './GeneralInfo';
import StaticSegmentInfo from './StaticSegmentInfo';
import DynamicSegmentInfo from 'components/DynamicSegment/DynamicSegmentInfo';
// Import Constants
import { SegmentTypesLabels } from 'constants/common';

const CompositeSegmentInfoTab = ({
    name,
    description,
    type,
    numberOfCustomers,
    usedInCampaigns,
    segmentData,
    compositeConfiguration,
    segmentId,
    targetObject,
    onClickNumberOfCustomers,
}) => {
    const dynamicSegmentFiltersData = useSelector((state) => state.dynamicSegment);
    const renderSegmentInfo = () => {
        switch (type) {
            case SegmentTypesLabels.Dynamic: {
                if (values(dynamicSegmentFiltersData).length > 1) {
                    return '';
                } else {
                    return <DynamicSegmentInfo />;
                }
            }
            case SegmentTypesLabels.Static:
                return <StaticSegmentInfo staticSegmentInfoData={segmentData} />;
            default:
                return '';
        }
    };

    return (
        <>
            <GeneralInfo
                name={name}
                description={description}
                type={type}
                numberOfCustomersData={numberOfCustomers}
                usedInCampaigns={usedInCampaigns}
                compositeConfiguration={compositeConfiguration}
                segmentId={segmentId}
                targetObject={targetObject}
                onClickNumberOfCustomers={onClickNumberOfCustomers}
            />
            {renderSegmentInfo()}
        </>
    );
};

CompositeSegmentInfoTab.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.number,
    numberOfCustomers: PropTypes.object,
    usedInCampaigns: PropTypes.array,
    segmentData: PropTypes.object,
    compositeConfiguration: PropTypes.object,
    segmentId: PropTypes.number,
    targetObject: PropTypes.number,
    onClickNumberOfCustomers: PropTypes.func,
};

export default memo(CompositeSegmentInfoTab);
