export default (
    state = {
        signInForm: {
            isLoading: false,
            errorMessage: '',
        },
    },
    action,
) => {
    switch (action.type) {
        case 'SIGN_IN_FETCH':
            state.signInForm.isLoading = true;
            state.signInForm.errorMessage = '';
            return Object.assign({}, state, {});
        case 'SIGN_IN_SUCCESSED':
            state.signInForm.isLoading = false;
            return Object.assign({}, state, {});
        case 'SIGN_IN_FAILED':
            state.signInForm.isLoading = false;
            state.signInForm.errorMessage = action.payload;
            return Object.assign({}, state, {});
        default:
            return state;
    }
};
