import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import configs
import { campaignsInActionColumnsConfigs, campaignsSectionRadioGroupOptionsConfigs } from './config';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
// Import UI Components
import { RadioGroup, ModuleTitle } from '@geneui/components';
import { DataTable } from 'components';
// Import Constants
import { l } from 'constants/common';

let getDashboardCampaignInActionObj;

const CampaignsInActionList = () => {
    const { doGetRequest } = useRequest();
    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { campaignsInActionColumns } = useMemo(() => campaignsInActionColumnsConfigs(t), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { campaignsSectionRadioGroupOptions } = useMemo(() => campaignsSectionRadioGroupOptionsConfigs(t), []);

    const [isLoadingCampaignsInAction, setIsLoadingCampaignsInAction] = useState(false);
    const [campaignsIinActionData, setCampaignsIinActionData] = useState([]);
    const [radioGroupButtonValue, setRadioGroupButtonValue] = useState(campaignsSectionRadioGroupOptions[1].value);

    const { getServicesRequest } = useRef({
        getServicesRequest: DashboardHttpService.getServices,
    }).current;

    const getCampaignsInActionTableColumns = () => {
        return campaignsInActionColumns;
    };

    const getCampaignsInAction = () => {
        setIsLoadingCampaignsInAction(true);
        if (!isNil(getDashboardCampaignInActionObj)) {
            getDashboardCampaignInActionObj.cancel('CampaignsInActionList:getServicesRequest');
        }
        getDashboardCampaignInActionObj = getServicesRequest();
        doGetRequest(getDashboardCampaignInActionObj.request, {
            queryString: radioGroupButtonValue,
            successCallback: (Data) => {
                setCampaignsIinActionData(
                    Data.map(({ Name, CreatorName, Type, Status, ObjectId, Hasbonus }, index) => {
                        return {
                            hasHover: true,
                            dragDisable: true,
                            data: {
                                index,
                                isRowOpend: false,
                                Title: { Name, ObjectId, Hasbonus, Type, Status },
                                CreatorName,
                                Type,
                                TypeStatus: { Type, Status },
                            },
                        };
                    }),
                );
            },
        }).then(() => setIsLoadingCampaignsInAction(false));
    };

    const handleRadioButtonChange = (e) => {
        setRadioGroupButtonValue(e.target.value);
    };

    useEffect(getCampaignsInAction, [radioGroupButtonValue]);

    return (
        <div className="campaign-action-metrics-wrap-column">
            <div className="campaign-action-wrap-column-head for-crs-holder-to-button-style">
                <ModuleTitle title={t(l.CampaignsInAction)} />

                <RadioGroup
                    type="tab"
                    options={campaignsSectionRadioGroupOptions}
                    value={radioGroupButtonValue}
                    onChange={handleRadioButtonChange}
                />
            </div>
            <div className="campaign-action-wrap-column-content">
                <DataTable
                    columnKey="dataKey"
                    rowKey="index"
                    data={campaignsIinActionData}
                    dataCount={campaignsIinActionData?.length}
                    columns={getCampaignsInActionTableColumns()}
                    isLoading={isLoadingCampaignsInAction}
                    isVisibleOnlyDataTable={true}
                    withPagination={false}
                    withPageSelector={false}
                />
            </div>
        </div>
    );
};

export default CampaignsInActionList;
