import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Dropdown, Icon, Tag } from '@geneui/components';
// Import components
import { RandomComponentWithHeadline } from 'components';
//Import constants
import { l, ColorVars } from 'constants/common';

const ProvidersSelector = ({ selectedEmails, selectedFtps, setSelectedEmails, setSelectedFtps, ftps, emails }) => {
    const { t } = useTranslation();
    const pageValidation = useSelector((state) => state.pageValidation);

    const [selectedEmailsValues, setSelectedEmailsValues] = useState([]);
    const [selectedFtpsValues, setSelectedFtpsValues] = useState([]);

    const mapToValues = (list) => {
        return list.map(({ value }) => value);
    };

    const deleteChosenEmail = (e) => {
        const filteredSelectedValues = selectedEmails.filter((val) => +val.value !== +e);
        setSelectedEmails(filteredSelectedValues);
    };

    const deleteChosenFtp = (e) => {
        const filteredSelectedValues = selectedFtps.filter((val) => +val.value !== +e);
        setSelectedFtps(filteredSelectedValues);
    };

    useEffect(() => {
        setSelectedEmailsValues(mapToValues(selectedEmails));
    }, [selectedEmails]);

    useEffect(() => {
        setSelectedFtpsValues(mapToValues(selectedFtps));
    }, [selectedFtps]);

    return (
        <div className="providers-wrap">
            <div className="providers-wrap-inner">
                <div className="title-wrapper">
                    <p className="title-wrapper-text">{t(l.EmailProviders)}</p>
                    <span className="title-wrapper-line" />
                </div>
                <div className="providers-wrap-row">
                    <Dropdown
                        data={emails}
                        value={selectedEmailsValues}
                        isMultiSelect={true}
                        onChange={(values) => setSelectedEmails(values)}
                        isValid={pageValidation?.EmailProviders?.isValid}
                        errorText={pageValidation?.EmailProviders?.errorText}
                    />
                    <RandomComponentWithHeadline className={'crm-form-single-input-equal'}>
                        <div className={'crm-multi-select-country-section-body'}>
                            <div className="crm-multi-select-country-inner">
                                <p>{t(l.SelectedItems)}</p>
                                {selectedEmails &&
                                    selectedEmails.map(({ label, value, isUnavailable }) => (
                                        <Tag
                                            name={label}
                                            key={value}
                                            appearance="minimal"
                                            cornerRadius="smooth-radius"
                                            className={'crm-multi-select-country-section__tag'}
                                            color={isUnavailable ? ColorVars.danger : ''}
                                            icons={
                                                <Icon type={'bc-icon-clear'} onClick={() => deleteChosenEmail(value)} />
                                            }
                                        />
                                    ))}
                            </div>
                        </div>
                    </RandomComponentWithHeadline>
                </div>
            </div>
            <div className="providers-wrap-inner">
                <div className="title-wrapper">
                    <p className="title-wrapper-text">{t(l.FTPProviders)}</p>
                    <span className="title-wrapper-line" />
                </div>
                <div className="providers-wrap-row">
                    <Dropdown
                        data={ftps}
                        value={selectedFtpsValues}
                        isMultiSelect={true}
                        onChange={(values) => setSelectedFtps(values)}
                    />
                    <RandomComponentWithHeadline className={'crm-form-single-input-equal'}>
                        <div className={'crm-multi-select-country-section-body'}>
                            <div className="crm-multi-select-country-inner">
                                <p>{t(l.SelectedItems)}</p>
                                {selectedFtps &&
                                    selectedFtps.map(({ label, value }) => (
                                        <Tag
                                            name={label}
                                            key={value}
                                            appearance="minimal"
                                            cornerRadius="smooth-radius"
                                            className={'crm-multi-select-country-section__tag'}
                                            icons={
                                                <Icon type={'bc-icon-clear'} onClick={() => deleteChosenFtp(value)} />
                                            }
                                        />
                                    ))}
                            </div>
                        </div>
                    </RandomComponentWithHeadline>
                </div>
            </div>
        </div>
    );
};

ProvidersSelector.propTypes = {
    emails: PropTypes.array,
    ftps: PropTypes.array,
    selectedEmails: PropTypes.array,
    selectedFtps: PropTypes.array,
    setSelectedEmails: PropTypes.func,
    setSelectedFtps: PropTypes.func,
};

ProvidersSelector.defaultProps = { selectedEmails: [], selectedFtps: [] };

export default memo(ProvidersSelector);
