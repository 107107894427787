import {
    CRMRequestService,
    CRMGalleryRequestService,
    getCancelableRequest,
    postCancelableRequest,
    deleteCancelableRequest,
} from './http.config';
// Import constants
import { TemplateTypesLabels } from 'constants/common';

const TemplateHttpService = {
    getTemplate: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ templateId, isClone }) => `/Template/Get?id=${templateId}&isClone=${isClone}`,
        });
    },
    getTemplateWithChilds: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ templateId, isClone }) => `/Template/GetMulti?id=${templateId}&isClone=${isClone}`,
        });
    },
    getCampaignsByTemplate: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ templateId }) => `/Template/CampaignsByTemplateId?templateId=${templateId}`,
        });
    },
    getTemplateList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Template/List',
        });
    },
    editTemplateMulti: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Template/UpdateMulti',
        });
    },
    deleteTemplate: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ templateId }) => `/Template/Delete?id=${templateId}`,
        });
    },
    deleteTemplates: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Template/Delete/`,
        });
    },
    archiveTemplate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ templateId }) => `/Template/Archive?id=${templateId}`,
        });
    },
    archiveTemplates: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Template/Archive`,
        });
    },
    unarchiveTemplate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ templateId }) => `/Template/Unarchive?id=${templateId}`,
        });
    },
    createTemplate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Template/CreateMulti`,
        });
    },
    unarchiveTemplates: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Template/unArchive`,
        });
    },
    editTemplate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Template/UpdateMulti`,
        });
    },
    getTemplateModels: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ channel, eventId }) => `/Template/GetTemplateModels?channel=${channel}&eventId=${eventId}`,
            defaultParams: { eventId: 0, channel: TemplateTypesLabels.Email },
        });
    },
    getTemplateModelsById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ templateId }) => `/Template/GetTemplateModelsById?templateId=${templateId}`,
        });
    },
    testSendTemplate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Template/Send',
        });
    },
    getTemplateById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ templateId, isClone }) => `/Template/GetMulti?id=${templateId}&isClone=${isClone}`,
        });
    },
    getSMSPartsCount: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ message }) => `/Template/GetSmsPartsCount?message=${message}`,
        });
    },
    getMaxSMSPartsCount: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/PartnerSettings/GetMaxSmsPartsCount',
        });
    },
    getAssets: () => {
        return getCancelableRequest({
            requestService: CRMGalleryRequestService,
            endpointPath: () => `/List/Image`,
        });
    },
    addAsset: () => {
        return postCancelableRequest({
            requestService: CRMGalleryRequestService,
            endpointPath: () => 'Save/Image',
        });
    },
    getShorterAndTrackerSettings: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ deliveryMethod }) =>
                `/Template/GetShorterAndTrackerSettings?deliveryMethod=${deliveryMethod}`,
        });
    },
    testTemplate: () => {},
};
export default TemplateHttpService;
