import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
// Import UI Components
import { Copy, Icon, Tooltip } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const PartnerRow = ({ data }) => {
    const { t } = useTranslation();
    const copyableContentRef = useRef({});
    const statusTooltipText = data.IsCrmActivated ? l.PartnerActiveTooltipText : l.PartnerNotActiveTooltipText;

    return (
        <>
            <Tooltip key={data.label} title={isString(data.label) ? data.label : ''}>
                <span className={`crm-option-column-${data.value} ellipsis-text`}>{data.label}</span>
            </Tooltip>

            <div className="partner-row-elements-container">
                {copyableContentRef.current && (
                    <Copy
                        key={data.value}
                        size="small"
                        copiedTooltipText={t(l.IdCopied)}
                        contentRef={copyableContentRef}
                        showOnHover={true}
                    />
                )}
                <p
                    ref={(node) => {
                        if (node) {
                            copyableContentRef.current = node;
                        }
                    }}
                >
                    {data.value}
                </p>
                <Tooltip text={t(statusTooltipText)}>
                    <Icon
                        type="bc-icon-status-circle"
                        className={`${data.IsCrmActivated ? 'partner-active' : 'partner-not-active'}`}
                    />
                </Tooltip>
            </div>
        </>
    );
};

PartnerRow.propTypes = {
    data: PropTypes.object,
    id: PropTypes.number,
};

export default memo(PartnerRow);
