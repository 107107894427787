import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Import UI components
import { Dropdown, Checkbox, Button, Icon, Tooltip } from '@geneui/components';
// Import components
import { UploadFile } from 'components';
// Import constants
import { l } from 'constants/common';
import { useSelector } from 'react-redux';

const StaticSegmentGeneralDetails = ({
    clientFields,
    clientFieldId,
    fileName,
    handleFormSubmit,
    handleIsIgnoreInvalidsChange,
    handleIsIncludeDuplicatesChange,
    handleClientFieldsChange,
    handleFileInputChange,
    isDisableUploadBtn,
    isDisableCheckBoxes,
    isDisableFormFields,
    fileRef,
}) => {
    const { t } = useTranslation();
    const { fileName: fileNameValidation } = useSelector((state) => state.pageValidation);

    const formSubmitHandler = (e) => {
        e.preventDefault();
        handleFormSubmit(e);
    };

    const fileInputChangeHandler = (e) => {
        handleFileInputChange(e);
    };

    const clientFieldsChangeHandler = (e) => {
        handleClientFieldsChange(e);
    };

    const isIgnoreInvalidsChangeHandler = (e) => {
        handleIsIgnoreInvalidsChange(e);
    };

    const isIncludeDuplicatesChangeHandler = (e) => {
        handleIsIncludeDuplicatesChange(e);
    };

    return (
        <div className="form-row-wrap">
            <div className="form-row">
                <div className="module-header cr-position-radius p-top s-big">
                    <div className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text">
                                <div className="crm-statics-segment-data-table-name">
                                    <h3>{t(l.GeneralDetails)}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={formSubmitHandler} className="form-row-inner">
                    <div className="crm-gen-details-uploader">
                        <UploadFile
                            onChange={fileInputChangeHandler}
                            value={fileName}
                            disabled={isDisableFormFields}
                            errorText={fileNameValidation?.errorText}
                            inputRef={fileRef}
                        />
                    </div>

                    <Tooltip text={t(l.TooltipStaticSegmentUploadFile)} position="bottom">
                        <Icon type="bc-icon-info" />
                    </Tooltip>

                    <Dropdown
                        disabled={isDisableFormFields}
                        appearance="outline"
                        noDataWithImage
                        data={clientFields}
                        noDataText={t(l.NoDataFound)}
                        labelAppearance="swap"
                        placeholder={t(l.ClientField)}
                        onChange={clientFieldsChangeHandler}
                        value={clientFieldId}
                        hasSearch={false}
                    />

                    <Tooltip text={t(l.TooltipClientField)} position="bottom">
                        <Icon type="bc-icon-info" onClick={() => {}} />
                    </Tooltip>

                    <Button
                        className="crm-gen-details-submit"
                        flexibility="content-size"
                        type="submit"
                        disabled={isDisableUploadBtn}
                    >
                        {t(l.UploadFile)}
                    </Button>

                    {!isDisableCheckBoxes && (
                        <div className="crm-gen-details-check-group">
                            <Checkbox label={t(l.IgnoreInvalids)} onChange={isIgnoreInvalidsChangeHandler} />
                            <Tooltip text={t(l.TooltipIgnoreInvalids)} position="bottom">
                                <Icon type="bc-icon-info" />
                            </Tooltip>
                            <Checkbox label={t(l.IncludeDuplicates)} onChange={isIncludeDuplicatesChangeHandler} />
                            <Tooltip text={t(l.TooltipIncludeDuplicates)} position="bottom">
                                <Icon type="bc-icon-info" />
                            </Tooltip>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

StaticSegmentGeneralDetails.propTypes = {
    clientFields: PropTypes.array,
    clientFieldId: PropTypes.string,
    fileName: PropTypes.string,
    isDisableUploadBtn: PropTypes.bool,
    isDisableCheckBoxes: PropTypes.bool,
    isDisableFormFields: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
    handleIsIgnoreInvalidsChange: PropTypes.func,
    handleIsIncludeDuplicatesChange: PropTypes.func,
    handleClientFieldsChange: PropTypes.func,
    handleFileInputChange: PropTypes.func,
    fileRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

StaticSegmentGeneralDetails.defaultProps = {
    clientFields: null,
    isDisableUploadBtn: false,
    isDisableCheckBoxes: false,
    isDisableFormFields: false,
    handleFormSubmit: noop,
    handleIsIgnoreInvalidsChange: noop,
    handleIsIncludeDuplicatesChange: noop,
    handleClientFieldsChange: noop,
    handleFileInputChange: noop,
    fileRef: noop,
};
export default memo(StaticSegmentGeneralDetails);
