import React from 'react';
// Import UI Components
import { Icon, Tooltip } from '@geneui/components';
import { RoutesList } from 'routes';
import { TittleLink } from 'components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { l, ReportTypesLabels, ReportStateLabels, DateTimeFormat, DataTableColumnsCustomTypes } from 'constants/common';

const { REPORT_EXECUTED_LIST } = RoutesList;

const { customMomentWithoutTimezoneConversion } = Helpers;
const getReportType = (type, t) => {
    switch (type) {
        case ReportTypesLabels.Report:
            return (
                <div className="crm-segment-type-label">
                    <Icon type="bc-icon-crm-standart-report crm-segment-type-icon dynamic" />
                    <span>{t(l.Report)}</span>
                </div>
            );
        case ReportTypesLabels.CustomReport:
            return (
                <div className="crm-segment-type-label">
                    <Icon type="bc-icon-crm-custom-report crm-segment-type-icon static" />
                    <span className="ellipsis-text">{t(l.CustomReport)}</span>
                </div>
            );
        case ReportTypesLabels.CompositeReport:
            return (
                <div className="crm-segment-type-label">
                    <Icon type="bc-icon-composite-report crm-segment-type-icon static" />
                    <span className="ellipsis-text">{t(l.CompositeReport)}</span>
                </div>
            );
        case ReportTypesLabels.CustomerJourneyReport:
            return (
                <div className="crm-segment-type-label">
                    <Icon type="bc-icon-cj-report crm-segment-type-icon static" />
                    <span className="ellipsis-text">{t(l.CustomerJourneyReport)}</span>
                </div>
            );
        default:
    }
};

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.ReportId),
                dataKey: 'ReportId',
                sortable: true,
            },
            {
                text: t(l.Title),
                dataKey: 'Name',
                sortable: true,
                isPermanent: true,
                // eslint-disable-next-line react/prop-types
                colRenderer: ({ Name, ReportId, ReportType, SelfExecutionCount, isViewMode }) => {
                    const isClickable = SelfExecutionCount > 0;
                    return (
                        <div className="crm-list-column-title-icon-components">
                            <TittleLink
                                path={REPORT_EXECUTED_LIST}
                                isClickable={isClickable && !isViewMode}
                                name={Name}
                                queryParams={{ reportId: ReportId, reportType: ReportType }}
                            />
                        </div>
                    );
                },
            },
            {
                text: t(l.ReportType),
                dataKey: 'ReportType',
                sortable: true,
                colRenderer: (type) => (
                    <Tooltip text={t(l[ReportTypesLabels[type]])}>
                        <div>{getReportType(type, t)}</div>
                    </Tooltip>
                ),
            },
            {
                text: t(l.State),
                dataKey: 'IsUsed',
                sortable: false,
                colRenderer: (isUsed) => (
                    <div className={`crm-used-or-not-used-badge-cnt crm-used-or-not-used-label-${isUsed}`}>
                        {isUsed ? t(l.Used) : t(l.NotUsed)}
                    </div>
                ),
            },
            {
                text: t(l.User),
                dataKey: 'CreatedBy.Name',
                sortable: true,
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.CreatedDate),
                dataKey: 'CreatedDate',
                sortable: true,
                formatter: (CreatedDate) => {
                    return customMomentWithoutTimezoneConversion(CreatedDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.LastModifier),
                dataKey: 'ModifiedBy.Name',
                sortable: true,
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.ModifiedDate),
                dataKey: 'ModifiedDate',
                sortable: true,
                formatter: (ModifiedDate) => {
                    return customMomentWithoutTimezoneConversion(ModifiedDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Status),
                dataKey: 'State',
                sortable: true,
                colRenderer: (state) => (
                    <div className={`crm-report-state-badge-cnt crm-report-state-label-${state}`}>
                        {t(ReportStateLabels[state])}
                    </div>
                ),
            },
            {
                text: t(l.ExecutionsSelfComposite),
                dataKey: 'SelfExecutionCount',
                sortable: false,
                colRenderer: (SelfExecutionCount, i, { data }) =>
                    SelfExecutionCount + '/' + data.CompositeExecutionCount,
            },
        ],
    };
};

export { DataTableColumnsConfigs };
