import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isEmpty, isNil, noop } from 'lodash';
// Import UI components
import { BusyLoader } from '@geneui/components';
// Import Components
import GeneralInfo from './GeneralInfo';
import { SegmentActionsButtons } from 'components/SegmentList';
// Import Services
import { SegmentHttpService } from 'services/http';
// Import Hooks
import { useRequest, useQueryParams, useGoToRoute } from 'hooks';
//Import Constants
import { l, SegmentTypesLabels } from 'constants/common';
import { modalsStateSetDictKeys } from 'pages/CampaignListPage/config';
import { RoutesList } from 'routes';

const {
    SEGMENT_DYNAMIC_CLONE,
    SEGMENT_COMPOSITE_CLONE,
    CONVERT_TO_REPORT,
    SEGMENT_DYNAMIC_EDIT,
    SEGMENT_COMPOSITE_EDIT,
} = RoutesList;

const SegmentInfoModalBody = ({
    isLoading,
    segmentInfo,
    segmentInfoType,
    isPredefined,
    numberOfCustomersData,
    usedInCampaigns,
    renderSegmentInfo,
    onClickNumberOfCustomers,
    handleInfoModalActionClick,
    withActionButtons,
}) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const { goToRoute } = useGoToRoute();
    const { search } = useLocation();
    const goToRouteQueryParams = useQueryParams();

    const { getCampaignsBySegmentRequest } = useMemo(
        () => ({
            getCampaignsBySegmentRequest: SegmentHttpService.getCampaignsBySegment(),
        }),
        [],
    );

    // Actions part
    const goToConvertSegmentToReport = ({ SegmentId }) => {
        const params = { segmentId: SegmentId };
        const path = CONVERT_TO_REPORT;
        goToRoute(path, params, goToRouteQueryParams.encode({ backParams: search }));
    };

    const deleteSegmentAction = (segment) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.deleteSegment, segment);
    };

    const archiveSegmentAction = (segment) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.archiveSegment, segment);
    };

    const unArchiveSegmentAction = (segment) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.unarchiveSegment, segment);
    };

    const cloneSegmentAction = ({ Type, SegmentId }) => {
        const params = { segmentId: SegmentId };
        if (SegmentTypesLabels.Dynamic === Type) {
            goToRoute(SEGMENT_DYNAMIC_CLONE, params);
        } else {
            goToRoute(SEGMENT_COMPOSITE_CLONE, params);
        }
    };

    const goToEditSegment = ({ Type, SegmentId }) => {
        const params = { segmentId: SegmentId };
        const path = SegmentTypesLabels.Dynamic === Type ? SEGMENT_DYNAMIC_EDIT : SEGMENT_COMPOSITE_EDIT;

        goToRoute(path, params, goToRouteQueryParams.encode({ backParams: search }));
    };

    const editSegmentAction = (segment) => {
        doGetRequest(getCampaignsBySegmentRequest.request, {
            queryString: { segmentId: segment.SegmentId },
            successCallback: (Data) => {
                if (isEmpty(Data)) {
                    goToEditSegment(segment);
                } else {
                    handleInfoModalActionClick(modalsStateSetDictKeys.editSegment, {
                        objects: Data.map(({ Name, Type }) => ({ Name, Type })),
                        segment,
                    });
                }
            },
        });
    };

    return (
        <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
            {!isNil(segmentInfo) && (
                <>
                    {withActionButtons && (
                        <div className="crm-info-segment-action-buttons-container">
                            <SegmentActionsButtons
                                data={{ ...segmentInfo, Name: { Name: segmentInfo.Name } }}
                                isInfoVisible={false}
                                isViewMode={false}
                                convertToReportAction={goToConvertSegmentToReport}
                                unArchiveSegmentAction={unArchiveSegmentAction}
                                archiveSegmentAction={archiveSegmentAction}
                                deleteSegmentAction={deleteSegmentAction}
                                cloneSegmentAction={cloneSegmentAction}
                                editSegmentAction={editSegmentAction}
                            />
                        </div>
                    )}
                    <GeneralInfo
                        segmentId={segmentInfo.SegmentId}
                        targetObject={segmentInfo.TargetObject}
                        name={segmentInfo.Name}
                        description={segmentInfo.Description}
                        type={segmentInfoType}
                        isPredefined={isPredefined}
                        numberOfCustomersData={numberOfCustomersData}
                        usedInCampaigns={usedInCampaigns}
                        compositeConfiguration={segmentInfo.CompositeConfiguration}
                        onClickNumberOfCustomers={onClickNumberOfCustomers}
                    />
                    <div className="segment-modal-bottom">
                        {/*For title text can add active-state class*/}
                        <div className="title-wrapper">
                            <p className="title-wrapper-text">{t(l.SegmentInfo)}</p>
                            <span className="title-wrapper-line" />
                        </div>
                        <div className="segment-modal-b-content c-s-b-content">{renderSegmentInfo()}</div>
                    </div>
                </>
            )}
        </BusyLoader>
    );
};

SegmentInfoModalBody.propTypes = {
    segmentInfo: PropTypes.object.isRequired,
    segmentInfoType: PropTypes.number.isRequired,
    numberOfCustomersData: PropTypes.object,
    isPredefined: PropTypes.bool,
    renderSegmentInfo: PropTypes.func,
    usedInCampaigns: PropTypes.array,
    isLoading: PropTypes.bool,
    onClickNumberOfCustomers: PropTypes.func,
    handleInfoModalActionClick: PropTypes.func,
    withActionButtons: PropTypes.bool,
};

SegmentInfoModalBody.defaultProps = {
    isLoading: false,
    isPredefined: false,
    renderSegmentInfo: noop,
    usedInCampaigns: null,
    numberOfCustomersData: null,
    onClickNumberOfCustomers: noop,
    withActionButtons: false,
};

export default SegmentInfoModalBody;
