import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
// Import Constants
import { BonusTypesLabels, KPISources, l, ObjectTypesMap } from 'constants/common';
// Import UI Components
import { BusyLoader } from '@geneui/components';
// Import Components
import BonusInfoModalBody from 'components/BonusModal/BonusInfoModalBody';
import GeneralInfo from 'components/BonusModal/GeneralInfo';
// Import Services
import { Helpers } from 'services';
import { TemplateHttpService, UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';

const { getBonusSupportedCurrencies } = Helpers;

const CampaignBonusInfoModalBody = ({ campaignBonus, bonusInfo, isLoading }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();

    const [bonusCalculateAction, setBonusCalculateAction] = useState();
    const [bonusKPI, setBonusKPI] = useState();
    const [hasKind, setHasKind] = useState();
    const [internalMessageTitle, setInternalMessageTitle] = useState(null);

    const secondaryCurrencyCode = useSelector((state) => state.partnerSettings.SecondaryCurrencyId);

    const { getBonusCalculateActionsRequest, getBonusObjectsByTypeRequest, getTemplateRequest } = useMemo(
        () => ({
            getBonusCalculateActionsRequest: UtilsHttpService.getBonusCalculateActions(),
            getBonusObjectsByTypeRequest: UtilsHttpService.getBonusObjectsByType(),
            getTemplateRequest: TemplateHttpService.getTemplate(),
        }),
        [],
    );

    const init = () => {
        if (!isNil(campaignBonus?.BonusConfig)) {
            const hasKind = campaignBonus?.BonusConfig?.ObjectType === ObjectTypesMap.KPI;
            setHasKind(hasKind);
            if (campaignBonus?.BonusConfig?.ObjectType === ObjectTypesMap.FromFile) {
                setBonusKPI(l.FromFile);
            } else {
                if (hasKind) {
                    doGetRequest(getBonusObjectsByTypeRequest.request, {
                        queryString: { type: KPISources.KPI },
                        successCallback: (Data) => {
                            const kpi = Data.find(
                                (element) => +element.Id === campaignBonus?.BonusConfig?.ColumnInfoId,
                            );
                            setBonusKPI(kpi?.Name);
                        },
                    });
                } else {
                    doGetRequest(getBonusCalculateActionsRequest.request, {
                        successCallback: (Data) => {
                            const action = Data.find((element) => +element.Id === campaignBonus?.BonusConfig?.Function);
                            setBonusCalculateAction(action?.Name);
                        },
                    });
                    doGetRequest(getBonusObjectsByTypeRequest.request, {
                        queryString: { type: KPISources.Object },
                        successCallback: (Data) => {
                            const object = Data.find(
                                (element) => +element.Id === campaignBonus?.BonusConfig?.ObjectType,
                            );
                            setBonusKPI(object?.Name);
                        },
                    });
                }
            }
        }
        if (!isNil(campaignBonus?.InternalTemplateId)) {
            doGetRequest(getTemplateRequest.request, {
                queryString: { templateId: campaignBonus.InternalTemplateId },
                successCallback: (data) => {
                    setInternalMessageTitle(data?.Name);
                },
            });
        }
    };

    const isVisibleUseSecondaryCurrency = useMemo(
        () =>
            !isEmpty(secondaryCurrencyCode) &&
            (getBonusSupportedCurrencies(bonusInfo).includes(secondaryCurrencyCode) ||
                bonusInfo?.Type === BonusTypesLabels.FreeSpin),
        [secondaryCurrencyCode, bonusInfo],
    );

    useEffect(init, []);

    const cleanUp = () => {
        return () => {
            getBonusCalculateActionsRequest.cancel('CampaignBonusInfoModalBody:getBonusCalculateActionsRequest');
            getBonusObjectsByTypeRequest.cancel('CampaignBonusInfoModalBody:getBonusObjectsByTypeRequest');
            getTemplateRequest.cancel('CampaignBonusInfoModalBody:getTemplateRequest');
        };
    };

    useEffect(cleanUp, []);

    return (
        <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
            {BonusTypesLabels.NFTBonus === bonusInfo?.Type ? (
                <BonusInfoModalBody
                    id={bonusInfo?.Id}
                    title={bonusInfo?.Name}
                    description={bonusInfo?.Description}
                    type={bonusInfo?.Type}
                    startDate={bonusInfo?.StartDate}
                    endDate={bonusInfo?.EndDate}
                    state={bonusInfo?.State}
                    wallet={bonusInfo?.BalanceType}
                    bonusDetails={bonusInfo?.BonusDetails}
                    internalMessageTitle={internalMessageTitle}
                />
            ) : (
                <>
                    <GeneralInfo
                        id={bonusInfo?.Id}
                        title={bonusInfo?.Name}
                        description={bonusInfo?.Description}
                        type={bonusInfo?.Type}
                        startDate={bonusInfo?.StartDate}
                        endDate={bonusInfo?.EndDate}
                        state={bonusInfo?.State}
                        wallet={bonusInfo?.BalanceType}
                        internalMessageTitle={internalMessageTitle}
                    />
                    {!isNil(campaignBonus) &&
                        (isNil(campaignBonus.BonusConfig) ? (
                            <div className="campaign-bonus-row">
                                {isVisibleUseSecondaryCurrency && (
                                    <div className="key-value-h">
                                        <span className="ellipsis-text kv-label">
                                            {t(l.UseSecondaryCurrency, { currencyCode: secondaryCurrencyCode })}
                                        </span>
                                        <span className="ellipsis-text kv-value kv-value-css">
                                            {campaignBonus.UseSecondaryCurrency ? t(l.On) : t(l.Off)}
                                        </span>
                                    </div>
                                )}
                                {!isEmpty(campaignBonus.Amounts) && (
                                    <>
                                        <div className="title-wrapper">
                                            <p className="title-wrapper-text">{t(l.Amounts)}</p>
                                            <span className="title-wrapper-line" />
                                        </div>
                                        <div className="key-value-h-wrapper">
                                            {campaignBonus.Amounts.map(({ CurrencyCode, OriginalValue }) => {
                                                return (
                                                    <div className="key-value-h-row" key={CurrencyCode}>
                                                        <div className="key-value-h">
                                                            <span className="ellipsis-text kv-label">
                                                                {t(l.CurrencyCode)}
                                                            </span>
                                                            <span className="ellipsis-text kv-value kv-value-css">
                                                                {CurrencyCode}
                                                            </span>
                                                        </div>
                                                        <div className="key-value-h">
                                                            <span className="ellipsis-text kv-label">
                                                                {t(l.Amount)}
                                                            </span>
                                                            <span className="ellipsis-text kv-value kv-value-css">
                                                                {OriginalValue}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="key-value-holder">
                                <div className="key-value-h">
                                    <span className="ellipsis-text kv-label">{t(l.ConsiderLimits)}</span>
                                    <span className="ellipsis-text kv-value kv-value-css">
                                        {campaignBonus.BonusConfig.FixOutOfRangeBonus ? t(l.On) : t(l.Off)}
                                    </span>
                                </div>

                                {hasKind && (
                                    <div className="key-value-h">
                                        <span className="ellipsis-text kv-label">{t(l.BonusCalculateAction)}</span>
                                        <span className="ellipsis-text kv-value kv-value-css">
                                            {t(bonusCalculateAction)}
                                        </span>
                                    </div>
                                )}
                                <div className="key-value-h">
                                    <span className="ellipsis-text kv-label">
                                        {t(hasKind ? l.BonusKPI : l.BonusObjectKind)}
                                    </span>
                                    <span className="ellipsis-text kv-value kv-value-css">{t(bonusKPI)}</span>
                                </div>
                                <div className="key-value-h">
                                    <span className="ellipsis-text kv-label">{t(l.Percentage)}</span>
                                    <span className="ellipsis-text kv-value kv-value-css">
                                        {campaignBonus.BonusConfig.Percentage}
                                    </span>
                                </div>
                            </div>
                        ))}
                </>
            )}
        </BusyLoader>
    );
};

CampaignBonusInfoModalBody.propTypes = {
    campaignBonus: PropTypes.object,
    bonusInfo: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
};

export default memo(CampaignBonusInfoModalBody);
