import { Helpers } from 'services';
import { FilterComparisions, StateModesLabels, CustomersTypesLabels } from './common';

const { generateRequestFilterField } = Helpers;

const defaultDataTablePageSize = 20;
const defaultDataTablePageNumber = 1;
const defaultSortingName = 'CreatedDate';
const defaultSortingDirection = 'desc';
const defaultSortingThenName = 'CreatedDate';
const defaultSortingThenDirection = 'desc';

const generateTableDataShape = (
    defaultFilters,
    sortingName = defaultSortingName,
    sortingDirection = defaultSortingDirection,
    sortingThenName = defaultSortingThenName,
    sortingThenDirection = defaultSortingThenDirection,
) => ({
    filters: [...defaultFilters],
    paging: {
        PageSize: defaultDataTablePageSize,
        PageNumber: defaultDataTablePageNumber,
    },
    sorting: {
        Name: sortingName,
        Direction: sortingDirection,
    },
    sortingThen: {
        Name: sortingThenName,
        Direction: sortingThenDirection,
    },
});

export const reportInitialState = {
    tableData: generateTableDataShape(
        [generateRequestFilterField('State', FilterComparisions.Equals)],
        'ArchivedDate',
        'asc',
    ),
};

export const campaignInitialState = {
    tableData: generateTableDataShape(
        [generateRequestFilterField('State', FilterComparisions.Equals)],
        'ArchivedDate',
        'asc',
    ),
};

export const bonusInitialState = {
    tableData: generateTableDataShape([], 'StartDate', 'desc', 'StartDate', 'desc'),
};

export const customerJourneyInitialState = {
    tableData: generateTableDataShape(
        [generateRequestFilterField('State', FilterComparisions.Equals)],
        'ArchivedDate',
        'asc',
    ),
};

export const templateInitialState = {
    tableData: generateTableDataShape(
        [generateRequestFilterField('State', FilterComparisions.Equals)],
        'ArchivedDate',
        'asc',
    ),
};

export const segmentInitialState = {
    tableData: generateTableDataShape(
        [generateRequestFilterField('State', FilterComparisions.Equals)],
        'ArchivedDate',
        'asc',
    ),
};

export const compositeSegmentInitialState = {
    tableData: generateTableDataShape(
        [
            generateRequestFilterField('State', FilterComparisions.Equals, StateModesLabels.Live),
            generateRequestFilterField('TargetObject', FilterComparisions.Equals, CustomersTypesLabels.Customers),
        ],
        'ArchivedDate',
        'asc',
    ),
};

export const compositeReportInitialState = {
    tableData: generateTableDataShape([generateRequestFilterField('ReportType', FilterComparisions.Equals, ['0'])]),
};

export const campaignPageSegmentInitialState = {
    tableData: generateTableDataShape(
        [generateRequestFilterField('State', FilterComparisions.Equals, StateModesLabels.Live)],
        'ArchivedDate',
        'asc',
    ),
};

export const campaignPageTemplateInitialState = {
    tableData: generateTableDataShape(
        [generateRequestFilterField('State', FilterComparisions.Equals, StateModesLabels.Live)],
        'ArchivedDate',
        'asc',
    ),
};

export const campaignLiveMonitorInitialState = {
    tableData: generateTableDataShape([generateRequestFilterField('CampaignId', FilterComparisions.Equals, '')]),
};

export const communicationInitialState = {
    tableData: generateTableDataShape([], 'SentDate'),
};

export const campaignPageCommunicationInitialState = {
    tableData: generateTableDataShape([], 'SentDate'),
};

export const customerJourneyPageCommunicationInitialState = {
    tableData: generateTableDataShape([], 'SentDate'),
};

export const adminPartnerSettingsInitialState = {
    tableData: generateTableDataShape([], 'Name', 'asc'),
};

export const adminProviderInitialState = {
    tableData: generateTableDataShape([], 'EmailProviderId'),
};

export const adminPartnersListInitialState = {
    tableData: generateTableDataShape([], 'PartnerId'),
};
export const actionCampaignPageCommunicationInitialState = {
    tableData: generateTableDataShape([], 'SentDate'),
};

export const adminClassifiersListInitialState = {
    tableData: generateTableDataShape([], 'GroupId', 'desc', 'NumId', 'desc'),
};

export const adminMenuItemsListInitialState = {
    tableData: generateTableDataShape([], 'Id'),
};

// Here we de not have a default Sorting value, because it is dynamic and with ColumnIds
// We get the sorting value after doing the reportColumns request in the page
export const clientListInitialState = {
    tableData: {
        filters: [],
        paging: {
            PageSize: defaultDataTablePageSize,
            PageNumber: defaultDataTablePageNumber,
        },
        sorting: {},
    },
};
