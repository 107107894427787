/* eslint-disable jsx-a11y/no-autofocus */
import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, isEmpty, isArray, isNil, cloneDeep, has, first } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Import Constants
import { l } from 'constants/common';
// Import UI Components
import { Popover, Button, Option, Icon, Empty, Tooltip } from '@geneui/components';
// Import Components
import ChildOption from './ChildOption';
import ParentOption from './ParentOption';
// Import Hooks
import { useOnClickOutside } from 'hooks';
// Import SCSS
import 'assets/scss/cascadeDropdown.scss';

const findPrevParent = (pathList, list) => {
    let result = list;

    pathList.forEach((path) => {
        result = list[path].children;
    });

    return result;
};

const iterateOptions = (data, options, parentLabel, parentValue) => {
    data.forEach((option) => {
        if (has(option, 'children')) {
            iterateOptions(option.children, options, option.label, option.value);
        } else {
            option.parentLabel = parentLabel;
            option.parentValue = parentValue;
            options.push(option);
        }
    });
};

const CascadeDropDown = ({ data, value, onChange, isDisabled, valueComparator }) => {
    const { t } = useTranslation();

    const cascadeDropdownRef = useRef(null);
    const valueInputRef = useRef(null);
    const searchHandlerTimeout = useRef(null);

    const [isOpened, setIsOpened] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [searchedList, setSearchedList] = useState([]);
    const [isVisibleSearchedList, setIsVisibleSearchedList] = useState(false);
    const [childOptions, setChildOptions] = useState([]);
    const [isChildrenListVisible, setIsChildrenListVisible] = useState(false);
    const [visibleChildrenList, setVisibleChildrenList] = useState([]);
    const [visibleChildrenListBackText, setVisibleChildrenListBackText] = useState('');
    const [selectionPath, setSelectionPath] = useState([]);

    const visibleChildrenListBackTextBuilder = (isRemove, label) => {
        let backText =
            !isNil(visibleChildrenListBackText) && !isEmpty(visibleChildrenListBackText)
                ? `${visibleChildrenListBackText} / `
                : '';

        if (isRemove) {
            let newBackText = visibleChildrenListBackText.split('/');
            newBackText.pop();
            backText = newBackText.toString().replaceAll(',', '/');
        } else {
            backText += `${label}`;
        }

        return backText;
    };

    const open = () => {
        if (isOpened) return;
        setIsOpened(true);
        valueInputRef.current.focus();
        valueInputRef.current.selectionStart = selectedValue?.label?.length || 0;
    };

    const close = () => {
        setIsOpened(false);
        setIsChildrenListVisible(false);
        setSelectionPath([]);
        setVisibleChildrenListBackText('');
    };

    const toggleDropdown = () => {
        if (isOpened) {
            close();
        } else {
            open();
        }
    };

    const openChildren = (label, value, children = [], index) => {
        return () => {
            setSelectionPath([...selectionPath, index]);
            setVisibleChildrenListBackText(visibleChildrenListBackTextBuilder(false, label));
            setVisibleChildrenList(children);
            setIsChildrenListVisible(true);
        };
    };

    const goPrevParent = () => {
        let newSelectionPath = cloneDeep(selectionPath);
        newSelectionPath.pop();
        if (newSelectionPath.length === 0) {
            setIsChildrenListVisible(false);
        }
        setVisibleChildrenListBackText(visibleChildrenListBackTextBuilder(true));
        setSelectionPath(newSelectionPath);
        setVisibleChildrenList(findPrevParent(newSelectionPath, data));
    };

    const onChangeHandler = useCallback((value) => {
        // TODO: need support array for multilevel selected value (like selectionPath)
        onChange(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // setSearchValue(visibleChildrenListBackTextBuilder(false, value?.label));

        setSearchValue(selectedValue?.label);

        close();
    }, [selectedValue]);

    const searchHandler = ({ currentTarget }) => {
        const searchString = currentTarget.value;
        setSearchValue(searchString);

        clearTimeout(searchHandlerTimeout.current);

        searchHandlerTimeout.current = setTimeout(() => {
            setSearchedList(
                childOptions.filter(({ label }) => label.toLowerCase().includes(searchString.toLowerCase())),
            );
        }, 300);
    };

    useEffect(() => {
        setIsVisibleSearchedList(
            searchValue?.toLowerCase() !== selectedValue?.label?.toLowerCase() && !isEmpty(searchValue),
        );
    }, [searchValue, selectedValue]);

    const init = () => {
        setSelectedValue(value);
    };

    useState(init, []);

    useEffect(() => {
        let _childOptions = [];
        iterateOptions(data, _childOptions);
        setChildOptions(_childOptions);
    }, [data]);

    useOnClickOutside(
        cascadeDropdownRef,
        useCallback(() => {
            if (isOpened) {
                setSearchValue(selectedValue?.label);
                close();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isOpened]),
    );

    const contentBuilder = (list) => {
        return list.map(({ label, value, children, parentValue, parentLabel }, optionIndex) => {
            const isHasChildren = isArray(children) && !isEmpty(children);
            const isOptionSelected = valueComparator(selectedValue, value, parentValue);

            return isHasChildren ? (
                <ParentOption
                    key={optionIndex}
                    label={label}
                    value={value}
                    optionIndex={optionIndex}
                    childrenList={children}
                    onSelect={openChildren}
                />
            ) : (
                <ChildOption
                    key={optionIndex}
                    label={label}
                    value={value}
                    parentValue={parentValue}
                    parentLabel={parentLabel}
                    onSelect={onChangeHandler}
                    isSelected={isOptionSelected}
                />
            );
        });
    };

    useEffect(() => {
        if (isNil(value)) return;
        const newSelectedValue = first(
            childOptions.filter((cValue) => valueComparator(value, cValue?.value, cValue?.parentValue)),
        );
        if (!isNil(newSelectedValue)) {
            setSelectedValue({ ...value, label: newSelectedValue.label, parentLabel: newSelectedValue.parentLabel });
            onChangeHandler(newSelectedValue);
        }
    }, [childOptions, onChangeHandler, selectedValue, value, valueComparator]);

    return (
        <div ref={cascadeDropdownRef} className={classNames('crm-cascade-dropdown', { active: isOpened })}>
            <div className="crm-cascade-dropdown-input">
                <input ref={valueInputRef} onFocus={open} onChange={searchHandler} value={searchValue} />
                <Button appearance="minimal" icon="bc-icon-arrow-down" onClick={toggleDropdown} />
            </div>
            {isOpened && (
                <Popover
                    isOpen={isOpened}
                    extendTargetWidth={false}
                    maxHeight={206}
                    height={206}
                    scrollbarNeeded={true}
                >
                    <div className="crm-cascade-dropdown-content">
                        {isVisibleSearchedList ? (
                            contentBuilder(searchedList)
                        ) : isEmpty(data) ? (
                            <Empty
                                title={t(l.NoDataToDisplay)}
                                appearance="greyscale"
                                withImage={true}
                                className="absolute"
                                type="image"
                                size="small"
                            />
                        ) : isChildrenListVisible ? (
                            <div>
                                <Tooltip text={visibleChildrenListBackText} position="right">
                                    <Option
                                        title={visibleChildrenListBackText}
                                        onClick={goPrevParent}
                                        leftCustomElement={<Icon type="bc-icon-arrow-left" />}
                                        border="bottom"
                                    />
                                </Tooltip>

                                {contentBuilder(visibleChildrenList)}
                            </div>
                        ) : (
                            contentBuilder(data)
                        )}
                    </div>
                </Popover>
            )}
        </div>
    );
};

CascadeDropDown.propTypes = {
    value: PropTypes.string.isRequired, // TODO value prop should be optional
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.arrayOf(
                PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.any.isRequired }).isRequired,
            ).isRequired,
        }),
    ).isRequired,
    defaultValue: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
    }),
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    valueComparator: PropTypes.func,
};

CascadeDropDown.defaultProps = {
    defaultClassNames: '',
    onChange: noop,
    isDisabled: false,
    valueComparator: noop,
};

export default memo(CascadeDropDown);
