import React, { useMemo, memo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Import UI Components
import { AdvancedSearch } from '@geneui/components';
import GlobalSearchActionsModals from './GlobalSearchActionsModals';
// Import Services
import { UtilsHttpService } from 'services/http';
import { mapToUIModel } from './service';
// Import Hooks
import {
    useAutocompleteRequest,
    useGoToRoute,
    useQueryParams,
    useRequest,
    useHasPermission,
    useGACustomEvent,
} from 'hooks';
// Import Constants
import { l } from 'constants/common';
import { getObjectTypes } from './config';

const GlobalSearch = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const { doGetRequest, doPostRequest } = useRequest();
    const partnerId = useSelector((state) => state.partnerSettings.PartnerId);
    const { goToRoute } = useGoToRoute();
    const { encode } = useQueryParams();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const [isOpen, setIsOpen] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedObjectTypes, setSelectedObjectTypes] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [openedModalData, setOpenedModalData] = useState({});
    const [isSearchLoading, setIsSearchLoading] = useState(true);
    const [isSegmentInfoModalOpen, setIsSegmentInfoModalOpen] = useState(false);
    const [isTemplateInfoModalOpen, setIsTemplateInfoModalOpen] = useState(false);
    const [isCampaignInfoModalOpen, setIsCampaignInfoModalOpen] = useState(false);
    const [isRealtimePromotionInfoModalOpen, setIsRealtimePromotionInfoModalOpen] = useState(false);
    const [isReportInfoModalOpen, setIsReportInfoModalOpen] = useState(false);
    const { logCustomEvent } = useGACustomEvent();

    const { getUsersRequest } = useMemo(
        () => ({
            getUsersRequest: UtilsHttpService.getGlobalSearchUsers(),
        }),
        [],
    );

    const onSearch = useCallback(
        (keyword) => {
            setIsOpen(true);
            setSearchKeyword(keyword);
            logCustomEvent('Global Search', 'search', 'text input', keyword);
        },
        [logCustomEvent],
    );

    const onObjectTypeSelect = useCallback((objectTypes) => {
        setSelectedObjectTypes(objectTypes.filter((o) => o.checked));
    }, []);

    const onUserSelect = useCallback((users) => {
        setSelectedUsers(users.filter((u) => u.checked));
    }, []);

    const [usersFilterSettings, setUsersFilterSettings] = useState({
        sectionNameText: t(l.FilterByUser),
        onChange: onUserSelect,
        isLoading: true,
        data: [],
    });

    const objectTypeFilterSettings = useMemo(() => {
        const allObjectTypes = getObjectTypes(t);
        const allowedObjectTypes = allObjectTypes.filter(
            ({ permissions }) => isEmpty(permissions) || hasPermission(permissions),
        );
        return { sectionNameText: t(l.FilterByObjectType), onChange: onObjectTypeSelect, data: allowedObjectTypes };
    }, [hasPermission, onObjectTypeSelect, t]);

    const modalsStateSetDict = useMemo(
        () => ({
            infoSegment: {
                key: 'infoSegment',
                fn: setIsSegmentInfoModalOpen,
            },
            infoTemplate: {
                key: 'infoTemplate',
                fn: setIsTemplateInfoModalOpen,
            },
            infoCampaign: {
                key: 'infoCampaign',
                fn: setIsCampaignInfoModalOpen,
            },
            infoRealtimePromotion: {
                key: 'infoRealtimePromotion',
                fn: setIsRealtimePromotionInfoModalOpen,
            },
            infoReport: {
                key: 'infoReport',
                fn: setIsReportInfoModalOpen,
            },
        }),
        [],
    );

    const openPopover = useCallback(() => {
        setIsOpen(true);
        if (!isOpen) {
            setIsSearchLoading(true);
        }
    }, [isOpen]);

    const closePopover = useCallback(() => {
        setIsOpen(false);
    }, []);

    const fetchSearchResult = useCallback(() => {
        setIsSearchLoading(true);
        const requestObj = getAutocompleteRequest(UtilsHttpService.getGlobalSearchResult);

        doPostRequest(requestObj.request, {
            requestBody: {
                Name: searchKeyword,
                ObjectTypes: selectedObjectTypes.map((objectType) => objectType.value),
                UserIds: selectedUsers.map((user) => user.value),
            },
            successCallback: (data) => {
                const parsedData = mapToUIModel({
                    data,
                    goToRoute,
                    encode,
                    partnerId,
                    setOpenedModalData,
                    modalsStateSetDict,
                    closePopover,
                    dispatch,
                    t,
                });

                setSearchResult(parsedData);
                setIsSearchLoading(false);
            },
            errorCallback: () => {
                setSearchResult([]);
            },
        });
    }, [
        getAutocompleteRequest,
        doPostRequest,
        searchKeyword,
        selectedObjectTypes,
        selectedUsers,
        goToRoute,
        encode,
        partnerId,
        modalsStateSetDict,
        closePopover,
        dispatch,
        t,
    ]);

    const onCloseModalHandler = (modalStateKey) => {
        modalsStateSetDict[modalStateKey].fn(false);
    };

    const fetchUsersList = useCallback(() => {
        doGetRequest(getUsersRequest.request, {
            successCallback: (data) => {
                const parsedUsers = data.map((user) => ({
                    id: user.UserId,
                    name: user.Name,
                    value: String(user.UserId),
                    checked: false,
                }));

                setUsersFilterSettings((current) => ({
                    ...current,
                    isLoading: false,
                    data: parsedUsers,
                }));
            },
            errorCallback: () => {
                setUsersFilterSettings((current) => ({
                    ...current,
                    isLoading: false,
                }));
            },
        });
    }, [doGetRequest, getUsersRequest]);

    const extendedInputConfigs = useMemo(
        () => ({
            onFocus: openPopover,
            onIconClick: openPopover,
            type: 'text',
            maxLength: 100,
            placeholder: t(l.Search),
        }),
        [openPopover, t],
    );

    useEffect(() => {
        if (isOpen && isEmpty(usersFilterSettings.data)) {
            fetchUsersList();
        }
    }, [isOpen, fetchUsersList, usersFilterSettings.data]);

    useEffect(() => {
        if (isOpen) {
            fetchSearchResult();
        } else {
            cancelRequest();
        }
    }, [searchKeyword, selectedObjectTypes, selectedUsers, isOpen, fetchSearchResult, cancelRequest]);

    const cleanUp = () => () => {
        cancelRequest();
    };

    useEffect(cleanUp, []);

    return (
        <div className="global-search-container">
            <AdvancedSearch
                isOpen={isOpen}
                onOutsideClick={closePopover}
                data={searchResult}
                position="left"
                noDataText={t(l.NoDataToDisplay)}
                isSearchLoading={isSearchLoading}
                initialData={[]}
                primaryFilterData={objectTypeFilterSettings}
                secondaryFilterData={usersFilterSettings}
                initialDataDescription={t(l.Result)}
                onSearch={onSearch}
                totalCount={searchResult.length}
                totalCountMax={19}
                totalCountText={t(l.Result)}
                extendedInputConfigs={extendedInputConfigs}
            />
            <GlobalSearchActionsModals
                isSegmentInfoModalOpen={isSegmentInfoModalOpen}
                isTemplateInfoModalOpen={isTemplateInfoModalOpen}
                isCampaignInfoModalOpen={isCampaignInfoModalOpen}
                isRealtimePromotionInfoModalOpen={isRealtimePromotionInfoModalOpen}
                isReportInfoModalOpen={isReportInfoModalOpen}
                openedModalData={openedModalData}
                modalsStateSetDict={modalsStateSetDict}
                onCloseModalHandler={onCloseModalHandler}
            />
        </div>
    );
};

export default memo(GlobalSearch);
