import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default (data) => {
    let errors = {};

    // username field validations
    if (Validator.isEmpty(data.username)) {
        errors.email = 'This field is required';
    }

    // password field validations
    if (Validator.isEmpty(data.password)) {
        errors.password = 'This field is required';
    }

    return {
        errors,
        isValid: isEmpty(errors),
    };
};
